<template>
    <div class="ds_wrapper">
        <el-form
            label-width="100px"
            :model="declaration_signature_form"
            style="text-align: right; margin-top: 20px; margin-left: 20px"
        >
            <el-form-item :label="$t('msg.SignatureStatus')">
                <span
                    v-if="this.declaration_signature_form.approveInd != 1"
                    style="font-weight: bold; font-size: large; color: red"
                    >{{$t('msg.Tobesigned')}}</span
                >
                <span
                    v-if="this.declaration_signature_form.approveInd == 1"
                    style="font-weight: bold; font-size: medium; color: red"
                    >{{$t('msg.HaveBeenSigned')}}</span
                >
            </el-form-item>
            <el-form-item :label="$t('msg.case')">
                <el-input
                    v-model="declaration_signature_form.caseNo"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.case_name')">
                <el-input
                    v-model="declaration_signature_form.caseName"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.Manager')">
                <el-input
                    v-model="declaration_signature_form.userName"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.Declaration')">
                <el-button color="green" @click="showDialog5">{{
                    $t('msg.declaration_signature_preview')
                }}</el-button>
            </el-form-item>
            <el-form-item :label="$t('msg.Applicant')">
                <el-input
                    v-model="declaration_signature_form.partyCompany1"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.AgentofTheApplicant')">
                <el-input
                    v-model="declaration_signature_form.deputyCompany1"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.TheDefendant')">
                <el-input
                    v-model="declaration_signature_form.partyCompany2"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.AgentoftheRespondent')">
                <el-input
                    v-model="declaration_signature_form.deputyCompany2"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.ArbitrationLanguage')">
                <el-input
                    v-model="declaration_signature_form.arbLanguage"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.ProcedureRules')">
                <el-input
                    v-model="declaration_signature_form.applyRule"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.TypeOfDispute')">
                <el-input
                    v-model="declaration_signature_form.disputeType"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.AmountinDispute')">
                <el-input
                    v-model="declaration_signature_form.projectQuote"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$t('msg.MySignature')">
                <el-image
                    style="width: 150px; height: 51px"
                    :src="this.src"
                ></el-image>
            </el-form-item>
            <el-form-item style="margin-bottom: 0">
                <el-checkbox v-model="declaration_signature_form.checked">{{
                    $t('msg.declaration_signature_agree_left')
                }}</el-checkbox>
                <el-link type="primary" @click="showAgreement">{{
                    $t('msg.declaration_signature_agreement')
                }}</el-link>
                <span>{{ $t('msg.declaration_signature_agree_right') }}</span>
            </el-form-item>
            <div style="display: flex; justify-content: center">
                <!-- v-if="this.declaration_signature_form.approveInd != 1" -->
                <span>
                    <el-button
                        color="#4578FF"
                        style="height: auto; display: block; margin-: 0 auto"
                        @click="acceptAssign"
                        >{{
                            $t('msg.declaration_signature_accept_assign')
                        }}</el-button
                    >
                </span>

                <!-- <el-button color="#4578FF" size="large" style="height: auto; display:block; margin-:0 auto;"  @click="acceptAssign">{{$t('msg.declaration_signature_accept_assign')}}</el-button> -->
            </div>
        </el-form>

        <!-- 声明书：廉洁办案告知书对话框 -->
        <el-dialog
            v-model="agreeVisible"
            width="500px"
            :title="$t('msg.declaration_signature_agreement')"
        >
            <div style="text-align: left">
                尊敬的仲裁员：
                <br /><br />
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    良好的职业操守和廉洁自律是合格仲裁员的基本要求，也是保证案件<br /><br />独立公正审理裁决的前提。根据《监察法》有关规定精神，仲裁员属于监<br /><br />察对象。为进一步贯彻落实中央“完善仲裁制度，提高仲裁公信力”决策部<br /><br />署，加强贸仲委仲裁员队伍建设，贸仲委郑重提示：
                </p>
                <br />
                <p style="font-weight: bold">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    请您严格遵守贸仲委《仲裁规则》《仲裁员守则》《仲裁员行为考察<br /><br />规定》等规定，忠实履行职责，恪守职业道德，规范自身行为，自觉接受<br /><br />监督，主动落实信息披露、回避制度，清正廉洁，坚持独立，公正办案，<br /><br />防止和杜绝“关系案”
                    “人情案”，积极维护贸仲委良好形象。
                </p>
                <br /><br />
                <span style="margin-left: 400px; font-weight: bold"
                    >贸仲委</span
                >
                <br />
            </div>

            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <el-button @click="closeDialog">{{
                        $t('msg.declaration_signature_dialog_close')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 声明书：检索与双方当事人及其仲裁代理人之间的利益冲突对话框 -->
        <el-dialog v-model="dialogVisible1" width="500px">
            <div style="text-align: left">
                您是否已检索与双方当事人及其仲裁代理人之间的利益冲突？
            </div>
            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <el-button @click="dialogVisible1 = false">{{
                        $t('msg.declaration_signature_dialog_no')
                    }}</el-button>
                    <el-button type="primary" @click="dialogVisible1 = false">{{
                        $t('msg.declaration_signature_dialog_yes')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 声明书：检索与同案仲裁员之间的利益冲突对话框 -->
        <el-dialog v-model="dialogVisible2" width="500px">
            <div style="text-align: left">
                您是否已检索与同案仲裁员之间的利益冲突？
            </div>
            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <el-button @click="dialogVisible2 = false">{{
                        $t('msg.declaration_signature_dialog_no')
                    }}</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">{{
                        $t('msg.declaration_signature_dialog_yes')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 声明书：确认签名对话框 -->
        <el-dialog
            v-model="dialogVisible3"
            width="500px"
            :title="$t('msg.declaration_signature_my_signature')"
        >
            <div style="text-align: left">
                <el-checkbox v-model="checked1" @change="onChange1"
                    >本人声明，本人独立于题述案双方当事人，并将公平审理案件。本人同时<br /><br />确认，就本人所知，不存在可能引起当事人对本人的独立性或公正性产生<br /><br />合理怀疑的任何事实或情况。</el-checkbox
                ><br /><br /><br /><br /><br /><br /><br />
                <el-checkbox v-model="checked2" @change="onChange2"
                    >本人声明，虽然本人认为在接受指定前无《中国国际经济贸易仲裁委员会<br /><br />仲裁规则》及《中国国际经济贸易仲裁委员会仲裁员守则》规定的必须回<br /><br />避的情形，并保证独立、公正、高效、勤勉地履行仲裁员的职责，但鉴于<br /><br />本人与当事人/当事人代理人存在如下可能引起当事人对本人公正性或独立<br /><br />性产生怀疑的情形，故给予披露：</el-checkbox
                ><br /><br /><br /><br /><br />
                <span v-if="this.checked2 === true">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4 }"
                        placeholder="请输入内容"
                        v-model="textarea"
                        @change="onChange3"
                    >
                    </el-input>
                </span>
                <span v-if="this.checked2 === false">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4 }"
                        readonly="true"
                        placeholder="请输入内容"
                        v-model="textarea"
                    >
                    </el-input>
                </span>
                <br /><br /><br />
                <span>日期：</span> {{ signatureDate }}
            </div>
            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <!-- <el-button @click="dialogVisible1 = false">{{$t('msg.declaration_signature_dialog_no')}}</el-button> -->
                    <el-button type="primary" @click="confirmSignature">{{
                        $t('msg.declaration_signature_confirm')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 声明书：签名成功对话框 -->
        <el-dialog v-model="dialogVisible4" width="500px">
            <div style="text-align: center; font-size: medium">
                {{ $t('msg.declaration_signature_success') }}
            </div>
            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <el-button type="primary" @click="dialogVisible4 = false">{{
                        $t('msg.dialog_confirm')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>
 
        <!-- 声明书：预览声明书对话框 -->
        <el-dialog v-model="dialogVisible5" width="500px" title="声明书">
            <div style="text-align: left">
                <span>案件编号：</span
                >{{ this.declaration_signature_form.caseNo }}<br /><br /><br />
                <span>案件名称：</span
                >{{ this.declaration_signature_form.caseName
                }}<br /><br /><br />
                <span>申请人：</span
                >{{ this.declaration_signature_form.partyCompany1
                }}<br /><br /><br />
                <span>申请代理人：</span
                >{{ this.declaration_signature_form.deputyCompany1
                }}<br /><br /><br />
                <span>被申请人：</span
                >{{ this.declaration_signature_form.partyCompany2
                }}<br /><br /><br />
                <span>被申请代理人：</span
                >{{ this.declaration_signature_form.deputyCompany2
                }}<br /><br /><br />
                <span style="font-weight: bold">接受指定声明</span
                ><br /><br /><br /><br />
                <el-checkbox v-model="checked0" @change="onChange1"
                    >本人确认接受指定，作为题述仲裁案之仲裁员，本人确知《中国国际经济<br /><br />贸易仲裁委员会仲裁规则》及《中国国际经济贸易仲裁委员会仲裁员守则》<br /><br />的要求，并能够按照要求履行仲裁员的职责。</el-checkbox
                ><br /><br /><br /><br />
                <span style="font-weight: bold">独立性声明</span><br /><br />
                <span
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(如果您接受指定，请在如下选项中做出选择)</span
                ><br /><br /><br /><br />
                <el-checkbox v-model="checked1" @change="onChange1"
                    >本人声明，本人独立于题述案双方当事人，并将公平审理案件。本人同时<br /><br />确认，就本人所知，不存在可能引起当事人对本人的独立性或公正性产生<br /><br />合理怀疑的任何事实或情况。</el-checkbox
                ><br /><br /><br /><br /><br /><br /><br />
                <el-checkbox v-model="checked2" @change="onChange2"
                    >本人声明，虽然本人认为在接受指定前无《中国国际经济贸易仲裁委员会<br /><br />仲裁规则》及《中国国际经济贸易仲裁委员会仲裁员守则》规定的必须回<br /><br />避的情形，并保证独立、公正、高效、勤勉地履行仲裁员的职责，但鉴于<br /><br />本人与当事人/当事人代理人存在如下可能引起当事人对本人公正性或独立<br /><br />性产生怀疑的情形，故给予披露：</el-checkbox
                ><br /><br /><br /><br /><br />
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    readonly="true"
                    placeholder="请输入内容"
                    v-model="this.declaration_signature_form.remarks"
                >
                </el-input
                ><br /><br /><br />
                <span style="font-weight: bold">披露义务</span><br /><br />
                <span
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在仲裁程序进行中，如果本人知悉新的可能引起当事人或其代理人对<br /><br />本人独立性或公正性产生怀疑的情形，本人将继续履行披露义务。</span
                ><br /><br /><br /><br /><br />
                <span style="margin-left: 250px">仲裁员签名：</span
                ><br /><br /><br /><br />
                <span style="margin-left: 350px">{{
                    this.declaration_signature_form.isignatureDate
                }}</span>
                <br />
            </div>

            <template #footer>
                <span
                    style="text-align: center; display: block"
                    class="dialog-footer"
                >
                    <el-button @click="dialogVisible5 = false">{{
                        $t('msg.declaration_signature_dialog_close')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'DeclarationSignature',
    data() {
        return {
            loading: false,
            case_id: '',
            src: '',
            textarea: '',
            iSignatureDate: '',
            agreeVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            dialogVisible5: false,
            checked0: false,
            checked1: false,
            checked2: false,
            has_signature: false, // 有无待签字声明书 false 没有  true 有
            declaration_signature_form: {
                caseNo: '',
                caseName: '',
                userName: '',
                applyRule: '',
                partyCompany1: '',
                deputyCompany1: '',
                partyCompany2: '',
                deputyCompany2: '',
                arbLanguage: '',
                disputeType: '',
                projectQuote: '',
                checked: false,
                approveInd: '',
                check1: '',
                check2: '',
                remarks: '',
                isignatureDate: '',
                courtUserId: '',
                sign: ''
            }
        }
    },

    computed: {
        signatureDate() {
            let date = new Date()
            return (
                date.getFullYear() +
                '年' +
                (date.getMonth() + 1) +
                '月' +
                date.getDate() +
                '日'
            )
        }
    },

    methods: {
        load(case_id) {
            this.case_id = case_id
            this.GetDeclarationSignature()
        },

        showAgreement() {
            this.agreeVisible = true
        },

        async showDialog1() {
            this.dialogVisible1 = true
        },

        async showDialog2() {
            this.dialogVisible2 = true
        },

        async showDialog3() {
            this.dialogVisible3 = true
        },

        async showDialog4() {
            this.dialogVisible4 = true
        },

        async showDialog5() {
            this.dialogVisible5 = true
        },

        closeDialog() {
            this.agreeVisible = false
        },

        async GetDeclarationSignature() {
            const param = {
                req: {
                    caseId: this.case_id,
                    signatureType: 'declaration'
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest(
                'DeclarationSignature',
                param
            )
            this.loading = false
            if (res.success) {
                this.has_signature = true
                this.declaration_signature_form = res.data
                const resgfvt = await this.$API.sendRequest('GetFileViewT', {})
                // this.src = 'http://192.168.1.18/signaturePic/' + this.declaration_signature_form.sign
                // 本地测试用
                // this.src = 'http://localhost:8080/fileview/signView?req.signName='+this.declaration_signature_form.sign+'&t='+ resgfvt.data
                // 生产使用
                //this.src = 'http://www.cietacodr.org/api/fileview/signView?req.signName='+this.declaration_signature_form.sign+'&t='+ resgfvt.data
                this.src =
                    'http://192.168.163.16/api/fileview/signView?req.signName=' +
                    this.declaration_signature_form.sign +
                    '&t=' +
                    resgfvt.data
                if (this.declaration_signature_form.approveInd == 1) {
                    this.checked0 = true
                }
                if (this.declaration_signature_form.check1 == 1) {
                    this.checked1 = true
                }
                if (this.declaration_signature_form.check2 == 1) {
                    this.checked2 = true
                }
            } else {
                this.$message.error(res.msg)
            }
        },

        async confirmSignature() {
            let date = new Date()
            this.iSignatureDate =
                date.getFullYear() +
                '-' +
                (date.getMonth() + 1) +
                '-' +
                date.getDate()
            const param = {
                req: {
                    courtUserId: this.declaration_signature_form.courtUserId,
                    approveInd: 1,
                    check1: this.checked1 === true ? 1 : 2,
                    check2: this.checked2 === true ? 1 : 2,
                    remarks: this.textarea,
                    isignatureDate: this.iSignatureDate
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest(
                'ConfirmDeclarationSignature',
                param
            )
            this.loading = false
            if (res.success) {
                await this.showDialog4()
                this.dialogVisible3 = false
                this.GetDeclarationSignature()
            } else {
                this.$message.error(res.msg)
            }
        },

        async acceptAssign() {
            if (this.has_signature) {
                if (!this.declaration_signature_form.checked) {
                    this.$message.error('请先勾选《廉洁办案告知书》')
                } else {
                    // await this.showDialog1()
                    // await this.showDialog2()
                    this.checked1 = false
                    this.checked2 = false
                    this.textarea = ''
                    await this.showDialog3()
                }
            } else {
                this.$message.error('暂无待签字声明书')
            }
        },

        onChange1() {},

        onChange2() {
            if (!this.checked2) {
                this.textarea = ''
            }
        },

        onChange3(value) {
            this.textarea = value
        }
    }
}
</script>

<style scoped></style>



