<template>
    <div v-loading="loading">
        <div class="containerBox">
            <el-card style="padding: 7px; margin: 0 4px 4px 0">
                <div class="imgBox">
                    <el-image
                        style="width: 103px; height: 115px"
                        :src="url"
                        :preview-src-list="srcList"
                    >
                    </el-image>
                    <span @click="clickModifyImg"> {{$t('msg.ModifyPhotos')}} </span>
                </div>
            </el-card>
            <div class="formBox">
                <el-descriptions border :column="2">
                    <el-descriptions-item
                        :label="$t('msg.register_real_name') "
                        label-class-name="my-label"
                        content-class-name="my-content"
                    >
                        <p style="height: 100%">
                            {{ infoForm.arbitratorName }}
                        </p>
                    </el-descriptions-item>

                    <el-descriptions-item :label="$t('msg.EnglishName') ">
                        <p style="height: 100%">
                            {{ infoForm.earbitratorName }}
                        </p>
                    </el-descriptions-item>

                    <el-descriptions-item :label="$t('msg.DateOfBirth') ">
                        <el-date-picker
                            v-model="infoForm.birthday"
                            type="date"
                            :placeholder="$t('msg.OptionDate')"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </el-descriptions-item>

                    <el-descriptions-item :label="$t('msg.IDNumber')">
                        <!-- {{ infoForm.idNumber }} -->
                        <p
                            style="height: 100%"
                            @click="
                                clickInfoInput('idNumber', infoForm.idNumber)
                            "
                            v-if="textActive !== 'idNumber'"
                        >
                            {{ infoForm.idNumber }}
                        </p>
                        <el-input
                            :ref="infoForm.idNumber + 'Ref'"
                            @blur="blurInputTab"
                            v-else
                            type="text"
                            v-model="infoForm.idNumber"
                        />
                    </el-descriptions-item>
                    <el-descriptions-item :label="$t('msg.Nationality') ">
                        <el-select
                            v-model="infoForm.nationality"
                            filterable
                            :placeholder="$t('msg.PleaseSelect')"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in getCountriesList"
                                :key="index"
                                :label="item.dictionaryItemName"
                                :value="item.dictionaryItemValue"
                            />
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$t('msg.EnglishNationality') ">
                        <!-- {{ infoForm.enationality }} -->
                        <p
                            style="height: 100%"
                            @click="
                                clickInfoInput(
                                    'enationality',
                                    infoForm.enationality
                                )
                            "
                            v-if="textActive !== 'enationality'"
                        >
                            {{ infoForm.enationality }}
                        </p>
                        <el-input
                            :ref="infoForm.enationality + 'Ref'"
                            @blur="blurInputTab"
                            v-else
                            type="text"
                            v-model="infoForm.enationality"
                        />
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>

        <!-- ----------------------------- -->
        <div class="contentInfo">
            <el-descriptions border :column="2">
                <el-descriptions-item
                    v-for="(item, index) in infoArrList"
                    :key="index"
                    :label="item.label"
                    label-class-name="my-label"
                    content-class-name="my-content"
                    class="redxxxxxxxxxx"
                >
                    <div
                        v-if="item.type !== 'mostGraduate'"
                        style="width: 100%; height: 100%"
                    >
                        <p
                            v-if="textActive !== item.type"
                            style="width: 100%; height: 100%"
                            @click="
                                clickInfoInput(item.type, infoForm[item.type])
                            "
                        >
                            <!-- {{ infoForm[item.type] }} -->
                            <span>{{ infoForm[item.type] }}</span>
                        </p>
                        <div v-else>
                            <el-input
                                :ref="infoForm[item.type] + 'Ref'"
                                :autosize="{ minRows: 2 }"
                                @blur="blurInputTab"
                                type="textarea"
                                v-model="infoForm[item.type]"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div v-else>
                        <el-select
                            v-model="infoForm.mostGraduate"
                            filterable
                            :placeholder="$t('msg.PleaseSelect')"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in educationList"
                                :key="index"
                                :label="item.dictionaryItemName"
                                :value="item.dictionaryItemValue"
                            />
                        </el-select>
                    </div>
                </el-descriptions-item>
            </el-descriptions>

            <div class="bottomBtn">
                <el-button color="#4578FF" @click="$router.back()">{{
                    $t('msg.back')
                }}</el-button>
                <el-button @click="submitForm" type="primary">
                   {{ $t('msg.SavetheMessage')}}
                </el-button>
            </div>
        </div>
        <input
            v-show="false"
            type="file"
            ref="inputFileRef"
            @change="fileChange"
            accept=".png, .jpeg, .jpg"
        />
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 小头像的展示
            url: '',
            // 头像预览的路径
            srcList: [],
            // form表单
            infoForm: {
                // 仲裁员姓名
                arbitratorName: '',
                // 英文姓名
                earbitratorName: '',
                // 出生日期
                birthday: '',
                // 身份证号
                idNumber: '',
                // 国籍
                nationality: '',
                // 英文国籍
                enationality: '',
                // 城市
                city: '',
                // 英文城市
                ecity: '',
                // 单位
                company: '',
                // 英文单位
                ecompany: '',

                // 学历
                mostGraduate: '',
                // 英文学历
                emostGraduate: '',

                // 职业分类
                professional: '',
                // 英文职业分类
                eprofessional: '',

                // 有效通信地址
                validAddress: '',
                // 英文有效通信地址
                evalidAddress: '',

                // 职称
                dutyTitle: '',
                // 英文职称
                edutyTitle: '',

                // 单位地址
                address: '',
                // 英文单位地址
                eaddress: '',

                // 职务
                duty: '',
                // 英文职务
                eduty: '',
                // 教育背景
                education: '',
                // 英文教育背景
                eeducation: '',
                // 工作经历
                workingExperience: '',
                // 英文工作经历
                eworkingExperience: '',
                // 仲裁相关工作经历
                rltArbExperience: '',
                // 英文仲裁相关工作经历
                erltArbExperience: '',

                // 社会团队兼职情况
                socialOccupation: '',
                // 英文社会团队兼职情况
                esocialOccupation: '',

                // 业务专长
                speciality: '',
                // 英文业务专长
                especiality: '',
                // 工作语言
                workingLanguage: '',
                // 英文工作语言
                eworkingLanguage: '',
                // 主要业务成果
                achievements: '',
                // 英文主要业务成果
                eachievements: '',

                // 自我描述或评价
                introduce: '',
                // 英文自我描述或评价
                eintroduce: '',

                // 手机
                mobileNo: '',
                // 电子信箱
                email: '',
                // 电话
                phone: '',
                // 传真
                fax: '',
                // 家庭电话
                homePhone: ''
            },

            // 循环表单
            infoArrList: [
                {
                    label: this.$t('msg.City'),
                    type: 'city'
                },
                {
                    label:this.$t('msg.EnglishCity'),
                    type: 'ecity'
                },
                {
                    label: this.$t('msg.Company'),
                    type: 'company'
                },
                {
                    label: this.$t('msg.EnglishCompany'),
                    type: 'ecompany'
                },
                {
                    label: this.$t('msg.EducationAttainment'),
                    type: 'mostGraduate'
                },
                {
                    label: this.$t('msg.EnglishEducationAttainment'),
                    type: 'emostGraduate'
                },
                {
                    label: this.$t('msg.OccupationalClassification'),
                    type: 'professional'
                },
                {
                    label: this.$t('msg.EnglishOccupationalClassification'),
                    type: 'eprofessional'
                },
                {
                    label: this.$t('msg.EffectiveCommunicationAddress'),
                    type: 'validAddress'
                },
                {
                    label: this.$t('msg.EnglishEffectiveCommunicationAddress'),
                    type: 'evalidAddress'
                },
                {
                    label: this.$t('msg.Title'),
                    type: 'dutyTitle'
                },
                {
                    label: this.$t('msg.EnglishTitle'),
                    type: 'edutyTitle'
                },
                {
                    label: this.$t('msg.WorkUnitAddress'),
                    type: 'address'
                },
                {
                    label: this.$t('msg.EnglishWorkUnitAddress'),
                    type: 'eaddress'
                },
                {
                    label: this.$t('msg.Position'),
                    type: 'duty'
                },
                {
                    label: this.$t('msg.EnglishPosition'),
                    type: 'eduty'
                },
                {
                    label: this.$t('msg.EducationalBackground'),
                    type: 'education'
                },
                {
                    label: this.$t('msg.EnglishEducationalBackground'),
                    type: 'eeducation'
                },
                {
                    label: this.$t('msg.WorkExperience'),
                    type: 'workingExperience'
                },
                {
                    label: this.$t('msg.EnglishWorkExperience'),
                    type: 'eworkingExperience'
                },
                {
                    label: this.$t('msg.ArbitrationRelatedWorkExperience'),
                    type: 'rltArbExperience'
                },
                {
                    label: this.$t('msg.EnglishArbitrationRelatedWorkExperience'),
                    type: 'erltArbExperience'
                },
                {
                    label: this.$t('msg.SocialTeamPartTimeJobSituation'),
                    type: 'socialOccupation'
                },
                {
                    label: this.$t('msg.EnglishSocialTeamPartTimeJobSituation'),
                    type: 'esocialOccupation'
                },
                {
                    label: this.$t('msg.BusinessExpertise'),
                    type: 'speciality'
                },
                {
                    label: this.$t('msg.EnglishBusinessExpertise'),
                    type: 'especiality'
                },
                {
                    label: this.$t('msg.WorkingLanguage'),
                    type: 'workingLanguage'
                },
                {
                    label: this.$t('msg.EnglishWorkingLanguage'),
                    type: 'eworkingLanguage'
                },
                {
                    label: this.$t('msg.MainBusinessAchievements'),
                    type: 'achievements'
                },
                {
                    label: this.$t('msg.EnglishMainBusinessAchievements'),
                    type: 'eachievements'
                },
                {
                    label: this.$t('msg.SelfDescriptionOrEvaluation'),
                    type: 'introduce'
                },
                {
                    label: this.$t('msg.EnglishSelfDescriptionOrEvaluation'),
                    type: 'eintroduce'
                },
                {
                    label:this.$t('msg.MobilePhoneNumber'),
                    type: 'mobileNo'
                },
                {
                    label: this.$t('msg.Email'),
                    type: 'email'
                },
                {
                    label: this.$t('msg.PhoneNumber'),
                    type: 'phone'
                },
                {
                    label: this.$t('msg.Fax'),
                    type: 'fax'
                },
                {
                    label: this.$t('msg.HomePhoneNumber'),
                    type: 'homePhone'
                }
            ],

            // 点击的文字
            textActive: '',
            value: '',

            // 国籍数组
            getCountriesList: [],
            // 学历数组
            educationList: [],
            // 加载效果
            loading: false
        }
    },
    methods: {
        load() {
            this.getUpdArbitratorInfo(false)
            this.GetDictionaryLtems(4)
        },

        // 点击字体切换为输入框
        clickInfoInput(type, ref) {
            this.textActive = type
            this.$nextTick(() => {
                if (this.$refs[ref + 'Ref'].length) {
                    this.$refs[ref + 'Ref'][0].focus()
                } else {
                    this.$refs[ref + 'Ref'].focus()
                }
            })
        },

        // 输入框失去焦点事件
        blurInputTab() {
            this.$nextTick(() => {
                this.textActive = ''
            })
        },

        // 请求详细数据
        async getUpdArbitratorInfo(type) {
            this.loading = true
            let res
            if (type) {
                let param = {
                    req: {
                        ...this.infoForm
                    }
                }
                res = await this.$API.sendRequest('UpdArbitratorInfo', param)
                if (!res.success) {
                    this.loading = false
                    return this.$message.error(res.msg)
                }
                this.$message.success(res.msg)
            } else {
                res = await this.$API.sendRequest('UpdArbitratorInfo')
                if (!res.success) {
                    return this.$message.error(res.msg)
                }
                let urlText = res.data.photo
                    ? 'data:image/jpg;base64,' + res.data.photo
                    : ''
                this.url = urlText
                this.infoForm = res.data
                this.srcList.push(urlText)
            }
            this.loading = false
        },

        // 清空表单项
        clearForm() {
            this.url = ''
            this.srcList = []
            this.infoForm = {}
        },
        // 提交表单
        submitForm() {
            this.infoForm.birthday = this.$GF.formatTime(
                this.infoForm.birthday,
                'yyyy-MM-DD'
            )
            console.log(this.infoForm)
            this.getUpdArbitratorInfo(true)
        },

        // 申请人-----》选择国家/地区
        async GetDictionaryLtems() {
            let res1 = await this.$API.sendRequest('styleTypeList', { req: 4 })
            let res2 = await this.$API.sendRequest('styleTypeList', {
                req: 117
            })
            this.getCountriesList = res1.data.materialStyleList
            this.educationList = res2.data.materialStyleList
        },

        // 点击修改图片  弹出选择文件
        clickModifyImg() {
            let inputRef = this.$refs.inputFileRef
            inputRef.click()
        },

        // 选择文件后,对头像的处理
        fileChange() {
            let inputRef = this.$refs.inputFileRef
            this.srcList = []
            if (inputRef.files.length > 1) {
                inputRef.files[0] = inputRef.files[1]
            }
            let oFReader = new FileReader()
            oFReader.readAsDataURL(inputRef.files[0])
            oFReader.onloadend = (oFRevent) => {
                let src = oFRevent.target.result
                this.url = src
                this.srcList.push(src)
                this.infoForm.photo = src
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
.containerBox {
    display: flex;
    p {
        margin: 0;
    }
    :deep(.el-card__body) {
        padding: 0 !important;
    }
    .formBox {
        flex: 1;
        :deep(.el-descriptions__label) {
            width: 200px !important;
            height: 50px !important;
        }
    }
    .imgBox {
        // width: 140px;
        // height: 170px;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        span {
            font-size: 14px;
            color: rgb(104, 37, 247);
            cursor: pointer;
            margin-top: 5px;
            &:hover {
                color: rgb(162, 133, 224);
            }
        }
    }
    :deep(.el-descriptions__content) {
        width: 400px;
        height: 1px !important;
    }
}
.contentInfo {
    :deep(.el-descriptions__label) {
        width: 250px !important;
        text-align: center;
    }
    :deep(.el-descriptions__content) {
        width: 600px;
        height: 1px !important;
    }
}

.textRed {
    color: red;
}

.bottomBtn {
    margin: 20px 0 100px 0;
}
</style>