<template>
    <div>
        <div class="registerCaseBox" v-loading="loading">
            <div class="showBox">
                <!-- 电子收据下载  -->
                <div class="titleBox" ref="top">
                    <h3>{{$t('msg.ElectronicReceiptdownload')}}</h3>
                </div>
                <el-card class="box-card">
                    <el-table :data="tableList" style="width: 100%">
                        <el-table-column
                            prop="payDate"
                            :label="$t('msg.Date')"
                            width="140"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="caseNo"
                            :label="$t('msg.case')"
                            width="160"
                        >
                        </el-table-column>
                        <el-table-column prop="caseName" :label="$t('msg.case_name')">
                        </el-table-column>

                        <el-table-column prop="payerCompany" :label="$t('msg.NameofPayer')">
                        </el-table-column>
                        <el-table-column prop="secName" :label="$t('msg.Manager')">
                        </el-table-column>

                        <el-table-column :label="$t('msg.Operate')">
                            <template #default="scope">
                                <div>
                                    <el-button
                                        @click="clickOpenDrawer(scope.row)"
                                        >{{$t('msg.View')}}</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </div>

        <el-drawer
            :title="$t('msg.InformationRetrieval')"
            v-model="drawerType"
            :before-close="drawerClose"
        >
            <el-form
                v-loading="formLoading"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <div v-for="(item, index) in formListFor" :key="index">
                    <!-- 正常情况 -->
                    <el-form-item :label="item.name" :prop="item.key">
                        <el-input
                            disabled
                            v-model="ruleForm[item.key]"
                            type="textarea"
                            autosize
                            resize="none"
                        >
                        </el-input>
                    </el-form-item>
                </div>

                <div class="bottomBox">
                    <div class="bottomBtn">
                        <el-button
                            type="primary"
                            style="padding: 19px 30px"
                            @click="downLoadFile"
                        >
                            {{$t('msg.Download')}}
                        </el-button>
                    </div>
                </div>
            </el-form>
        </el-drawer>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            tableList: [],
            loading: false,
            caseId: '',
            ruleForm: {},
            formListFor: [
                {
                    name: this.$t('msg.TypeOfExpense'),
                    key: 'expensiveType'
                },
                {
                    name: this.$t('msg.NameofPayer'),
                    key: 'payerCompany'
                },
                {
                    name: this.$t('msg.PaymentItems'),
                    key: 'jkxm'
                },
                {
                    name: this.$t('msg.amountInWords'),
                    key: 'bigJe'
                },
                {
                    name: this.$t('msg.AmountInFigures'),
                    key: 'je'
                },
                {
                    name: this.$t('msg.NoteBillNumber'),
                    key: 'debitNo'
                }
            ],
            drawerType: false,
            rules: [],
            formLoading: false,
            payFeeRecordId: ''
        }
    },
    methods: {
        load(caseId) {
            this.caseId = caseId
            this.getElectronicReceiptList()
        },
        // 获取列表
        async getElectronicReceiptList() {
            this.loading = true
            let param = {
                req: this.caseId
            }
            let res = await this.$API.sendRequest(
                'ElectronicReceiptList',
                param
            )
            if (!res.success) {
                this.loading = false
                return this.$message.error(res.msg)
            }
            this.loading = false
            this.tableList = res.data
        },

        // 打开侧边栏--点击查看
        async clickOpenDrawer(row) {
            this.drawerType = true
            // this.formLoading = true
            this.payFeeRecordId = row.payFeeRecordId
            // let param = {
            //     req: row.payFeeRecordId
            // }
            // let res = await this.$API.sendRequest('EleReceiptInfo', param)
            // if (!res.success) {
            //     this.formLoading = false
            //     return this.$message.error(res.msg)
            // }
            this.ruleForm = {
                ...row.electronicReceiptInfoVo
            }
            // this.formLoading = false
        },
        drawerClose() {
            this.drawerType = false
        },

        async downLoadFile() {
            this.formLoading = true
            let param = {
                req: this.payFeeRecordId
            }
            let res = await this.$API.sendRequest('DownloadReceiptPdf', param)
            let blob = new Blob([res], { type: 'application/pdf' })
            this.tableDataLoading = false
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = '电子收据'
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.formLoading = false
        }
    }
}
</script>
 
<style scoped lang='scss'>
.registerCaseBox {
    position: relative;
    left: 0;
    text-align: left;
    padding: 0 10px;
    .ToppingBox {
        position: fixed;
        bottom: 50px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s;
        &.activeToppingBox {
            opacity: 1 !important;
        }
    }
}

.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #070707;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
}
</style>