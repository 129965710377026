<template>
    <div></div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {}
    },

    created() {
        let obj = this.$route.query
        if (+obj.type === 1) {
            // 下载电子收据
            this.preReceiptPdfOut(obj.payFeeRecordId, obj.extractCode)
        } else {
            console.log('有问题,请严查')
        }
    },

    methods: {
        // 下载电子收据
        async preReceiptPdfOut(payFeeRecordId, extractCode) {
            let param = {
                req: {
                    payFeeRecordId,
                    extractCode
                }
            }
            let res = await this.$API.sendRequest('preReceiptPdfOut', param)
            if (!res) {
                return
            }
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = '电子收据.pdf'
            link.click()
            window.URL.revokeObjectURL(link.href)
        }
    }
}
</script>
 
<style scoped >
</style>