<template>
    <div class="wb_wrapper" v-loading="loading">
        <!-- 首页展示 -->
        <div class="showPartyHome">
            <parayHomePage></parayHomePage>
        </div>
        <!-- 底部两个点击事项 -->
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <div class="title">
                        <img src="../assets/image/workbench/todo.png" alt="" />
                        <span>{{ $t('msg.workbench_todo') }}</span>
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </div>
                    <el-link
                        :underline="false"
                        type="primary"
                        @click="showDialog(1)"
                        >{{ $t('msg.workbench_more') }}</el-link
                    >
                </div>
            </template>
            <ul class="list">
                <li
                    v-for="(item, index) in todo_list.slice(0, 10)"
                    :key="index"
                    @click="handleJump(item.classType, item.caseId)"
                >
                    {{ item.info }}(
                    <span
                        v-if="item.classType == 11 && item.approveInd == 1"
                        style="color: red"
                        >已签</span
                    >
                    <span
                        v-if="item.classType == 11 && item.approveInd != 1"
                        style="color: red"
                        >未签</span
                    >
                    <span
                        v-if="item.classType == 13 && item.approvePage == 1"
                        style="color: red"
                        >已签</span
                    >
                    <span
                        v-if="item.classType == 13 && item.approvePage != 1"
                        style="color: red"
                        >未签</span
                    >)
                </li>
            </ul>
        </el-card>
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <div class="title">
                        <img src="../assets/image/workbench/todo.png" alt="" />
                        <span>{{ $t('msg.workbench_case_tip') }}</span>
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </div>
                    <el-link
                        :underline="false"
                        type="primary"
                        @click="showDialog(2)"
                        >{{ $t('msg.workbench_more') }}</el-link
                    >
                </div>
            </template>
            <ul class="list">
                <li
                    v-for="(item, index) in case_tip_list.slice(0, 10)"
                    :key="index"
                >
                    {{ item.info }}
                </li>
            </ul>
        </el-card>
        <!-- 底部两个点击事项 -->
        <!-- 更多弹出框 -->
        <el-dialog
            width="70%"
            :title="title"
            v-model="dialogVisible"
            @close="closeDialog"
        >
            <div class="table_wrapper">
                <!-- 搜索 -->
                <div class="search">
                    <el-form :model="searchModel" inline>
                        <el-form-item :label="$t('msg.workbench_search_no')">
                            <el-input
                                v-model="searchModel.caseNo"
                                clearable
                                id="caseNo"
                            />
                        </el-form-item>
                        <el-form-item :label="$t('msg.workbench_search_info')">
                            <el-input
                                v-model="searchModel.info"
                                clearable
                                id="info"
                            />
                        </el-form-item>
                        <el-form-item>
                            <div class="btn_group">
                                <el-button @click="handleSearch">
                                    {{ $t('msg.case_search') }}
                                </el-button>
                                <el-button @click="handleReset">
                                    {{ $t('msg.case_reset') }}
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table
                    v-loading="loading"
                    :data="list"
                    border
                    :stripe="true"
                    style="width: 100%"
                >
                    <el-table-column
                        :label="$t('msg.case')"
                        prop="caseNo"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        :label="$t('msg.case_content')"
                        prop="info"
                        align="center"
                    >
                        <template v-slot="scope">
                            <el-link
                                type="primary"
                                :underline="false"
                                @click="
                                    handleJump(
                                        scope.row.classType,
                                        scope.row.caseId
                                    )
                                "
                                >{{ scope.row.info }}</el-link
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('msg.case_create_time')"
                        prop="recDate"
                        align="center"
                    >
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination
                    style="
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                    "
                    small="small"
                    @size-change="paginationSizeChange"
                    @current-change="paginationCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size="pagination.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total"
                >
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import parayHomePage from './parayHomePage.vue'
export default {
    name: 'WorkBench',
    created() {
        this.GetCaseTip()
        this.GetToDo()
    },
    components: {
        parayHomePage
    },
    data() {
        return {
            loading: false,
            list: [],
            type: 1, // 1.待办事项 2.案件提醒
            title: '',
            dialogVisible: false,
            pagination: {
                page: 1,
                pages: 0,
                limit: 20,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            todo_list: [],
            case_tip_list: [],
            searchModel: {
                caseNo: '',
                info: ''
            },
            init_todo: false,
            init_tip: false
        }
    },
    methods: {
        paginationSizeChange(val) {
            this.pagination.limit = val
            this.GetList()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.GetList()
        },
        doSearch() {
            this.pagination.page = 1
            this.GetList()
        },
        async GetList() {
            if (this.type === 1) {
                await this.GetToDo()
            } else if (this.type === 2) {
                await this.GetCaseTip()
            }
        },
        async GetToDo() {
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        caseNo: this.searchModel.caseNo,
                        info: this.searchModel.info
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('ToDoList', param)
            this.loading = false
            if (res && res.success) {
                this.list = res.data.records
                this.pagination.total = res.data.total
                if (this.pagination.page === 1 && !this.init_todo)
                    this.todo_list = res.data.records
                this.init_todo = true
            } else {
                this.$message.error(res.msg)
            }
        },
        async GetCaseTip() {
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        caseNo: this.searchModel.caseNo,
                        info: this.searchModel.info
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('CaseTipList', param)
            this.loading = false
            if (res && res.success) {
                this.list = res.data.records
                this.pagination.total = res.data.total
                if (this.pagination.page === 1 && !this.init_tip)
                    this.case_tip_list = res.data.records
                this.init_tip = true
            } else {
                this.$message.error(res.msg)
            }
        },
        async showDialog(val) {
            this.type = val
            if (val === 1) {
                await this.GetToDo()
            } else if (val === 2) {
                await this.GetCaseTip()
            }
            this.dialogVisible = true
        },
        closeDialog() {
            this.pagination.page = 1
            this.pagination.total = 0
            this.searchModel = {
                caseNo: '',
                info: ''
            }
        },
        GoDetail() {},

        async handleSearch() {
            await this.GetList()
        },

        async handleJump(val1, val2) {
            var storage = window.localStorage
            storage.setItem('caseId', val2)
            if (val1 == 11) {
                this.$router.push({
                    name: 'DeclarationSignatureJump',
                    params: { caseId: val2 }
                })
            } else {
                this.$router.push({
                    name: 'ArbitramentSignatureJump',
                    params: { caseId: val2 }
                })
            }
        },

        async handleReset() {
            this.searchModel = {
                caseNo: '',
                info: ''
            }
            await this.GetList()
        }
    }
}
</script>

<style scoped lang="sass">
.wb_wrapper
    text-align: left
.quick,
span
    position: relative
    top: 2px
img
    width: 12px
    height: 12px
.custom_btn
    background-color: rgba(69, 120, 255, 0.1)
    ::v-deep(span)
        color: rgba(69, 120, 255, 1)
.card
    margin-bottom: 20px
    margin-top: 20px
::v-deep(.el-card__body)
    padding: 0
.card-header
    display: flex
    justify-content: space-between
.title
    display: flex
    align-items: center
    span
        font-weight: bolder
    img
        width: 16px
        height: 16px
        margin-right: 5px

.list
    padding-inline-start: 0
    font-size: 14px
    list-style: none
    color: #6B778D
li
    height: 40px
    line-height: 40px
    padding-left: 25px
    &:hover
        background-color: rgba(69, 120, 255, 0.1)
        color: rgba(69, 120, 255, 1)
    &::before
        content: "•"
        position: relative
        left: -5px
        top: 1px
</style>
<style scoped lang="scss">
.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: 2px;
    &:first-of-type {
        background-color: #4578ff;
        position: relative;
        left: 5px;
    }

    &:last-of-type {
        background-color: #ffbd50;
    }
}
</style>