<template>
    <div class="login_wrapper">
        <div class="top">
            <img src="../../assets/image/scan/sign_logo.png" alt="logo" />
            <div class="company_name">
                <div>贸仲扫码签章系统</div>
            </div>
        </div>
        <div class="grey_strip_top"></div>
        <div class="center">
            <!-- 登录 -->
            <div class="pan_in" v-loading="submitLoading">
                <div v-show="login_type === 'account'">
                    <div @click="handleLoginType('account')" class="top_type">
                        <span>账号登录</span>
                    </div>
                    <!-- 账号登录 -->
                    <el-form
                        :model="login_form"
                        :rules="loginRules"
                        ref="login_form"
                        class="login_form"
                    >
                        <el-form-item prop="account">
                            <el-input
                                v-model="login_form.account"
                                size="large"
                                :placeholder="$t('msg.login_username')"
                            >
                                <template #prefix>
                                    <img
                                        class="prefix"
                                        src="../../assets/image/login/user.png"
                                        alt=""
                                    />
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                v-model="login_form.password"
                                type="password"
                                show-password
                                size="large"
                                :placeholder="$t('msg.login_password')"
                            >
                                <template #prefix>
                                    <img
                                        class="prefix"
                                        src="../../assets/image/login/lock.png"
                                        alt=""
                                    />
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="captcha">
                            <div class="code_wrapper">
                                <el-input
                                    v-model="login_form.captcha"
                                    class="left"
                                    size="large"
                                    :placeholder="$t('msg.login_verify_code')"
                                >
                                    <template #prefix>
                                        <img
                                            class="prefix"
                                            src="../../assets/image/login/code.png"
                                            alt=""
                                        />
                                    </template>
                                </el-input>
                                <div
                                    v-loading="captchaLoading"
                                    class="verify_code"
                                    @click="Captcha"
                                >
                                    <el-image fit="contain" :src="captchaUrl" />
                                </div>
                            </div>
                        </el-form-item>
                        <el-button
                            color="#4578FF"
                            size="large"
                            style="width: 100%"
                            @click="handleLogin"
                            >{{ $t('msg.login_btn') }}</el-button
                        >
                    </el-form>
                    <div @click="handleLoginType('wechat')" class="bottom_type">
                        <span>{{ $t('msg.login_wechat_type') }}</span>
                    </div>
                </div>

                <div v-show="login_type === 'wechat'">
                    <div id="wx_login_form"></div>
                    <div
                        @click="handleLoginType('account')"
                        class="bottom_type"
                    >
                        <span>账号登录</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="copy_right">
            Copyright <span>©</span
            >{{ new Date().getFullYear() }} 贸仲委版权所有
        </p>
        <div class="grey_strip_footer"></div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    async created() {
        console.log('首页的created调用了')
        await this.GetGuestToken()
        await this.Captcha()
        this.$listener.$on('lang', () => {
            if (this.form_type === 'login') {
                this.$refs.login_form?.resetFields()
            } else if (this.form_type === 'register') {
                this.$refs.register_form?.resetFields()
            } else if (this.form_type === 'forget') {
                this.$refs.forget_form?.resetFields()
            }
        })
        document.addEventListener('keydown', (e) => {
            if (
                e.keyCode === 13 &&
                this.form_type === 'login' &&
                this.$route.path === '/login'
            ) {
                this.handleLogin()
            }
        })
        // 处理微信扫码登录失败提示
        if (this.$route.query.success !== undefined) {
            this.$message.error(this.$route.query.msg)
        }
        await this.handleLoginType('wechat')
    },
    data() {
        return {
            login_type: 'wechat', // account wechat
            login_form: {
                account: '',
                password: '',
                captcha: ''
            },
            loginRules: {
                account: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ],
                captcha: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ]
            },
            submitLoading: false,
            captchaLoading: false,
            captchaUrl: ''
        }
    },
    methods: {
        async handleLoginType(val) {
            this.login_type = val
            if (this.login_type === 'wechat') {
                const info = await this.getWechatInfo()
                new window.WxLogin({
                    id: 'wx_login_form',
                    appid: info.appid,
                    scope: info.scope,
                    redirect_uri: info.redirect_uri,
                    state: info.state,
                    style: '',
                    href: 'http://www.cietacodr.org/wx.css'
                })
            }
        },
        handleLogin() {
            this.$refs.login_form.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: { ...this.login_form }
                    }
                    const headers = { 'c-guest-token': this.guestToken }
                    this.submitLoading = true
                    const res = await this.$API.sendRequest(
                        'Login',
                        param,
                        headers
                    )
                    if (res.success) {
                        this.c_auth_token = res.data
                        localStorage.setItem('c_auth_token', this.c_auth_token)
                        this.$message.success('登录成功')
                        this.submitLoading = false
                        await this.$router.replace({ path: '/sign_file_list' })
                    } else {
                        this.$message.error(res.msg)
                        this.submitLoading = false
                    }
                }
            })
        },
        async GetGuestToken() {
            console.log('diaoyongle ')
            const res = await this.$API.sendRequest('GetGuestToken')
            if (res && res.data) {
                this.guestToken = res.data
                this.t1 = setInterval(() => {
                    this.GuestHeartBeat()
                }, 60000 * 5)
            }
        },
        async GuestHeartBeat() {
            if (localStorage.getItem('c_auth_token')) {
                clearInterval(this.t1)
                return
            }
            const headers = { 'c-guest-token': this.guestToken }
            await this.$API.sendRequest('GuestHeartBeat', {}, headers)
        },
        async Captcha() {
            const headers = { 'c-guest-token': this.guestToken }
            if (this.guestToken) {
                this.captchaLoading = true
                const res = await this.$API.sendRequest('Captcha', {}, headers)
                this.captchaLoading = false
                if (res && res.success) {
                    this.captchaUrl = res && res.data
                } else {
                    this.captchaUrl = ''
                    this.$message.error(res.msg)
                }
            }
        },
        async getWechatInfo() {
            const headers = { 'c-guest-token': this.guestToken }
            const param = {
                req: {
                    loginChannel: 'scansignature' //common - 通用登录页；scansignature - 触摸屏签章登录
                }
            }
            const res = await this.$API.sendRequest(
                'GetWxLoginConfigGuest',
                param,
                headers
            )
            if (res) {
                return res.data
            } else {
                this.$message.error('获取appid失败')
            }
        }
    }
}
</script>

<style scoped lang="sass">
.login_wrapper
    height: 100vh
    padding: 93px 0 0 0
    box-sizing: border-box
::-webkit-scrollbar
    display: none

.top
    position: absolute
    left: 15px
    top: 25px
    height: 45px
    display: flex
    align-items: center
    cursor: pointer
    font-size: 15px
img
    width: 73px
    height: 61px
    margin-right: 15px
span
    margin-left: 13px
.company_name
    text-align: left
    color: rgb(25,53,117)
    font-weight: 800
    div:first-child
        font-size: 28px

.lang_right
    position: absolute
    right: 30px
    top: 40px
    display: flex
    gap: 10px

$radius_01: 4px
$color: #A7B9E3
$color01: #434A54

.grey_strip_top
    width: 100%
    height: 30px
    background-color: rgba(197,191,191,1.0)

.grey_strip_footer
    width: 100%
    height: 35px
    background-color: rgba(197,191,191,1.0)
    position: absolute
    bottom: 0px

.center
    background: url("../../assets/image/scan/sign_login_bg.png") no-repeat
    background-size: 100% 100%
    min-height: 78%
    position: relative
.less_padding
    padding: 0!important
.pan_in
    border-radius: $radius_01
    width: 450px
    height: 480px
    background-color: rgba(255,255,255,0.8)
    padding: 20px 23px
    box-sizing: border-box
    overflow: scroll
    position: absolute
    right: 15%
    top: 6%
    .login_type
        height: 40px
        position: relative
        top: -20px
        left: -23px
        display: flex
        width: 347px
        border-bottom: 1px solid #A7B9E3
    img
        height: 24px
        margin-right: 10px
    .tab_item
        width: 100px
        height: 40px
        display: flex
        align-items: center
        font-size: 15px
        padding: 7px 20px
        box-sizing: border-box
        font-weight: 500
        position: relative
        span
            color: #434A54
        &.active
        span
            color: #4578FF
        .active_bar
            width: 20px
            height: 3px
            background-color: #4578FF
            position: absolute
            top: 37px
            left: 40px
            border-radius: 3px
img.prefix
    width: 12px
.forget
    color: #434A54
    text-align: right
    font-size: 12px
    cursor: pointer
.verify_code
    height: 41px
    width: 126px
    background-color: #fff
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
.code_wrapper
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
.left
    width: 75%
    margin-right: 5px
.register
    text-align: center
    color: $color01
    font-size: 13px
    cursor: pointer

.login_form
    height: 400px
::v-deep(.el-input__wrapper)
    height: 43px!important
.register_form
::v-deep(.el-input__wrapper)
    height: 30px!important
::v-deep(.el-form-item)
    margin-right: 10px
::v-deep(.el-form-item)
    margin-bottom: 14px

.copy_right
    color: #000
    font-size: 20px
    text-align: center
    width: 100%
    font-weight: 800
a
    color: #434A54
    font-size: 13px

.login_footer
    text-align: right
    margin-top: 16px
span:first-child
    margin-right: 20px

.sticky01
    position: sticky
    top: 0
    border-bottom: 1px solid #A7B9E3
    z-index: 1
    background-color: #CDDCF3
    height: 40px
    line-height: 40px
    padding-left: 10px
.sticky02
  //position: sticky
    position: relative
    bottom: -15px
    z-index: 1
    background-color: #EDF0F3
    padding: 5px 15px 2px 15px
a
    text-decoration: none
.link
    border: 1px solid #7F96CD
    padding: 0 10px
    border-radius: 3px
    color: rgb(18, 89, 184)
    font-size: 13px
    position: relative
    top: -2.5px

.top_type
    height: 40px
    cursor: pointer
    border-bottom: 1px solid #ccc
    margin-bottom: 10px

.bottom_type
    height: 50px
    line-height: 50px
    position: sticky
    bottom: 0
    cursor: pointer
    border-top: 1px solid #ccc
</style>
