/**
 * Created by Leo
 * Date: 2022-05-28 21:54
 * Email: asdfpeng@qq.com
 */
import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            count: 0,
            loginInfo: {},
                // 下载枚举
                downEnum: {
                    '.gif': 'image/gif',
                    '.jpg': 'image/jpeg',
                    '.jpeg': 'image/jpeg',
                    '.png': 'image/png',
                    '.doc': 'application/msword',
                    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    '.pdf': 'application/pdf',
                    '.rar': 'application/x-rar-compressed',
                    '.zip': 'application/zip',
                    '.mp4': 'video/mp4',
                    'xls':'application/vnd.ms-excel'
            },
            caseObj: {},
            // 上传文件的大小
            uploadSize:50
        }
    },
    mutations: {
        increment (state) {
            state.count++
        },
        getLoginInfo(state,info) {
            state.loginInfo = info
        },
        
        // 申请人静态数组
        setPartyList(state, obj) {
            state[obj.key] = obj.list
        },
        // 删除申请人静态数组
        removePartyList(state) {
            state['164'] = null
            state['166'] = null
            state['294'] = null
            state['275'] = null
            state['4'] = null
            state['163'] = null
            state['277'] = null
        },

        setCaseObj(state, caseObj) {
            state.caseObj = caseObj
        },

        // 删除当事人下拉框列表本地存储的数组
        removestyleTypePartyList() {
            localStorage.removeItem('styleTypePartyList')
        },

        // 删除发票申请页的下拉框数据列表的本地存储的数据
        removeReceiptApplicationListStorage() {
            localStorage.removeItem('receiptApplicationListStorage')
        }

    }
})

export default store