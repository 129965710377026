<template>
    <div class="container" v-loading="loading">
        <el-card>
            <el-row>
                <!-- 姓名 -->
                <el-col :span="2">
                    <span class="leftTitle">{{$t('msg.register_real_name')}}:</span>
                </el-col>
                <el-col :span="10">
                    <p>{{ form.arbName }}</p>
                </el-col>
            </el-row>

            <el-row>
                <!-- 身份 -->
                <el-col :span="2">
                    <span class="leftTitle">{{$t('msg.Identity')}}:</span>
                </el-col>
                <el-col :span="10">
                    <p>{{ form.place }}</p>
                </el-col>
            </el-row>

            <el-row>
                <!-- 指定日期 -->
                <el-col :span="2">
                    <span class="leftTitle">{{$t('msg.AppointedDate')}}:</span>
                </el-col>
                <el-col :span="10">
                    <p>{{ form.appointDate }}</p>
                </el-col>
            </el-row>

            <el-row>
                <!-- 状态 -->
                <el-col :span="2">
                    <span class="leftTitle">{{$t('msg.Status')}}:</span>
                </el-col>
                <el-col :span="10">
                    <p>{{ form.state === 1 ?$t('msg.effective') : $t('msg.invalid') }}</p>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="2"></el-col>
                <el-col :span="10">
                    <p class="statementBox">
                        {{$t('msg.IdeclarethatalthoughIconsiderthattherearenoArbitrationRulesoftheChinaInternationalEconomic')}}：
                    </p>
                </el-col>
            </el-row>
            <el-row>
                <!-- 状态 -->
                <el-col :span="2">
                    <span class="leftTitle">{{$t('msg.Disclosure')}}:</span>
                </el-col>
                <el-col :span="10">
                    <el-input
                        style="font-size: 18px"
                        v-model="form.publish"
                        type="textarea"
                        :autosize="{ minRows: 5 }"
                    ></el-input>
                </el-col>
            </el-row>

            <div class="bottomBtn">
                <el-row>
                    <!-- 状态 -->
                    <el-col :span="2"> </el-col>
                    <el-col :span="10">
                        <el-button
                            @click="submit"
                            type="primary"
                            :disabled="buttonDisabled"
                        >
                           {{$t('msg.Save')}}
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>
 
<script>
export default {
    name: 'statenmentPublish',

    data() {
        return {
            loading: '',
            form: {
                arbName: '',
                place: '',
                appointDate: '',
                state: '',
                publish: ''
            },
            buttonDisabled: true,
            caseId: '',
            messageType: false
        }
    },
    methods: {
        async load(caseId) {
            this.messageType = false
            this.clearForm()
            this.caseId = caseId
            this.getArbPublish()
        },

        // 请求数据
        async getArbPublish() {
            this.loading = true
            let param = {
                req: {
                    caseId: this.caseId,
                    publish: this.form.publish
                }
            }
            let res = await this.$API.sendRequest('ArbPublish', param)
            if (!res.success) {
                if (this.messageType) {
                    this.$message.error(res.msg)
                }

                this.loading = false
                this.buttonDisabled = true
                this.form = { ...res.data }
                this.form.publish = res.data.publishStr
                return false
            }
            this.buttonDisabled = false
            this.form = { ...res.data }
            this.form.publish = res.data.publishStr
            this.loading = false
            return true
        },

        // 点击保存
        submit() {
            if (!this.form.publish) {
                return this.$message.error('披露不能为空')
            }
            let type = this.getArbPublish()
            if (type) {
                return this.$message.success('修改成功')
            }
        },

        // 清空
        clearForm() {
            this.form = {
                arbName: '',
                place: '',
                appointDate: '',
                state: '',
                publish: ''
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
.container {
    font-size: 20px;
    .el-row {
        margin: 10px 0;
    }
}
p {
    margin: 0;
    text-align: left;
    padding-left: 30px;
    font-size: 18px;
}

.bottomBtn {
    margin-top: 20px;
}

.statementBox {
    font-size: 14px;
    text-align: left;
}
</style>