<template>
	<div>
		<div class="my-tabs">
			<el-tabs tab-position="left" v-model="activeName">
				<el-tab-pane :label="item" v-for="(item, index) in tabsList" :key="index" :name="item.nameus">
					<template #label>
						<span v-if="storedProides=='en'">{{ item.Engname  }}</span>
						<span v-else>{{ item.nameus }}</span>
					</template>
					<!-- 右上角的英语切换 -->
					<span class="lang_right" style="    position: absolute;top: -25px;">
						<LangOption :style-obj="langStyle" position="login" />
					</span>
					<h1>{{$t('msg.UnitName')}}</h1>

					<!-- <h1>{{ item }}</h1> -->
					<div v-show="item.nameus === '注册指引'||item.Engname==='Guide to Registration'" class="fontType">
						<div class="leftTextBox">
							<h3 class="centerText">{{$t('msg.Smartplatformregistrationaccountusageguide')}}</h3>
							<h3>{{$t('msg.WelcomoMAozhongSmartPlatformNewuserspleaseregisterfirst')}}：</h3>
							<h3>一、{{$t('msg.Accountregistration')}}</h3>
							<strong>{{$t('msg.Partiescanaccessthesmartplatformintwoways')}}：</strong>
							<p>
								1.
								{{$t('msg.LogontotheofficialwebsiteofChinaInternationCommission')}}<a
									href="http://www.cietac.org"
									target="_blank">www.cietac.org</a>{{$t('msg.OnthehomepageclickOnlineFilingtoplatform')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy1)" :src="imgObj.zczy1" alt=""
									style="width: 30%" />
							</div>
							<p>
								2. {{$t('msg.Directinput')}}<a href="http://www.cietacodr.org"
									target="_blank">www.cietacodr.org</a>，{{$t('msg.Enterthesmartplatform')}}。
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy2)" :src="imgObj.zczy2" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Newuserneetoregisterbeforeusingthesystem')}}
							</p>
							<p><strong>{{$t('msg.gentlereminder')}}：</strong></p>
							<p>
								{{$t('msg.ForcasessubmittedbeforeJanuarypleasealsologintothehomepageoftheofficial')}}
							</p>
							<p>1. {{$t('msg.register_type_mobile')}}</p>
							<p>
								{{$t('msg.BydefaultmobilephoneregistrationpreferredPleasefillinthemobilephonenumberemail')}}
							</p>
							<p>
								{{$t('msg.PleasereadandchecktheServiceAgreementandclick')}}
							</p>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy3)" :src="imgObj.zczy3" alt=""
									style="width: 30%" />
							</div>
							<p>2. {{$t('msg.register_type_email')}}</p>
							<p>
								{{$t('msg.PleaseswitchtoEmailregistrationatthetopofheregistrationinformationpageasshownbelow')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy4)" :src="imgObj.zczy4" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.PleasefillintheemailnameIDnumberasrequiredsetthepassword')}}
							</p>
							<p>
								{{$t('msg.PleasereandchecktheServiceAgreementandclickRegisternow')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy5)" :src="imgObj.zczy5" alt=""
									style="width: 30%" />
							</div>
							<p>{{$t('msg.Aftertheregistrationissuccessfulthesystemautomaticallyreturnstothelogin')}}</p>

							<h3>二、{{$t('msg.Loginplatform')}}</h3>
							<p>
								<strong>1.{{$t('msg.ThepartiescanlogintothesmartPlatformbyfillingnthewechat')}}</strong>
							</p>
							<p><strong>{{$t('msg.Howtobindwechat')}}：</strong></p>
							<p>
								{{$t('msg.Afterloggingintothesmartplatformyoucanfillinyourpersonalinformation')}}
							</p>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy6)" :src="imgObj.zczy6" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy7)" :src="imgObj.zczy7" alt=""
									style="width: 30%" />
							</div>
							<p>
								<strong>
									{{$t('msg.Afterbindingtowechatyoucanusewechattime')}}</strong>
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy8)" :src="imgObj.zczy8" alt=""
									style="width: 30%" />
							</div>

							<h3>二、{{$t('msg.login_forget')}}</h3>
							<p>
								{{$t('msg.IfyouforgetthepasswordoftheregiyoucanclickForgotPasswordtooperate')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy9)" :src="imgObj.zczy9" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.ToretrievethroughthemobilephonenumberhmobilephonenumberuseddurickSend')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy10)" :src="imgObj.zczy10" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Toretrievebyemailouneedtoentertheemailaddressuse')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.zczy11)" :src="imgObj.zczy11" alt=""
									style="width: 30%" />
							</div>

							<div class="topIcoBox" @click="clickTop" :title="$t('msg.Top')">
								<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-zhiding"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>

					<div v-show="item.nameus === '常见问题'||item.Engname==='Common Problem'" class="fontType problemBox">
						<h1>{{$t('msg.CommonProblem')}}</h1>
						<h3 class="redText" style="text-align: center">
							{{$t('msg.Pleasenotethatthefollowinganswersareforthereferenceofthepartie')}}
						</h3>
						<div v-if="storedProides=='en'">
							<el-collapse accordion>
								<el-collapse-item :title="item.title" :name="index" v-for="(item, index) in problemList"
									:key="index">
									<div v-for="(item, i) in item.content" :key="i" class="text">
										<!-- {{ item.text }} -->
										<p v-html="item.text"></p>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>
						<div v-else>
							<el-collapse accordion>
								<el-collapse-item :title="item.title" :name="index"
									v-for="(item, index) in problemListuess" :key="index">
									<div v-for="(item, i) in item.content" :key="i" class="text">
										<!-- {{ item.text }} -->
										<p v-html="item.text"></p>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>

					</div>
					<div v-show="item.nameus === '立案指引'||item.Engname==='Guidelines for Filing'">
						<div class="leftTextBox">
							<h3>{{$t('msg.Createanarbitrationcase')}}</h3>
							<p>{{$t('msg.AfterregisteringandlogginginentertheCasescreen')}}</p>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy1)" :src="imgObj.lazy1" alt=""
									style="width: 30%" />
							</div>
							<p>{{$t('msg.ClickNewCase')}}</p>

							<p><strong>{{$t('msg.Fillincaseinformation')}}</strong></p>
							<p>
								{{$t('msg.Article21ofthecurrentCIETACArbitrationRulessionandthepaperversionagreed')}}
							</p>
							<p>1. {{$t('msg.Fillintheinformationofbothpartiesandagents')}}</p>
							<p>
								{{$t('msg.AfterselectinganewcasetheNewcasepagewillbedisplayedPlease')}}
							</p>
							<p>
								{{$t('msg.Pleasefirstpleasefillintherelevantpartyinformationnumberisrequired')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy2)" :src="imgObj.lazy2" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy3)" :src="imgObj.lazy3" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy4)" :src="imgObj.lazy4" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Whenfillingintheinformationofthepartiesaupload')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy5)" :src="imgObj.lazy5" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Theapplicantanuploadthesubjectqualificationdocument')}}
							</p>
							<p>2. {{$t('msg.Fillintheaddressforservice')}}</p>
							<p>
								{{$t('msg.Afterenteringheinformationofbothparties')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy6)" :src="imgObj.lazy6" alt=""
									style="width: 30%" />
							</div>
							<p>
								<strong>{{$t('msg.Pleasenotethatthedeliverybefilledinaccurately')}}</strong>
							</p>
							<p>
								<strong>{{$t('msg.Theapplicantwillreceiveamessagenotificationfromthesmart')}}</strong>
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy7)" :src="imgObj.lazy7" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.AfterselectingApplicantServiceAddresspleasenotethatonly')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy8)" :src="imgObj.lazy8" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Afterselectingproviderespondentingoodfaithandtherelevant')}}
							</p>
							<p>{{$t('msg.Theapplicantmayprovidemultipleserviceaddressesrespondent')}}</p>
							<p>
								{{$t('msg.Ifthereisanyorderofservicepleasesendthearbitration')}}
							</p>
							<p>
								{{$t('msg.Ifyouneedtosenditatthesametimepleasesubmitadocuments')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy9)" :src="imgObj.lazy9" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy10)" :src="imgObj.lazy10" alt=""
									style="width: 30%" />
							</div>
							<p>3. {{$t('msg.Fillinthebasisofarbitration')}}</p>
							<p>{{$t('msg.PleaseclickAddtoenterthearbitrationbasis')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy11)" :src="imgObj.lazy11" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Pleaseaccuratelyinputnamearbitrationclauseifanysigningdate')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy12)" :src="imgObj.lazy12" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Ifarbitrationisfiledbasedonmorethanonecontractclick')}}
							</p>
							<p>4. {{$t('msg.Fillinthearbitrationclaimfactsandeasons')}}</p>
							<p>{{$t('msg.PleaseclickAddtoenterthearbitration')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy13)" :src="imgObj.lazy13" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy14)" :src="imgObj.lazy14" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy15)" :src="imgObj.lazy15" alt=""
									style="width: 30%" />
							</div>
							<p>5. {{$t('msg.ConservatoryMeasures')}}</p>
							<p>
								{{$t('msg.Iftheapplicantneedspropertpreservationnameuploadthescannedopyofheapplica')}}
							</p>
							<p>{{$t('msg.Iftheapplicantdoesnotneedpreservation')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy16)" :src="imgObj.lazy16" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy17)" :src="imgObj.lazy17" alt=""
									style="width: 30%" />
							</div>
							<p>6. {{$t('msg.Uploadtheapplicantevidence')}}</p>
							<p>
								{{$t('msg.Thesubjectqualificationevidencmaterialsustuploadnewev')}}
							</p>
							<p>
								{{$t('msg.Itisrecommendedthattheapplicantmaterialsabout500Mforaingledocument')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy18)" :src="imgObj.lazy18" alt=""
									style="width: 30%" />
							</div>
							<p>7. {{$t('msg.Submitthecaseandobtainthecasenumber')}}</p>
							<p>
								{{$t('msg.AfterfillingintheaboveinformationaccuratelyclickSubmitforfilingnot')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy19)" :src="imgObj.lazy19" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.AfterclickingSubmittoCIETACpleasenotethataccordingto')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy20)" :src="imgObj.lazy20" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.AfterlickingSubmittoCIETACyouwilbepromptedwithyourcasenumber')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy21)" :src="imgObj.lazy21" alt=""
									style="width: 30%" />
							</div>
							<p><strong>{{$t('msg.CasefilingInvestigation')}}</strong></p>
							<p>1.{{$t('msg.initialExamination')}}</p>
							<p>
								{{$t('msg.AftertheapplicantsubmitsanewcaseCIETACwillonduc')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy22)" :src="imgObj.lazy22" alt=""
									style="width: 30%" />
							</div>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy23)" :src="imgObj.lazy23" alt=""
									style="width: 30%" />
							</div>
							<p>2. {{$t('msg.Contactthefilingsecretary')}}</p>
							<p>
								{{$t('msg.AfterthecasesecretaryisdeterminedthenameaceofMyCase')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy24)" :src="imgObj.lazy24" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Thepplicanalsocheckthemessageboardtofillsecretary')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy25)" :src="imgObj.lazy25" alt=""
									style="width: 30%" />
							</div>
							<p>3.{{$t('msg.Returnofcase')}}</p>
							<p>
								{{$t('msg.Whenthefilingreviewfindtthecaseneedstobemodified')}}
							</p>
							<p>
								{{$t('msg.Aftertheapplicantsupplementstheuploadedcarbitration')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy26)" :src="imgObj.lazy26" alt=""
									style="width: 30%" />
							</div>
							<p>4.{{$t('msg.Thecasewasfiledndexamined')}}</p>
							<p>
								{{$t('msg.Afterpassingtheexaminationthemobilephonebytheapplicantnglyintime')}}
							</p>
							<p>
								（1）{{$t('msg.Pleaseotethatafterpassingotice')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy27)" :src="imgObj.lazy27" alt=""
									style="width: 30%" />
							</div>
							<p>
								（2）{{$t('msg.Pleaseselectarbitrationprepaymentaccordingtothenotice')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy28)" :src="imgObj.lazy28" alt=""
									style="width: 30%" />
							</div>
							<p>
								(3)
								{{$t('msg.ClickthebuttonofthecorrespondiversionficeofCIETACasrequired')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy29)" :src="imgObj.lazy29" alt=""
									style="width: 30%" />
							</div>

							<p>{{$t('msg.attentionshouldbepaidto')}}：</p>
							<p>
								1、{{$t('msg.ApplicationforarbiotesandndnoteThiscanbeusedwheofthedocument')}}
							</p>
							<p>2、{{$t('msg.Aboutthenumberingofthepagesvidence')}}：</p>
							<p>
								1）{{$t('msg.Eachpieceofevidencebepaginatedseparatelthebottomrightofachpage')}}
							</p>
							<p>
								2）{{$t('msg.Allevidenceshallbepaginatedtotalpageeachpage')}}
							</p>
							<p>
								3）{{$t('msg.WhentherearemorethetotalpagenumberconsecutiveWhenevidenceyubmissions')}}
							</p>
							<p>
								4）{{$t('msg.Thecontentstobeprovedevidenceshallbeaffected')}}
							</p>
							<p>
								5）{{$t('msg.Whenthevidencesubmittedagainisrepeateeindicated')}}
							</p>
							<p>
								3、{{$t('msg.Asforthebindingofdocumentsifthedocumentsarethickg')}}
							</p>
							<p>{{$t('msg.Annotation')}}：</p>
							<p>
								[1]{{$t('msg.ThepurposeofwritingTheCalculattheaward')}}
							</p>
							<p>
								[2]{{$t('msg.Thissectionthearbitrationcaseprojectincludingthenamenproblemsetc')}}
							</p>
							<p>
								{{$t('msg.Thespecificclaimlaimpaymentevidencearenotinvolvedhere')}}
							</p>
							<p>
								{{$t('msg.Boththeapplicantandthesubmittingthedocumentlater')}}
							</p>
							<p>
								{{$t('msg.Thepurposeofbriefntroductionivethearbitratorbitrationcase')}}
							</p>

							<p>
								{{$t('msg.Pleasetionllproceedtothelicantandarbitrationfeepaidinadvance')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.lazy30)" :src="imgObj.lazy30" alt=""
									style="width: 30%" />
							</div>
							<p>{{$t('msg.Atthisointtheonlinefilingprocess')}}</p>

							<div class="topIcoBox" @click="clickTop" :title="$t('msg.Top')">
								<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-zhiding"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>
					<div v-show="item === '参加仲裁'" class="videoBox">
						<video style="width: 50vw" preload="none" controlslist="nodownload" controls
							src="https://scia.oss-cn-shenzhen.aliyuncs.com/%E8%BF%9C%E7%A8%8B%E7%AB%8B%E6%A1%88%E6%B5%81%E7%A8%8B20210622.mp4"></video>
					</div>
					<div v-show="item.nameus === '费用快算'||item.Engname==='Fee Calculator'">
						<h1>{{$t('msg.FeeCalculator')}}</h1>
						<div class="costCountBox">
							<div class="container">
								<div class="radioBox">
									<el-radio v-model="radioCount"
										:label="1">{{$t('msg.DomesticArbitrationCase')}}</el-radio>
									<el-radio v-model="radioCount"
										:label="2">{{$t('msg.ForeignRelatedArbitrationCase')}}</el-radio>
								</div>
								<div class="inputBox">
									<el-row>
										<el-col :span="18">
											<el-input type="number"
												:placeholder="$t('msg.Pleaseenterthecalculatedamount')"
												v-model="moneyCount" clearable></el-input></el-col>
										<el-col :span="4" style="margin-left: 10px"><el-button
												@click="sum_result2024">{{$t('msg.Calculate')}}</el-button></el-col>
									</el-row>
								</div>

								<!-- showTextType -->
								<div class="textBox" v-show="showTextType">
									<h3>{{$t('msg.Result')}}</h3>
									<p>
										{{$t('msg.AmountinDispute')}}：<span>{{ a }}</span>
									</p>
									<p v-if="slh">
										{{$t('msg.RegistrationFee')}}：<span>{{ slh }}</span>
									</p>
									<p v-if="slh">
										{{$t('msg.HandlingFee')}}：<span>{{ clh }}</span>
									</p>
									<p>
										{{$t('msg.TotalAmount')}}：<span>{{ c }}</span>
									</p>
								</div>
								<el-divider></el-divider>
								<h3 style="color: #ce3658">
									{{$t('msg.ThepecificdeliveryamountofthefeeshallCommission')}}
								</h3>
							</div>
						</div>
					</div>
					<div v-show="item.nameus === '办案指引'||item.Engname==='Guidelines on Handling Cases'">
						<h1>{{$t('msg.GuidelinesonHandlingCases')}}</h1>
						<div class="leftTextBox" style="padding-bottom: 0; font-size: 20px">
							<div class="fontType problemBox">
								<el-collapse accordion>
									<el-collapse-item :title="$t('msg.Party')">
										<div class="text">
											<div class="leftTextBox" style="padding-bottom: 0">
												<h3>1.{{$t('msg.Thepartiesbindthecase')}}</h3>
												<p>
													{{$t('msg.Aftersuccessfullyloggingindisplayed')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy1
                                                            )
                                                        " :src="imgObj.bazy1" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.ClickAddCasetobindtheaccount')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy2
                                                            )
                                                        " :src="imgObj.bazy2" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Enterthecasenumbertobeboundherebinding')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy3
                                                            )
                                                        " :src="imgObj.bazy3" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Afterthebindingthetheboundcaseinformation')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy4
                                                            )
                                                        " :src="imgObj.bazy4" alt="" style="width: 50%" />
												</div>
												<h3>2.{{$t('msg.Uploadelectronicdocuments')}}</h3>
												<p>{{$t('msg.Clickotheasename')}}</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy5
                                                            )
                                                        " :src="imgObj.bazy5" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Afterlickitherewillbeinstructionndapplicant')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy6
                                                            )
                                                        " :src="imgObj.bazy6" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Youcanclickafolderdirectortouploadneedstouploadilesrectory')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy7
                                                            )
                                                        " :src="imgObj.bazy7" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.AfterselectingtheidentityfolderclickUpload')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy8
                                                            )
                                                        " :src="imgObj.bazy8" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Clickuploadimagemiddleselectthefiledraganddropupload')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy9
                                                            )
                                                        " :src="imgObj.bazy9" alt="" style="width: 50%" />
												</div>
												<p>
													{{$t('msg.Iftheuploadissuccessfulpleaseuploadeduploadissuccessful')}}
												</p>
												<div class="imgBox">
													<img @click="
                                                            showImagePreview(
                                                                imgObj.bazy10
                                                            )
                                                        " :src="imgObj.bazy10" alt="" style="width: 50%" />
												</div>
												<strong>{{$t('msg.Inthiswindowpartiesarbitratorsndhandlers')}}</strong>
											</div>
										</div>
									</el-collapse-item>
									<el-collapse-item :title="$t('msg.Arbitrator')">
										<div class="text">
											<h3>1.{{$t('msg.Operatingguideforarbitratorend')}}</h3>
											<p>
												<strong>1、{{$t('msg.Registerofarbitrators')}}</strong>
											</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy11
                                                        )
                                                    " :src="imgObj.bazy11" alt="" style="width: 50%" />
											</div>
											<p>
												<strong>{{$t('msg.Notethathearbitratordoeseratedcharactercode')}}</strong>
											</p>
											<p>
												{{$t('msg.Afterenteringtheaccountpasswordanderificationlogging')}}
											</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy12
                                                        )
                                                    " :src="imgObj.bazy12" alt="" style="width: 50%" />
											</div>
											<p>
												{{$t('msg.ClickheArbitratorAdministrationagain')}}
											</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy13
                                                        )
                                                    " :src="imgObj.bazy13" alt="" style="width: 50%" />
											</div>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy14
                                                        )
                                                    " :src="imgObj.bazy14" alt="" style="width: 50%" />
											</div>
											<h3>2、{{$t('msg.Viewelectronicfiles')}}</h3>
											<p>{{$t('msg.AfterlogginginclickonElectronicManagement')}}</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy15
                                                        )
                                                    " :src="imgObj.bazy15" alt="" style="width: 50%" />
											</div>
											<p>
												{{$t('msg.Thearbitratorcanviewllelectronicuploadedbythesecretary')}}
											</p>
											<h3>3、{{$t('msg.Viewcaseprogress')}}</h3>
											<p>
												{{$t('msg.ClicktheViewCaseprogressmenuthefollowingup')}}
											</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy16
                                                        )
                                                    " :src="imgObj.bazy16" alt="" style="width: 50%" />
											</div>
											<h3>4、{{$t('msg.Changasswordandbindwechat')}}</h3>
											<p>
												{{$t('msg.AftersystemclicompleteasswordAshownbelow')}}
											</p>
											<div class="imgBox">
												<img @click="
                                                        showImagePreview(
                                                            imgObj.bazy17
                                                        )
                                                    " :src="imgObj.bazy17" alt="" style="width: 50%" />
											</div>
											<p>
												{{$t('msg.IfwantunbindclickUnbindwindow')}}
											</p>
										</div>
									</el-collapse-item>
								</el-collapse>
							</div>
							<div class="topIcoBox" @click="clickTop" :title="$t('msg.Top')">
								<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-zhiding"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>
					<div v-show="item.nameus === '庭审指引'||item.Engname==='Court Trial Guidelines'">
						<h1>{{$t('msg.Instructionsforusingthevideotrialsystem')}}</h1>
						<div class="leftTextBox">
							<h3>
								{{$t('msg.Computersidetoparticipateincourt')}}<span>{{$t('msg.Completefunctionsrecommended')}}</span>
							</h3>
							<h3>{{$t('msg.StepOpenthevideopage')}}</h3>
							<p>
								{{$t('msg.Checkthatthcameramicrophoneandothernormally')}}<span>{{$t('msg.UsingGoogleChrome')}}</span>{{$t('msg.OR')}}<span>{{$t('msg.Extremebrowser360')}}</span>{{$t('msg.Unfold')}}<span>
									<a href="http://kt.cietac.org" target="_blank">http://kt.cietac.org</a></span>

								。{{$t('msg.Clickonhetopofthehomepage')}}<span>【{{$t('msg.TheWisdomTrial')}}】</span>。
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen1)" :src="imgObj.tinshen1" alt=""
									style="width: 30%" />
							</div>
							<p>1.{{$t('msg.Checkinonline')}}</p>
							<p>
								{{$t('msg.AfterenteringthetrialthtrialageClickon')}}<span>【{{$t('msg.Signin')}}】</span>{{$t('msg.Buttonforidentityinformationcheckpersoncheck')}}
							</p>
							<p>2.{{$t('msg.Pretrialtest')}}</p>
							<p>
								{{$t('msg.Beforetheofficialcourtsessionhesystlldetectetworksession')}}
							</p>
							<p>
								{{$t('msg.Ifyoufindthathemicrophonespeakertheisselectedyoursystem')}}
							</p>
							<p>3.{{$t('msg.Boardinfrontofcourt')}}</p>
							<p>
								{{$t('msg.Beforetheformalcourtsessiyoucancheckndcliwhenready')}}<span>【{{$t('msg.Gototrial')}}】</span>。
							</p>
							<p>4.{{$t('msg.Disciplineoftrial')}}</p>
							<p>
								{{$t('msg.Afterenteringthetrialthetrialdisciplineplayed')}}
							</p>

							<h3>{{$t('msg.Step2Enterthevideotrialsystem')}}</h3>
							<p>{{$t('msg.EnterhetrialcodereceivedintheSMSystem')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen2)" :src="imgObj.tinshen2" alt=""
									style="width: 30%" />
							</div>
							<h3>{{$t('msg.Step3Completetheealpersonertificationtrial')}}</h3>
							<p>
								{{$t('msg.Afterenteringthetrialsystemtheartthetrial')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen3)" :src="imgObj.tinshen3" alt=""
									style="width: 30%" />
							</div>
							<h3>{{$t('msg.Step4Confirmsigningtranscriptscourthighlights')}}</h3>
							<p>
								{{$t('msg.AfterthehearingthecasesecretaryFollowthesesteps')}}
							</p>
							<p>
								1.
								{{$t('msg.CheckagreetotheElectronicSignatureEntrustme')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen4)" :src="imgObj.tinshen4" alt=""
									style="width: 30%" />
							</div>
							<p>
								2.{{$t('msg.CheckhaveverifiedthatmyidentityclickConfirm')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen5)" :src="imgObj.tinshen5" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Check')}}
								<span>{{$t('msg.Pagelocatioofsignature')}}</span>
								，{{$t('msg.AQRcodewilpoppthescreenAfterscanninghecodewithyourclick')}}
								<span>{{$t('msg.Acknowledgereceipt')}}</span>
								。{{$t('msg.Afterconfirmationwaitrial')}}
							</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen6)" :src="imgObj.tinshen6" alt=""
									style="width: 30%" />
							</div>
							<p>
								{{$t('msg.Ifthetranscriptmainpointsofhetrialneedtomodifiedbysecretary')}}
							</p>

							<h3>{{$t('msg.Casefileuploadfunction')}}</h3>
							<p>
								{{$t('msg.Afterreceivingthecourtthevideocourtcode')}}
								{{$t('msg.Afterenteringthecourtystemclickeft')}}
								<span>{{$t('msg.ElectronicMaterial')}}</span> ，{{$t('msg.Click')}}
								<span>{{$t('msg.NewMaterials')}}</span>
								{{$t('msg.Uploadevidencaterialsopinionsparticipantsandotherdocuments')}}
							</p>
							<p>{{$t('msg.Thefileuploadfunctioncanduringhetrial')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.tinshen7)" :src="imgObj.tinshen7" alt=""
									style="width: 30%" />
							</div>

							<el-divider></el-divider>
							<!-- 移动端 -->
							<h3>
								{{$t('msg.Mobileterminalstoparticipateinonlinetrials')}}<span>{{$t('msg.Setupcalltransferindvancetoallsession')}}</span>
							</h3>
							<h3>{{$t('msg.Step1download')}}“云晤”APP</h3>
							<p>{{$t('msg.Methodofdownload')}}：</p>
							<p>
								1．{{$t('msg.Downloadlink')}}https://gdv.yuntrial.com/view/mobile/index/download
							</p>
							<p>2．在安卓应用宝及苹果APP Store{{$t('msg.Download')}}</p>
							<p>3．{{$t('msg.ScantheQRcodebelowtodownload')}}</p>
							<div class="imgBox">
								<img @click="showImagePreview(imgObj.yunwu1)" :src="imgObj.yunwu1" alt=""
									style="width: 10%" />
							</div>

							<h3>{{$t('msg.Step2EnterthetrialcodereceivedSMStoenterthetrial')}}</h3>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.yunwu2)" :src="imgObj.yunwu2" alt=""
									style="width: 10%" />
							</div>
							<h3>{{$t('msg.Step3Completetherealpersoncertificationrial')}}</h3>
							<p>
								{{$t('msg.Afterenteringthetrialsystemrecognitionstartthetrial')}}
							</p>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.yunwu3)" :src="imgObj.yunwu3" alt=""
									style="width: 10%" />
								<img @click="showImagePreview(imgObj.yunwu4)" :src="imgObj.yunwu4" alt=""
									style="width: 10%; margin-left: 30px" />
							</div>

							<h3>{{$t('msg.Step4Confirmsigningtranscriptscourthighlights')}}</h3>

							<p>
								{{$t('msg.Afterheasesecretaryinitiatesthetrmationclickright')}}
								<span>{{$t('msg.Recordofwords')}}</span> ，{{$t('msg.Checkfirst')}}
								<span>{{$t('msg.IagretotheElectronicSignatureAgreement')}}</span>
								，{{$t('msg.Andthenclickagain')}}
								<span>{{$t('msg.Recordconfirmation')}}</span>
								。{{$t('msg.Afteronfirmingthetranscriptrialpointsclick')}}
								<span>【{{$t('msg.Confirm')}}】</span> 。{{$t('msg.Afterconfirmationwaitforend')}}
							</p>

							<p>
								{{$t('msg.Ifthetranscripttrialpointsmendedyoucancretarywillchangeit')}}
							</p>

							<div class="imgBox">
								<img @click="showImagePreview(imgObj.yunwu5)" :src="imgObj.yunwu5" alt=""
									style="width: 10%" />
								<img @click="showImagePreview(imgObj.yunwu6)" :src="imgObj.yunwu6" alt=""
									style="width: 10%; margin-left: 30px" />
							</div>

							<h3>{{$t('msg.Themobileterminaldoesnotuploading')}}</h3>
							<el-divider></el-divider>
							<h3>{{$t('msg.NewslettersAttendonlinetrials')}}</h3>
							<p>
								{{$t('msg.Telephoneaccesssavailabdnswerthehonetoparticipateinthetrial')}}
							</p>

							<div class="topIcoBox" @click="clickTop" title="置顶">
								<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-zhiding"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>
					<div v-show="item === '举证指引'">
						<h1>举证指引</h1>
					</div>
					<div v-show="item.nameus === '申请开票'||item.Engname==='Apply for Billing'">
						<h1>{{$t('msg.Applyforinvoiceinstructionsnline')}}</h1>
						<div class="leftTextBox">
							<p>
								{{$t('msg.ThecasenumbeD20233915andthenvoice')}}
							</p>
							<p>{{$t('msg.Thespecificstepsarefollows')}}</p>
							<p>
								1、{{$t('msg.Pleasevisitourfficialwebsite')}}
								<a href="http://www.cietac.org" target="_blank">www.cietac.org</a>
								，{{$t('msg.ScantheQcodCIETACeattheAPPStore')}}
							</p>
							<p>2、{{$t('msg.OpenCIETACtheAPPelow')}}</p>
							<div class="imgBox">
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao1)
                                    " :src="imgObj.shenqingfapiao1" alt="" style="width: 15%" />
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao2)
                                    " :src="imgObj.shenqingfapiao2" alt="" style="width: 15%; margin: 0 20px" />
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao3)
                                    " :src="imgObj.shenqingfapiao3" alt="" style="width: 15%" />
							</div>
							<p>
								{{$t('msg.Firstofllselectthepartybuttoninoiceonline')}}
							</p>
							<p>
								<strong>{{$t('msg.Notenvoiceleasetheloginheextractioncode')}}</strong>
							</p>
							<p>3、{{$t('msg.Submittheinvoicepplicationbelow')}}</p>
							<div class="imgBox">
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao4)
                                    " :src="imgObj.shenqingfapiao4" alt="" style="width: 15%" />
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao5)
                                    " :src="imgObj.shenqingfapiao5" alt="" style="width: 15%; margin: 0 20px" />
								<img @click="
                                        showImagePreview(imgObj.shenqingfapiao6)
                                    " :src="imgObj.shenqingfapiao6" alt="" style="width: 15%" />
							</div>
							<p>
								{{$t('msg.Afteruccessfulhenvoicecanseetheubmissionstatus')}}
							</p>
							<p>
								<strong>{{$t('msg.NoteAccordinpartiestothecasedissue')}}</strong>
							</p>
							<p>
								<strong>{{$t('msg.Pleasecheckthebillingubmissionofficialsealbefore')}}</strong>
							</p>
							<div class="imgBox" style="display: flex; justify-content: center">
								<img @click="
                                        showImagePreview(imgObj.fpsqAndroid)
                                    " :src="imgObj.fpsqAndroid" alt="" style="
                                        width: 10%;
                                        background-color: #0067a6;
                                    " />
								<img @click="showImagePreview(imgObj.fpsqIOS)" :src="imgObj.fpsqIOS" alt="" style="
                                        width: 10%;
                                        margin-left: 30px;
                                        background-color: #0067a6;
                                    " />
							</div>
							<div class="topIcoBox" @click="clickTop" title="置顶">
								<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-zhiding"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>
					<div v-show="item.nameus === '申请退费'||item.Engname==='Apply for Refund'">
						<h1>{{$t('msg.RefundofArbitrationFeesforWithdrawalofCase')}}</h1>
						<div class="leftTextBox">
							<p>
								{{$t('msg.TheseMeasuresreformulateiccordancewithithdrawn')}}
							</p>
							<h3>{{$t('msg.Article1Refundsomesticases')}}</h3>
							<p>
								1、{{$t('msg.Ifthcaswithdrbeforehormationfthendlingfeeshallreturned')}}
							</p>
							<p>
								2、{{$t('msg.Ifacaseswithdrawnbitrationfeechargedhallotbeless')}}
							</p>
							<p>
								3、{{$t('msg.Ifthearbitrationtribunallcircumstancesrefundedundheprocessingfee')}}
							</p>
							<h3>{{$t('msg.ArticleRefundforforeigrelatedcases')}}</h3>
							<p>
								1、{{$t('msg.Ifthecaseiswithdrawnbeforeefeethebelowreturned')}}
							</p>
							<p>
								2、{{$t('msg.Ifthithdrawnrationrbitrationfeeshallreturned')}}
							</p>
							<p>
								3、{{$t('msg.Ifthearbitrationtribunalearbitrationfee')}}
							</p>
							<h3>
								{{$t('msg.ArticleRefundsfortheCommissionnonattendancereason')}}
							</h3>
							<p>
								{{$t('msg.Ifanarbitrationwithdrawnapeopleisourtfthereturned')}}
							</p>
							<p>
								{{$t('msg.Ifthearbitrationcasedueshalligherthanarbitrationfee')}}
							</p>
							<p>
								{{$t('msg.Ifpauponwrittennoticeppearinnorefundshallbegranted')}}
							</p>
							<h3>
								{{$t('msg.ArticlRefundsshallapplyArbitrationdradeArbitrationCommission')}}
							</h3>
							<p>
								{{$t('msg.TherefundoffeesincaseswhertheChinaeasures')}}
							</p>
							<p>
								{{$t('msg.ThesemeasuresareapprovedytheCIETACecretarBureau')}}
							</p>
						</div>
					</div>
					<div v-show="item.nameus === '仲裁申请须知'||item.Engname==='Notes on Application for Arbitration'">
						<h1>{{$t('msg.NotesonApplicationforArbitration')}}</h1>
						<div class="leftTextBox">
							<p>
								{{$t('msg.WelcometoChinaInternationalEconomicandtration')}}
							</p>
							<p>
								一、{{$t('msg.ArbitrationtoAssociationgenerallyauseinthecontracagreement')}}
							</p>
							<p>二、{{$t('msg.Tofileanapplicationyouanchoose')}}</p>
							<p>1. {{$t('msg.Onsitesubmissionnoreservation')}}；</p>
							<p>2. {{$t('msg.Filingbymail')}}；</p>
							<p>
								{{$t('msg.TheaddressformailDistrictBeing')}}
							</p>
							<p>
								3.
								{{$t('msg.Thepartiesosemitapplicationplatformis')}}
								<a href="http://www.cietacodr.org" target="_blank">www.cietacodr.org</a>
							</p>
							<p>三、{{$t('msg.Whenapplyingrbitrationpleaseocuments')}}</p>
							<p>
								一）{{$t('msg.Applicationforarbitrationandtharbitrationwritten')}}
							</p>
							<p>
								1.
								{{$t('msg.Unlessotherwisepartientiaryfivecopieseach')}}
							</p>
							<p>
								2.
								{{$t('msg.Ifthereismoreespondentlsshallbeaddedaccordingly')}}
							</p>
							<p>
								3.
								{{$t('msg.Iftheapplicantasbitrationdocumentesametime')}}<span>{{$t('msg.Pleaseubmitaclearwrittenrequest')}}</span>，{{$t('msg.Andsubmitcorrespondingnumberofmaterials')}}
							</p>
							<p>
								4.
								{{$t('msg.Theapplicationfoneedsompanysealorsignedbyepresentative')}}
							</p>
							<p>
								{{$t('msg.Evidencedocumentsenerallytocopiesecrossexamination')}}
							</p>
							<p>
								（二）{{$t('msg.Ifappointhandlethearbitratiriginalpoweropies')}}
							</p>
							<p>
								（三）{{$t('msg.Ifapplicantiscompanyplease')}}<span>{{$t('msg.Pershare')}}</span>{{$t('msg.Submitofcompantiveaturalpersonplease')}}<span>{{$t('msg.Pershare')}}</span>{{$t('msg.Submitcopiesdentitydocumentsassport')}}
							</p>
							<p>（四）{{$t('msg.Ifyouneedtoapplypropertypreservatipreservationplease')}}</p>
							<p>
								1.
								{{$t('msg.Submitapropertypreservationapplicationevide')}}
							</p>
							<p>2. {{$t('msg.Submitoneadditionalapplicationforarbitration')}}；</p>
							<p>
								3.
								{{$t('msg.Givewritteninstructionstothespecificourt')}}
							</p>
							<p>
								三、{{$t('msg.Pleasepplicationeatlydocumentedpaginabound')}}
							</p>
							<p>
								四、{{$t('msg.Pleasepayheinbebornebythelosingparty')}}
							</p>
							<p>
								五、{{$t('msg.Ifyouhaveanyotherquesti82217766website')}}
								<a href="http://www.cietacodr.org" target="_blank">www.cietacodr.org</a>。
							</p>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import LangOption from '@/components/LangOption'
	export default {
		name: 'searchCuideTab',
		components: {
			LangOption
		},
		data() {
			return {
				activeName: '',
				tabsList: [
					{
						nameus:'常见问题',
						Engname:'Common Problem',
					},
					{
						nameus:'费用快算',
						Engname:'Fee Calculator',
					},
					{
						nameus:'注册指引',
						Engname:'Guide to Registration',
					},
					{
						nameus:'立案指引',
						Engname:'Guidelines for Filing',
					},
					{
						nameus:'办案指引',
						Engname:'Guidelines on Handling Cases',
					},
					{
						nameus:'庭审指引',
						Engname:'Court Trial Guidelines',
					},
					{
						nameus:'申请开票',
						Engname:'Apply for Billing',
					},
					{
						nameus:'申请退费',
						Engname:'Apply for Refund',
					},
					{
						nameus:'仲裁申请须知',
						Engname:'Notes on Application for Arbitration',
					}
					// '常见问题(Common Problem)',
					// '费用快算(Fee Calculator)',
					// '注册指引(Guide to Registration)',
					// '立案指引(Guidelines for Filing)',
					// '办案指引(Guidelines on Handling Cases)',
					// '庭审指引(Court Trial Guidelines)',
					// '举证指引',
					// '申请开票(Apply for Billing)',
					// '申请退费(Apply for Refund)',
					// '仲裁申请须知(Notes on Application for Arbitration)'
				],
				// tabsListtues:[
				// 	this.$t('msg.CommonProblem'),
				// 	'费用快算',
				// 	'注册指引',
				// 	'立案指引',
				// 	'办案指引',
				// 	'庭审指引',
				// 	// '举证指引',
				// 	'申请开票',
				// 	'申请退费',
				// 	'仲裁申请须知'
				// ],
				// 计算金额
				moneyCount: '',
				// 案件分类
				radioCount: 1,
				// 处理费
				clh: '',
				// 受理费
				slh: '',
				obj4: '',
				obj5: '',
				obj6: '',
				c: '',
				a: '',
				// 展示结果
				showTextType: false,
				storedProides: '',
				problemListuess: [{
						title: '我们能到中国国际经济贸易仲裁委员会来解决争议吗？',
						content: [{
								text: '1.通过向中国国际经济贸易仲裁委员会提请仲裁的方式解决争议，需要您与对方的合同中包含有效的仲裁条款（或者有单独的仲裁协议），明确规定一旦双方发生争议，应提交中国国际经济贸易仲裁委员会，通过仲裁的方式解决。'
							},
							{
								text: '2.如果没有仲裁条款（仲裁协议），可以请求本仲裁委员会对双方的纠纷进行调解。'
							},
							{
								text: '示范仲裁条款（一）：凡因本合同引起的或与本合同有关的任何争议，均应提交中国国际经济贸易仲裁委员会，按照申请仲裁时该会现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。'
							},
							{
								text: '示范仲裁条款（二）：凡因本合同引起的或与本合同有关的任何争议，均应提交中国国际经济贸易仲裁委员会__________分会（仲裁中心），按照仲裁申请时中国国际经济贸易仲裁委员会现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。'
							}
						]
					},
					{
						title: '哪些类型的争议可提交仲裁？',
						content: [{
								text: '涉及国际、涉外（涉港、澳、台）和国内的契约性或非契约性的经济贸易争议均可提交本会仲裁。但本会不受理以下争议：'
							},
							{
								text: '（1）婚姻、收养、监护、扶养、继承争议；'
							},
							{
								text: '（2）依法应当由行政机关处理的行政争议；'
							},
							{
								text: '（3）劳动争议和农业集体经济组织内部的农业承包合同争议。'
							}
						]
					},
					{
						title: '一方当事人是政府机构，可以申请仲裁吗？',
						content: [{
							text: '如果双方之间涉及的是经济贸易纠纷，而不是行政争议，即使一方当事人是政府机构，也可以申请仲裁。'
						}]
					},
					{
						title: '申请仲裁需要提交哪些文件？',
						content: [{
							text: '请见“申请仲裁须知”。<a href="http://www.cietac.org/index.php?m=Page&a=index&id=4" target="_blank">http://www.cietac.org/index.php?m=Page&a=index&id=4</a>'
						}]
					},
					{
						title: '是否必须委托律师才可处理仲裁案件？',
						content: [{
							text: '请当事人根据自身情况判断是否需要委托律师代为办理仲裁案件。本会不强制要求当事人必须委托律师，也不会为当事人推荐律师，也不与任何律师事务所进行案件方面的合作。（加授权委托书模板）'
						}]
					},
					{
						title: '仲裁案件中只能有两位代理人吗？',
						content: [{
							text: '仲裁案件中，并不限制代理人的人数。当事人提交授权委托书即可。（加授权委托书模板）'
						}]
					},
					{
						title: '有关文件是否需要经过公证认证？',
						content: [{
								text: '在立案阶段，境外形成的主体文件、授权委托书及证据材料等，不必须进行公证认证、即可提交立案申请。'
							},
							{
								text: '如果仲裁庭在案件审理过程中认为有必要进行公证认证，当事人则应予办理；如对方当事人在案件审理过程中提出相应材料应当进行公证认证，请根据案件实际情况或仲裁庭的安排处理。'
							}
						]
					},
					{
						title: '有关文件是否需要经过翻译？',
						content: [{
								text: '原则上，案件文件应与本案仲裁语言保持一致。仲裁语言请参考《仲裁规则》（2024版）的下述规定：'
							},
							{
								text: '（一）当事人对仲裁语言有约定的，从其约定。'
							},
							{
								text: '（二）当事人对仲裁语言没有约定的，以中文为仲裁语言。仲裁委员会也可以在适当考虑合同所用语言在内的所有情况后决定使用一种或数种语言进行仲裁。仲裁庭组成后，可根据案件具体情形重新确定仲裁程序使用的仲裁语言。'
							},
							{
								text: '（三）仲裁庭开庭时，当事人或其代理人、证人需要语言翻译的，可由仲裁委员会仲裁院提供译员，也可由当事人自行提供译员。'
							},
							{
								text: '（四）当事人提交的各种文书和证明材料，仲裁庭或仲裁委员会仲裁院认为必要时，可以要求当事人提供相应的中文译本或其他语言译本。'
							},
							{
								text: '因此，在立案阶段，原始文件或证据是中文或英文的，不必须翻译、即可提交立案申请。但部分仲裁委员会在立案阶段必须审查的文件（如主体资格、授权委托书及案涉合同等文件）是其他语言，则需要当事人在立案阶段提供英文或中文翻译件。'
							}
						]
					},
					{
						title: '仲裁文件必须当面提交吗？',
						content: [{
								text: '仲裁文件不一定当面提交。'
							},
							{
								text: '当事人可以选择邮寄立案，地址为：北京市西城区桦皮厂胡同2号国际商会大厦6层 中国国际经济贸易仲裁委员会 邮政编码：100035。'
							},
							{
								text: '当事人可以选择通过贸仲智慧平台，在线提交立案申请，网址为：<a href="http://www.cietacodr.org" target="_blank">www.cietacodr.org</a>'
							}
						]
					},
					{
						title: '申请仲裁需要交费吗？',
						content: [{
							text: '需要，申请人须预付仲裁费。仲裁费根据仲裁请求的金额按比例收取。该项费用属预付性质，最终由谁承担，将由仲裁庭在裁决中予以决定。原则上，仲裁费由败诉方承担。如果受理后因双方和解解决或由仲裁庭调解解决，有可能会酌情退回部分仲裁费。'
						}]
					},
					{
						title: '预交仲裁费有期限吗？',
						content: [{
								text: '申请人预交仲裁费没有严格的期限，原则上仲裁委员会收到的邮寄立案材料将保留三个月，实践中将会尽量延长立案材料保存的时间，在此期间内，申请人均可缴纳仲裁费。'
							},
							{
								text: '但是提醒申请人注意的是，仲裁委员会只有在申请人办理完毕付款手续后才能受理案件，开始仲裁程序。'
							}
						]
					},
					{
						title: '申请人在仲裁前能申请财产保全吗？',
						content: [{
							text: '根据《中华人民共和国仲裁法》第二十八条、第四十六条的规定，申请人的保全申请将由仲裁委员会提交相应人民法院。'
						}]
					},
					{
						title: '申请保全的材料、如何确定保全法院？',
						content: [{
								text: '因各地关于财产保全的规定不同，建议当事人根据《中华人民共和国民事诉讼法》的相关规定确定有管辖权的财产保全法院，并咨询相应人民法院保全所需材料。'
							},
							{
								text: '特别提示：根据【2006】京高法发357号文件，涉及北京市的仲裁保全、执行事宜，统一由中级人民法院管辖。'
							}
						]
					},
					{
						title: '仲裁庭做出的裁决在国外能执行吗？',
						content: [{
							text: '本会仲裁庭做出的裁决可以根据联合国“承认及执行外国仲裁裁决公约”（《纽约公约》）在全球134个国家和地区得到承认和执行，并可根据有关安排在中国香港特别行政区得到承认和执行。'
						}]
					},
					{
						title: '到境外执行仲裁裁决应办理什么手续？',
						content: [{
							text: '到境外执行本会仲裁裁决应到被执行人财产所在地法院进行申请，根据当地的有关法律办理相关手续。建议当事人委托当地律师协助办理。'
						}]
					}
				],


				problemList: [{
						title: 'Can we go to the China International Economic and Trade Arbitration Commission to settle the dispute?',
						content: [{
								text: '1. To settle a dispute by submitting it to the China International Economic and Trade Arbitration Commission for arbitration, the contract between you and the other party shall contain a valid arbitration clause (or a separate arbitration agreement), which clearly stipulates that in case of any dispute between the two parties, the dispute shall be submitted to the China International Economic and Trade Arbitration Commission for arbitration.'
							},
							{
								text: '2. If there is no arbitration clause (arbitration agreement), the arbitration Commission may be requested to mediate the dispute between the two parties.'
							},
							{
								text: 'Model Arbitration Clause (1): Any dispute arising out of or in connection with this contract shall be submitted to the China International Economic and Trade Arbitration Commission for arbitration in accordance with the arbitration rules in force at the time of application for arbitration. The arbitral award shall be final and binding on both parties.'
							},
							{
								text: 'Model Arbitration Clause (2): Any dispute arising out of or in connection with this contract shall be submitted to the __________ Sub-Commission of the China International Economic and Trade Arbitration Commission (CIETAC) for arbitration in accordance with the arbitration rules currently in force at the time of the application for arbitration. The arbitral award shall be final and binding on both parties.'
							}
						]
					},
					{
						title: 'What types of disputes can be submitted for arbitration?',
						content: [{
								text: 'International, foreign (Hong Kong, Macao, Taiwan) and domestic contractual or non-contractual economic and trade disputes may be submitted to the Council for arbitration. However, the Association will not accept the following disputes:'
							},
							{
								text: '(1) Marriage, adoption, guardianship, maintenance, succession disputes;'
							},
							{
								text: '(2) Administrative disputes that should be handled by administrative organs according to law;'
							},
							{
								text: '(3) Labor disputes and disputes over agricultural contracts within agricultural collective economic organizations.'
							}
						]
					},
					{
						title: 'A party is a government agency. Can it apply for arbitration?',
						content: [{
							text: 'If the two parties are involved in an economic and trade dispute, rather than an administrative dispute, even if one of the parties is a government agency, they can apply for arbitration.'
						}]
					},
					{
						title: 'What documents do I need to submit for arbitration?',
						content: [{
							text: 'Please refer to the "Notes on Application for Arbitration"。<a href="http://www.cietac.org/index.php?m=Page&a=index&id=4" target="_blank">http://www.cietac.org/index.php?m=Page&a=index&id=4</a>'
						}]
					},
					{
						title: 'Is it necessary to appoint a lawyer to handle an arbitration case?',
						content: [{
							text: 'The parties are invited to judge whether they need to entrust a lawyer to handle the arbitration case according to their own circumstances. The Association does not require clients to appoint lawyers, nor does it recommend lawyers for clients, nor does it cooperate with any law firm on cases. (Plus power of attorney template)'
						}]
					},
					{
						title: 'Is there only two agents in an arbitration case?',
						content: [{
							text: 'There is no limit to the number of agents in an arbitration case. The parties may submit a power of attorney. (Plus power of attorney template)'
						}]
					},
					{
						title: 'Do the documents need to be notarized?',
						content: [{
								text: 'In the filing stage, the main documents, power of attorney and evidentiary materials formed abroad do not have to be notarized and certified, and the filing application can be submitted.'
							},
							{
								text: 'If the arbitral tribunal in the course of the case considers it necessary to conduct notarial certification, the parties shall do so; If the other party proposes that the relevant materials should be notarized and certified during the trial of the case, please handle the matter according to the actual situation of the case or the arrangement of the arbitration tribunal.'
							}
						]
					},
					{
						title: 'Does the document need to be translated?',
						content: [{
								text: 'In principle, the case documents should be consistent with the arbitration language of the case. Please refer to the following provisions of the Arbitration Rules (2024) :'
							},
							{
								text: '(1) If the parties agree on the language of arbitration, such agreement shall prevail.'
							},
							{
								text: '(2) If the parties do not agree on the language of arbitration, Chinese shall be the language of arbitration. The arbitration Commission may also decide to arbitrate in one or more languages, having due regard to all the circumstances, including the language of the contract. After the arbitration tribunal is formed, the arbitration language used in the arbitration proceedings may be re-determined according to the specific circumstances of the case.'
							},
							{
								text: '(3) If the parties or their agents or witnesses require language translation during the hearing of the arbitral tribunal, the arbitration Court of the Arbitration Commission may provide an interpreter, or the parties themselves may provide an interpreter.'
							},
							{
								text: '(4) The arbitral tribunal or the arbitration court of the Arbitration Commission may, when it deems it necessary, require the parties to provide corresponding Chinese or other language translations of the documents and supporting materials submitted by the parties.'
							},
							{
								text: 'Therefore, at the filing stage, if the original document or evidence is in Chinese or English, the filing application can be submitted without translation. However, some of the documents that the arbitration Commission must examine at the filing stage (such as the qualification of the principal, the power of attorney and the contract involved in the case) are in other languages, and the parties are required to provide English or Chinese translations at the filing stage.'
							}
						]
					},
					{
						title: 'Must the arbitration document be presented in person?',
						content: [{
								text: 'Arbitration documents are not necessarily presented in person.'
							},
							{
								text: 'The parties may choose to file the case by mail, and the address is: China International Economic and Trade Arbitration Commission, 6th Floor, International Chamber of Commerce Building, 2 Huapichang Hutong, Xicheng District, Beijing 100035, China.'
							},
							{
								text: 'The parties may choose to submit the application online through the MAzhong Smart platform, the website is:<a href="http://www.cietacodr.org" target="_blank">www.cietacodr.org</a>'
							}
						]
					},
					{
						title: 'Is there any fee for applying for arbitration?',
						content: [{
							text: 'Yes, the applicant must pay the arbitration fee in advance. The arbitration fee shall be charged in proportion to the amount of the arbitration claim. The cost will be prepaid and the final liability will be determined by the arbitral Tribunal in its award. In principle, the arbitration fee shall be borne by the losing party. If the arbitration is settled by settlement or mediation by the arbitral tribunal, part of the arbitration fee may be returned at the discretion of the parties.'
						}]
					},
					{
						title: 'Is there a time limit for pre-payment of arbitration fee?',
						content: [{
								text: 'There is no strict time limit for the applicant to pay the arbitration fee in advance. In principle, the filing materials received by the arbitration Commission by mail will be retained for three months. In practice, the retention time of filing materials will be extended as far as possible.'
							},
							{
								text: 'However, the applicant should be reminded that the arbitration commission can only accept the case and begin the arbitration procedure after the applicant has completed the payment procedures.'
							}
						]
					},
					{
						title: 'Can an applicant apply for property preservation before arbitration?',
						content: [{
							text: 'In accordance with Articles 28 and 46 of the Arbitration Law of the People is Republic of China, the applicant is application for preservation shall be submitted by the arbitration Commission to the relevant people is court.'
						}]
					},
					{
						title: 'Application for preservation materials, how to determine the court of preservation?',
						content: [{
								text: 'Due to the different provisions on property preservation in different places, it is recommended that the parties determine the property preservation court with jurisdiction according to the relevant provisions of the Civil Procedure Law of the People is Republic of China, and consult the relevant people is court for materials required for preservation.'
							},
							{
								text: 'Special note: According to [2006] Jinggao Law issued No. 357, involving Beijing arbitration preservation, enforcement matters, uniformly under the jurisdiction of the intermediate People is Court.'
							}
						]
					},
					{
						title: 'Can an award made by an arbitral tribunal be enforced abroad?',
						content: [{
							text: 'The awards made by the Arbitral Tribunal of the United Nations may be recognized and enforced in 134 countries and regions around the world in accordance with the United Nations Convention on the Recognition and Enforcement of Foreign Arbitral Awards (the New York Convention), and may be recognized and enforced in the Hong Kong Special Administrative Region of China in accordance with relevant arrangements.'
						}]
					},
					{
						title: 'What are the procedures for enforcing an arbitration award abroad?',
						content: [{
							text: 'To enforce the arbitration award abroad, the applicant shall apply to the court where the property of the person subject to enforcement is located, and go through the relevant procedures in accordance with the relevant local laws. It is suggested that the parties entrust local lawyers to assist in handling.'
						}]
					}
				],

				// tinshen  庭审
				//    yunwu    云吾
				// shenqingfapiao  申请发票
				// fpsqAndroid   发票申请安卓
				// fpsqIOS     发票申请苹果
				// zczy    注册指引
				// bazy     办案指引
				// lazy      立案指引
				imgObj: {
					fpsqAndroid: require('@/assets/image/guideImage/fpsqAndroid.png'),
					fpsqIOS: require('@/assets/image/guideImage/fpsqIOS.png')
				}
			}
		},
		// computed: {
		// 	displayedTabs() {
		// 		return this.storedProides === 'zh' ? this.tabsList : this.tabsListtues;
		// 	}
		// },
		mounted() {
			this.getures()
		},

		created() {
			let type = this.$route.query.type
			this.activeName = type
			// 最开始直接引入图片
			// tinshen
			for (let i = 1; i < 7; i++) {
				this.imgObj[
					`tinshen${i}`
				] = require(`@/assets/image/guideImage/tinshen${i}.png`)
			}
			// yunwu   +   shenqingfapiao
			for (let i = 1; i <= 6; i++) {
				this.imgObj[
					`yunwu${i}`
				] = require(`@/assets/image/guideImage/yunwu${i}.png`)
				this.imgObj[
					`shenqingfapiao${i}`
				] = require(`@/assets/image/guideImage/shenqingfapiao${i}.png`)
			}
			// zczy
			for (let i = 1; i <= 11; i++) {
				this.imgObj[
					`zczy${i}`
				] = require(`@/assets/image/guideImage/zczy${i}.png`)
			}
			// bazy
			for (let i = 1; i <= 17; i++) {
				this.imgObj[
					`bazy${i}`
				] = require(`@/assets/image/guideImage/bazy${i}.png`)
			}
			// lazy
			for (let i = 1; i <= 30; i++) {
				this.imgObj[
					`lazy${i}`
				] = require(`@/assets/image/guideImage/lazy${i}.png`)
			}
		},
		methods: {
			getures() {
				this.storedProides = localStorage.getItem('lang');
				console.log(this.storedProides, '88888888FFGDDDD');
			},
			// 计算公式
			sum_result2024() {
				if (this.moneyCount === '') {
					return this.$message.info('请先输入计算金额')
				}
				this.showTextType = true
				// let obj5 = 0
				// let obj6 = 0
				let obj4 = 0
				let a = this.moneyCount
				let clh //处理费
				let slh //受理费
				let c //c = slh + clh ;   处理费 + 受理费
				let b = 1

				//国内
				if (this.radioCount == 1) {
					//受理费
					if (a <= 100000) {
						//1000元至100000元 争议金额的4%，最低不少于100元
						slh = (a * 4) / 100
						if (slh < 100) {
							slh = 100
						}
					} else if (100000 < a && a <= 500000) {
						//100,001元至500,000元 4,000元+争议金额100,000元以上部分的2%
						slh = 4000 + ((a - 100000) * 2) / 100
					} else if (500000 < a && a <= 1000000) {
						//500,001元至1,000,000元 12,000元+争议金额500,000元以上部分的1%
						slh = 12000 + ((a - 500000) * 1) / 100
					} else if (1000000 < a && a <= 50000000) {
						//100,001元至5,000,000元 17,000元+争议金额1,000,000元以上部分的0.5%
						slh = 17000 + ((a - 1000000) * 0.5) / 100
					} else if (50000000 < a && a <= 300000000) {
						//50,000,001元至300,000,000元 262,000元+争议金额50,000,000元以上部分的0.48%
						slh = 262000 + ((a - 50000000) * 0.48) / 100
					} else if (300000000 < a && a <= 1000000000) {
						//300,000,001元至1,000,000,000元 1,462,000元+争议金额300,000,000元以上部分的0.46%
						slh = 1462000 + ((a - 300000000) * 0.46) / 100
					} else if (1000000000 < a && a <= 2000000000) {
						//1,000,000,001元至2,000,000,000元 4,682,000元+争议金额1,000,000,000元以上部分的0.42%
						slh = 4682000 + ((a - 1000000000) * 0.42) / 100
					} else if (2000000000 < a) {
						//2,000,000,001元以上 8,882,000元+争议金额2,000,000,000元以上部分的0.4%
						if (a > 3000000000) {
							a = 3000000000 //30亿以上部分不收费
						}
						slh = 8882000 + ((a - 2000000000) * 0.4) / 100
					}

					//处理费
					if (0 <= a && a <= 200000) {
						clh = 6000 //20万元以下 最低不少于6,000元
					} else if (200000 < a && a <= 500000) {
						//20万元至50万元 6,000元+争议金额20万元以上部分的2%
						clh = 6000 + ((a - 200000) * 2) / 100
					} else if (500000 < a && a <= 1000000) {
						//50万元至100万元 12,000元+争议金额50万元以上部分的1.5%
						clh = 12000 + ((a - 500000) * 1.5) / 100
					} else if (1000000 < a && a <= 5000000) {
						//100万元至500万元 19,500元+争议金额100万元以上部分的0.45%
						clh = 19500 + ((a - 1000000) * 0.45) / 100
					} else if (5000000 < a && a <= 20000000) {
						//500万元至2000万元 37,500元+争议金额500万元以上部分的0.3%
						clh = 37500 + ((a - 5000000) * 0.3) / 100
					} else if (5000000 < a && a <= 100000000) {
						//2000万元至1亿元 82,500元+争议金额2000万元以上部分的0.2%
						clh = 82500 + ((a - 20000000) * 0.2) / 100
					} else if (100000000 < a && a <= 1000000000) {
						//1亿元至10亿元 242,500元+争议金额1亿元以上部分的0.1%
						clh = 242500 + ((a - 100000000) * 0.1) / 100
					} else if (1000000000 < a) {
						//10亿元以上 1,142,500元+争议金额10亿元以上部分的0.03%
						if (a > 3000000000) {
							a = 3000000000 //30亿以上部分不收费用
						}
						clh = 1142500 + ((a - 1000000000) * 0.03) / 100
					}
					//c = slh + clh ; 取完四舍五入后再加
					// if (obj5 != null) obj5.value = Math.round(clh / b)
					// if (obj6 != null) obj6.value = Math.round(slh / b)
					// obj5 = Math.round(clh / b)
					// obj6 = Math.round(slh / b)
					c = Math.round(clh / b) + Math.round(slh / b)
				} else {
					//涉外
					if (0 <= a && a < 1000000) {
						//1,000,000元以下 争议金额的4%,最低 不少于10,000元
						c = (a * 4) / 100
						if (c < 10000) {
							c = 10000
						}
					} else if (1000000 <= a && a < 2000000) {
						//1,000,000元至2,000,000元 40,000元+争议金额 1,000,000元以上部分的3.5%
						c = 40000 + ((a - 1000000) * 3.5) / 100
					} else if (2000000 <= a && a < 5000000) {
						//2,000,000元至5,000,000元 35,000元+争议金额 2,000,000元以上部分的2.5%
						c = 75000 + ((a - 2000000) * 2.5) / 100
					} else if (5000000 <= a && a < 10000000) {
						//5,000,000元至10,000,000元 150,000元+争议金额 5,000,000元以上部分的1.5%
						c = 150000 + ((a - 5000000) * 1.5) / 100
					} else if (10000000 <= a && a < 100000000) {
						//10,000,000元至100,000,000元 225,000元+争议金额 10,000,000元以上部分的1%
						c = 225000 + ((a - 10000000) * 1) / 100
					} else if (100000000 <= a && a < 300000000) {
						//100,000,000元至300,000,000元 1,125,000元+争议金额 100,000,000元以上部分的0.65%
						c = 1125000 + ((a - 100000000) * 0.65) / 100
					} else if (300000000 <= a && a < 10000000000) {
						//300,000,000元至1,000,000,000元 2,425,000元+争议金额 300,000,000元以上部分的0.6%
						c = 2425000 + ((a - 300000000) * 0.6) / 100
					} else if (1000000000 <= a && a < 2000000000) {
						//1,000,000,000元至2,000,000,000元 6,675,000元+争议金额 1,000,000,000元以上部分的0.45%
						c = 6675000 + ((a - 1000000000) * 0.45) / 100
					} else if (2000000000 <= a) {
						//2,000,000,000元以上 11,175,000元+争议金额 2,000,000,000元以上部分的0.4%
						if (a > 3000000000) {
							a = 3000000000 //超过30亿以上部分不计费
						}
						c = 11175000 + ((a - 2000000000) * 0.4) / 100
					}
					//涉外要加10000
					c = c + 10000
				}
				var sum_result = c / b
				sum_result = Math.round(sum_result)
				obj4 = sum_result

				// console.log('--------------')

				this.clh = clh
				this.slh = slh
				this.obj4 = obj4

				this.c = c
				this.a = a
				obj4
				// console.log('--------------')
			},

			// 点击了置顶按钮
			clickTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
			},

			// 放大图片的js方法
			showImagePreview(src) {
				const image = new Image()
				image.src = src
				image.onload = () => {
					// 创建弹出层
					const previewContainer = document.createElement('div')
					previewContainer.style.position = 'fixed'
					previewContainer.style.top = 0
					previewContainer.style.bottom = 0
					previewContainer.style.left = 0
					previewContainer.style.right = 0
					previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
					previewContainer.style.display = 'flex'
					previewContainer.style.justifyContent = 'center'
					previewContainer.style.alignItems = 'center'
					previewContainer.style.zIndex = '99'
					document.body.appendChild(previewContainer)
					// 在弹出层中添加图片
					const previewImage = document.createElement('img')
					previewImage.src = src
					previewImage.style.maxWidth = '80%'
					previewImage.style.maxHeight = '80%'
					previewContainer.appendChild(previewImage)
					// 点击弹出层，关闭预览
					previewContainer.addEventListener('click', () => {
						document.body.removeChild(previewContainer)
					})
				}
			}
		},
		watch: {
			radioCount() {
				this.showTextType = false
				this.moneyCount = ''
			},
			activeName() {
				this.showTextType = false
				this.moneyCount = ''
			}
		}
	}
</script>

<style scoped lang="scss">
	:deep(.my-tabs .el-tabs__item) {
		height: 3.5vw !important;

		&:last-child {
			border-bottom: 1px solid #e4e7ed;
		}

		font-size: 19px;
	}

	:deep(.my-tabs .el-tabs__item.is-active) {
		background-color: #4578ff !important;
		color: #fff;
	}

	:deep(.my-tabs .el-tabs__active-bar) {
		background-color: #4578ff;
	}

	:deep(.my-tabs .el-tabs__item) {
		display: flex !important;
		align-items: center;
		justify-content: center;
	}

	.costCountBox {
		padding: 20px;
		display: flex;
		justify-content: center;

		.radioBox {
			text-align: left;
		}

		.container {
			padding: 20px;
			border: 3px solid #ccc;
			width: 55vw;
		}

		.textBox {
			text-align: left;
			font-size: 18px;
		}
	}

	.registerBox {
		font-size: 20px;
		font-weight: 300;
		font-family: emoji;

		p {
			padding: 0 3%;
			text-indent: 2em;
			text-align: left;

			span {
				font-weight: 600;
			}
		}

		// .overFlowBox {
		//     height: 85vh;
		//     overflow: auto;
		// }
	}

	.problemBox {
		:deep(.el-collapse-item__header) {
			font-size: 18px;
			font-weight: 500;
			padding: 0 3%;
		}

		.text {
			text-indent: 2em;
			text-align: left;
			font-size: 16px;
			padding: 0 60px;
		}
	}

	.fontType {
		font-family: emoji;
		font-size: 18px;
		text-align: left;
	}

	.leftTextBox {
		position: relative;
		text-align: left;
		padding: 0 3% 100px;

		h3 {
			span {
				font-weight: 400;
			}
		}

		p {
			text-indent: 2em;

			span {
				font-weight: bold;
			}

			a {
				text-decoration: none;
				color: #000;
			}
		}

		.topIcoBox {
			font-size: 30px;
			width: 70px;
			height: 70px;
			position: fixed;
			bottom: 5%;
			right: 1%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			transition: all 0.3s;

			&:hover {
				background-color: rgba(0, 128, 255, 0.3);
			}
		}
	}

	h1 {
		text-align: center;
	}

	a {
		color: #4578ff !important;
	}

	.imgBox {
		padding-left: 2%;
	}

	.redText {
		color: red;
	}

	.centerText {
		text-align: center;
	}
</style>