<template>
    <!-- 图片 -->
    <div class="containerBox">
        <h1>{{ fileName }}</h1>
        <div class="imgBox" v-if="filePdf === 0">
            <img :src="ulr" alt="" ref="imgRef" />
            <div v-if="filePdf === 0" class="btnXz" @click="clickRotate">
                旋转
            </div>
        </div>
        <div class="embedBox" v-else>
            <embed
                :src="ulr"
                type="application/pdf"
                width="100%"
                height="100%"
            />
        </div>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            fileName: '',
            ulr: '',
            filePdf: '',
            edg: 0,
            // 下载枚举
            downEnum: {
                '.gif': 'image/gif',
                '.jpg': 'image/jpeg',
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
                '.doc': 'application/msword',
                '.docx':
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                '.xlsx':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                '.pdf': 'application/pdf',
                '.rar': 'application/x-rar-compressed',
                '.zip': 'application/zip'
            }
        }
    },

    async created() {
        this.GetOneTimeToken(this.$route.query.token)
    },
    methods: {
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            let fileName = this.$route.query.fileNameDsp
            let filePath = this.$route.query.filePath
            let flagPdf = this.$route.query.flagPdf
            this.fileName = fileName
            await this.viewFileMaterial({
                fileName,
                filePath,
                flagPdf
            })
        },
        // 申请材料列表预览
        async viewFileMaterial(row) {
            this.materialBoxLoading = true
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.materialBoxLoading = false
            let blob
            if (+row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else if (+row.flagPdf === 0) {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            } else {
                let fileType = row.fileName.substring(
                    row.fileName.lastIndexOf('.')
                )
                for (let key in this.downEnum) {
                    if (key === fileType) {
                        let blob = new Blob([res], { type: this.downEnum[key] })
                        let url = window.URL.createObjectURL(blob)
                        let link = document.createElement('a')
                        link.href = url
                        link.download = row.fileName
                        link.click()
                        window.URL.revokeObjectURL(link.href)
                    }
                }
                return
            }
            let url = window.URL.createObjectURL(blob)
            this.ulr = url
            // window.open(url)
        },

        // 点击旋转
        clickRotate() {
            this.edg += 90
            this.$refs.imgRef.style.transform = `rotateZ(${this.edg}deg)`
        }
    },

    mounted() {}
}
</script>
 
<style scoped lang=scss>
.containerBox {
    background-color: #ccc;
}
.imgBox {
    position: relative;
    margin-top: 20px;
    img {
        width: 1000px;
    }
}
.btnXz {
    position: fixed;
    right: 10%;
    bottom: 10%;
    border-radius: 50%;
    padding: 30px;
    background-color: rgba(100, 100, 100, 0.5);
    font-size: 50px;
}
.embedBox {
    height: 100vh;
}
</style>
