/**
 * Created by Leo
 * Date: 2022-05-20 08:55
 * Email: asdfpeng@qq.com
 */
const env = process.env.VUE_APP_ENV;
console.log("env", env)
const config = {
    apiBaseUrl: '',
    apiOCR:''
}

if (env === 'development') {
    config.apiBaseUrl = '/api',
    config.apiOCR = '/ocr'
}
if (env === 'production') {
    config.apiBaseUrl = '/api',
    config.apiOCR = '/ocr'

}

export default config;
