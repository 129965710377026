<template>
    <div class="searchGuidePagesBox">
        <div class="header_wrapper">
            <div class="top">
                <div class="left" @click="goHome">
                    <img src="../assets/image/home/logo.png" alt="logo" />
                    <div class="company_name">
                        <div>中国国际经济贸易仲裁委员会</div>
                        <div>
                            {{
                                'China International Economic and Trade Arbitration Commission'.toUpperCase()
                            }}
                        </div>
                    </div>
                </div>
                <div class="right"></div>
            </div>
        </div>
        <div>
            <searchGuideTab :type="type"></searchGuideTab>
        </div>
    </div>
</template>
 
<script>
import searchGuideTab from './searchGuideTab'
export default {
    name: 'serachGuidePages',
    components: {
        searchGuideTab
    },
    data() {
        return {
            username: 'loading...',
            // 传递的需要哪一个tbs栏
            type: ''
        }
    },

    methods: {
        async GetUserInfo() {
            const res = await this.$API.sendRequest('UserInfo')
            this.$store.commit('getLoginInfo', res.data)
            if (res) {
                this.username = res.data.realName || 'unknown'
            }
        },
        goHome() {
            window.open('http://www.cietacodr.org')
        }
    },
    created() {
        // this.GetUserInfo()
        // let type = this.$route.query.type
        // this.type = type
    },
    mounted() {}
}
</script>
 


<style scoped lang="sass">
.header_wrapper
    background: url("../assets/image/home/header_bg.png") no-repeat
    background-size: cover
    width: 100%
    height: 107px
    color: #fff

.top
    display: flex
    height: 50%
    justify-content: space-between
    padding: 15px 10px 0 10px
    color: rgba(255, 255, 255, 0.8)
.left
    display: flex
    align-items: center
    margin-left: 14px
    cursor: pointer
    img
        height: 100%
    .company_name
        text-align: left
        margin-left: 12px
    div:first-child
        font-size: 18px
        font-weight: 500
    div:last-child
        font-size: 12px
        font-weight: 400
.right
    display: flex
    align-items: center
    margin-top: -12px
    margin-right: 20px
    span
        font-size: 14px
        cursor: pointer
        display: flex
        align-items: center
        font-weight: 500
    img
        height: 15px
        position: relative
        margin-right: 5px
        margin-bottom: 2px

.el-divider--vertical
    height: 10px
    margin: 0 13px

a
    text-decoration: none
.link
    border: 1px solid #7F96CD
    padding: 1px 10px
    border-radius: 3px
    color: rgba(255, 255, 255, 0.8)
    font-size: 13px
    position: relative
    top: -1px
</style>
