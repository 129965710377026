<template>
    <div class="finder_wrapper" @click="clear">
        <div class="left" :style="styleObj" v-loading="menu_loading">
            <SideMenu
                :menu_tree="menu_tree"
                :case_id="case_id"
                :btn="false"
                @set_c="set_c"
                type="tree"
                style="height: 100%"
            />
            <div class="handle"></div>
        </div>

        <div
            class="right"
            v-loading="loading"
            @contextmenu.prevent="handleContextClick($event)"
        >
            <!-- 局部顶部的导航栏    比如返回等等  上传文件夹这些-->
            <div class="top_bar" @contextmenu.prevent="refuse($event)">
                <div class="left_tool">
                    <el-button
                        style="margin-left: 12px"
                        size="small"
                        class="square"
                        :disabled="!back_stack.length"
                        @click="GoBack"
                    >
                        <template #icon
                            ><img src="../assets/image/finder/back.png" alt=""
                        /></template>
                    </el-button>
                    <el-button
                        size="small"
                        class="square"
                        :disabled="!forward_stack.length"
                        @click="GoForward"
                    >
                        <template #icon
                            ><img
                                src="../assets/image/finder/forward.png"
                                alt=""
                        /></template>
                    </el-button>
                    <el-button
                        size="small"
                        class="square"
                        :disabled="!current_parent_target"
                        @click="GoUp"
                    >
                        <template #icon
                            ><img src="../assets/image/finder/up.png" alt=""
                        /></template>
                    </el-button>

                    <!-- v-if="false" -->
                    <el-button
                        size="small"
                        class="rectangle"
                        @click="OpenUpload"
                    >
                        <template #icon
                            ><img
                                src="../assets/image/finder/upload.png"
                                alt=""
                        /></template>
                        {{ $t('msg.finder_upload') }}
                    </el-button>
                    <el-button
                        size="small"
                        class="rectangle"
                        @click="CreateFolder"
                    >
                        <template #icon
                            ><img
                                src="../assets/image/finder/new_folder.png"
                                alt=""
                        /></template>
                        {{ $t('msg.finder_create_folder') }}
                    </el-button>
                    <el-button-group class="ml-4">
                        <el-button
                            size="small"
                            :class="
                                view_type === 'grid' ? 'square act' : 'square'
                            "
                            @click="setView('grid')"
                        >
                            <template #icon
                                ><img
                                    src="../assets/image/finder/grid_view.png"
                                    alt=""
                            /></template>
                        </el-button>
                        <el-button
                            size="small"
                            :class="
                                view_type === 'list' ? 'square act' : 'square'
                            "
                            @click="setView('list')"
                        >
                            <template #icon
                                ><img
                                    src="../assets/image/finder/list_view.png"
                                    alt=""
                            /></template>
                        </el-button>
                    </el-button-group>
                </div>
                <div class="right_tool">
                    <el-input
                        v-model="keyword"
                        class="w-50"
                        :placeholder="$t('msg.finder_search')"
                        prefix-icon="Search"
                        clearable
                        @change="FileSearch"
                    />
                    <div class="btn_group">
                        <el-button @click="FileSearch">
                            {{ $t('msg.case_search') }}
                        </el-button>
                    </div>
                    <LangOption
                        v-if="config.lang"
                        :style-obj="langStyle"
                        position="finder"
                    />
                </div>
            </div>

            <div class="content">
                <div>
                    <div v-show="fileList.length">
                        <div
                            v-show="view_type === 'grid'"
                            class="file_list"
                            style="padding: 0"
                        >
                            <div
                                class="file_item"
                                v-for="(item, index) in fileList"
                                :key="index"
                                :data-hash="item.hash"
                                @click="handleSelect(index, item)"
                                @contextmenu.prevent="
                                    handleContextClick($event, item)
                                "
                                @dblclick="handleContextClick($event, item)"
                            >
                                <drag
                                    v-if="item.extension === 'directory'"
                                    class="drag_item"
                                    @dragstart="myListener(item.name)"
                                    @dblclick="OpenFolder(item)"
                                >
                                    <drop
                                        class="drop_area"
                                        :id="index"
                                        @dragenter="handleDragEnter(index)"
                                        @dragleave="handleDragLeave(index)"
                                        @drop="handleDrop"
                                    >
                                        <img
                                            class="thumb"
                                            :src="fileTypeMap[item.extension]"
                                            alt=""
                                        />
                                    </drop>
                                </drag>
                                <drag
                                    v-else
                                    class="drag_item"
                                    @dragstart="myListener(item.name)"
                                >
                                    <img
                                        v-if="
                                            Object.keys(fileTypeMap).includes(
                                                item.extension
                                            )
                                        "
                                        class="thumb"
                                        :src="fileTypeMap[item.extension]"
                                        alt=""
                                    />
                                    <img
                                        v-else
                                        class="thumb"
                                        :src="fileTypeMap.other"
                                        alt=""
                                    />
                                </drag>
                                <span class="file_name">{{
                                    $GF.niceName(item.name)
                                }}</span>
                            </div>
                        </div>
                        <div v-show="view_type === 'list'">
                            <el-table
                                v-loading="loading"
                                :data="fileList"
                                border
                                :stripe="true"
                            >
                                <el-table-column
                                    :label="$t('msg.finder_table_name')"
                                    prop="name"
                                    width="200"
                                    sortable
                                >
                                    <template v-slot="scope">
                                        <div
                                            v-if="
                                                scope.row.extension ===
                                                'directory'
                                            "
                                            class="file_item_table"
                                            style="
                                                display: flex;
                                                align-items: center;
                                                gap: 5px;
                                            "
                                            :key="scope.$index"
                                            :data-hash="scope.row.hash"
                                            @click="
                                                handleSelectTable(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            @contextmenu.prevent="
                                                handleContextClick(
                                                    $event,
                                                    scope.row
                                                )
                                            "
                                            @dblclick="OpenFolder(scope.row)"
                                        >
                                            <span>
                                                <img
                                                    v-if="
                                                        Object.keys(
                                                            fileTypeMap
                                                        ).includes(
                                                            scope.row.extension
                                                        )
                                                    "
                                                    class="thumb_mini"
                                                    :src="
                                                        fileTypeMap[
                                                            scope.row.extension
                                                        ]
                                                    "
                                                    alt=""
                                                />
                                                <img
                                                    v-else
                                                    class="thumb_mini"
                                                    :src="fileTypeMap.other"
                                                    alt=""
                                                />
                                            </span>
                                            <span>{{ scope.row.name }}</span>
                                        </div>
                                        <div
                                            v-else
                                            class="file_item_table"
                                            style="
                                                display: flex;
                                                align-items: center;
                                                gap: 5px;
                                            "
                                            :key="scope.$index + 1"
                                            :data-hash="scope.row.hash"
                                            @click="
                                                handleSelectTable(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            @contextmenu.prevent="
                                                handleContextClick(
                                                    $event,
                                                    scope.row
                                                )
                                            "
                                            @dblclick="
                                                handleContextClick(
                                                    $event,
                                                    scope.row
                                                )
                                            "
                                        >
                                            <span>
                                                <img
                                                    v-if="
                                                        Object.keys(
                                                            fileTypeMap
                                                        ).includes(
                                                            scope.row.extension
                                                        )
                                                    "
                                                    class="thumb_mini"
                                                    :src="
                                                        fileTypeMap[
                                                            scope.row.extension
                                                        ]
                                                    "
                                                    alt=""
                                                />
                                                <img
                                                    v-else
                                                    class="thumb_mini"
                                                    :src="fileTypeMap.other"
                                                    alt=""
                                                />
                                            </span>
                                            <span>{{ scope.row.name }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('msg.finder_table_type')"
                                    prop="extension"
                                >
                                </el-table-column>
                                <el-table-column
                                    :label="$t('msg.finder_table_size')"
                                >
                                    <template v-slot="scope">{{
                                        $GF.bytesToSize(scope.row.size)
                                    }}</template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('msg.finder_table_update')"
                                    prop="date"
                                    sortable
                                >
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-show="fileList.length === 0">
                        <el-empty
                            :description="$t('msg.finder_no_data')"
                            style="margin: 50px auto"
                        />
                    </div>
                </div>
            </div>

            <!-- 底部的提示信息 -->
            <div class="current_bottom">
                <div class="current_info">
                    <span>{{ current_name }}</span>
                    <div>
                        <span v-show="batch_list.length > 0"
                            >{{ $t('msg.finder_selected_count') }}:{{
                                batch_list.length
                            }},
                        </span>
                        <span
                            >{{ $t('msg.finder_count') }}:{{ current_count }},
                        </span>
                        <span
                            >{{ $t('msg.finder_size') }}:{{
                                current_size
                            }}</span
                        >
                    </div>
                </div>
            </div>
        </div>

        <!-- 右键 -->
        <vue-simple-context-menu
            element-id="myUniqueId"
            :options="options"
            ref="vueSimpleContextMenu"
            @option-clicked="optionClicked"
        />
        <!-- 新增文件夹对话框 -->
        <el-dialog
            v-model="dialogVisible"
            :title="$t('msg.finder_create_folder')"
            width="400px"
        >
            <el-form :model="folderForm" ref="folderForm" :rules="formRules">
                <el-form-item :label="$t('msg.finder_folder_name')" prop="name">
                    <el-input v-model="folderForm.name" autocomplete="off" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="CloseDialog">{{
                        $t('msg.finder_cancel')
                    }}</el-button>
                    <el-button type="primary" @click="Confirm">{{
                        $t('msg.finder_confirm')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 上传文件对话框 -->
        <el-dialog
            v-model="uploadVisible"
            :title="$t('msg.finder_upload')"
            width="400px"
        >
            <el-upload
                v-loading="uploading"
                action="xxx"
                :http-request="() => {}"
                drag
                multiple
                :file-list="file_list"
                :on-change="change"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                    {{ $t('msg.finder_upload_tip') }}
                </div>
            </el-upload>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="CloseUploadDialog">{{
                        $t('msg.finder_cancel')
                    }}</el-button>
                    <el-button type="primary" @click="ConfirmUpload">{{
                        $t('msg.finder_confirm')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import SideMenu from '@/components/SideMenu'
import LangOption from '@/components/LangOption'
import Drag from '@/components/dnd/Drag'
import Drop from '@/components/dnd/Drop'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import axiosConfig from '@/config/api'
import SelectionArea from '../libs/selection/selection-area'

export default {
    name: 'Finder',
    components: {
        SideMenu,
        LangOption,
        Drag,
        Drop,
        VueSimpleContextMenu
    },
    created() {
        // 页面加载获取顶层目录
        if (this.$route.query.token) {
            this.GetOneTimeToken(this.$route.query.token)
            this.styleObj = { height: '100vh' }
        }
        // 初始化视图类型
        this.view_type = localStorage.getItem('view_type') || 'grid'
        // 初始化语言
        this.initLang(localStorage.getItem('lang'))
        // 监听语言变化
        this.$listener.$on('lang', (val) => {
            this.initLang(val)
        })
        // 监听toggle处理表格视图
        this.$listener.$on('toggle', () => {
            if (this.view_type === 'list') {
                this.loading = true
                this.view_type = 'grid'
                setTimeout(() => {
                    this.view_type = 'list'
                    this.loading = false
                }, 300)
            }
        })
    },
    mounted() {
        let th = this
        // 通过鼠标按下事件来监听鼠标移动事件
        let d = document.querySelector('.handle')
        d.addEventListener('mousedown', function (e) {
            th.x = e.clientX
            document.addEventListener('mousemove', th.move)
        })
        document.addEventListener('mouseup', function () {
            // console.log('mouseup')
            document.removeEventListener('mousemove', th.move)
        })
        if (this.$route.query.token) {
            this.target = document.querySelectorAll('.el-menu')[0]
        } else {
            this.target = document.querySelectorAll('.el-menu')[1]
        }
        let config = {
            container: document.querySelector('.content'),
            area: {
                class: 'custom-area'
            },
            targets: '.file_item,.file_item_table',
            touchable: false,
            autostart: true,
            callback: (selection) => {
                if (!th.shift) {
                    document.querySelectorAll('.file_item').forEach((item) => {
                        item.classList.remove('active')
                        item.classList.remove('active2')
                    })
                    document
                        .querySelectorAll('.file_item_table')
                        .forEach((item) => {
                            item.classList.remove('active')
                            item.classList.remove('active2')
                        })
                    this.batch_list = []
                    selection.forEach((item) => {
                        item.classList.add('active')
                        this.batch_list.push(item.dataset.hash)
                    })
                }
            }
        }
        new SelectionArea(config)
        // 处理键盘事件
        document.addEventListener('keydown', function (e) {
            if (e.key === 'Shift' || e.key === 'Control') {
                th.shift = true
            }
            if (e.key === 'Control') {
                th.key_stack[0] = 'Control'
            }
            if (e.key === 'a' && th.key_stack[0] === 'Control') {
                document.querySelectorAll('.file_item').forEach((item) => {
                    item.classList.add('active')
                    th.batch_list.push(item.dataset.hash)
                })
            }
        })
        document.addEventListener('keyup', function () {
            th.shift = false
            th.key_stack = []
        })
    },
    props: {
        config: {
            type: Object,
            default() {
                return {
                    lang: true,
                    collapse_btn: true
                }
            }
        }
    },
    data() {
        return {
            langStyle: {
                border: '1px solid #dcdfe6',
                padding: '0px 10px',
                borderRadius: '3px',
                color: '#606266'
            },
            keyword: '',
            fileList: [],
            fileTypeMap: {
                directory: require('../assets/image/finder/folder.png'),
                ppt: require('../assets/image/finder/ppt.png'),
                xls: require('../assets/image/finder/excel.png'),
                xlsx: require('../assets/image/finder/excel.png'),
                doc: require('../assets/image/finder/word.png'),
                docx: require('../assets/image/finder/word.png'),
                pdf: require('../assets/image/finder/pdf.png'),
                jpg: require('../assets/image/finder/jpg.png'),
                jpeg: require('../assets/image/finder/jpeg.png'),
                gif: require('../assets/image/finder/gif.png'),
                png: require('../assets/image/finder/png.png'),
                mp3: require('../assets/image/finder/mp3.png'),
                wmv: require('../assets/image/finder/wmv.png'),
                mp4: require('../assets/image/finder/mp4.png'),
                flv: require('../assets/image/finder/flv.png'),
                rmvb: require('../assets/image/finder/rmvb.png'),
                wav: require('../assets/image/finder/wav.png'),
                aac: require('../assets/image/finder/aac.png'),
                m4a: require('../assets/image/finder/m4a.png'),
                tar: require('../assets/image/finder/tar.png'),
                zip: require('../assets/image/finder/zip.png'),
                gz: require('../assets/image/finder/gz.png'),
                rar: require('../assets/image/finder/rar.png'),
                txt: require('../assets/image/finder/other.png'),
                other: require('../assets/image/finder/other.png')
            },
            fileOptions: [],
            contentOptions: [],
            options: [],
            loading: false,
            case_id: 123,
            // 携带后到后端
            fileName: '',
            current_target: '',
            current_parent_target: '',
            current_name: '',
            current_count: 0,
            current_size: 0,
            selected_count: 0,
            dialogVisible: false,
            uploadVisible: false,
            folderForm: {
                name: ''
            },
            formRules: {
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ]
            },
            copy_item: '',
            remove_item: '',
            menu_loading: false,
            menu_tree: [
                { id: 1, label: '归档目录 DIRECTORY', hash: '', children: [] }
            ],
            back_stack: [], // 打开文件夹的时候记录
            forward_stack: [], // 回退的时候记录
            bread: [{ name: '归档目录 DIRECTORY', hash: '', children: [] }],
            bread_forward: [],
            file_list: [],
            download_item: {},
            preview_item: {},
            styleObj: {},
            view_type: 'grid', // grid or list
            uploading: false,
            batch_list: [],
            shift: false,
            key_stack: []
        }
    },
    methods: {
        load(caseId) {
            this.case_id = caseId
            this.menu_tree = [
                { id: 1, label: '归档目录 DIRECTORY', hash: '', children: [] }
            ]
            this.fileList = []
            this.bread = [
                { name: '归档目录 DIRECTORY', hash: '', children: [] }
            ]
            // this.GetMenuTree("")
        },
        // 初始化语言
        initLang(lang) {
            this.fileOptions = [
                { name: lang === 'en' ? 'preview' : '预览', type: 'preview' },
                { name: lang === 'en' ? 'copy' : '复制', type: 'copy' },
                { name: lang === 'en' ? 'download' : '下载', type: 'download' },
                // { name: lang==='en'?'rename':'重命名', type: 'rename' },
                { name: lang === 'en' ? 'remove' : '删除', type: 'remove' }
            ]
            this.contentOptions = [
                {
                    name: lang === 'en' ? 'create folder' : '新建文件夹',
                    type: 'create_folder'
                },
                { name: lang === 'en' ? 'paste' : '粘贴', type: 'paste' }
            ]
        },
        // 交互逻辑
        handleDrop(val) {
            console.log('drop', val)
        },
        myListener(val) {
            console.log('dragstart', val)
        },
        handleDragEnter(element_id) {
            console.log('handleDragEnter', element_id)
            document
                .getElementById(element_id)
                .parentElement.parentElement.classList.add('active')
        },
        handleDragLeave(element_id) {
            console.log('handleDragLeave', element_id)
            document
                .getElementById(element_id)
                .parentElement.parentElement.classList.remove('active')
        },
        handleSelect(index, item) {
            const els = document.querySelectorAll('.file_item')
            // 处理control反选
            if (this.shift) {
                // 处理样式 & 维护 batch_list
                if (els[index].classList.contains('active')) {
                    els[index].classList.remove('active')
                    this.batch_list = this.batch_list.filter(
                        (it) => it !== item.hash
                    )
                } else {
                    els[index].classList.add('active')
                    if (!this.batch_list.includes(item.hash))
                        this.batch_list.push(item.hash)
                }
            } else {
                els.forEach((d, i) => {
                    if (index !== i) {
                        d.classList.remove('active2')
                        d.classList.remove('active')
                    } else {
                        d.classList.add('active2')
                    }
                })
            }
        },
        handleSelectTable(index, item) {
            const els = document.querySelectorAll('.file_item_table')
            // 处理control反选
            if (this.shift) {
                // 处理样式 & 维护 batch_list
                if (els[index].classList.contains('active')) {
                    els[index].classList.remove('active')
                    this.batch_list = this.batch_list.filter(
                        (it) => it !== item.hash
                    )
                } else {
                    els[index].classList.add('active')
                    if (!this.batch_list.includes(item.hash))
                        this.batch_list.push(item.hash)
                }
            } else {
                els.forEach((d, i) => {
                    if (index !== i) {
                        d.classList.remove('active2')
                        d.classList.remove('active')
                    } else {
                        d.classList.add('active2')
                    }
                })
            }
        },
        handleContextClick($event, item) {
            $event.stopPropagation()
            if (item) {
                this.options = this.fileOptions
            } else {
                this.options = this.contentOptions
            }
            this.$refs.vueSimpleContextMenu.showMenu($event, item)
        },
        // handleAuth(item, options) {
        //
        // },

        // 点击右键后看执行什么操作
        optionClicked(event) {
            console.log(event, 'event')
            // window.alert(JSON.stringify(event))
            if (event.option.type === 'create_folder') {
                console.log(event.option.type)

                this.CreateFolder()
            }
            if (event.option.type === 'copy') {
                this.copy_item = event.item
                this.$message.success('已复制')
            }
            if (event.option.type === 'paste') {
                this.Paste()
            }
            if (event.option.type === 'remove') {
                this.remove_item = event.item
                this.RemoveFile()
            }
            if (event.option.type === 'download') {
                if (this.batch_list.length) {
                    this.BatchDownload()
                    this.fileName = event.item.name
                } else if (event.item.extension === 'directory') {
                    this.$message.warning('当前文件类型不支持下载')
                } else {
                    this.download_item = event.item
                    this.fileName = event.item.name
                    this.DownloadFile()
                }
            }
            if (event.option.type === 'preview') {
                // console.log('点击预览');
                // console.log(event.item);
                this.preview_item = event.item
                this.fileName = event.item.name
                if (
                    ['png', 'jpg', 'jpeg', 'pdf', 'txt'].includes(
                        event.item.extension
                    )
                ) {
                    this.PreviewFile()
                } else {
                    this.$message.warning('当前文件类型不支持预览')
                }
            }
        },
        refuse(e) {
            e.stopPropagation()
        },
        clear() {
            this.$refs.vueSimpleContextMenu.hideContextMenu()
        },

        // 接口逻辑
        // 左侧菜单树
        async GetMenuTree(target) {
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'tree',
                    args: {
                        target: target
                    }
                }
            }
            this.menu_loading = true
            const res = await this.$API.sendRequest('FileOperation', param)
            if (res.success) {
                res.data.tree.forEach((item) => {
                    console.log(res.data)
                    this.menu_tree[0].children.push({
                        id: item.hash,
                        label: item.name,
                        hash: item.hash,
                        extension: item.extension,
                        size: item.size,
                        children: []
                    })
                })
                this.menu_loading = false
            }
        },
        async GetFileList(target) {
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'open',
                    args: {
                        target: target
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('FileOperation', param)
            this.loading = false
            if (res.success) {
                this.fileList = res.data.files || []
                this.computeCurrentInfo(this.fileList)
            } else {
                this.$message.error(res.msg)
            }
        },
        async CreateFolder() {
            this.dialogVisible = true
        },
        CloseDialog() {
            this.dialogVisible = false
            this.$refs.folderForm.resetFields()
        },
        async Confirm() {
            this.$refs.folderForm.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: {
                            caseId: this.case_id,
                            cmd: 'mkdir',
                            args: {
                                name: this.folderForm.name,
                                target: this.current_target
                            }
                        }
                    }
                    this.loading = true
                    const res = await this.$API.sendRequest(
                        'FileOperation',
                        param
                    )
                    this.loading = false
                    if (res && res.success) {
                        this.loading = false
                        await this.GetFileList(this.current_target)
                    } else {
                        this.$message.error(res.msg)
                    }
                    this.CloseDialog()
                }
            })
        },
        async Paste() {
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'paste',
                    args: {
                        cut: false,
                        targets: [this.copy_item.hash],
                        dst: this.current_target
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('FileOperation', param)
            this.loading = false
            if (res && res.success) {
                await this.GetFileList(this.current_target)
            } else {
                this.loading = false
                this.$message.error(res.msg)
            }
        },
        set_c(node_data) {
            this.current_target = node_data.hash
            this.bread[1] = node_data.label
            this.current_name = node_data.label
            this.GetFileList(this.current_target)
            this.clear()
        },
        async OpenFolder(item) {
            this.back_stack.push(this.current_target)
            this.current_name = item.name
            await this.GetFileList(item.hash)
            this.current_target = item.hash
            this.current_parent_target = item.phash
            // this.bread.push(item)
        },
        async GoBack() {
            this.forward_stack.push(this.current_target)
            const t = this.back_stack.pop()
            await this.GetFileList(t)
            this.current_target = t
            this.current_parent_target = this.fileList[0]?.phash
            const b = this.bread.pop()
            this.bread_forward.push(b)
        },
        async GoForward() {
            this.back_stack.push(this.current_target)
            const t = this.forward_stack.pop()
            await this.GetFileList(t)
            this.current_target = t
            this.current_parent_target = this.fileList[0]?.phash
            this.bread.push(this.bread_forward.pop())
        },
        async GoUp() {
            if (this.current_parent_target === this.current_target) {
                this.current_parent_target = ''
                return
            }
            await this.GetFileList(this.current_parent_target)
            this.current_target = this.current_parent_target
            this.current_parent_target = this.fileList[0]?.phash
        },
        async FileSearch() {
            if (this.keyword.trim() === '') {
                let lang = localStorage.getItem('lang')
                this.keyword = ''
                this.$message.warning(
                    lang === 'en'
                        ? 'can not search empty string'
                        : '请输入检索的内容'
                )
                return
            }
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'search',
                    args: {
                        q: this.keyword
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('FileOperation', param)
            if (res) {
                this.loading = false
                this.fileList = res.data.files || []
            } else {
                this.loading = false
                this.fileList = []
            }
        },
        async RemoveFile() {
            const lang = localStorage.getItem('lang')
            this.$confirm(
                lang === 'en'
                    ? `Confirm remove${this.remove_item.name}?`
                    : `确认要删除${this.remove_item.name}？`,
                lang === 'en' ? 'Tip' : '提示',
                {
                    confirmButtonText: lang === 'en' ? 'confirm' : '确认',
                    cancelButtonText: lang === 'en' ? 'cancel' : '取消',
                    type: 'warning'
                }
            )
                .then(async () => {
                    const param = {
                        req: {
                            caseId: this.case_id,
                            cmd: 'rm',
                            args: {
                                targets: [this.remove_item.hash]
                            }
                        }
                    }
                    this.loading = true
                    const res = await this.$API.sendRequest(
                        'FileOperation',
                        param
                    )
                    this.loading = false
                    if (res && res.success) {
                        await this.GetFileList(this.current_target)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                .catch(() => {
                    this.remove_item = ''
                })
        },
        async GetT() {
            const res = await this.$API.sendRequest('GetT', {})
            return res.data
        },
        async DownloadFile() {
            const t = await this.GetT()
            let query = `?req.caseId=${this.case_id}&req.args.fileName=${this.fileName}&req.cmd=file&req.args.download=true&req.args.target=${this.download_item.hash}&t=${t}`
            window.open(axiosConfig.apis.DownLoad.url + query)
        },
        async PreviewFile() {
            const t = await this.GetT()
            let query = `?req.caseId=${this.case_id}&req.cmd=file&req.args.fileName=${this.fileName}&req.args.download=false&req.args.target=${this.preview_item.hash}&t=${t}`
            //
            console.log(axiosConfig.apis.DownLoad.url)
            window.open(axiosConfig.apis.DownLoad.url + query)
        },
        OpenUpload() {
            this.uploadVisible = true
        },
        change(file, fileList) {
            console.log(file)
            console.log(fileList)
        },
        CloseUploadDialog() {
            this.uploadVisible = false
            this.file_list = []
            this.uploading = false
        },
        async ConfirmUpload() {
            // 构造form表单
            let form = new FormData()
            form.append('req.caseId', this.case_id)
            form.append('req.cmd', 'upload')
            form.append('req.args.fromUploadList', false)
            form.append('req.args.target', this.current_target)
            this.file_list.forEach((item) => {
                form.append('req.args.upload', item.raw)
            })
            this.uploading = true
            const res = await this.$API.sendRequest(
                'FileOperation',
                form,
                null,
                300000
            )
            if (res && res.success) {
                this.$message.success('上传成功')
                this.CloseUploadDialog()
                await this.GetFileList(this.current_target)
            } else {
                this.$message.error(res.msg)
            }
            this.uploading = false
        },
        setView(val) {
            this.view_type = val
            localStorage.setItem('view_type', val)
            if (val === 'list') {
                document.querySelectorAll('.file_item').forEach((d) => {
                    d.classList.remove('active2')
                    d.classList.remove('active')
                })
            } else if (val === 'grid') {
                document.querySelectorAll('.file_item_table').forEach((d) => {
                    d.classList.remove('active2')
                    d.classList.remove('active')
                })
            }
        },
        move(e) {
            // console.log(e.clientX - this.x, this.target.offsetWidth, this.target.style.width)
            if (this.target.offsetWidth > 270) {
                this.target.style.width = '270px'
            } else if (this.target.offsetWidth < 205) {
                this.target.style.width = '205px'
            } else {
                this.target.style.width =
                    this.target.offsetWidth + (e.clientX - this.x) + 'px'
                this.x = e.clientX
            }
        },
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.GetMenuTree('')
        },
        computeCurrentInfo(list = []) {
            this.current_count = list.length
            this.current_size = 0
            list.forEach((item) => {
                if (item.extension !== 'directory') {
                    this.current_size += item.size
                }
            })
            this.current_size = this.$GF.bytesToSize(this.current_size)
        },
        async BatchDownload() {
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'zip',
                    args: {
                        targets: this.batch_list
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('FileOperation', param)
            this.loading = false
            if (res && res.success) {
                const lang = localStorage.getItem('lang')
                if (res.data === null)
                    return this.$message.error(
                        lang === 'en' ? 'zip download failed' : '打包下载失败'
                    )
                this.download_item.hash = res.data?.file
                await this.DownloadFile()
            } else {
                this.$message.error(res.msg)
            }
        }
    }
}
</script>

<style scoped lang="sass">
.finder_wrapper
    display: flex
    height: 100%
    user-select: none

.left
    //height: 100vh
    position: relative
    transition: width linear 100ms
    z-index: 1
    .handle
        height: 100%
        width: 2px
        position: absolute
        right: 0
        top: 0
        transition: all linear 200ms
    .handle:hover
        background-color: #4578FF
        cursor: col-resize
.right
    width: 100%
    box-shadow: 0 4px 27px 0 rgba(153,148,178,0.5)
    .top_bar
        height: 60px
        box-shadow: 0 4px 27px 0 rgba(153,148,178,0.1)
        display: flex
        align-items: center
        justify-content: space-between
    .left_tool
        text-align: left
        padding-left: 3px
        .square
            width: 24px
            height: 24px
        .square.act
            background-color: rgba(160, 207, 255, 0.5)
            border-color: rgba(160, 207, 255, 0.8)
        img
            width: 12px
            height: 12px
        .rectangle
            height: 27px
        .ml-4
            margin-left: 12px
    .right_tool
        display: flex
        align-items: center
        margin-right: 20px
        gap: 20px
        .w-50
            height: 32px
            width: 233px
        ::v-deep(.el-input__inner)
            font-size: 12px
.current_info
    height: 25px
    width: 100%
    background-color: #ecf5ff
    font-size: 12px
    color: #555
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 15px
    box-sizing: border-box
    z-index: 1
.current_bottom
    position: sticky
    bottom: 0
    background-color: transparent
    padding-left: 3px

.content
    padding: 15px
    height: calc(100vh - 250px)
    overflow-y: scroll
.b_wrapper
    padding: 10px 25px
::v-deep(.custom-area)
    background: rgba(52, 152, 219, 0.2)
    border: 1px dotted #2980b9

.file_list
    display: flex
    gap: 10px
    flex-wrap: wrap
    padding: 15px
.file_item
    display: flex
    flex-direction: column
    align-items: center
    width: 100px
    height: 115px
    font-size: 12px
    cursor: pointer
    padding: 5px
    border-radius: 5px
    box-sizing: border-box
    .thumb
        width: 50px
        height: 50px
    .file_name
        color: #434A54
        margin: 15px 0 8px 0
        max-width: 80px
        word-wrap: break-word
    .file_size
        color: #7F96CD
.active
    border: 1px dashed #FF8200!important
    background-color: #F7F8FF
.active2
    background-color: #F7F8FF

::v-deep(.vue-simple-context-menu)
    background-color: #fff
    color: #545465
    font-size: 12px
    position: fixed
    box-shadow: 0 0 6px 0 rgb(51 51 51 / 40%)
    list-style: none
    padding-inline-start: 0
    text-align: left
    display: none
    z-index: 999
::v-deep(.vue-simple-context-menu.vue-simple-context-menu--active)
    display: block
::v-deep(.vue-simple-context-menu__item)
    padding: 10px 20px
::v-deep(.vue-simple-context-menu__item:hover)
    background-color: #F8FAFF
    color: #7A84FF
.btn_group,
.el-button
    font-size: 12px
img
    width: 13px
    height: 13px
.thumb_mini
    width: 15px
    height: 15px
    margin-top: 3px
</style>