<template>
    <!-- 扫码快递单录入 -->
    <div>
        <!-- 顶部搜索框开始  -->
        <el-card shadow="hover">
            <div class="inputBox">
                <el-row>
                    <el-col :span="6">
                        <el-row>
                            <el-col :span="8">{{$t('msg.CourierNumber')}}</el-col>
                            <el-col :span="16">
                                <el-input
                                    v-model="searchEMSCode"
                                    @keydown.enter="searchEMS('1')"
                                    clearable
                                    :placeholder="$t('msg.Enterthetrackingquery')"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="6" style="display: flex; padding-left: 40px">
                        <el-button type="primary" @click="searchEMS('1')">
                            <el-icon class="el-icon--right">
                                <Search />
                            </el-icon>
                            {{$t('msg.case_search')}}</el-button
                        >
                    </el-col>
                    <el-col></el-col>
                </el-row>
            </div>
        </el-card>
        <!-- 顶部搜索框开始  -->

        <!-- 表格主体开始 -->
        <div class="table" v-loading="loading">
            <el-card>
                <el-row>
                    <el-col :span="4"
                        ><el-input
                            ref="tableInputFocus"
                            size="small"
                            v-model="EMSCode"
                            clearable
                            @keydown.enter="enterInsert"
                        ></el-input
                    ></el-col>
                    <el-col :span="6">{{$t('msg.Pleasemovethecursoricketbarcode')}}</el-col>
                    <el-col :span="6"
                        >{{$t('msg.Todayhasreceivedtheexpresscode')}} <span>{{ total }}</span
                        >{{$t('msg.Pieces')}}</el-col
                    >
                    <el-col :span="8"> </el-col>
                </el-row>

                <el-table :data="tableData" style="width: 100%">
                    <el-table-column
                        prop="emsId"
                        :label="$t('msg.CourierNumber')"
                        width="180"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        :label="$t('msg.case')"
                        width="180"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column prop="userName" :label="$t('msg.DataEntryKeyers')" sortable>
                    </el-table-column>
                    <el-table-column
                        prop="receiveDate"
                        :label="$t('msg.ReceivedDate')"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column :label="$t('msg.Operate')">
                        <template #default="scope">
                            <div>
                                <el-button
                                    type="primary"
                                    @click="deleteCode(scope.row)"
                                >
                                    <el-icon class="el-icon--center">
                                        <Delete />
                                    </el-icon>
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="current"
                        :page-sizes="[100, 200, 300, 400]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <!-- 表格主体开结束 -->
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 表格数据
            tableData: [],
            // 需要一个总页数
            tableDatalength: [],
            // 录入邮编
            EMSCode: '',
            // 搜索框的code
            searchEMSCode: '',
            // 加载效果
            loading: false,
            // 总条数
            total: 0,
            // 默认每页条数
            pageSize: 100,
            // 页数
            current: 1
        }
    },
    methods: {
        load() {
            // 聚焦
            this.$refs.tableInputFocus.focus()
            this.current = 1
            this.searchEMS()
        },
        // 按回车之后根据  邮编  去录入
        async enterInsert() {
            if (!this.EMSCode)
                return this.$message.error('搜索快递单号不能为空！')
            let param = {
                req: this.EMSCode
            }
            let res = await this.$API.sendRequest('InsertEMS', param)
            if (res.success) {
                this.$message.success(res.msg)
                this.searchEMS()
                this.EMSCode = ''
            } else {
                this.EMSCode = ''
                this.$message.error(res.msg)
            }

            this.EMSCode = ''
        },
        // 点击顶部查询
        async searchEMS(type = '2') {
            this.loading = true
            let param = {
                req: {
                    q: this.searchEMSCode || '',
                    current: this.current,
                    size: this.pageSize
                }
            }
            let res = await this.$API.sendRequest('SearchEMS', param)
            if (res.success) {
                this.tableData = res.data.records
                this.total = res.data.total
                this.casePageCount = res.data.pages
                if (type !== '1') {
                    this.tableDatalength = res.data.records
                }
            }
            this.searchEMSCode = ''
            this.loading = false
        },
        // 点击删除
        deleteCode(row) {
            let res
            this.$confirm(this.$t('msg.Whethertodelerackingnumber'), '提示', {
                confirmButtonText: this.$t('msg.Confirm'),
                cancelButtonText: this.$t('msg.finder_cancel'),
                type: 'warning'
            })
                .then(async () => {
                    this.$message({
                        type: 'success',
                        message: this.$t('msg.SuccessfullyDelete')
                    })
                    let param = {
                        req: row.emsId
                    }
                    res = await this.$API.sendRequest('DeleteEMS', param)
                    if (!res.success) return this.$message.error(this.$t('msg.FailtoDelete'))
                    this.tableData = this.tableData.filter(
                        (item) => item.emsId !== row.emsId
                    )
                    this.tableDatalength = this.tableData
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },
        // 分页器功能 --- 每页条数发生变化时触发
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val
            this.current = 1
            this.searchEMS()
        },
        // 分页器功能 --- 当前页数发生变化时触发
        handleCurrentChange(val) {
            // console.log(`当前页 ${val} 条`);
            // this.pageSize = 1
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.total / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.pageSize > pageMax) {
                this.pageSize = pageMax
            }
            this.searchEMS()
        }
    }
}
</script>
 
<style scoped lang="scss">
.inputBox .el-col-8 {
    display: flex;
    /* justify-content: end; */
    align-items: center;
    padding-right: 10px;
}

.table .el-row {
    margin: 10px 0;

    .el-col-4 {
        display: flex;
        align-items: center;
    }

    .el-col-6 {
        display: flex;
        align-items: center;

        &:nth-child(2) {
            padding-left: 10px;
            font-size: 14px;
        }

        span {
            color: red;
            font-size: 27px;
        }
    }
}

:deep(.el-button) {
    background-color: #79bbff;
}
</style>
