<template>
    <div class="CaseApproval">
        <div class="search">
            <el-form :model="searchModel" inline>
                <el-form-item>
                    <el-input
                        style="width: 250px"
                        id="applicant"
                        v-model="searchModel.applicant"
                        :placeholder="$t('msg.Nameofparty')"
                        clearable
                    />
                </el-form-item>
                <el-form-item>
                    <el-input
                        style="width: 250px"
                        id="receiveLetterNo"
                        v-model="searchModel.receiveLetterNo"
                        :placeholder="$t('msg.ApplicationNumber')"
                        clearable
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="organizationType"
                        filterable
                        :placeholder="$t('msg.TheFullState')"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.dictionaryItemValue"
                            :label="item.dictionaryItemName"
                            :value="item.dictionaryItemValue"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="search"
                        @click="handleSearch"
                        >{{$t('msg.finder_search')}}</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="list"
            border
            :stripe="true"
            style="width: 100%"
        >
            <el-table-column
                :label="$t('msg.case')"
                prop="caseNo"
                align="center"
                min-width="10%"
            >
                <template #default="scope">
                    <div>
                        {{ scope.row.caseNo || '暂无案号' }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('msg.ApplicationNumber')"
                prop="receiveLetterNo"
                align="center"
                min-width="10%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.Applicant')"
                prop="applicant"
                align="center"
                min-width="10%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.case_create_time')"
                prop="webSubmitTime"
                align="center"
                min-width="15%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.finder_table_update')"
                prop="webUpdateTime"
                align="center"
                min-width="15%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.Acceptanceof')"
                prop="organization"
                align="center"
                min-width="10%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.case_status')"
                prop="webStatusStr"
                align="center"
                min-width="10%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.IftheMoneyhasBeenCredited')"
                prop="isIntoAccount"
                align="center"
                min-width="7%"
            >
            </el-table-column>

            <el-table-column
                :label="$t('msg.Numberofcallsback')"
                prop="webRepulseNum"
                align="center"
                min-width="7%"
            >
            </el-table-column>

            <el-table-column
               :label="$t('msg.Operate')"
                prop="operation"
                align="center"
                min-width="6%"
            >
                <template #default="scope">
                    <el-button
                        type="primary"
                        icon="edit"
                        @click="goApproveDetail(scope.row)"
                    ></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            style="margin-top: 10px; display: flex; justify-content: center"
            small="small"
            @size-change="paginationSizeChange"
            @current-change="paginationCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'CaseApproval',
    data() {
        return {
            loading: false,
            case_id: '',
            list: [],
            searchModel: {
                applicant: '',
                receiveLetterNo: ''
            },
            options: [],
            pagination: {
                page: 1,
                pages: 0,
                limit: 10,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            // 全部状态
            organizationType: 0
        }
    },
    methods: {
        load(case_id) {
            this.case_id = case_id
            this.GetCaseApproveList()
            this.getShowDepartment()
        },

        paginationSizeChange(val) {
            this.pagination.limit = val
            this.GetCaseApproveList()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.GetCaseApproveList()
        },

        async handleSearch() {
            await this.GetCaseApproveList()
        },

        // 点击修改跳到详情页
        goApproveDetail(row) {
            this.$emit('ApproveDetail', row)
        },

        async GetCaseApproveList() {
            this.loading = true
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        receiveLetterNo: this.searchModel.receiveLetterNo,
                        applicant: this.searchModel.applicant,
                        webStatus: this.organizationType
                    }
                }
            }
            const res = await this.$API.sendRequest('CaseApproveList', param)
            if (res.success) {
                this.list = res.data.records
                this.pagination.total = res.data.total
                this.loading = false
            }
        },

        async getShowDepartment() {
            let param = {
                req: 276
            }
            let res = await this.$API.sendRequest('styleTypeList', param)
            this.options = res.data.materialStyleList
            this.options.unshift({
                dictionaryItemValue: 0,
                dictionaryItemName: '全部'
            })
        }
    }
}
</script>

<style scoped>
.search {
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-left: 100px;
}

:deep(.el-button) {
    background-color: #79bbff;
}
</style>



