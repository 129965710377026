<template>
    <div class="registerCaseBox" v-loading="registerLoading">
        <div class="showBox">
            <!-- 案件审批  -->
            <div class="titleBox" ref="top">
                <h3>案件审批</h3>
                <div class="button">
                    <el-button type="primary" @click="handlePDF"
                        >打印案件审批</el-button
                    >
                </div>
            </div>
            <el-card class="box-card">
                <header>
                    <el-row>
                        <el-col :span="14">
                            <h2>案件号：<span>暂无案件编号</span></h2>
                        </el-col>
                        <el-col :span="10"> </el-col>
                    </el-row>
                    <el-row>
                        <h2 style="font-size: 16px">
                            申请号：<span>{{ listItem.receiveLetterNo }}</span>
                        </h2>
                    </el-row>
                    <el-row>
                        <h2 style="font-size: 16px">
                            案件性质：<span>{{ listItem.webStatusStr }}</span>
                        </h2>
                    </el-row>
                    <el-row>
                        <h2 style="font-size: 16px">
                            备注：<span>xxxxxxxx</span>
                        </h2>
                    </el-row>
                </header>
            </el-card>

            <!-- 已发送复函 -->
            <div class="titleBox" ref="letter">
                <h3>已发送复函</h3>
            </div>
            <div class="materialBox">
                <!-- 有数据 展示 -->
                <el-card v-if="true"> 嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻信息 </el-card>
            </div>

            <!-- 标注 -->
            <div class="titleBox" ref="dimension">
                <h3>标注</h3>
                <div class="button">
                    <el-button type="primary">打印标注</el-button>
                </div>
            </div>
            <div class="materialBox">
                <!-- 有数据 展示 -->
                <el-card v-if="true">
                    <el-table :data="listA" style="width: 100%">
                        <el-table-column prop="text" label="内容">
                        </el-table-column>
                        <el-table-column prop="date" label="标注人">
                        </el-table-column>
                        <el-table-column prop="date" label="时间">
                        </el-table-column>
                    </el-table>
                    <div class="dimensionInputBox">
                        <el-row>
                            <el-col :span="20">
                                <el-input
                                    type="textarea"
                                    autosize
                                    placeholder="请输入内容"
                                >
                                </el-input
                            ></el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="3">
                                <el-button type="success">标注</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </div>

            <!-- 留言板 -->
            <div class="titleBox" ref="message">
                <h3>留言板</h3>
                <div class="button">
                    <el-button type="primary">打印留言板</el-button>
                </div>
            </div>
            <div class="materialBox">
                <!-- 有数据 展示 -->
                <el-card v-if="true">
                    <div
                        class="leaveMessage"
                        v-for="(item, index) in stayMessage"
                        :key="index"
                    >
                        <el-card>
                            <div>{{ item.msgContent }}</div>
                            <div class="footerText">
                                <span>{{ item.submitTime }}</span>
                                <span>发布人:{{ item.userName }}</span>
                            </div>
                        </el-card>
                    </div>
                    <div class="leaveMessage">
                        <el-card>
                            <el-row>
                                <el-col :span="2"
                                    ><div class="col2BtnBox">
                                        <span
                                            style="
                                                font-size: 20px;
                                                font-weight: 500;
                                            "
                                            >我的留言:</span
                                        >
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <el-input v-model="msgContent"></el-input>
                                </el-col>
                                <el-col :span="2"
                                    ><div class="col2BtnBox">
                                        <el-button
                                            type="success"
                                            @click="saveMessageBoard"
                                            >留言</el-button
                                        >
                                    </div></el-col
                                >
                            </el-row>
                        </el-card>
                    </div>
                </el-card>
            </div>

            <!-- 当事人  -->
            <div class="titleBox" ref="party">
                <h3>当事人</h3>
            </div>
            <div class="partyBox">
                <!-- 左侧  申请人-----申请人代理人 -->
                <div class="left">
                    <!-- 申请人 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>申请人</span>
                                </div>
                            </div>
                        </template>
                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in partyInfo1"
                                    :key="index"
                                >
                                    <!-- 自然人列表 -->
                                    <el-card v-if="item.partyClass === 1">
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>{{
                                                    item.partyClassName
                                                }}</el-tag>
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        1,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>

                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                >{{
                                                    item.nationalityName
                                                }}</span
                                            >
                                        </p>
                                        <!-- 身份证 、  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span
                                            >:
                                            <span v-html="item.idNum"></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <!-- <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p> -->
                                        <p>
                                            特别更改：
                                            <el-input
                                                type="textarea"
                                                v-model="
                                                    getPartyContentList[
                                                        item.caseId
                                                    ]
                                                "
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                checkItem,
                                                                checkindex
                                                            ) in party1Entry"
                                                            :key="checkindex"
                                                            :label="
                                                                checkItem.remark
                                                            "
                                                            @change="
                                                                checkboxChange(
                                                                    $event,
                                                                    checkItem.remark,
                                                                    item.caseId
                                                                )
                                                            "
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                    <!-- 法人列表  -->
                                    <el-card v-if="item.partyClass === 2">
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>{{
                                                    item.partyClassName
                                                }}</el-tag>
                                                <!-- &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyName"
                                                ></span> -->
                                                &nbsp; &nbsp;<span
                                                    v-html="item.partyCompany"
                                                ></span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        1,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>

                                        <p>
                                            法人分类:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.corporationTypeModify ===
                                                        1
                                                }"
                                                v-html="
                                                    item.corporationTypeName
                                                "
                                            ></span>
                                            <span
                                                :class="{
                                                    redText:
                                                        item.corporationType1Modify ===
                                                        1
                                                }"
                                                style="margin: 0 10px"
                                                v-html="
                                                    item.corporationType1Name
                                                "
                                            ></span>
                                            <span
                                                :class="{
                                                    redText:
                                                        item.investTypeNameModify ===
                                                        1
                                                }"
                                                style="margin: 0 10px"
                                                v-html="item.investTypeName"
                                            ></span>
                                        </p>
                                        <p>
                                            营业执照注册码:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"> </span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：
                                            <el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in party1Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                    <!-- 个体工商户  -->
                                    <el-card v-if="item.partyClass === 4">
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>{{
                                                    item.partyClassName
                                                }}</el-tag>

                                                &nbsp; &nbsp;<span
                                                    v-html="item.partyCompany"
                                                ></span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        1,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>

                                        <p>
                                            经营者:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.partyName"
                                            ></span>
                                        </p>
                                        <p>
                                            营业执照号:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>
                                        <p>
                                            营业执照地址:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            营业执照号:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"> </span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：
                                            <el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in party1Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                    <!-- 其他组织  -->
                                    <el-card v-if="item.partyClass === 3">
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>{{
                                                    item.partyClassName
                                                }}</el-tag>
                                                <!-- &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyName"
                                                ></span> -->
                                                &nbsp; &nbsp;<span
                                                    v-html="item.partyCompany"
                                                ></span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        1,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>
                                        <p>
                                            组织名称:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.partyCompany"
                                            ></span>
                                        </p>

                                        <p>
                                            纳税人识别号:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"> </span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：
                                            <el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in party1Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>

                    <!-- 申请人代理人/法定代表人 开始-->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>申请人代理人/法定代表人</span>
                                </div>
                            </div>
                        </template>
                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in deputyInfo1"
                                    :key="index"
                                >
                                    <el-card>
                                        <p class="rightBtnFlex">
                                            <span
                                                v-html="item.partyCompany"
                                            ></span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        2,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>
                                        <p>
                                            名称:
                                            <span
                                                v-html="item.deputyName"
                                            ></span>
                                        </p>
                                        <p>
                                            国家:
                                            <span>{{
                                                item.nationalityName
                                            }}</span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span>
                                            :

                                            <span v-html="item.idNum"></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                        <p>
                                            单位地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in deputy1Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>

                    <!-- 申请人代理人/法定代表人 结束-->
                </div>
                <div class="center"></div>
                <!-- 右侧  被申请人-----被申请人代理人 开始-->
                <div class="right">
                    <!-- 被申请人 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>被申请人</span>
                                </div>
                            </div>
                        </template>
                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in partyInfo2"
                                    :key="index"
                                >
                                    <!-- 非法人 -->
                                    <el-card v-if="item.partyClass !== 2">
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>
                                                    {{ item.partyClassName }}
                                                </el-tag>
                                                <!-- &nbsp; &nbsp; -->
                                                <!-- <span v-html="item.partyName">
                                                </span> -->
                                                &nbsp;&nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                ></span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        3,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>

                                        <p>
                                            国家:
                                            <span>{{
                                                item.nationalityName
                                            }}</span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span>
                                            :
                                            <span v-html="item.idNum"></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in party2Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                    <!-- 法人列表  -->
                                    <el-card v-else>
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag>{{
                                                    item.partyClassName
                                                }}</el-tag>
                                                &nbsp; &nbsp;
                                                <span v-html="item.partyName">
                                                </span>
                                                &nbsp;&nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                ></span>
                                            </span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        3,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>

                                        <p>
                                            法人分类:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.corporationTypeModify ===
                                                        1
                                                }"
                                                v-html="
                                                    item.corporationTypeName
                                                "
                                            ></span>
                                            <span
                                                :class="{
                                                    redText:
                                                        item.corporationType1Modify ===
                                                        1
                                                }"
                                                style="margin: 0 10px"
                                                v-html="
                                                    item.corporationType1Name
                                                "
                                            ></span>
                                            <span
                                                :class="{
                                                    redText:
                                                        item.investTypeModify ===
                                                        1
                                                }"
                                                style="margin: 0 10px"
                                                v-html="item.investTypeName"
                                            ></span>
                                        </p>
                                        <p>
                                            营业执照注册码:
                                            <span
                                                style="margin: 0 10px"
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"> </span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in party2Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>
                    <!-- 被申请人代理人/法定代表人 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>被申请人代理人/法定代表人</span>
                                </div>
                            </div>
                        </template>

                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in deputyInfo2"
                                    :key="index"
                                >
                                    <el-card>
                                        <p class="rightBtnFlex">
                                            <span></span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        4,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>
                                        <p>
                                            名称:
                                            <span
                                                v-html="item.deputyName"
                                            ></span>
                                        </p>
                                        <p>
                                            国家:
                                            <span>{{
                                                item.nationalityName
                                            }}</span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span>
                                            :
                                            <span v-html="item.idNum"></span>
                                        </p>

                                        <p>
                                            详细地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            联系电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            联系邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                        <p>
                                            单位地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            材料:
                                            <el-dropdown
                                                v-for="(
                                                    fileItem, index
                                                ) in item.fileListVos"
                                                :key="index"
                                            >
                                                <span class="el-dropdown-link">
                                                    {{ fileItem.fileNameDsp }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                fileItem.flagPdf ===
                                                                    1 ||
                                                                fileItem.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(
                                                                    fileItem
                                                                )
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <el-divider></el-divider>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in deputy2Entry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
                <!-- 右侧  被申请人-----被申请人代理人 结束-->
            </div>

            <!-- 仲裁依据 + 仲裁请求  -->
            <div ref="according">
                <el-row>
                    <el-col :span="12">
                        <!-- 仲裁依据  -->
                        <div class="titleBox">
                            <h3>仲裁依据</h3>
                        </div>
                        <div class="partyBox">
                            <div class="left">
                                <!-- 仲裁依据 -->
                                <el-card class="box-card" shadow="hover">
                                    <template #header>
                                        <div class="card-header">
                                            <div class="text">
                                                <span>仲裁依据</span>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="box">
                                        <div class="mainText">
                                            <el-card
                                                v-for="(
                                                    item, index
                                                ) in caseDetailInfoVos"
                                                :key="index"
                                            >
                                                <p
                                                    style="
                                                        display: flex;
                                                        justify-content: space-between;
                                                    "
                                                >
                                                    <el-tag
                                                        ><span
                                                            v-html="
                                                                item.contractName
                                                            "
                                                        ></span
                                                    ></el-tag>
                                                    <el-button
                                                        type="primary"
                                                        plain
                                                        @click="
                                                            clickContrastDialogType(
                                                                5,
                                                                item
                                                            )
                                                        "
                                                        >比对</el-button
                                                    >
                                                </p>
                                                <p>
                                                    仲裁条款:
                                                    <span
                                                        v-html="item.note1"
                                                    ></span>
                                                </p>
                                                <p>
                                                    签订日期:
                                                    <span
                                                        :class="{
                                                            redText:
                                                                item.contractDateModify
                                                        }"
                                                        >{{
                                                            item.contractDate
                                                        }}</span
                                                    >
                                                </p>
                                                <p>
                                                    合同号:
                                                    <span
                                                        v-html="item.contractNo"
                                                    ></span>
                                                </p>

                                                <el-divider></el-divider>
                                                <p>
                                                    需要更改：<i
                                                        style="color: red"
                                                        >{{ joinText }}</i
                                                    >
                                                </p>
                                                <p>
                                                    特别更改：<el-input
                                                        v-model="inputValue"
                                                        type="textarea"
                                                    />
                                                </p>
                                                <el-divider></el-divider>
                                                <div>
                                                    <el-collapse
                                                        v-model="activeNames"
                                                    >
                                                        <el-collapse-item
                                                            title="添加说明"
                                                        >
                                                            <el-checkbox-group
                                                                style="
                                                                    display: flex;
                                                                    flex-direction: column;
                                                                "
                                                                v-model="
                                                                    checkList
                                                                "
                                                            >
                                                                <el-checkbox
                                                                    v-for="(
                                                                        item,
                                                                        index
                                                                    ) in caseInfoEntry"
                                                                    :key="index"
                                                                    :label="
                                                                        item.remark
                                                                    "
                                                                ></el-checkbox>
                                                            </el-checkbox-group>
                                                        </el-collapse-item>
                                                    </el-collapse>
                                                </div>
                                            </el-card>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                            <div class="center"></div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <!-- 仲裁请求  -->
                        <div class="titleBox">
                            <h3>仲裁请求</h3>
                        </div>
                        <div class="partyBox">
                            <div class="left">
                                <!-- 仲裁依据 -->
                                <el-card class="box-card" shadow="hover">
                                    <template #header>
                                        <div class="card-header">
                                            <div class="text">
                                                <span>仲裁请求</span>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="box">
                                        <div class="mainText">
                                            <div
                                                class="leftMainText"
                                                approveReceiveLetterAppend
                                            >
                                                <el-card>
                                                    <p
                                                        style="
                                                            display: flex;
                                                            justify-content: space-between;
                                                        "
                                                    >
                                                        <span></span>
                                                        <el-button
                                                            type="primary"
                                                            plain
                                                            @click="
                                                                clickContrastDialogType(
                                                                    6,
                                                                    appendDetailVos
                                                                )
                                                            "
                                                            >比对</el-button
                                                        >
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            仲裁请求:</strong
                                                        >

                                                        <span
                                                            v-html="
                                                                appendDetailVos.arbClaim
                                                            "
                                                        >
                                                        </span>
                                                    </p>

                                                    <el-divider></el-divider>
                                                    <p>
                                                        <strong>
                                                            事实与理由:</strong
                                                        >
                                                        <span
                                                            v-html="
                                                                appendDetailVos.factReason
                                                            "
                                                        ></span>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            争议金额:</strong
                                                        >
                                                        <span
                                                            :class="{
                                                                redText:
                                                                    appendDetailVos.coinTypeModify ===
                                                                    1
                                                            }"
                                                            v-html="
                                                                appendDetailVos.coinTypeName
                                                            "
                                                        ></span>
                                                    </p>

                                                    <el-divider></el-divider>
                                                    <p>
                                                        需要更改：<i
                                                            style="color: red"
                                                            >{{ joinText }}</i
                                                        >
                                                    </p>
                                                    <p>
                                                        特别更改：<el-input
                                                            v-model="inputValue"
                                                            type="textarea"
                                                        />
                                                    </p>
                                                    <el-divider></el-divider>
                                                    <div>
                                                        <el-collapse
                                                            v-model="
                                                                activeNames
                                                            "
                                                        >
                                                            <el-collapse-item
                                                                title="添加说明"
                                                            >
                                                                <el-checkbox-group
                                                                    style="
                                                                        display: flex;
                                                                        flex-direction: column;
                                                                    "
                                                                    v-model="
                                                                        checkList
                                                                    "
                                                                >
                                                                    <el-checkbox
                                                                        v-for="(
                                                                            item,
                                                                            index
                                                                        ) in appendEntry"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :label="
                                                                            item.remark
                                                                        "
                                                                    ></el-checkbox>
                                                                </el-checkbox-group>
                                                            </el-collapse-item>
                                                        </el-collapse>
                                                    </div>
                                                </el-card>
                                            </div>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                            <div class="center"></div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <!-- 证据保全  -->
            <div class="titleBox" ref="damage">
                <h3>证据保全</h3>
            </div>
            <div class="partyBox">
                <div class="left">
                    <!-- 仲裁依据 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>证据保全</span>
                                </div>
                            </div>
                        </template>
                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    approveReceiveLetterAppend
                                >
                                    <el-card
                                        v-for="(
                                            item, index
                                        ) in preserveDetailVo"
                                        :key="index"
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span></span>
                                            <el-button
                                                type="primary"
                                                plain
                                                @click="
                                                    clickContrastDialogType(
                                                        8,
                                                        item
                                                    )
                                                "
                                                >比对</el-button
                                            >
                                        </p>
                                        <p>
                                            <strong> 保全类型:</strong>
                                            <span
                                                :class="{
                                                    redText:
                                                        item.preserveTypeModify ===
                                                        1
                                                }"
                                                v-html="item.preserveTypeName"
                                            >
                                            </span>
                                        </p>

                                        <p>
                                            <strong>法院名称 :</strong>
                                            <span
                                                v-html="item.courtName"
                                            ></span>
                                        </p>
                                        <p>
                                            <strong>法院地址 :</strong>
                                            <span
                                                v-html="item.courtAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            <strong>保全内容 :</strong>
                                            <span
                                                v-html="item.preserveContent"
                                            ></span>
                                        </p>

                                        <p>
                                            材料:
                                            <el-dropdown>
                                                <span class="el-dropdown-link">
                                                    {{ item.fileName }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                item.flagPdf ===
                                                                    1 ||
                                                                item.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(item)
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(item)
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <div>
                                            <el-collapse v-model="activeNames">
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in preserveEntry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
                <div class="center"></div>
            </div>

            <!-- 申请人材料  -->
            <div class="titleBox" ref="material">
                <h3>申请人材料</h3>
            </div>
            <div class="partyBox">
                <div class="left">
                    <!-- 申请人材料 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>申请人材料</span>
                                </div>
                            </div>
                        </template>
                        <div class="box">
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    approveReceiveLetterAppend
                                >
                                    <el-card
                                        v-for="(item, index) in detailVos"
                                        :key="index"
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>第{{ index + 1 }}证据</span>
                                        </p>
                                        <p>
                                            <strong> 材料名称:</strong>
                                            <span v-html="item.profile"> </span>
                                        </p>

                                        <p>
                                            <strong>证明内容 :</strong>
                                            <span v-html="item.remarks"></span>
                                        </p>

                                        <p>
                                            材料:
                                            <el-dropdown>
                                                <span class="el-dropdown-link">
                                                    {{ item.fileName }}
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-if="
                                                                item.flagPdf ===
                                                                    1 ||
                                                                item.flagPdf ===
                                                                    0
                                                            "
                                                            @click="
                                                                viewFile(item)
                                                            "
                                                            >预览</el-dropdown-item
                                                        >
                                                        <el-dropdown-item
                                                            @click="
                                                                downFile(item)
                                                            "
                                                            >下载</el-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </p>
                                        <el-divider></el-divider>
                                        <p>
                                            需要更改：<i style="color: red">{{
                                                joinText
                                            }}</i>
                                        </p>
                                        <p>
                                            特别更改：<el-input
                                                v-model="inputValue"
                                                type="textarea"
                                            />
                                        </p>
                                        <div>
                                            <el-collapse>
                                                <el-collapse-item
                                                    title="添加说明"
                                                >
                                                    <el-checkbox-group
                                                        style="
                                                            display: flex;
                                                            flex-direction: column;
                                                        "
                                                        v-model="checkList"
                                                    >
                                                        <el-checkbox
                                                            v-for="(
                                                                item, index
                                                            ) in applicantEntry"
                                                            :key="index"
                                                            :label="item.remark"
                                                        ></el-checkbox>
                                                    </el-checkbox-group>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
                <div class="center"></div>
            </div>

            <!-- 案件日志 -->
            <div class="titleBox" ref="log">
                <h3>案件日志</h3>
            </div>
            <div class="materialBox">
                <!-- 有数据 展示 -->
                <el-card v-if="true">
                    <el-table :data="logList" style="width: 100%">
                        <el-table-column prop="remark" label="内容">
                        </el-table-column>
                        <el-table-column prop="subDate" label="时间">
                        </el-table-column>
                    </el-table>
                </el-card>
                <!-- 没数据展示 -->
                <el-card v-else class="materialBoxItem"> 当前数据为空 </el-card>
            </div>
        </div>

        <!-- 理由 -->
        <div class="titleBox" ref="reason">
            <h3>理由</h3>
            <div class="button">
                <el-button type="primary">打印</el-button>
            </div>
        </div>
        <div class="materialBox">
            <!-- 有数据 展示 -->
            <el-card>
                <div class="reasonInputBox">
                    <el-row>
                        <el-col :span="2" style="text-align: center"
                            >理由：</el-col
                        >
                        <el-col :span="20"> </el-col>
                        <el-col :span="2">
                            <el-button type="primary" @click="getAllContent"
                                >获取</el-button
                            ></el-col
                        >
                    </el-row>
                    <TinymceEditor ref="TinymceEditorRef"></TinymceEditor>
                    <el-row style="margin-top: 20px">
                        <el-col :span="10"></el-col>
                        <el-col :span="14">
                            <el-button
                                type="danger"
                                v-if="listItem.webStatus !== '6'"
                                >不通过</el-button
                            >
                            <el-button type="primary">通过</el-button>
                            <el-button type="primary">发送到</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </div>

        <!-- 比对弹窗-------开始 -->
        <el-dialog
            :title="contrastTitle"
            v-model="contrastDialogType"
            width="60%"
            :before-close="clickContrastDialogClose"
        >
            <div>
                <partyComparison ref="partyComparisonRef" />
            </div>
        </el-dialog>
        <!-- 申请人比对弹窗-------结束 -->

        <el-dialog
            title="仲裁申请书"
            v-model="pdfDialogType"
            width="80%"
            :before-close="closepdfDialogType"
            top="0"
        >
            <div ref="pdfContentRef">
                <embed
                    :src="embedPdfSrc"
                    width="100%"
                    height="800px"
                    type="application/pdf"
                />
            </div>
            <template #footer>
                <div>
                    <el-button @click="getPdf">下载pdf</el-button>
                </div>
            </template>
        </el-dialog>

        <div :class="{ ToppingBox: true, activeToppingBox: scrollTopType }">
            <ul>
                <li>
                    <el-button
                        @click="clickScollTop('top')"
                        circle
                        size="large"
                        title="置顶"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-zhiding"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('letter')"
                        circle
                        size="large"
                        title="已发送复函"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-tongzhigonggao"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('dimension')"
                        circle
                        size="large"
                        title="标注"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use
                                xlink:href="#icon-zhibiaozhushibiaozhu
"
                            ></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('message')"
                        circle
                        size="large"
                        title="留言板"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-liuyanban-xian"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('party')"
                        circle
                        size="large"
                        title="当事人"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-gerenzhongxin"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('according')"
                        circle
                        size="large"
                        title="仲裁依据/仲裁请求"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use
                                xlink:href="#icon-a-xiangmuguanli_huaban1"
                            ></use>
                        </svg>
                    </el-button>
                </li>

                <li>
                    <el-button
                        @click="clickScollTop('damage')"
                        circle
                        size="large"
                        title="证据保全"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-wj-fzzj"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('material')"
                        circle
                        size="large"
                        title="申请人材料"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-renwu"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('log')"
                        circle
                        size="large"
                        title="案件日志"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-chinese"></use>
                        </svg>
                    </el-button>
                </li>
                <li>
                    <el-button
                        @click="clickScollTop('reason')"
                        circle
                        size="large"
                        title="理由"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-neironggengxin"></use>
                        </svg>
                    </el-button>
                </li>
            </ul>
        </div>
    </div>
</template>
 
<script>
// 富文本
import TinymceEditor from '../../components/TinymceEditor.vue'
// 当事人对比框
import partyComparison from './partyComparison.vue'
export default {
    name: 'registerCase',
    components: {
        partyComparison,
        TinymceEditor
    },
    data() {
        return {
            // 页面加载效果
            registerLoading: false,
            // 案件日志数组
            listA: [
                {
                    date: '2021-11-08',
                    text: '补充材料A'
                },
                {
                    date: '2021-11-09',
                    text: '补充材料AB'
                }
            ],

            // 选中的数组
            checkList: [],
            // 比对弹出框
            contrastDialogType: false,
            // --------------------------
            // 仲裁依据
            caseDetailInfoVos: {},

            // 申请人
            partyInfo1: [],
            // 被申请人
            partyInfo2: [],
            // 申请代理人
            deputyInfo1: [],
            // 被申请代理人
            deputyInfo2: [],
            // 申请人材料
            detailVos: [],
            // 保全
            preserveDetailVo: [],
            // 仲裁请求
            appendDetailVos: {},
            // 日志列表
            logList: [],
            // 数组携带的每一条数据
            listItem: {},
            // 留言列表
            stayMessage: [],
            // 输入框的值
            inputValue: '',
            // 仲裁请求弹框状态
            claimsVsType: false,
            // 仲裁依据弹框状态
            accordingVsType: false,
            // 下载枚举
            downEnum: this.$store.state.downEnum,
            // 滚动高度
            scrollTop: '',
            // 对比弹窗的题目
            contrastTitle: '我是玛卡巴卡',

            // 数组
            // 申请人词条
            party1Entry: [],
            // 被申请人词条
            party2Entry: [],
            // 申请人代理人词条
            deputy1Entry: [],
            // 被申请代理人词条
            deputy2Entry: [],
            // 申请人材料词条
            applicantEntry: [],
            // 保全材料词条
            preserveEntry: [],
            // 合同信息词条
            caseInfoEntry: [],
            // 仲裁请求词条
            appendEntry: [],
            // 输入框
            inputText: '',
            // 折叠面板默认关闭
            activeNames: [],

            // pdf弹窗
            pdfDialogType: false,
            // 页面中预览pdf
            embedPdfSrc: '',
            // 留言内容
            msgContent: '',

            // 申请人需要更改
            getPartyContentList: []
        }
    },
    methods: {
        async load(row) {
            this.registerLoading = true
            this.activeNames = []
            // 给整体发起请求并赋值
            await this.getCaseApproveDetail(row)
            // 案件日志请求
            await this.getShowArbitrationLog(row)
            // 请求留言
            await this.getStayMessage(row)
            // 请求词条
            await this.getEntryList()
            this.listItem = row
            this.registerLoading = false
        },

        // 比对弹出框打开
        async clickContrastDialogType(type, item) {
            let caseId = item.caseId
            let itemId
            if (type === 1 || type === 3) {
                itemId = item.partyId
                if (type === 1) {
                    this.contrastTitle = '申请人比对'
                } else {
                    this.contrastTitle = '被申请人比对'
                }
            } else if (type === 2 || type === 4) {
                itemId = item.deputyId
                if (type === 2) {
                    this.contrastTitle = '申请人代理人比对'
                } else {
                    this.contrastTitle = '被申请人代理人比对'
                }
            } else if (type === 5) {
                itemId = item.note3
                this.contrastTitle = '仲裁依据比对'
            } else if (type === 6) {
                itemId = null
                this.contrastTitle = '仲裁请求比对'
            } else if (type === 7) {
                itemId = item.id
                this.contrastTitle = '申请人材料比对'
                caseId = this.listItem.caseId
            } else if (type === 8) {
                itemId = item.id
                this.contrastTitle = '证据保全比对'
            }
            // console.log(item, 'item')
            // console.log(
            //     type,
            //     caseId,
            //     itemId,
            //     this.listItem.webRepulseNum,
            //     '2069'
            // )
            let param = {
                req: {
                    copyType: type,
                    caseId,
                    itemId
                }
            }

            let res = await this.$API.sendRequest('CaseComparison', param)
            if (res.data.length > 0) {
                this.contrastDialogType = true
                this.$nextTick(() => {
                    this.$refs.partyComparisonRef.load(
                        type,
                        caseId,
                        itemId,
                        res
                    )
                })
            } else {
                this.$message.info('暂无比对信息')
            }
        },
        // 弹出框关闭
        clickContrastDialogClose() {
            this.contrastDialogType = false
        },

        // 给整体发起请求并赋值
        async getCaseApproveDetail(row) {
            let param = {
                req: {
                    caseId: row.caseId,
                    receiveNo: row.receiveLetterNo
                }
            }
            const res = await this.$API.sendRequest('CaseApproveDetail', param)
            if (!res.success) this.$message.error(res.msg)
            // 申请人
            this.partyInfo1 = res.data.partyInfo1
            // 被申请人
            this.partyInfo2 = res.data.partyInfo2
            // 申请人代理人
            this.deputyInfo1 = res.data.deputyInfo1
            // 被申请人代理人
            this.deputyInfo2 = res.data.deputyInfo2
            // 仲裁依据
            this.caseDetailInfoVos = res.data.caseDetailInfoVos
            // 仲裁请求
            this.appendDetailVos = res.data.appendDetailVos
            // 保全
            this.preserveDetailVo = res.data.preserveDetailVo
            // 申请人材料
            this.detailVos = res.data.detailVos
        },

        // 案件日志请求
        // ShowArbitrationLog
        async getShowArbitrationLog(row) {
            let param = {
                req: row.caseId
            }
            let { data } = await this.$API.sendRequest(
                'ShowArbitrationLog',
                param
            )
            this.logList = data
        },

        // 请求留言
        async getStayMessage(row) {
            let param = {
                req: row.caseId
            }
            let { data } = await this.$API.sendRequest(
                'ShowMessageBoard',
                param
            )
            this.stayMessage = data
        },

        // 仲裁请求比对框、
        claimsVsDialog() {
            this.claimsVsType = true
        },

        // 仲裁请求比对框关闭
        clickclaimsVsTypeClose() {
            this.claimsVsType = false
        },

        // 仲裁依据对比框开启
        clickaccordingDialog() {
            this.accordingVsType = true
        },

        // 仲裁依据对比框关闭
        clickaccordingVsTypeClose() {
            this.accordingVsType = false
        },

        // 预览文件-----------只能预览pdf，j
        async viewFile(fileItem) {
            this.registerLoading = true
            let param = {
                req: {
                    fileNameDsp: fileItem.fileNameDsp || fileItem.fileName,
                    filePath: fileItem.filePath,
                    flagPdf: fileItem.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.registerLoading = false
            let blob
            if (fileItem.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            window.open(url)
        },

        // 点击下载
        async downFile(fileItem) {
            this.registerLoading = true
            let param = {
                req: {
                    fileNameDsp: fileItem.fileNameDsp || fileItem.fileName,
                    filePath: fileItem.filePath,
                    flagPdf: fileItem.flagPdf
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.registerLoading = false
            const filename = param.req.fileNameDsp
            let fileType = filename.substring(filename.lastIndexOf('.'))
            for (let key in this.downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: this.downEnum[key] })
                    let url = window.URL.createObjectURL(blob)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = param.req.fileNameDsp
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            }
        },

        // 滚动事件
        handleScrollx() {
            this.scrollTop = window.pageYOffset
        },

        // 点击置顶按钮
        clickScollTop(type) {
            // 顶部为top
            // 点击已发送复函B
            // 点击标注C
            // 点击留言板D
            // 点击当事人E
            // 点击仲裁依据F
            // 点击仲裁请求G
            // 点击证据保全H
            // 点击申请人材料I
            // 点击案件日志J
            // 点击理由K
            let setPosition = this.$refs[type].offsetTop
            let setHeight = setPosition
            window.scrollTo({
                top: setHeight,
                behavior: 'smooth'
            })
        },

        // 请求词条所有的数组
        async getEntryList() {
            let { data } = await this.$API.sendRequest('CaseShowEntry')
            // 申请人词条
            this.party1Entry = data.party1Entry
            // 被申请人词条
            this.party2Entry = data.party2Entry
            // 申请人代理人词条
            this.deputy1Entry = data.deputy1Entry
            // 被申请人词条
            this.deputy2Entry = data.deputy2Entry
            // 申请人材料词条
            this.applicantEntry = data.applicantEntry
            // 保全材料词条
            this.preserveEntry = data.preserveEntry
            // 合同信息词条
            this.caseInfoEntry = data.caseInfoEntry
            // 仲裁请求词条
            this.appendEntry = data.appendEntry
        },

        // 给多选框绑定事件并拼接文字
        checkboxChange(e, remark, caseId) {
            if (e) {
                this.getPartyContentList[caseId] += remark
            } else {
                this.getPartyContentList[caseId] = this.getPartyContentList[
                    caseId
                ].replaceAll(remark, ' ')
            }
        },

        // 打印pdf
        async handlePDF() {
            this.pdfDialogType = true
            let param = {
                req: null
            }
            let res = await this.$API.sendRequest('GeneratePdf', param)
            let blob = new Blob([res], {
                type: 'application/pdf'
            })
            let url = window.URL.createObjectURL(blob)
            this.embedPdfSrc = url

            // htmlToPdf.downloadPDF(
            //     document.getElementById('pdfBox'),
            //     '下载pdf文件名'
            // )
        },
        closepdfDialogType() {
            this.pdfDialogType = false
        },
        clickPDF() {
            // htmlToPdf.downloadPDF(this.$refs.pdfContentRef, '下载pdf文件名')
        },

        // 创建一个名为 `getPdf()` 的函数，用于将指定的 HTML 元素转换为 PDF 文件并进行下载。
        async getPdf() {
            let param = {
                req: this.listItem.caseId
            }
            let res = await this.$API.sendRequest('GeneratePdf', param)
            let blob = new Blob([res], {
                type: 'application/pdf'
            })
            let url = window.URL.createObjectURL(blob)
            this.embedPdfSrc = url
        },

        // 添加留言
        async saveMessageBoard() {
            let param = {
                req: {
                    caseId: this.listItem.caseId,
                    msgContent: this.msgContent,
                    receiveLetterNo: this.listItem.receiveLetterNo
                }
            }
            let res = await this.$API.sendRequest('SaveMessageBoard', param)
            if (!res.success) this.$message.error('留言失败')
            this.msgContent = ''
            this.$message.success(res.msg)
            // 重新渲染
            await this.getStayMessage(this.listItem)
        },

        // 点击底部获取
        getAllContent() {
            console.log(this.getPartyContentList)
        }
    },

    computed: {
        joinText() {
            if (this.checkList.length < 1) {
                return this.inputValue
            }
            return this.checkList.join(',') + '。' + this.inputValue
        },
        scrollTopType() {
            return this.scrollTop > 200
        }
    },
    // 监视滚动条
    mounted() {
        window.addEventListener('scroll', this.handleScrollx, true)
    }
}
</script>
 
<style lang="scss" scoped>
.registerCaseBox {
    position: relative;
    left: 0;
    text-align: left;
    padding: 0 10px;
    .ToppingBox {
        position: fixed;
        bottom: 50px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s;
        &.activeToppingBox {
            opacity: 1 !important;
        }
    }
}
header {
    h2 {
        font-weight: 500;
        font-size: 18px;
    }
    .rightBtnBox {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.alert {
    .el-alert--success.is-light {
        height: 40px !important;
    }
}
.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #ff0000;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
    .butBox {
        margin-right: 20px;
        display: flex;
        align-self: center;
    }
    .button {
        display: flex;
        align-items: center;
    }
}
.partyBox {
    display: flex;
    .left {
        flex: 1;
    }
    .center {
        width: 20px;
    }
    .right {
        flex: 1;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        .text {
            display: flex;
            align-items: center;
            color: #060606;
        }
    }
    :deep(.el-card__header) {
        background-color: #d9ecf6;
        padding: 10px 20px;
    }
    .box-card {
        .mainText {
            font-size: 15px;
            span {
                color: #827878;
            }
            .leftMainText {
                width: 100%;
            }
            .elseBoxSpan {
                span {
                    color: #8788a3;
                }
            }
        }
    }
    :deep(.el-col-20) {
        align-items: center;
    }
}

.materialBoxItem {
    background-color: #fbf9e5;
}

.applyForBox {
    p {
        text-align: right;
        font-weight: 600;
    }
    .applyForItem {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.addressBox {
    .nullAddressItem {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
    }
    p {
        font-size: 20px;
        margin-left: 20px;
    }
    span {
        margin-left: 20px;
        font-size: 16px;
        color: red;
    }
}

.my-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .but {
        display: flex;
        align-self: center;
    }
    .titleClass {
        font-size: 20px;
        font-weight: 500;
    }
}
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-content: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.addressCardBox {
    .box-card {
        cursor: pointer;
    }
    .box-card:nth-child(1) {
        margin: 20px 0;
    }
}

.elseBoxSpan {
    span {
        color: #8788a3;
    }
}

.dimensionInputBox {
    margin-top: 20px;
}
.leaveMessage {
    .footerText {
        text-align: right;
        margin-top: 20px;
        span {
            color: #6c6565;
            margin-right: 10px;
            font-size: 14px;
        }
    }
}
.leaveMessageButBox {
    display: flex;
    flex-direction: row-reverse;
}
// :deep(.el-checkbox__input):nth-child(1) {
//     display: none;
// }
/* 隐藏不选节点 */
:deep(.stafftree > .el-tree-node > .el-tree-node__content .el-checkbox) {
    display: none;
}
.col2BtnBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contrastDialogBox {
    display: flex;
    .left {
        flex: 1;
    }
    .right {
        flex: 1;
    }
    .iconContrasDialog {
        font-size: 110px;
        padding: 0 30px;
        display: flex;
        align-items: center;
    }
}

.reasonInputBox {
    width: 100%;
}

.el-dropdown-link {
    padding: 0 10px;
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
}

.redText {
    color: red !important;
}
.rightBtnFlex {
    display: flex;
    justify-content: space-between;
}

ul li {
    margin: 10px 0;
}

.el-checkbox {
    white-space: initial !important;
}

.pdfBox {
    :deep(.el-dialog) {
        margin-top: 0;
    }
}
</style>