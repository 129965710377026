<template>
    <div>
        <!-- 顶部搜索开始 -->
        <div class="search">
            <el-form :inline="true" :model="searchModel" class="demo-form-inline">

                <el-form-item :label="$t('msg.Dateofeceiptfrom')" prop="receiveFromDate"> <!-- 双语 -->
                    <el-date-picker v-model="searchModel.receiveFromDate" align="right" type="date" :placeholder="$t('msg.OptionDate')"
                        :picker-options="pickerOptions" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('msg.Arrive')" prop="receiveEndDate"> <!-- 双语 -->
                    <el-date-picker v-model="searchModel.receiveEndDate" align="right" type="date" :placeholder="$t('msg.OptionDate')"
                        :picker-options="pickerOptions" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('msg.Typeofcorrespondence')" prop="materialType">
                    <el-select :placeholder="$t('msg.MaterialType')" v-model="searchModel.materialType">
                        <el-option v-for="item in DictionaryList" :key="item.dictionaryItemValue"
                            :label="item.dictionaryItemName" :value="item.dictionaryItemValue">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSearch">
                        <el-icon class="el-icon--right">
                            <Search />
                        </el-icon>{{$t('msg.case_search')}}
                    </el-button>
                    <el-button @click="reset" style="background-color: #fff;">
                       {{$t('msg.case_reset')}}
                    </el-button>

                </el-form-item>
            </el-form>
        </div>
        <!-- 顶部搜索结束 -->

        <!-- 渲染数据表格开始 -->
        <div class="table">
            <el-table :data="list" strip border style="width: 100%">
                <el-table-column prop="dictionaryItemName" :label="$t('msg.Typeofcorrespondence')" width="180">
                </el-table-column>

                <el-table-column prop="counts" :label="$t('msg.Copies')">
                </el-table-column>

                <el-table-column :label="$t('msg.Operate')">
                    <template #default="scope">
                        <el-button @click="listMaterialDetail(scope.row)" type="primary" size="small">{{$t('msg.View')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>


        </div>
        <!-- 渲染数据表格结束 -->



    </div>
</template>

<script>
// import {Plus} from '@element-plus/icons-vue'
export default {
    created() {

    },
    data() {
        return {
            // 顶部搜索数据
            searchModel: {
                receiveFromDate: '',
                receiveEndDate: '',
                materialType: ''
            },
            // 弹出框行为
            dialogFormVisible: false,
            // 渲染数组列表
            list: [],
            // 加载等待效果
            loading: false,
            // 分页参数
            pagination: {
                page: 1,
                pages: 0,
                limit: 50,
                sizes: [10, 20, 50, 100, 500, 1000],
                total: 0
            },
            // 弹出框提示语句
            dialogTitle: '新增', // 弹出框标题 点编辑按钮 dialogTitle: '编辑'
            // 国别
            countryList: [],
            // 表单数据对象
            bindModel: {
                name: '',
                receiveLetterDate: '',
                nation: '',
                register_type: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            // 时间相关
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
            DictionaryList: []


        }
    },
    methods: {
        // 页面一进入触发
        load() {
            this.GetFileList()
            this.GetDictionaryItems()
        },

        onSearch() {
            this.GetFileList()
        },
        // 发送请求   获取表单数据
        async GetFileList() {
            //
            const param = {
                "req": {
                    "startDate": this.$GF.formatTime(this.searchModel.receiveFromDate, 'yyyy-MM-DD') === 'NaN-NaN-NaN' ? '' : this.$GF.formatTime(this.searchModel.receiveFromDate, 'yyyy-MM-DD'),
                    "endDate": this.$GF.formatTime(this.searchModel.receiveEndDate, 'yyyy-MM-DD') === 'NaN-NaN-NaN' ? '' : this.$GF.formatTime(this.searchModel.receiveEndDate, 'yyyy-MM-DD'),
                    "materialStyle": this.searchModel.materialType || '',
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('ListReceiveStyle', param)
            this.loading = false
            if (res.success) {
                this.list = res.data || []
            } else {
                this.$message.error(res.msg)
            }
        },
        async GetDictionaryItems() {
            const param = {
                "req": 30
            }
            this.loading = true
            const res = await this.$API.sendRequest('styleTypeList', param)
            this.loading = false
            this.DictionaryList = res.data.materialStyleList || []
        },
        showDialog() {
            this.dialogFormVisible = true
            this.GetCountryList()
        },
        closeDialog() {
            this.dialogFormVisible = false
            // 重置表单 所有字段全部清空
            this.bindModel = {
                name: '',
                receiveLetterDate: '',
                nation: '',
                register_type: ''
            }
            this.$refs.bindModel.resetFields()
        },
        reset() {
            this.searchModel = {
                receiveFromDate: '',
                receiveEndDate: '',
                materialType: ''
            }
        },
        onSubmit() {
            this.$refs.bindModel.validate(async (valid) => {
                if (valid) {
                    // 收集表单数据 提交到服务端
                    const params = {
                        "req": {
                            senderCompany: '北京1234',
                            senderAddress: '西直门',
                            materialStyle: 2

                        }
                        // "req": this.bindModel
                    }
                    // 特殊字段处理
                    // params.req.content = this.bindModel.content + '123'
                    const res = await this.$API.sendRequest('saveOrUpdate', params)
                    if (res && res.success) {
                        // 关闭对话框
                        this.closeDialog()
                        this.$message.success('操作成功') // 保存成功弹框提示
                        // 保存成功刷新列表
                        this.GetFileList()
                    } else {
                        this.$message.error('获取数据失败')
                    }
                } else {
                    return false;
                }
            });
        },
        async GetCountryList() {
            const params = {
                "req": {
                    "caseNo": '',
                    "receiveLetterTitle": '',
                    "receiveLetterDate": '',
                    "receiveLetterAmount": ''
                }
            }
            const res = await this.$API.sendRequest('detailInput', params)
            if (res && res.success) {
                this.countryList = res.data.receiveDetailVo.senderNationList
            } else {
                this.$message.error('获取数据失败')
            }
        },
        senderNationalityChange() { },
        listMaterialDetail(row) {
            let tempObject = {
                ...row,
                ...this.searchModel
            }
            this.$emit("ListMaterialdata", tempObject)
        }

    }
}
</script>

<style lang="scss" scoped>
:deep(.el-button) {
    background-color: #79bbff;
}
</style>