<template>
    <div>
        <div class="registerCaseBox" v-loading="loading">
            <div class="showBox">
                <!-- 退费信息补录  -->
                <div class="titleBox" ref="top">
                    <h3>{{$t('msg.Refundinformationissupplemented')}}</h3>
                </div>
                <el-card class="box-card">
                    <el-table :data="tableList" style="width: 100%">
                        <el-table-column
                            prop="caseName"
                            :label="$t('msg.case_name')"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="caseNo"
                            :label="$t('msg.workbench_search_no')"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column prop="moneySum" :label="$t('msg.AmountOfMoney')">
                        </el-table-column>
                        <el-table-column
                            prop="withdrawStateString"
                            :label="$t('msg.Status')"
                        >
                            <template #default="scope">
                                <div>
                                    <el-tag :type="tagEnum(scope.row.state)">
                                        {{
                                            scope.row.state
                                                ? scope.row.state > 6
                                                    ? stateMap[scope.row.state]
                                                    : '审核中'
                                                : '无'
                                        }}
                                    </el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.Operate')">
                            <template #default="scope">
                                <div>
                                    <el-button @click="clickDetail(scope.row)"
                                        >{{$t('msg.View')}}</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </div>
        <el-drawer
            :title="$t('msg.InformationRetrieval')"
            v-model="drawerType"
            :before-close="drawerClose"
        >
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <div v-for="(item, index) in formListFor" :key="index">
                    <!-- 如果是开关的特殊情况 -->
                    <el-form-item
                        :label="item.name"
                        v-if="item.key === 'switchType'"
                    >
                        <el-switch
                            v-model="switchBtnType"
                            :active-text="$t('msg.declaration_signature_dialog_yes')"
                            :inactive-text="$t('msg.declaration_signature_dialog_no')"
                        >
                        </el-switch>
                    </el-form-item>
                    <!-- 如果是币种的特殊情况需要改字段 -->
                    <el-form-item
                        :label="item.name"
                        v-else-if="item.key === 'coinType'"
                    >
                        <el-select
                            style="width: 100%"
                            v-model="ruleForm[item.key]"
                            :placeholder="$t('msg.PleaseSelect')"
                            :disabled="item.type"
                        >
                            <el-option :label="$t('msg.RMB')" :value="1"> </el-option>
                            <el-option :label="$t('msg.foreignCurrency')" :value="2"> </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 正常情况 -->
                    <el-form-item :label="item.name" :prop="item.key" v-else>
                        <el-input
                            :disabled="item.type"
                            v-model="ruleForm[item.key]"
                        >
                        </el-input>
                    </el-form-item>
                </div>
                <!-- 境外地址 -->
                <el-form-item
                    :label="$t('msg.PayeeAddress')"
                    prop="gatherAddr"
                    v-if="switchBtnType"
                >
                    <el-input
                        v-model="ruleForm['gatherAddr']"
                        :placeholder="$t('msg.PleaseenterEnglish')"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item
                    :label="$t('msg.AddressofReceivingBank')"
                    prop="gatherAddr1"
                    v-if="switchBtnType"
                >
                    <el-input
                        v-model="ruleForm['gatherAddr1']"
                        :placeholder="$t('msg.PleaseenterEnglish')"
                    >
                    </el-input>
                </el-form-item>

                <!-- swift code -->
                <el-form-item
                    label="swift code"
                    prop="swiftCode"
                    v-if="switchBtnType"
                >
                    <el-input
                        v-model="ruleForm['swiftCode']"
                        :placeholder="$t('msg.PleaseenterEnglish')"
                    >
                    </el-input>
                </el-form-item>

                <!-- IBAN号 -->
                <el-form-item
                    label="IBAN号"
                    prop="ibanCode"
                    v-if="switchBtnType"
                >
                    <el-input
                        v-model="ruleForm['ibanCode']"
                       :placeholder="$t('msg.PleaseenterEnglish')"
                    >
                    </el-input>
                </el-form-item>

              
                <h3 style="align-items: center;color: red;" v-if="ruleForm.state === 10">
                    {{ ruleForm.remark1 }}
                </h3>

                <el-form-item :label="$t('msg.InvoicingInstructions')">
                    <div class="uploadBox">
                        <input
                            v-show="false"
                            type="file"
                            ref="inputFileRef"
                            @change="fileChange"
                            accept=".png, .jpeg, .jpg"
                        />

                        <div class="imgBox" v-show="srcList[0]">
                            <el-image
                                style="width: 103px; height: 115px"
                                :src="url"
                                :preview-src-list="srcList"
                            >
                            </el-image>
                        </div>
                        <el-button @click="clickModifyImg" type="primary">
                            {{$t('msg.Addadescriptionofmakeoutaninvoice')}}
                        </el-button>
                    </div>
                    <span style="color: red"> {{$t('msg.Theuploadformatis')}}jpg,jpeg,png</span>
                </el-form-item>
                <div class="bottomBox">
                    <div class="bottomBtn">
                        <el-button
                            type="primary"
                            style="padding: 19px 30px"
                            @click="submitForm"
                            :disabled="ruleForm.state !== 9"

                            >{{$t('msg.SubmissionofaSupplement')}}</el-button
                        >
                        <!-- @click="submitForm" -->

                        <!-- <el-button @click="resetForm">重置</el-button> -->
                        <!-- <p v-else>注:已无开票额度</p> -->
                    </div>
                </div>
            </el-form>
        </el-drawer>
    </div>
</template>
 
<script>
export default {
    name: '',

    data() {
        return {
            loading: false,
            tableList: [],
            caseId: '',
            // 状态枚举
            stateMap: {
                8: '待退费',
                9: '已退费',
                10: '退费驳回'
            },
            // 侧边栏状态
            drawerType: false,
            // 开关
            switchBtnType: false,
            // 循环的数据结构
            formListFor: [
                {
                    name: this.$t('msg.case_name'),
                    type: true,
                    key: 'caseName'
                },
                {
                    name: this.$t('msg.workbench_search_no'),
                    type: true,
                    key: 'caseNo'
                },
                {
                    name: this.$t('msg.Manager'),
                    type: true,
                    key: 'secName'
                },
                {
                    name: this.$t('msg.Currency'),
                    type: true,
                    key: 'coinType'
                },
                {
                    name: this.$t('msg.NameOfRecipientsAccount'),
                    type: false,
                    key: 'gatheringCompany'
                },
                {
                    name: this.$t('msg.RecipientsAccount'),
                    type: false,
                    key: 'account'
                },
                {
                    name: this.$t('msg.Addressofthepayeeisopeningbank'),
                    type: false,
                    key: 'bank'
                },
                {
                    name: this.$t('msg.WhetherOverseas'),
                    type: false,
                    key: 'switchType'
                },

                {
                    name: '手机',
                    type: false,
                    key: 'refundMobile'
                },
                // {
                //     name: 'swift code',
                //     type: false,
                //     key: 'swiftCode'
                // },
                // {
                //     name: 'IBAN号',
                //     type: false,
                //     key: 'ibanCode'
                // }
            ],
            // 收集数据
            ruleForm: {
                // 案件名称
                caseName: '',
                // 案件编号
                caseNo: '',
                // 经办人
                secName: '',
                // 币种
                coinType: '',
                // 收款人账户名称
                gatheringCompany: '',
                // 收款账号
                account: '',
                // 收款人开户行地址
                bank: '',

                // 境外地址
                gatherAddr1: '',
                // swift cod
                swiftCode: '',
                // IBAN号
                ibanCode: '',
                gatherAddr: '',
                // 手机号 
                refundMobile:''
            },
            // 校验
            rules: {
                // 收款人账户名称
                gatheringCompany: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 收款账号
                account: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 收款人开户行地址
                bank: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],

                // 境外地址
                gatherAddr: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 境外地址
                gatherAddr1: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // swiftCode
                swiftCode: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // ibanCode
                ibanCode: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                  // refundMobile
                  refundMobile: [
                      { required: true, message: '此项为必填', trigger: 'blur' },
                      { min:8, message: '输入正确的手机', trigger:[ 'change','blur'] },
                    
                ]
            },
            moneyEnum: {
                1: '人民币',
                2: '外币'
            },
            srcList: [],
            url: ''
        }
    },
    methods: {
        load(caseId) {
            this.caseId = caseId
            this.getRefundSupplementList()
        },

        // 获取列表
        async getRefundSupplementList() {
            this.loading = true
            let param = {
                req: this.caseId
            }
            let res = await this.$API.sendRequest('RefundSupplementList', param)
            if (!res.success) {
                this.loading = false
                return this.$message.error(res.msg)
            }
            this.tableList = res.data
            this.loading = false
        },

        // tag标签的枚举--颜色的变化
        tagEnum(state) {
            if (state < 6) {
                return 'info'
            } else if (state === 8 || state === 9) {
                return 'info'
            } else if (state === 10) {
                return 'danger'
            } else {
                return ''
            }
        },

        // 点击查看打开侧边栏
        clickDetail(row) {
            this.switchBtnType = row.swiftCode ? true : false
            this.ruleForm = {
                ...row
            }
            if (this.ruleForm.fileBase) {
                this.url = 'data:image/jpg;base64,' + this.ruleForm.fileBase
                this.srcList[0] =
                    'data:image/jpg;base64,' + this.ruleForm.fileBase
            }
            this.drawerType = true
        },

        // 点击查看关闭侧边栏
        drawerClose() {
            this.ruleForm = {}
            this.url = ''
            this.srcList = []
            this.drawerType = false
        },

        // 点击   补录  收集数据
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (!this.switchBtnType) {
                        this.ruleForm.gatherAddr1 = ''
                        this.ruleForm.gatherAddr = ''
                        this.ruleForm.swiftCode = ''
                        this.ruleForm.ibanCode = ''
                    }
                    let param = {
                        req: {
                            ...this.ruleForm
                        }
                    }
                    let res = await this.$API.sendRequest(
                        'UpdRefundSupplement',
                        param
                    )
                    if (!res.success) {
                        return this.$message.error(res.msg)
                    }
                    this.$message.success(res.msg)
                    this.getRefundSupplementList()
                    this.drawerType = false
                }
            })
        },

        // 选择文件后,对头像的处理
        fileChange() {
            let inputRef = this.$refs.inputFileRef
            let oFReader = new FileReader()
            oFReader.readAsDataURL(inputRef.files[0])
            oFReader.onloadend = (oFRevent) => {
                let src = oFRevent.target.result
                this.url = src
                this.srcList[0] = src
                this.ruleForm.fileBase = src
            }
        },
        // 点击修改图片  弹出选择文件
        clickModifyImg() {
            let inputRef = this.$refs.inputFileRef
            inputRef.click()
        }
    }
}
</script>
 
<style scoped lang='scss'>
.registerCaseBox {
    position: relative;
    left: 0;
    text-align: left;
    padding: 0 10px;
    .ToppingBox {
        position: fixed;
        bottom: 50px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s;
        &.activeToppingBox {
            opacity: 1 !important;
        }
    }
}

.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #070707;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
}

header {
    h2 {
        font-weight: 500;
        font-size: 18px;
    }
    .rightBtnBox {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.box-card {
    .mainText {
        font-size: 15px;
        span {
            color: #827878;
        }
        .leftMainText {
            width: 100%;
        }
        .elseBoxSpan {
            span {
                color: #8788a3;
            }
        }
    }
    .btnBox {
        display: flex;
        justify-content: center;
    }
}

:deep(.el-form-item__label) {
    width: 175px !important;
}

:deep(.el-input-group__append) {
    height: 35px !important;
    padding: 0;
}

.searchPartyCompanyBtn {
    width: 60px;
    height: 100%;
    cursor: pointer;
}

:deep(.selectSearchPartyCompanyBox) {
    width: 100% !important;
    .selectItemSearch {
        padding-left: 20px;
        max-height: 300px;
        background-color: #e2e2e2;
        text-align: left;
        cursor: pointer;
        overflow: auto;
        & > div:hover {
            background: #ccc;
        }
    }
}

.uploadBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bottomBox {
    p {
        font-size: 20px;
        color: red;
    }
}

.rejectReasonBox {
    font-size: 20px;
    color: red;
}
.imgBox {
    width: 100%;
    height: 100%;
}
</style>