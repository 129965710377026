<template>
    <div>
        <div class="block" v-loading="loading">
            <el-timeline>
                <el-timeline-item
                    :timestamp="item.subDate"
                    placement="top"
                    v-for="(item, index) in list"
                    :key="index"
                    color="#0e85ff"
                >
                    <el-card>
                        <h4 style="font-size: 22px">{{ item.remark }}</h4>
                        <p style="font-size: 16px">{{ item.operateDesc }}</p>
                        <p style="font-size: 16px">
                            操作人:{{ item.submitUserName }}
                        </p>
                        <p style="color: blue">{{ item.subDate }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseTimeLineLogDialog',
    data() {
        return {
            loading: false,
            list: []
        }
    },
    methods: {
        async load(obj) {
            this.loading = true
            await this.getList(obj)
            this.loading = false
        },
        // 点击查看日志
        async getList(obj) {
            let param = {
                req: obj.caseId
            }
            let res = await this.$API.sendRequest('ShowArbitrationLog', param)
            if (!res.success) this.$message.error(res.msg)
            this.list = res.data
        }
    }
}
</script>

<style lang="scss">
.block {
    padding-top: 20px;
}
</style>