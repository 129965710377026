<template>
    <el-container>
        <el-header>
            <Header />
        </el-header>
        <el-main>
            <div class="as_wrapper">
                <el-form
                    label-width="100px"
                    :model="arbitrament_signature_form"
                    style="
                        text-align: right;
                        margin-top: 50px;
                        margin-left: 40px;
                        margin-right: 40px;
                    "
                >
                    <el-form-item label="签名状态">
                        <span
                            v-if="
                                this.arbitrament_signature_form.approvePage != 1
                            "
                            style="
                                font-weight: bold;
                                font-size: large;
                                color: red;
                            "
                            >待签名</span
                        >
                        <span
                            v-if="
                                this.arbitrament_signature_form.approvePage == 1
                            "
                            style="
                                font-weight: bold;
                                font-size: medium;
                                color: red;
                            "
                            >已签名</span
                        >
                    </el-form-item>
                    <el-form-item label="案件编号">
                        <el-input
                            v-model="arbitrament_signature_form.caseNo"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="案件名称">
                        <el-input
                            v-model="arbitrament_signature_form.caseName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="经办秘书">
                        <el-input
                            v-model="arbitrament_signature_form.userName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="裁决书">
                        <el-button
                            color="green"
                            @click="CheckArbitramentSignature"
                            >{{
                                $t('msg.arbitrament_signature_preview')
                            }}</el-button
                        >
                    </el-form-item>
                    <el-form-item label="申请人">
                        <el-input
                            v-model="arbitrament_signature_form.partyCompany1"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="申请代理人">
                        <el-input
                            v-model="arbitrament_signature_form.deputyCompany1"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="被申请人">
                        <el-input
                            v-model="arbitrament_signature_form.partyCompany2"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="被申请代理人">
                        <el-input
                            v-model="arbitrament_signature_form.deputyCompany2"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="仲裁语言">
                        <el-input
                            v-model="arbitrament_signature_form.arbLanguage"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="程序规则">
                        <el-input
                            v-model="arbitrament_signature_form.applyRule"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="争议类型">
                        <el-input
                            v-model="arbitrament_signature_form.disputeType"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="争议金额">
                        <el-input
                            v-model="arbitrament_signature_form.projectQuote"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="本人签名">
                        <el-image
                            style="width: 150px; height: 51px"
                            :src="this.src"
                        ></el-image>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-checkbox
                            v-model="arbitrament_signature_form.checked"
                        >
                            本人已详细阅读此裁决书，同意签署裁决</el-checkbox
                        >
                    </el-form-item>
                    <div style="display: flex; justify-content: center">
                        <span
                            v-if="
                                this.arbitrament_signature_form.approvePage != 1
                            "
                        >
                            <el-button
                                color="#4578FF"
                                style="
                                    height: auto;
                                    display: block;
                                    margin-: 0 auto;
                                "
                                @click="acceptSignature"
                                >{{
                                    $t('msg.arbitrament_signature_agreement')
                                }}</el-button
                            >
                        </span>
                    </div>
                </el-form>
            </div>

            <!-- 裁决书：同意签名成功对话框 -->
            <el-dialog v-model="dialogVisible" width="500px">
                <div style="text-align: center; font-size: medium">
                    {{ $t('msg.declaration_signature_success') }}
                </div>
                <template #footer>
                    <span
                        style="text-align: center; display: block"
                        class="dialog-footer"
                    >
                        <el-button
                            type="primary"
                            @click="dialogVisible = false"
                            >{{ $t('msg.dialog_confirm') }}</el-button
                        >
                    </span>
                </template>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import axiosConfig from '@/config/api'
import Header from '@/components/Header'
export default {
    name: 'ArbitramentSignatureJump',
    created() {
        this.GetArbitramentSignature()
    },
    components: {
        Header
    },
    data() {
        return {
            loading: false,
            case_id: this.$route.params.caseId,
            dialogVisible: false,
            src: '',
            has_signature: false, // 有无待签字裁决书 false 没有  true 有
            arbitrament_signature_form: {
                caseNo: '',
                caseName: '',
                userName: '',
                applyRule: '',
                partyCompany1: '',
                deputyCompany1: '',
                partyCompany2: '',
                deputyCompany2: '',
                arbLanguage: '',
                disputeType: '',
                projectQuote: '',
                checked: false,
                approveInd: '', // 声明书签名标志 1 已签名  非1 待签名
                approvePage: 2, // 裁决书签名标志 1 已签名  非1 待签名
                check1: '',
                check2: '',
                remarks: '',
                isignatureDate: '',
                courtUserId: '',
                sign: ''
            }
        }
    },
    methods: {
        load(case_id) {
            this.case_id = case_id
            this.GetArbitramentSignature()
        },

        async showDialog() {
            this.dialogVisible = true
        },

        async GetT() {
            const res = await this.$API.sendRequest('GetT', {})
            return res.data
        },
        async CheckArbitramentSignature() {
            const token = await this.GetT() //获取一次性token
            let query = `?caseId=${this.case_id}&token=${token}` //
            window.open(axiosConfig.apis.CheckArbitramentSignature.url + query)
        },

        async GetArbitramentSignature() {
            var storage = window.localStorage
            const param = {
                req: {
                    caseId: storage.getItem('caseId'),
                    signatureType: 'arbitrament'
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest(
                'ArbitramentSignature',
                param
            )
            this.loading = false
            if (res.success) {
                this.has_signature = true
                this.arbitrament_signature_form = res.data
                const resgfvt = await this.$API.sendRequest('GetFileViewT', {})
                // this.src = 'http://192.168.1.18/signaturePic/' + this.declaration_signature_form.sign
                // 本地测试用
                // this.src = 'http://localhost:8080/fileview/signView?req.signName='+this.arbitrament_signature_form.sign+'&t='+ resgfvt.data
                // 生产使用
                //this.src = 'http://www.cietacodr.org/api/fileview/signView?req.signName='+this.declaration_signature_form.sign+'&t='+ resgfvt.data
                this.src =
                    'http://192.168.163.16/api/fileview/signView?req.signName=' +
                    this.arbitrament_signature_form.sign +
                    '&t=' +
                    resgfvt.data
            } else {
                this.$message.error(res.msg)
            }
        },

        async acceptSignature() {
            if (this.has_signature) {
                if (!this.arbitrament_signature_form.checked) {
                    this.$message.error('请先勾选同意裁决书')
                } else {
                    let date = new Date()
                    let pageDate =
                        date.getFullYear() +
                        '-' +
                        (date.getMonth() + 1) +
                        '-' +
                        date.getDate()
                    const param = {
                        req: {
                            courtUserId:
                                this.arbitrament_signature_form.courtUserId,
                            approvePage: 1,
                            pageDate: pageDate
                        }
                    }
                    this.loading = true
                    const res = await this.$API.sendRequest(
                        'ConfirmArbitramentSignature',
                        param
                    )
                    this.loading = false
                    if (res.success) {
                        await this.showDialog()
                        this.GetArbitramentSignature()
                    } else {
                        this.$message.error(res.msg)
                    }
                }
            } else {
                this.$message.error('暂无待签字裁决书')
            }
        }
    }
}
</script>

<style scoped>
</style>



