<template>
    <!-- ocr全文检索 -->

    <div>
        <el-card class="box-card" shadow="hover">
            <!-- 顶部搜索框开始  -->
            <div class="topSearch">
                <el-row>
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="4">{{
                                typeMaterialValue ? $t('msg.FilenameRetrieval') : $t('msg.ContentDetection')
                            }}</el-col>
                            <el-col :span="20">
                                <el-input
                                    type="textarea"
                                    v-model="inputText"
                                    :placeholder="
                                        typeMaterialValue
                                            ? $t('msg.PleaseenteraFileName')
                                            : $t('msg.Pleaseenterhecontentharacters')
                                    "
                                    @keyup.enter="serachClick"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-switch
                            v-model="typeMaterialValue"
                            :active-text="$t('msg.FilenameRetrieval')"
                            :inactive-text="$t('msg.ContentDetection')"
                        >
                        </el-switch>
                    </el-col>
                    <el-col :span="6"></el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-row>
                            <el-col :span="8">{{$t('msg.case')}}</el-col>
                            <el-col :span="16">
                                <el-input
                                    :placeholder="$t('msg.Bycasenumber')"
                                    v-model="caseNo"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="18">
                        <el-row>
                            <el-col :span="8">
                                {{$t('msg.Selectchapter')}} &nbsp;&nbsp;
                                <el-select
                                    v-model="selectBranch"
                                    :placeholder="$t('msg.PleaseSelect')"
                                >
                                    <el-option
                                        v-for="(
                                            item, index
                                        ) in selectBranchData"
                                        :key="index"
                                        :label="item.organizationName"
                                        :value="item.organizationId"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="8"
                                >{{$t('msg.Handovertarttime')}} &nbsp;&nbsp;<el-date-picker
                                    v-model="startTime"
                                    type="date"
                                    :placeholder="$t('msg.OptionDate')"
                                    :disabled-date="disabledDateFn"
                                >
                                </el-date-picker
                            ></el-col>

                            <el-col :span="8"
                                >{{$t('msg.HandoverendTime')}}  &nbsp;&nbsp;<el-date-picker
                                    v-model="endTime"
                                    type="date"
                                    :placeholder="$t('msg.OptionDate')"
                                    :disabled-date="disabledDateFn"
                                >
                                </el-date-picker
                            ></el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <!-- 顶部搜索框按键 -->
                <el-row>
                    <el-col :span="6"></el-col>
                    <el-col :span="6">
                        <el-button
                            type="primary"
                            :loading="butLoading"
                            @click="serachClick"
                            style="background-color: #79bbff"
                        >
                            <el-icon class="el-icon--right">
                                <Search />
                            </el-icon>
                            {{$t('msg.Find')}} </el-button
                        >
                        <el-button
                            style="background-color: #fff"
                            @click="searchClear"
                            >{{$t('msg.case_reset')}}</el-button
                        >
                    </el-col>
                    <el-col :span="6"></el-col>
                </el-row>
            </div>
            <!-- 顶部搜索框结束  -->

            <!-- 分割线 -->
            <el-divider></el-divider>

            <!-- 渲染表格开始 -->
            <div v-loading="loading" style="postion: relative; top: 0">
                <el-card
                    v-for="(item, i) in searchList"
                    :key="i"
                    style="margin: 15px 0"
                >
                    <div class="box" style="height: 30px">
                        <el-row type="flex">
                            <!-- 申请书图标 -->
                            <el-col :span="4">
                                <div class="itemStyle" v-if="true">
                                    <el-dropdown trigger="hover">
                                        <span class="el-dropdown-link">
                                            <div>
                                                <svg
                                                    class="icon"
                                                    style="
                                                        width: 30px;
                                                        height: 30px;
                                                        vertical-align: middle;
                                                        fill: currentColor;
                                                        overflow: hidden;
                                                    "
                                                    viewBox="0 0 1024 1024"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    p-id="11463"
                                                >
                                                    <path
                                                        d="M205.5 64H665l232 232v639c0 13.807-11.193 25-25 25H205.5c-13.807 0-25-11.193-25-25V89c0-13.807 11.193-25 25-25z m449.145 25H205.5v846H872V306.355L654.645 89z"
                                                        fill="#B7B7BD"
                                                        p-id="11464"
                                                    ></path>
                                                    <path
                                                        d="M665 64l-11 23.5v197c0 13.807 11.193 25 25 25h194.5L897 296 665 64z m14 49.355L850.145 284.5H679V113.355z"
                                                        fill="#B7B7BD"
                                                        p-id="11465"
                                                    ></path>
                                                    <path
                                                        d="M255 571m10 0l556 0q10 0 10 10l0 0q0 10-10 10l-556 0q-10 0-10-10l0 0q0-10 10-10Z"
                                                        fill="#B7B7BD"
                                                        p-id="11466"
                                                    ></path>
                                                    <path
                                                        d="M255 707m10 0l556 0q10 0 10 10l0 0q0 10-10 10l-556 0q-10 0-10-10l0 0q0-10 10-10Z"
                                                        fill="#B7B7BD"
                                                        p-id="11467"
                                                    ></path>
                                                    <path
                                                        d="M255 639m10 0l556 0q10 0 10 10l0 0q0 10-10 10l-556 0q-10 0-10-10l0 0q0-10 10-10Z"
                                                        fill="#B7B7BD"
                                                        p-id="11468"
                                                    ></path>
                                                    <path
                                                        d="M255 774m10 0l556 0q10 0 10 10l0 0q0 10-10 10l-556 0q-10 0-10-10l0 0q0-10 10-10Z"
                                                        fill="#B7B7BD"
                                                        p-id="11469"
                                                    ></path>
                                                    <path
                                                        d="M255 842m10 0l556 0q10 0 10 10l0 0q0 10-10 10l-556 0q-10 0-10-10l0 0q0-10 10-10Z"
                                                        fill="#B7B7BD"
                                                        p-id="11470"
                                                    ></path>
                                                    <path
                                                        d="M67 193m16 0l287 0q16 0 16 16l0 287q0 16-16 16l-287 0q-16 0-16-16l0-287q0-16 16-16Z"
                                                        fill="#FF4867"
                                                        p-id="11471"
                                                    ></path>
                                                    <path
                                                        d="M314.229 459.289c-21.407 0-40.606-36.765-50.708-60.673-16.991-7.098-35.722-13.728-53.918-18.014-15.92 10.514-43.014 26.251-63.818 26.251-12.911 0-22.21-6.496-25.622-17.813-2.609-9.309-0.4-15.738 2.409-19.22 5.485-7.5 16.79-11.317 33.715-11.317 13.714 0 31.107 2.41 50.507 7.098 12.51-8.907 25.22-19.22 36.525-30.135-5.017-23.84-10.503-62.48 3.412-80.294 6.89-8.505 17.393-11.318 30.103-7.5 13.914 4.017 19.199 12.522 20.804 19.22 5.887 23.237-20.804 54.578-38.8 72.994 4.015 15.938 9.3 32.747 15.721 48.15 25.822 11.518 56.527 28.728 60.006 47.48 1.405 6.495-0.602 12.522-5.887 17.813-4.549 3.75-9.365 5.96-14.45 5.96z m-31.647-52.419c12.785 26.402 24.975 38.862 31.4 38.862 0.995 0 2.386-0.404 4.373-2.02 2.385-2.425 2.385-4.041 1.988-5.523-1.325-6.937-12.124-18.32-37.761-31.319z m-126.377-35.247c-16.73 0-21.33 4.093-22.73 6.003-0.399 0.614-1.599 2.455-0.399 7.23 1 4.092 3.8 8.458 12.464 8.458 10.865 0 26.595-6.207 44.857-17.325-13.063-2.933-24.594-4.366-34.192-4.366z m67.632-1.765c10.845 2.983 22.09 6.827 32.535 10.803-3.792-9.809-6.853-20.015-9.448-29.824-7.651 6.561-15.436 12.99-23.087 19.021zM265.9 259.556c-3.827 0-6.513 1.409-8.93 4.024-7.118 8.917-7.924 31.38-2.418 60.144 20.884-22.26 32.232-42.711 29.411-53.64-0.402-1.61-1.611-6.504-11.348-9.32-2.686-0.805-4.7-1.208-6.715-1.208z"
                                                        fill="#FFFFFF"
                                                        p-id="11472"
                                                    ></path>
                                                </svg>
                                            </div>
                                            &nbsp;
                                            <div>
                                                {{ item.fileNameDsp }}.pdf
                                            </div>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(item)
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="PdfUpload(item)"
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </el-col>
                            <!-- 材料类型  -->
                            <el-col :span="5" class="flexCenter">
                                {{ item.evidenceTypeName }}</el-col
                            >
                            <!-- 具体页数  -->
                            <el-col
                                :span="5"
                                style="text-align: left"
                                class="flexCenter"
                                >在第<span>{{ item.pageCount }}</span
                                >页， <span>共{{ item.pages }}页</span></el-col
                            >
                            <!-- 时间  -->
                            <el-col :span="5" class="flexCenter"
                                >{{$t('msg.HandoverTime')}}：<span
                                    >{{
                                        item.transferDate.split('T')[0] + '' ===
                                        '1100-01-01'
                                            ? '无'
                                            : item.transferDate.split('T')[0]
                                    }}
                                </span></el-col
                            >
                            <!-- 操作  -->
                            <el-col :span="5" style="text-align: left">
                                <el-button
                                    @click="isShow(item)"
                                    round
                                    :title="$t('msg.Viewcurrentpage')"
                                >
                                    <svg
                                        class="icon"
                                        aria-hidden="true"
                                        style="font-size: 20px"
                                    >
                                        <use xlink:href="#icon-chakan"></use>
                                    </svg>
                                </el-button>
                                <el-button
                                    @click="PreviewPdfClick(item)"
                                    round
                                    :title="$t('msg.ViewcurrentFile')"
                                >
                                    <svg
                                        class="icon"
                                        aria-hidden="true"
                                        style="font-size: 20px"
                                    >
                                        <use xlink:href="#icon-yulan"></use>
                                    </svg>
                                </el-button>
                                <el-button
                                    @click="PdfUpload(item)"
                                    round
                                    :title="$t('msg.DownloadcurrentFile')"
                                >
                                    <svg
                                        class="icon"
                                        aria-hidden="true"
                                        style="font-size: 20px"
                                    >
                                        <use
                                            xlink:href="#icon-a-download"
                                        ></use>
                                    </svg>
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </div>

            <!-- 渲染表格结束 -->

            <div class="paginationStyle" v-show="total !== 0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>

        <!-- 弹出框----》查找的单页的pdf -->
        <el-dialog
            :title="boxTitle"
            v-model="dialogVisible"
            width="70%"
            :before-close="handleClose"
            ref="text"
        >
            <!-- <div class="boxText" style="width: 100%;white-space:pre-line" v-html="content"> </div>
             -->
            <div class="maskLoading" v-loading="maskLoading">
                <iframe
                    :src="imageUrl"
                    width="100%"
                    height="800px"
                    class="my-iframe"
                    ref="iframeRef"
                >
                </iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            // 输入的内容
            inputText: '',
            inputContent: '',
            // 开关选择    false:内容检测     true:文件名检索
            typeMaterialValue: false,
            // 案件编号
            caseNo: '',
            // 搜索到的数组
            searchList: [],
            // 加载效果
            loading: false,
            // 搜索点击详细查看当前页弹出框
            dialogVisible: false,
            // 搜索的内容
            content: '',
            // 弹出框的顶部title
            boxTitle: '',
            // 详情页中的pdf路径
            imageUrl: '',
            // 点击打开后，防止pdf没有加载完成，进行加载效果
            maskLoading: '',
            // 时间相关
            // 时间相关
            pickerOptions: {
                shortcuts: [
                    {
                        text: '上星期',
                        value: () => {
                            let end = new Date()
                            let start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            )
                            return [start, end]
                        }
                    },
                    {
                        text: '上个月',
                        value: () => {
                            let end = new Date()
                            let start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            )
                            return [start, end]
                        }
                    },
                    {
                        text: '上三个月',
                        value: () => {
                            let end = new Date()
                            let start = new Date()
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            )
                            return [start, end]
                        }
                    }
                ]
            },
            // 被选中的时间
            // serachTime: ['', ''],
            startTime: '',
            endTime: '',
            // 点击查找的loading
            butLoading: false,
            // 当前页
            currentPage: 1,
            // 每页的条数
            pageSize: 100,
            // 获取的总条数
            total: 0,
            // 选择分会
            selectBranch: 0,
            // 下拉框的数据数据
            selectBranchData: []
        }
    },
    methods: {
        load() {
            this.SearchOrganization()
        },

        // 页面打开调用函数
        async GetCaseList() {
            this.loading = true
            let param = {
                req: {
                    flagContent: this.typeMaterialValue ? 1 : 0,
                    keyword: this.inputContent,
                    caseNo: this.caseNo,
                    typeId: this.materialValue,
                    organizationId: this.selectBranch,
                    transferDateStart:
                        this.$GF.formatTime(this.startTime, 'yyyy-MM-DD') ===
                        'NaN-NaN-NaN'
                            ? '1000-01-01'
                            : this.$GF.formatTime(this.startTime, 'yyyy-MM-DD'),
                    transferDateEnd:
                        this.$GF.formatTime(this.endTime, 'yyyy-MM-DD') ===
                        'NaN-NaN-NaN'
                            ? ''
                            : this.$GF.formatTime(this.endTime, 'yyyy-MM-DD'),
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }
            }
            let data = await this.$API.sendRequest('searchFileInfo', param)
            if (data.success) {
                this.searchList = data.data.esFieldUploads
                this.total = data.data.total
            } else {
                this.searchList = []
                this.$message.error(data.msg)
                this.total = 0
            }
            this.loading = false
        },
        // 点击搜索
        async serachClick() {
            if (!this.inputText) {
                if (this.typeMaterialValue) {
                    return this.$message.info('请先输入文件名')
                } else {
                    return this.$message.info('请先输入内容')
                }
            }
            if (!this.typeMaterialValue) {
                if (this.inputText.trim().length < 2)
                    return this.$message.info('至少输入两个字符')
            }

            this.butLoading = true
            this.inputContent = this.inputText
            await this.GetCaseList()
            this.butLoading = false
        },
        // 点击清空
        searchClear() {
            this.inputText = ''
            this.materialValue = 1
            this.caseNo = ''
            this.searchList = []
            this.total = 0
            this.startTime = ''
            this.endTime = ''
            this.butLoading = false
            this.selectBranch = 0
        },
        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let params = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: '1'
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                params
            )
            if (res.success === false) {
                this.loading = false
                this.$message.error('预览失败')
                return
            }
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            // link.click()
            window.open(url)
            this.loading = false
        },
        // 点击下载pdf
        async PdfUpload(row) {
            this.loading = true
            let paramData = {
                req: {
                    flagPdf: '1',
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                paramData
            )

            if (res.success === false) {
                this.loading = false
                this.$message.error('下载失败')
                return
            }
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileNameDsp
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.loading = false
        },
        // 点击查看当前页
        async isShow(item) {
            this.dialogVisible = true
            this.maskLoading = true
            // this.content = item.content.replace(/[^\S\n]+/g, "");
            this.boxTitle = item.fileNameDsp
            let param = {
                req: {
                    filePath: item.filePath,
                    pageCount: item.pageCount,
                    content: this.inputContent
                }
            }
            let data = await this.$API.sendRequest('extractPage', param)
            let blob = new Blob([data], { type: 'application/pdf' })
            this.imageUrl = URL.createObjectURL(blob) + '#toolbar=0'

            this.maskLoading = false
        },
        handleClose() {
            this.dialogVisible = false
        },

        // 时间组件  默认打开是当前月份和上一月
        changeInitCalendarRange() {
            let leftBut = document.querySelectorAll('.arrow-left')
            leftBut[6].click()
            this.$nextTick(() => {
                leftBut[7].click()
            })
        },
        // 分页器相关
        handleSizeChange(val) {
            // 每页条数改了
            this.pageSize = val
            this.currentPage = 1
            this.GetCaseList()
        },
        // 分页器相关
        handleCurrentChange(val) {
            // 每页页数改了
            this.currentPage = val
            // 获取最大页数

            this.GetCaseList()
        },
        // 时间组件设置当天以后不能选择
        disabledDateFn(time) {
            if (time.getTime() > Date.now()) {
                return true
            } else {
                return false
            }
        },
        // 页面已进入下拉框数据获取
        async SearchOrganization() {
            let { data } = await this.$API.sendRequest('SearchOrganization')
            this.selectBranchData = data
            this.selectBranchData.unshift({
                organizationId: 0,
                organizationName: '全部',
                organizationAbbr: ''
            })
        }
    }
}
</script>

<style scoped lang="scss">
.topSearch {
    font-size: 14px;
}

.el-dropdown-link {
    display: flex;

    div {
        display: flex;
        align-items: center;

        i {
            margin: 0 15px;
            font-size: 20px;
            color: red;
        }
    }
}

.el-row:nth-child(2) {
    margin: 15px 0;
}

.box {
    font-size: 14px;
}

.boxText {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding: 0 14%;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.el-overlay-dialog) {
    position: fixed;
    top: -130px;
    left: 150px;
}

.el-col-8 {
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.el-loading-spinner) {
    position: absolute;
    top: 300px;
}

p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemStyle {
    display: flex;
}
</style>
