<template>
    <!-- 案件审批--------------不要 -->
    <div>
        <el-card>
            <div class="container">
                <!-- 案件基本信息  -->
                <el-row :gutter="20">
                    <el-col :span="24" class="col">
                        <h2>案件审批</h2>
                        <el-card shadow="always">
                            案件号：暂无案号(打回补充材料)<br /><br />
                            申请号：WS2023004388<br /><br />
                            案件性质：申诉中<br /><br />
                            备注：<br />
                        </el-card>
                    </el-col>
                </el-row>

                <!-- 案件日志  -->
                <h3 class="col">案件日志</h3>
                <div class="print">
                    <!-- <el-button type="primary" size="medium">打印</el-button>   -->
                    <el-button type="primary">打印</el-button>
                </div>
                <br />
                <div class="item">
                    &nbsp;&nbsp;内容&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;时间<br />
                </div>
                <el-divider></el-divider>

                <el-row :gutter="20">
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 李克强同志逝世 </el-card>
                    </el-col>
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 2023年10月27日 </el-card>
                    </el-col>
                    <el-col :span="18" class="col">
                        <el-card shadow="always">
                            新华社北京10月27日电
                            中国共产党第十七届、十八届、十九届中央政治局常委，国务院原总理李克强同志，近日在上海休息，2023年10月26日因突发心脏病，经全力抢救无效，于10月27日0时10分在上海逝世，享年68岁。（讣告后发）
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />
                <el-row :gutter="20">
                    <el-col :span="3" class="col">
                        <el-card shadow="always">
                            李克强做政府工作报告
                        </el-card>
                    </el-col>
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 2023年3月5日 </el-card>
                    </el-col>
                    <el-col :span="18" class="col">
                        <el-card shadow="always">
                            在看到发展成就的同时，我们也清醒认识到，我国是一个发展中大国，仍处于社会主义初级阶段，发展不平衡不充分问题仍然突出。
                        </el-card>
                    </el-col>
                </el-row>

                <br /><br />
                <el-row :gutter="20">
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 已提交至贸仲 </el-card>
                    </el-col>
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 2023年10月19日 </el-card>
                    </el-col>
                    <el-col :span="18" class="col">
                        <el-card shadow="always">
                            已经提交至贸仲委立案系统，等待审核
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />
                <el-row :gutter="20">
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 新建草稿案件 </el-card>
                    </el-col>
                    <el-col :span="3" class="col">
                        <el-card shadow="always"> 2023年10月19日 </el-card>
                    </el-col>
                    <el-col :span="18" class="col">
                        <el-card shadow="always">
                            已经新建草稿案件，请去详情页完善信息
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />

                <!-- 已发送复函  -->
                <h3 class="col">已发送复函</h3>
                <br />
                <el-row :gutter="20">
                    <el-col :span="24" class="col">
                        <el-card shadow="always">
                            10月25日下午，国家主席习近平在人民大会堂会见美国加利福尼亚州州长纽森。
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />

                <!-- 标注  -->
                <h3 class="col">标注</h3>
                <div class="print">
                    <el-button type="primary">打印</el-button>
                </div>
                <br />
                <el-row :gutter="0">
                    <el-col :span="8" class="col2">
                        <el-card shadow="never"> 内容 </el-card>
                    </el-col>
                    <el-col :span="8" class="col2">
                        <el-card shadow="never"> 标注人 </el-card>
                    </el-col>
                    <el-col :span="8" class="col2">
                        <el-card shadow="never"> 时间 </el-card>
                    </el-col>
                </el-row>
                <br />
                <el-row :gutter="20">
                    <el-col :span="8" class="col">
                        <el-card shadow="always"> 李克强同志逝世 </el-card>
                    </el-col>
                    <el-col :span="8" class="col">
                        <el-card shadow="always"> 2023年10月27日 </el-card>
                    </el-col>
                    <el-col :span="8" class="col">
                        <el-card shadow="always">
                            新华社北京10月27日电
                            中国共产党第十七届、十八届、十九届中央政治局常委，国务院原总理李克强同志，近日在上海休息，2023年10月26日因突发心脏病，经全力抢救无效，于10月27日0时10分在上海逝世，享年68岁。（讣告后发）
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />
                <div class="print">
                    <el-button type="primary">标注</el-button>
                </div>
                <br /><br />

                <!-- 留言板  -->
                <h3 class="col">留言板</h3>
                <div class="print">
                    <el-button type="primary">下载</el-button>
                    <el-button type="primary">打印</el-button>
                </div>
                <br />
                <el-row :gutter="0">
                    <el-col :span="12" class="col">
                        <el-card shadow="never">
                            中共中央 全国人大常委会 国务院 全国政协讣告
                            李克强同志逝世
                        </el-card>
                    </el-col>
                    <el-col :span="12" class="col">
                        <el-card shadow="never">
                            中国共产党的优秀党员，久经考验的忠诚的共产主义战士，杰出的无产阶级革命家、政治家，党和国家的卓越领导人，中国共产党第十七届、十八届、十九届中央政治局常委，国务院原总理李克强同志，因突发心脏病，经全力抢救无效，于2023年10月27日0时10分在上海逝世，享年68岁。
                        </el-card>
                    </el-col>
                </el-row>
                <br /><br />
                <el-row :gutter="0">
                    <el-col :span="24" class="col">
                        <el-card shadow="never"> 李克强同志永垂不朽！ </el-card>
                    </el-col>
                </el-row>
                <br /><br />
                <div class="print">
                    <el-button type="primary">留言</el-button>
                </div>
                <br /><br />
                <el-divider background-color="#007bff"></el-divider>

                <!-- 第一行  申请人  被申请人  -->
                <el-row :gutter="20">
                    <el-col :span="12" class="col">
                        <h4>申请人</h4>
                        <el-card
                            shadow="always"
                            v-for="(item, index) in approveDetailAjPartyList1"
                            :key="index"
                        >
                            <div class="moveLeft">
                                {{ item.partyClassName }}
                            </div>
                            <div class="moveRight">
                                <el-checkbox v-model="checked"
                                    >名称：{{ item.partyName
                                    }}{{ item.partyCompany }}</el-checkbox
                                ><br />
                                <el-checkbox v-model="checked"
                                    >国家：{{
                                        item.nationalityName
                                    }}</el-checkbox
                                ><br />
                                <el-checkbox v-model="checked"
                                    >身份证：{{ item.idNum }}</el-checkbox
                                ><br />
                                <el-checkbox v-model="checked"
                                    >详细地址：<span>{{
                                        item.companyAddress
                                    }}</span> </el-checkbox
                                ><br />
                                <el-checkbox v-model="checked"
                                    >联系电话：{{ item.phone }}</el-checkbox
                                ><br />
                                <el-checkbox v-model="checked"
                                    >联系邮箱：{{ item.eMail }}</el-checkbox
                                ><br />
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12" class="col">
                        <h4>被申请人</h4>
                        <el-card
                            shadow="always"
                            v-for="(item, index) in approveDetailAjPartyList2"
                            :key="index"
                        >
                            <el-checkbox v-model="checked"
                                >法人分类：{{ item.partyClass }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >营业执照注册号：{{
                                    item.partyZipcode
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >详细地址：
                                <p>{{ item.companyAddress }}</p> </el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系电话：{{ item.phone }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系邮箱：{{ item.eMail }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >材料：{{}}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                </el-row>

                <!-- 第二行  申请人代理人  被申请人代理人  -->
                <el-row :gutter="20">
                    <el-col :span="12" class="col">
                        <h4>申请人代理人</h4>
                        <el-card
                            shadow="always"
                            v-for="(item, index) in approveDetailAjDeputyList1"
                            :key="index"
                        >
                            <el-checkbox v-model="checked"
                                >国家：{{ item.nationality }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >身份证：{{ item.idNum }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >详细地址：{{
                                    item.companyAddress
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系电话：{{ item.phone }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系邮箱：{{ item.eMail }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >材料名称：{{}}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >授权：{{}}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                    <el-col :span="12" class="col">
                        <h4>被申请人代理人</h4>
                        <el-card
                            shadow="always"
                            v-for="(item, index) in approveDetailAjDeputyList2"
                            :key="index"
                        >
                            <el-checkbox v-model="checked"
                                >名称：{{ item.deputyName }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >国家：{{ item.nationality }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >身份证：{{ item.idNum }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >详细地址：{{
                                    item.companyAddress
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系电话：{{ item.phone }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >联系邮箱：{{ item.eMail }}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                </el-row>

                <!-- 第三行  仲裁依据  仲裁请求  -->
                <el-row :gutter="20">
                    <el-col :span="12" class="col">
                        <h4>仲裁依据</h4>
                        <el-card shadow="always">
                            <el-checkbox v-model="checked"
                                >仲裁条款：{{
                                    approveCaseInfo.note1
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >签订日期：{{
                                    approveCaseInfo.contractDate
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >合同号：{{
                                    approveCaseInfo.contractNo
                                }}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                    <el-col :span="12" class="col">
                        <h4>仲裁请求</h4>
                        <el-card shadow="always">
                            <el-checkbox v-model="checked"
                                >仲裁请求：</el-checkbox
                            >
                            <p>报错-------》待修改</p>
                            <el-checkbox v-model="checked"
                                >事实与理由：</el-checkbox
                            >
                            <p>{{ approveReceiveLetterAppend.factReason }}</p>
                            <el-checkbox v-model="checked"
                                >争议金额：{{
                                    approveReceiveLetterAppend.tempProjectQuote
                                }}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                </el-row>

                <!-- 第四行  申请人材料  证据保全  -->
                <el-row :gutter="20">
                    <el-col :span="12" class="col">
                        <h4>申请人材料</h4>
                        <el-card shadow="always">
                            <el-checkbox v-model="checked"
                                >材料名称：{{
                                    approveDigitalUpload.profile
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >证明内容：{{
                                    approveDigitalUpload.remarks
                                }}</el-checkbox
                            ><br />
                            <el-checkbox v-model="checked"
                                >证据清单：{{}}</el-checkbox
                            ><br />
                        </el-card>
                    </el-col>
                    <el-col :span="12" class="col">
                        <h4>证据保全</h4>
                        <el-card shadow="always"> </el-card>
                    </el-col>
                </el-row>
                <br /><br />

                <!-- 第四行  申请人材料  证据保全  -->
                <h4 class="col">理由</h4>
                <el-row>
                    <el-col :span="24" class="col">
                        <el-card shadow="always">
                            <div class="d-flex">
                                <div style="width: 100%">
                                    <TinymceEditor />
                                </div>
                                <!-- <div class="right" v-html="content"></div> -->
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <br /><br /><br />

            <div class="print">
                <el-button type="primary">通过</el-button>
                <el-button type="primary">不通过</el-button>
            </div>
        </el-card>
    </div>
</template>



<script>
import TinymceEditor from '../../components/TinymceEditor'

export default {
    name: 'CaseApprovalDetail',
    components: { TinymceEditor },
    data() {
        return {
            loading: false,
            case_id: '',
            approveDetailAjPartyList1: [],
            approveDetailAjPartyList2: [],
            approveDetailAjDeputyList1: [],
            approveDetailAjDeputyList2: [],
            approveCaseInfo: {},
            approveReceiveLetterAppend: {},
            approveDigitalUpload: {},
            approvapprovePropertyPreserveeCaseInfo: {},
            checked: false,
            content: '222222222',
            value: '1'
        }
    },
    methods: {
        load(case_id) {
            this.case_id = case_id
            // this.ApproveDetail(case_id)
        },

        async ApproveDetail(caseId) {
            this.loading = true
            const param = {
                req: {
                    caseId: caseId
                }
            }
            const res = await this.$API.sendRequest('CaseApproveDetail', param)
            if (res.success) {
                this.approveDetailAjPartyList1 =
                    res.data.approveDetailAjPartyList1
                this.approveDetailAjPartyList2 =
                    res.data.approveDetailAjPartyList2
                this.approveDetailAjDeputyList1 =
                    res.data.approveDetailAjDeputyList1
                this.approveDetailAjDeputyList2 =
                    res.data.approveDetailAjDeputyList2
                this.approveDetailAjPartyList1 =
                    res.data.approveDetailAjPartyList1
                this.approveCaseInfo = res.data.approveCaseInfo
                this.approveReceiveLetterAppend =
                    res.data.approveReceiveLetterAppend
                this.approveDigitalUpload = res.data.approveDigitalUpload || []
                this.approvePropertyPreserve =
                    res.data.approvePropertyPreserve || []
            }
        }
    }
}
</script>


<style scoped lang='scss'>
.ds1_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #0000f4; */
    overflow: hidden;
}

.container1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 20px;
}
.col {
    text-align: left;
}

.moveLeft {
    width: 80px;
    height: 25px;
    line-height: 25px;
    background-color: deepskyblue;
    text-align: center;
    font-size: 14px;
    color: white;
}
.moveRight {
    margin-top: 20px;
    margin-left: 50px;
}

.print {
    margin-right: 10px;
    text-align: right;
}

.item {
    margin-left: 10px;
    text-align: left;
}

.d-flex {
    display: flex;
    gap: 10px;
    .right {
        flex: 1;
        box-shadow: 0 1px 10px 3px #dbdbdb;
        margin-right: 10px;
        padding: 10px;
        box-sizing: border-box;
    }
}
</style>



