<template>
    <div class="main_wrapper">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <!-- <el-tab-pane v-for="(item,index) in topMenus" :label="item.menu_name" :name="item.id" :key="index"> -->
            <!-- 我的工作台 -->
            <!-- <WorkBench v-if="index === 0"/> -->
            <!-- second top menu -->
            <!-- <CaseList v-if="index === 1" :ref="setItemRef" :menu_tree="sideMenus" @back="handleClick({paneName: '13', index: 1})"/>
        <CaseList v-if="index === 2" :ref="setItemRef" :menu_tree="sideMenus" @back="handleClick({paneName: '13', index: 1})"/> -->
            <!-- </el-tab-pane> -->

            <el-tab-pane
                v-for="(item, index) in topMenus"
                :label="item.menu_name"
                :name="item.id"
                :key="index"
            >
                <!-- 我的工作台 -->
                <WorkBench v-if="index === 0" />
                <!-- second top menu -->

                <!-- 经办人 -->

                <!-- 收发管理外用 -->
                <ReceiveConfigList
                    v-if="item.id === '16'"
                    ref="rcf"
                    :menu_tree="sideMenus"
                    @back="handleClick({ paneName: topMenus[1].id, index: 1 })"
                />

                <!-- 系统管理外用  -->
                <ManageConfigList
                    v-if="item.id === '17'"
                    ref="manage"
                    :menu_tree="sideMenus"
                    @back="handleClick({ paneName: topMenus[1].id, index: 1 })"
                />

                <!-- 立案 -->

                <!-- 仲裁员 -->
                <ArbConfigList
                    v-if="['14'].includes(item.id)"
                    ref="caseList"
                    :activeId="activeId"
                    :menu_tree="sideMenus"
                    @back="handleClick({ paneName: topMenus[1].id, index: 1 })"
                />
                <!-- 仲裁员 -->

                <!-- 当事人---经办人管理外用 -->
                <CaseList
                    v-if="['13', '15'].includes(item.id)"
                    ref="caseList"
                    :activeId="activeId"
                    :menu_tree="sideMenus"
                    @back="handleClick({ paneName: topMenus[1].id, index: 1 })"
                />

                <!-- 当事人---经办人管理外用 -->
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import CaseList from '@/components/CaseList' //经办人管理外用
import WorkBench from '@/components/WorkBench'
import ReceiveConfigList from '@/components/ReceiveConfigList.vue' // 引入收发模块菜单配置
import ManageConfigList from '@/components/ManageConfigList.vue' //系统管理外用模块
import ArbConfigList from './ArbConfigList.vue'
export default {
    name: 'MainContent',
    components: {
        CaseList,
        WorkBench,
        ReceiveConfigList, // 为当前页面引入组件
        ManageConfigList,
        ArbConfigList
    },
    created() {
        this.GetTopMenu()
        this.lang = localStorage.getItem('lang') || 'zh'
        this.$listener.$on('lang', (val) => {
            this.lang = val
            this.topMenus = this.$GF.langChange(this.topMenus)
            this.sideMenus = this.$GF.langChange(this.sideMenus)
        })
        this.$listener.$on('expire', () => {
            this.$router.replace({ name: 'Login' })
        })
        // TabList 点击案件触发的 case_change
        this.$listener.$on('case_change', () => {
            this.SideMenu(parseInt(this.activeName), 2)
        }),
            this.$listener.$on('topNavupload', () => {
                this.activeName = '13'
                this.SideMenu(parseInt(this.activeName), 1)
            })
    },
    data() {
        return {
            activeName: 'workbench', // 默认工作台菜单
            topMenus: [],
            sideMenus: [],
            refList: [],
            newParamId: '1',
            activeId: ''
        }
    },
    methods: {
        async GetTopMenu() {
            const res = await this.$API.sendRequest('TopMenu')
            if (res) this.topMenus = this.$GF.langChange(res.data, this)
            // TODO 第一期可以这样处理
            if (this.activeName !== 'workbench') {
                await this.SideMenu(parseInt(this.activeName), 1) // 顶级顶部获取侧边栏菜单
            }
            // 需求是如果是当事人就直接跳入这个页面
            if (this.$store.state.loginInfo.role === 1) {
                this.activeName === '13'
                this.handleClick({ paneName: '13', index: 1 })
            }
        },

        /**
         * 获取侧边栏菜单
         * @param module_id
         * @param param_id  // 1:点击顶部菜单获取侧边菜单; 2: 点击案件获取侧边菜单
         * @returns {Promise<void>}
         * @constructor
         */

        async SideMenu(module_id, param_id) {
            const param = {
                req: {
                    currentModuleId: module_id,
                    currentParam: param_id
                }
            }
            // 不重复发送请求
            if (!param.req.currentModuleId) return

            const res = await this.$API.sendRequest('SideMenu', param)
            if (res) this.sideMenus = this.$GF.langChange(res.data)

            // 菜单定位逻辑
            // console.log('this.newParamId', this.newParamId)
            if (this.newParamId === '1') {
                // 当事人管理：默认定位 案件列表 子菜单
                this.$listener.$emit('set_active', 'myCaseList')
            }
            if (this.newParamId === '2') {
                // 案件获取菜单附加逻辑(1.默认选中 文件管理 2. 右侧展示 文件管理 页面)
                // console.log('jinlaile');
                this.$listener.$emit('set_active', 'receive') // 收文登记默认选中
            }
            if (this.newParamId === '3') {
                this.$listener.$emit('set_active', 'CaseAudition')
            }
        },

        setItemRef(el) {
            this.refList.push(el)
        },
        async handleClick(tab) {
            console.log('Top tab.paneName', tab.paneName, tab.index, 'aaaa')

            this.activeName = tab.paneName
            this.activeId = tab.paneName
            // 1. 我的工作台
            if (this.activeName === 'workbench') {
                // console.log('TODO  我的工作台')
                return
            }
            if (this.activeName === '13') {
                this.newParamId = '1'
                this.SideMenu(parseInt(this.activeName), 1)
                // 需求是如果是当事人就直接跳入这个页面
                this.$nextTick(() => {
                    this.$refs.caseList[0].load()
                })

                return
            }
            if (this.activeName === '14') {
                this.newParamId = '1'
                this.SideMenu(parseInt(this.activeName), 1)
                this.$refs.caseList[0].load()
                return
            }

            if (this.activeName === '15') {
                this.newParamId = '1'
                this.SideMenu(parseInt(this.activeName), 1)
                this.$refs.caseList[0].load()
                return
            }
            if (this.activeName === '16') {
                this.newParamId = '2'
                this.SideMenu(parseInt(this.activeName), 1)
                this.$refs.rcf[0].load()
                return
            }
            if (this.activeName === '17') {
                this.newParamId = '3'
                this.SideMenu(parseInt(this.activeName), 1)
                this.$refs.manage[0].load()
                return
            }

            // console.log(this.sideMenus);
            // 2. 第二个顶部菜单 > 案件列表 // 当事人管理13、仲裁员14、经办人15
            this.SideMenu(parseInt(this.activeName), 1)
            this.refList[tab.index].load() // 获取case_tabs

            // 3. TODO 后续会增加其他顶部菜单
        }
    }
}
</script>

<style scoped lang="sass">
.main_wrapper
    padding: 0 53px
    position: relative
::v-deep(.el-tabs__item)
    height: 30px
    line-height: 30px
    color: #fff
    border-left: none
    border-radius: 3px 3px 0 0
::v-deep(.el-tabs__item.is-active)
    color: #4578FF
    background-color: #fff
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__nav)
    border: none
::v-deep(.el-tabs__header)
    margin: 0
::v-deep(.el-tab-pane)
    padding-top: 15px
::v-deep(.el-tabs__item.is-top)
    border: none
</style>
