<template>
    <!-- 仲裁依据  -->
    <div v-loading="agentDialog">
        <el-form
            :model="info"
            class="partyForm"
            ref="partyFormRef"
            :rules="infoRules"
        >
            <!-- 添加申请人信息 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>{{$t('msg.AddArbitrationBasis')}}</h4>
                    <el-divider></el-divider>
                    <!-- 合同名称  -->
                    <el-row style="margin-top: 20px">
                        <el-col :span="4"
                            ><p>
                                <span class="requireIconBox"
                                    >{{$t('msg.ContractName')}}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-bitian"
                                            ></use></svg
                                    ></span>
                                </span></p
                        ></el-col>
                        <el-col :span="20">
                            <el-form-item prop="contractName">
                                <el-input
                                    v-model.trim="info.contractName"
                                    :placeholder="$t('msg.PleaseEntertheContractName')"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 仲裁条款 -->
                    <el-row>
                        <el-col :span="4"
                            ><p>
                                <span class="requireIconBox"
                                    >{{$t('msg.ArbitrationClause')}}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-bitian"
                                            ></use></svg
                                    ></span>
                                </span></p
                        ></el-col>
                        <el-col :span="20">
                            <el-form-item prop="note1">
                                <el-input
                                    v-model.trim="info.note1"
                                    :placeholder="$t('msg.PleaseentertheArbitrationClause')"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 转换    年月日   或  年月  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox"
                                    >{{$t('msg.TypeofSigningDate')}}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-bitian"
                                            ></use></svg
                                    ></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-radio
                                v-model="radioContractDate"
                                label="MMMM-yy-DD"
                                >{{$t('msg.YearMonthDay')}}</el-radio
                            >
                            <el-radio
                                v-model="radioContractDate"
                                label="MMMM-yy"
                                >{{$t('msg.YearMonth')}}</el-radio
                            >
                        </el-col>
                    </el-row>

                    <!-- 签订日期  -->
                    <el-row>
                        <el-col :span="4">
                            <p>{{$t('msg.SigningDate')}}</p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="contractDate">
                                <el-date-picker
                                    v-if="radioContractDate === 'MMMM-yy'"
                                    v-model="info.note2"
                                    type="month"
                                    :placeholder="$t('msg.SelecttheYearandMonth')"
                                    :disabled-date="disabledDateFn"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-else
                                    v-model="info.contractDate"
                                    type="date"
                                    :placeholder="$t('msg.SelecttheyearmonthandDay')"
                                    :disabled-date="disabledDateFn"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 合同号  -->
                    <el-row>
                        <el-col :span="4">
                            <p>{{$t('msg.ContractNo')}}</p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-input
                                    v-model.trim="info.contractNo"
                                    :placeholder="$t('msg.PleaseentertheContractNumber')"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 合同号  -->
                    <el-row>
                        <el-col :span="4">
                            <p>{{$t('msg.SigningPlace')}}</p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-input
                                    v-model.trim="info.contractPlace"
                                    :placeholder="$t('msg.PleaseenterthePlaceWhereyouSignedtheContract')"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 提示文本 -->
            <div class="cardTextBox">
                <el-card>
                    <p>
                        1.
                        {{$t('msg.Thapplicantisreqenamepllvedonebyone')}}
                    </p>
                    <p>
                        2.
                         {{$t('msg.Iftheapplicantinitiatesarbitrantracsnotnecesheconbmission')}}
                    </p>
                    <p>
                        3.
                        {{$t('msg.IftheapplicantinitiatesroviationRules')}}
                    </p>
                    <span>
                        {{$t('msg.Article14Multicontratrationandaddiitration')}}<br />
                        {{$t('msg.Anapplicantmayapparbitrinbitraolloremet')}}<br />
                        &nbsp; &nbsp;
                        1.{{$t('msg.Multiplecontractsaremastertiomeactsinvolvethesublicated')}}<br />
                        &nbsp; &nbsp;
                        2.{{$t('msg.Disputesarisingtransationortransaciplecontracts')}}<br />
                        &nbsp; &nbsp; 3.{{$t('msg.Thearbitratmentsinultiplecontticalorcompatible')}}<br />

                        {{$t('msg.Wheretheircumstancepeciftheapplicationaffectdinonalcontract')}}<br />
                        {{$t('msg.Theproceduralmattersapedthearbitrationtribunalshalldecide')}}
                    </span>
                </el-card>
            </div>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button
                        type="primary"
                        @click="addPartySubmit"
                        v-if="allType"
                        >{{$t('msg.Save')}}</el-button
                    >
                </div>
            </el-row>
        </el-form>
    </div>
</template>
 
<script>
export default {
    name: 'arbitrationDialog',
    components: {},
    data() {
        return {
            agentDialog: false,
            // 表单对象
            info: {
                // 合同号
                contractNo: '',
                // 合同名称
                contractName: '',
                // 仲裁条框
                note1: '',
                // 签订日期---年月日
                contractDate: '',
                // 年月
                note2: '',
                // 签订地点
                contractPlace: ''
            },
            // 校验规则
            infoRules: {
                contractName: [
                    {
                        required: true,
                        message: '请输入合同名称！',
                        trigger: 'blur'
                    }
                ],
                note1: [
                    {
                        required: true,
                        message: '请输入仲裁条款！',
                        trigger: 'blur'
                    }
                ]
            },
            myObj: {},
            // 全部的状态
            allType: false,
            // 我的myReceiveNo
            myReceiveNo: '',
            // 选择合同日期的类型  年月日MMMM-yy-DD  或   年月MMMM-yy
            radioContractDate: 'MMMM-yy-DD'
        }
    },
    methods: {
        load(obj, allType, myReceiveNo) {
            this.agentDialog = true
            this.myObj = obj
            this.allType = allType
            this.myReceiveNo = myReceiveNo

            if (obj.contractName) {
                this.radioContractDate = !obj.contractDate
                    ? 'MMMM-yy'
                    : 'MMMM-yy-DD'
                this.info = {
                    ...obj
                }
            } else {
                this.radioContractDate = 'MMMM-yy-DD'
            }
            this.agentDialog = false
        },
        // 点击保存
        addPartySubmit() {
            if (this.radioContractDate === 'MMMM-yy') {
                this.info.note2 = this.info.note2
                    ? this.$GF.formatTime(this.info.note2, 'yyyy-MM-DD')
                    : ''
                this.info.note2 = this.info.note2.replace('-01', '')
            } else {
                this.info.contractDate = this.info.contractDate
                    ? this.$GF.formatTime(this.info.contractDate, 'yyyy-MM-DD')
                    : ''
            }

            this.info.caseId = this.myObj.caseId
            this.$refs.partyFormRef.validate(async (valid) => {
                if (valid) {
                    let param = {
                        req: {
                            ...this.info,
                            note2:
                                this.radioContractDate === 'MMMM-yy'
                                    ? this.info.note2
                                    : '',
                            contractDate:
                                this.radioContractDate === 'MMMM-yy-DD'
                                    ? this.info.contractDate
                                    : '',
                            receiveNo: this.myReceiveNo
                        }
                    }
                    let res = await this.$API.sendRequest('SaveCaseInfo', param)

                    if (res.success) {
                        this.$message.success(res.msg)
                        this.emitClose()
                    } else {
                        this.$message.error(res.msg)
                    }
                } else {
                    this.$message.error('请先填写完整表单！')
                }
            })
        },
        // 时间组件设置当天以后不能选择
        disabledDateFn(time) {
            if (time.getTime() > Date.now()) {
                return true
            } else {
                return false
            }
        },
        clearForm() {
            this.info = {}
            this.$refs.partyFormRef.resetFields()
        },
        // 清空表单和校验
        emitClose() {
            this.$emit('arbitrationDialogClose')
        }
    }
}
</script>
 



<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-items: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
            margin-top: 0;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cardTextBox {
    margin-top: 20px;
    a {
        text-decoration: none;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;
        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}
// :deep(.el-date-editor--date) {
//     width: 100% !important;
// }

.requireIconBox {
    position: relative;
    span {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

.cardTextBox {
    margin-top: 20px;
    span {
        text-align: 2em;
        font-size: 14px;
    }
}
</style>