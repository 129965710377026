<template>
  <div style="text-align: left;">
    <el-form label-width="80px" inline>
      <el-form-item label="案件编号">
        <el-input v-model="searchModel.caseNum" placeholder="" style="width: 250px">
        </el-input>
      </el-form-item>
      <el-form-item label="文件名称">
        <el-input v-model="searchModel.fileName" placeholder="" style="width: 250px">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="doSearch">
          {{ $t('msg.case_search') }}
        </el-button>
        <el-button @click="reset">
          {{ $t('msg.case_reset') }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="list"
        border :stripe="true" style="width: 100%">
      <el-table-column
          label="序号"
          prop="serialNo"
          align="center"
      ></el-table-column>
      <el-table-column
          v-if="type === '2'"
          label="签章日期"
          prop="signatureTimeStr"
          align="center"
      ></el-table-column>
      <el-table-column
          label="案件编号"
          prop="caseNo"
          align="center"
      ></el-table-column>
      <el-table-column
          label="签章文件内容概要"
          prop="evidenceType"
          align="center"
      ></el-table-column>
      <el-table-column
          label="用印类型"
          prop="signType"
          align="center"
      ></el-table-column>
      <el-table-column
          label="审批人"
          prop="approveUserName"
          align="center"
      ></el-table-column>
      <el-table-column
          label="份数"
          prop="beginPage"
          align="center"
      ></el-table-column>
      <el-table-column
          label="部门"
          prop="departName"
          align="center"
      ></el-table-column>
      <el-table-column
          label="经办人签名"
          prop="secName"
          align="center"
      ></el-table-column>
      <el-table-column
          v-if="type === '1'"
          label="待签章文件名"
          prop="saveAttName"
          align="center"
      >
        <template v-slot="scope">
          <el-link v-if="scope.row.allowOpening" type="primary" :underline="false" @click="PreviewFile(scope.row)">
            <span >{{ scope.row.saveAttName }}</span>
          </el-link>
          <span v-else>{{ scope.row.saveAttName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          v-if="type === '2'"
          label="已签章文件名"
          prop="saveAttName"
          align="center"
      >
        <template v-slot="scope">
          <el-link v-if="scope.row.allowOpening" type="primary" :underline="false" @click="PreviewFile(scope.row)">
            <span >{{ scope.row.saveAttName }}</span>
          </el-link>
          <span v-else>{{ scope.row.saveAttName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="签章状态"
          prop="signatureStatusName"
          align="center"
      ></el-table-column>
      <el-table-column
          label="签批件"
          prop="displayApprovalName"
          align="center"
      >
        <template v-slot="scope">
          <el-link type="primary" :underline="false" @click="JustPreview(scope.row)">
            <span >{{ scope.row.displayApprovalName }}</span>
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
          label="备注"
          prop="remark"
          align="center"
      ></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
        style="margin-top:10px;display: flex;justify-content: center;"
        small="small"
        @size-change="paginationSizeChange"
        @current-change="paginationCurrentChange"
        :current-page="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size="pagination.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
import axiosConfig from "@/config/api";

export default {
  name: "Common",
  props: {
    type: String,
  },
  data() {
    return {
      searchModel: {
        caseNum: '',
        fileName: '',
        signState: 'signed',// to_be_signed-待签章，signed-已签章
      },
      list: [],
      pagination: {
        page: 1,
        pages: 0,
        limit: 20,
        sizes: [10, 20, 50, 100],
        total: 0
      },
      loading: false
    }
  },
  methods: {
    load(status) {
      this.searchModel.signState = status
      this.doSearch()
    },
    paginationSizeChange (val) {
      this.pagination.limit = val;
      this.GetCaseList()
    },
    paginationCurrentChange (val) {
      this.pagination.page = val;
      this.GetCaseList()
    },
    doSearch () {
      this.pagination.page = 1
      this.GetCaseList()
    },
    reset() {
      this.searchModel.caseNum = ''
      this.searchModel.fileName = ''
    },
    async GetCaseList() {
      this.loading = true
      const param = {
        "req": {
          "current": this.pagination.page,
          "size": this.pagination.limit,
          "q": {
            "signState": this.searchModel.signState,
            "caseNo": this.searchModel.caseNum,
            "fileName": this.searchModel.fileName
          }
        }
      }
      const res = await this.$API.sendRequest('SignFileList', param)
      if (res) {
        this.list = res.data.records
        this.pagination.total = res.data.total
        this.loading = false
      }
    },
    async PreviewFile(row) {
      let query = `?attId=${row.attId}&id=${row.id}&sign=true&filename=${row.saveAttName}`
      window.open('/#/sign_preview'+query)
    },
    async GetPDF_T() {
      const res = await this.$API.sendRequest('GetPDF_T', {})
      return res.data
    },
    async JustPreview(row) {
      const t = await this.GetPDF_T()
      let query = `?attId=${row.attId}&id=${row.id}&previewType=approval&t=${t}`
      window.open(axiosConfig.apis.PreviewUrl.url+query)
    }
  }
}
</script>

<style scoped>

</style>
