<template>
    <div v-loading="loading">
        <!-- 送达地址 -->
        <div class="titleBox">
            <h3>{{$t('msg.Notificationinstruction')}}</h3>
        </div>
        <div class="addressBox">
            <el-row style="margin-bottom: 20px">
                <el-col :span="6">
                    <el-input
                        v-model="inputText"
                        clearable
                        :placeholder="$t('msg.Posttitlequery')"
                        @keydown.enter="getDisplayReplyLetter"
                    ></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button @click="getDisplayReplyLetter">{{$t('msg.finder_search')}}</el-button>
                    <el-button @click="clickBlack" type="primary"
                        >{{$t('msg.back')}}</el-button
                    >
                </el-col>
                <el-col :span="14"></el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <!-- title  materialTypeName  fileName   -->
                <el-table-column prop="caseNo" :label="$t('msg.case')">
                    <template #default="scope">
                        <div>
                            {{
                                scope.row.caseNo
                                    ? scope.row.caseNo
                                    : $t('msg.NoCaseNumberisAvailable')
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="receiveNo" :label="$t('msg.ApplicationNumber')">
                </el-table-column>

                <el-table-column prop="title" :label="$t('msg.Titleofpost')">
                </el-table-column>

                <el-table-column prop="materialTypeName" :label="$t('msg.TypeofPosting')">
                </el-table-column>
                <el-table-column prop="fileName" :label="$t('msg.FileName')">
                    <template #default="scope">
                        <!-- {{ scope.row.filePath }} -->
                        <el-dropdown trigger="hover">
                            <span class="el-dropdown-link">
                                <p
                                    :class="{
                                        redText: scope.row.flagMessage
                                            ? true
                                            : false
                                    }"
                                >
                                    {{ scope.row.fileName }}
                                    <el-icon :size="size">
                                        <ArrowDown />
                                    </el-icon>
                                </p>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        @click="PreviewPdfClick(scope.row)"
                                        >{{$t('msg.Preview')}}</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        @click="PdfUpload(scope.row)"
                                        >{{$t('msg.Download')}}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="uploadDate" :label="$t('msg.Date')">
                </el-table-column>
            </el-table>
            <div
                class="demo-pagination-block"
                style="margin-top: 10px; display: flex; justify-content: center"
            >
                <!-- 分页 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'todoList',
    components: {},
    data() {
        return {
            loading: false,
            tableData: [],
            current: 1,
            size: 10,
            total: 0,
            // 搜索框的值
            inputText: '',
            currentPage4: 1
        }
    },
    methods: {
        async load() {
            this.loading = true
            this.current = 1
            this.size = 10
            this.inputText = ''
            await this.getDisplayReplyLetter()
            this.loading = false
        },
        // 获取列表
        async getDisplayReplyLetter() {
            let param = {
                req: {
                    current: this.current,
                    size: this.size,
                    q: this.inputText
                }
            }
            let res = await this.$API.sendRequest('DisplayReplyLetter', param)
            this.total = res.data.total
            this.tableData = res.data.records
        },
        // 改变size
        handleSizeChange(val) {
            this.size = val
            this.getDisplayReplyLetter()
        },
        // 改变current
        handleCurrentChange(val) {
            this.current = val
            this.currentPage4 = val
            this.getDisplayReplyLetter()
        },

        // 预览
        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let params = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: '1'
                }
            }
            this.setPreviewDownloadMessage(row, 3)
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                params
            )
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
            this.loading = false
        },

        // 点击下载pdf
        async PdfUpload(row) {
            this.loading = true
            let paramData = {
                req: {
                    flagPdf: '1',
                    fileNameDsp: row.fileName,
                    filePath: row.filePath
                }
            }
            this.setPreviewDownloadMessage(row, 4)
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                paramData
            )

            if (res.success === false) {
                this.loading = false
                this.$message.error('下载失败')
                return
            }
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.loading = false
        },

        // 保存消息接口
        setPreviewDownloadMessage(row, readStatus) {
            let param = {
                req: {
                    caseId: row.caseId,
                    msgTitle: row.title,
                    readStatus,
                    receiveNo: row.receiveNo,
                    fileName: row.fileName,
                    id: row.id
                }
            }
            this.$API.sendRequest('PreviewDownloadMessage', param)
        },

        // 返回我的案件页
        clickBlack() {
            this.$emit('gomyCaseList')
        }
    }
}
</script>
 
<style scoped lang="scss">
.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #ff0000;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
    .butBox {
        margin-right: 20px;
        display: flex;
        align-self: center;
    }
}

.redText {
    color: red;
}
.el-dropdown-link {
    color: #6cb4ff;
}
</style>