<template>
    <div>
        <!-- <h1>历史案件查询</h1> -->
        <el-card>
            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"></el-col>
                        <el-col :span="18">
                            <el-select
                                style="width: 100%"
                                v-model="remFlag"
                                :placeholder="$t('msg.PleaseSelect')"
                                @change="changeRemFlag"
                            >
                                <el-option
                                    :label="$t('msg.Compensationnquiryding')"
                                    :value="2"
                                >
                                </el-option>
                                <el-option
                                    :label="$t('msg.Compensationinquiryudingcluding')"
                                    :value="1"
                                >
                                </el-option> </el-select
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8"> </el-col>
            </el-row>

            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.Queryyear')}}</span></el-col>
                        <el-col :span="18">
                            <!-- 2016以前  -->
                            <el-select
                                v-if="remFlag === 2"
                                v-model="infoForm.year"
                                :placeholder="$t('msg.PleaseSelect')"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in newFrontYear"
                                    :key="item"
                                    :label="item + '年'"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                            <!-- 2016以后  -->
                            <el-select
                                v-else
                                v-model="infoForm.year"
                               :placeholder="$t('msg.PleaseSelect')"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in oldFrontYear"
                                    :key="item"
                                    :label="item + '年'"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row v-if="remFlag === 1">
                        <el-col :span="6"><span>{{$t('msg.Querymonth')}}</span></el-col>
                        <el-col :span="18">
                            <el-select
                                v-model="infoForm.month"
                                 :placeholder="$t('msg.PleaseSelect')"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in 12"
                                    :key="item"
                                    :label="item + '月'"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.case')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.caseNo"
                                :placeholder="$t('msg.Pleaseenterhecasenumber')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8"> </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.Nameofthehandler')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.handleName"
                                :placeholder="$t('msg.Pleaseenternamehandler')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8"> </el-col>
            </el-row>

            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.Nameofparty')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.partyName"
                                :placeholder="$t('msg.Pleaseenterthenameparty')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8"> </el-col>
            </el-row>

            <el-row>
                <div class="blackBtnBox">
                    <el-button type="primary" @click="clickQueryBtn">
                        {{$t('msg.case_search')}}
                    </el-button>
                    <el-button @click="clickResetting"> {{$t('msg.case_reset')}} </el-button>
                    <el-button type="info" @click="clickGomyCaseList"
                        >{{$t('msg.back')}}</el-button
                    >
                </div>
            </el-row>
        </el-card>

        <el-table
            v-if="remFlag === 2"
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :summary-method="getSummaries"
            show-summary
            :row-class-name="tableRowClassName"
        >
            <el-table-column :label="$t('msg.S_N')" type="index" width="70px">
            </el-table-column>
            <el-table-column prop="fncNo" :label="$t('msg.FinancialNumber')"> </el-table-column>
            <el-table-column prop="caseNo" :label="$t('msg.case')"> </el-table-column>
            <el-table-column prop="arbSumAmt" :label="$t('msg.PreTaxIncome')">
            </el-table-column>
            <el-table-column prop="arbSumTax" :label="$t('msg.personalIncomeTax')">
            </el-table-column>
            <el-table-column prop="arbSumGet" :label="$t('msg.ActualAmountReceived')">
            </el-table-column>
            <el-table-column prop="name" :label="$t('msg.CreditingAccountNumber')" v-if="remFlag === 2">
            </el-table-column>
            <el-table-column :label="$t('msg.Issuestatus')">
                <template #default="scope">
                    <div>
                        <span>{{ scope.row.name ?$t('msg.Sent') : $t('msg.Unbilled')  }}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-table
            v-else
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :summary-method="getSummaries"
            show-summary
            :row-class-name="tableRowClassName"
        >
            <el-table-column :label="$t('msg.S_N')" type="index" width="70px">
            </el-table-column>
            <el-table-column prop="fncNo" :label="$t('msg.FinancialNumber')"> </el-table-column>
            <el-table-column prop="caseNo" :label="$t('msg.case')"> </el-table-column>
            <el-table-column prop="arbSumAmt" :label="$t('msg.PreTaxIncome')">
            </el-table-column>
            <el-table-column prop="arbSumTax" :label="$t('msg.personalIncomeTax')">
            </el-table-column>
            <el-table-column prop="arbSumGet" :label="$t('msg.ActualAmountReceived')">
            </el-table-column>

            <el-table-column :label="$t('msg.Issuestatus')">
                <div>
                    <span>{{$t('msg.Sent') }}</span>
                </div>
            </el-table-column>
        </el-table>

        <div class="paginationBox">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'RemunerationQuery',
    components: {},
    data() {
        return {
            tableData: [],
            // 默认展示
            remFlag: 2,
            infoForm: {
                // 查询的年
                year: '',
                // 查询的月
                month: '',
                // 案件编号
                caseNo: '',
                // 当事人名称
                partyName: '',
                // 经办人姓名
                handleName: ''
            },
            // 2004-2015年渲染数组
            oldFrontYear: [
                2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013,
                2014, 2015
            ],
            // 新数组
            newFrontYear: [],
            size: 100,
            current: 1,
            total: 0,
            loading: false
        }
    },
    methods: {
        load() {
            this.clickResetting()
        },

        // 请求列表
        async ArbRemuneration() {
            this.loading = true
            let param = {
                req: {
                    q: {
                        remFlag: this.remFlag,
                        ...this.infoForm
                    },
                    current: this.current,
                    size: this.size
                }
            }
            let { data } = await this.$API.sendRequest('ArbRemuneration', param)
            this.total = data.total
            this.tableData = data.records
            this.loading = false
        },

        clickQueryBtn() {
            this.ArbRemuneration()
        },
        // 点击了返回
        clickGomyCaseList() {
            this.$emit('gomyCaseList')
        },

        // 本页的合计
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = '本业合计'
                    return
                }

                let values = data.map((item) => {
                    return Number(item[column.property])
                })
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    if (
                        index === 3 ||
                        index === 4 ||
                        index === 5 ||
                        index === 6
                    ) {
                        sums[index] = this.formatAmount(sums[index]) + ' 元'
                    } else {
                        sums[index] = ''
                    }
                } else {
                    sums[index] = ' '
                }
            })

            return sums
        },

        // 金额使用，号划分
        formatAmount(amount) {
            // 将金额转换为字符串，并去除可能存在的空格
            amount = String(amount).trim()
            // 使用正则表达式匹配最后一个小数点后的数字（如果有）
            const match = amount.match(/(\.\d+)$/)
            // 分割整数部分，每三位添加逗号
            const integerPart = amount
                .replace(/\.\d+$/, '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            // 如果存在小数部分，则将整数部分和小数部分拼接起来
            if (match) {
                return integerPart + match[1]
            } else {
                return integerPart
            }
        },

        // 点击了重置数据
        clickResetting() {
            this.remFlag = 2
            this.current = 1
            this.size = 100
            this.infoForm = {
                // 查询的年
                year: '',
                // 查询的月
                month: '',
                // 案件编号
                caseNo: '',
                // 当事人名称
                partyName: '',
                // 经办人姓名
                handleName: ''
            }
            this.getNewFrontYear()
            this.ArbRemuneration()
        },

        // 获取2016年到当前年的方法为数组
        getNewFrontYear() {
            // 定义起始年份和结束年份
            const startYear = 2016
            const endYear = new Date().getFullYear()
            // 创建一个空数组来存储所有年份
            let years = []
            // 循环从起始年份到结束年份，将每个年份添加到数组中
            for (let year = startYear; year <= endYear; year++) {
                years.unshift(year)
            }
            // 输出所有年份
            this.newFrontYear = years
        },

        // 如果选择年份的下拉框改变了就重新请求
        changeRemFlag() {
            this.current = 1
            this.size = 100
            this.infoForm = {
                // 查询的年
                year: '',
                // 查询的月
                month: '',
                // 案件编号
                caseNo: '',
                // 当事人名称
                partyName: '',
                // 经办人姓名
                handleName: ''
            }
            this.ArbRemuneration()
        },

        // 每页条数改变
        handleSizeChange(e) {
            this.current = 1
            this.size = e
            this.ArbRemuneration()
        },
        handleCurrentChange(e) {
            this.current = e
            this.ArbRemuneration()
        },

        tableRowClassName({ rowIndex }) {
            if (rowIndex % 2 === 0) {
                return 'warning-row'
            } else {
                return ''
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
.el-row {
    margin: 10px 0;
    span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.blackBtnBox {
    width: 67%;
    display: flex;
    justify-content: center;
}

.paginationBox {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

:deep(.el-table) .warning-row {
    background: rgb(183, 167, 144, 0.4) !important;
}
</style>    