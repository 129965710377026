<template>
    <div>
        <div v-loading="loading">
            <div ref="topBox">
                <el-row>
                    <el-col :span="8">
                        <div :class="[caseNo ? 'topTitle' : 'opacityShow']">
                            <span>{{ caseNo }}号案电子程序</span>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="radio">
                            排序：
                            <el-radio-group v-model="reverse">
                                <el-radio :label="true">倒序</el-radio>
                                <el-radio :label="false">正序</el-radio>
                            </el-radio-group>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-button round @click="clickExport">
                            <svg
                                class="icon"
                                aria-hidden="true"
                                style="font-size: 20px; margin: 10px"
                            >
                                <use xlink:href="#icon-file-word-export"></use>
                            </svg>
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <div ref="exportContent">
                <el-timeline>
                    <el-timeline-item
                        timestamp="当事人材料"
                        placement="top"
                        color="skyblue"
                        v-show="sotedItem.length !== 0"
                    >
                        <el-card>
                            <div class="block">
                                <el-timeline>
                                    <el-timeline-item
                                        :timestamp="item.submitDateStr"
                                        placement="top"
                                        color="#368ffc"
                                        v-for="(item, index) in sotedItem"
                                        :key="index"
                                    >
                                        <el-card>
                                            <!-- <p>【2022年6月22日】，仲裁院收到【被申请人】提交的【“被申请人答辩书”“被申请人证据目录（一）”及其所附证据】。</p>                       模版一    -->
                                            <p>
                                                {{
                                                    item.submitDateStr
                                                }}，仲裁院收到{{
                                                    item.partyTypeName
                                                }}提交的{{
                                                    item.evidenceDesc
                                                        ? item.evidenceDesc
                                                        : item.receiveLetterContent
                                                        ? item.receiveLetterContent
                                                        : '无'
                                                }}。
                                            </p>
                                        </el-card>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </el-card>
                    </el-timeline-item>

                    <el-timeline-item
                        timestamp="经办人发文"
                        placement="top"
                        color="skyblue"
                        class="bottomLine"
                        v-show="sotedSend.length !== 0"
                    >
                        <el-card>
                            <div class="block">
                                <el-timeline>
                                    <el-timeline-item
                                        :timestamp="item.sendDateStr"
                                        placement="top"
                                        color="#368ffc"
                                        v-for="(item, index) in sotedSend"
                                        :key="index"
                                    >
                                        <el-card>
                                            <!-- 发文模版一 -->
                                            <!-- <p>{{ item.sendDateStr }}，仲裁院收到【被申请人】提交的{{ item.letterTitle }}，并于{{ item.sendDateStr }}转寄【申请人】，请其对上述【回避申请】发表意见。</p> -->
                                            <p>
                                                仲裁院于{{
                                                    item.sendDateStr
                                                }}寄送{{ item.letterTitle }}。
                                            </p>
                                        </el-card>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </el-card>
                    </el-timeline-item>

                    <el-timeline-item
                        timestamp="发文"
                        placement="top"
                        style="opacity: 0"
                    >
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 导出的页面元素
            exportContent: '',
            // 请求且需要渲染的对象
            caseList: {},
            loading: false,
            caseInfoVosList: [],
            caseInfoVosDocumentList: [],
            reverse: true,
            caseNo: ''
        }
    },
    methods: {
        load(row) {
            this.caseNo = row.caseNo
            this.CaseInfo(row.caseId)
        },
        // 点击导出导出为word
        clickExport() {
            const container = this.$refs.exportContent
            const topBox = this.$refs.topBox
            const paragraphs = container.getElementsByTagName('p')
            const h1Text = topBox.getElementsByTagName('span')
            const paragraphContents = []
            let titleElement = document.createElement('span')
            if (this.caseNo === null) {
                let date = new Date()
                titleElement.innerText = this.$GF.formatTime(date, 'yyyy-MM-DD')
                paragraphContents.push(
                    titleElement.innerHTML + '的电子程序' + '\n'
                )
            } else {
                titleElement.innerText = h1Text[0].innerText

                paragraphContents.push(titleElement.innerHTML + '\n')
            }

            for (let i = 0; i < paragraphs.length; i++) {
                if (i === 0) {
                    paragraphContents.push('当事人材料' + '\n')
                }
                if (i === this.sotedItem.length) {
                    paragraphContents.push('经办人发文' + '\n')
                }
                const paragraph = paragraphs[i]
                paragraphContents.push(paragraph.innerText + '\n')
            }
            let blob = new Blob([paragraphContents.join('\n')], {
                type: 'application: msword'
            })
            if (this.caseNo === null) {
                let date = new Date()
                this.saveAs(
                    blob,
                    this.$GF.formatTime(date, 'yyyy-MM-DD') + '.doc'
                )
            } else {
                this.saveAs(blob, this.caseNo + '.doc')
            }
        },
        // 导出的执行事件
        saveAs(blob, fileName) {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display:none'
            let url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
        },
        // 请求数据请求
        async CaseInfo(caseId) {
            this.loading = true
            let param = {
                req: caseId
            }
            let res = await this.$API.sendRequest('CaseProgram', param)
            this.caseInfoVosList = res.data.caseInfoVos
            this.caseInfoVosDocumentList = res.data.caseInfoVosDocument
            this.loading = false
        }
    },

    computed: {
        // Element排序
        sotedItem() {
            let items = [...this.caseInfoVosList]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.submitDate) - new Date(b.submitDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.submitDate) - new Date(a.submitDate)
                )
            }
            return items
        },
        sotedSend() {
            let items = [...this.caseInfoVosDocumentList]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.sendDate) - new Date(b.sendDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.sendDate) - new Date(a.sendDate)
                )
            }
            return items
        }
    }
}
</script>
 
<style scoped lang="scss">
:deep(.el-timeline-item__timestamp.is-top) {
    display: flex;
    font-size: 20px;
    color: #000;
}

:deep(.block .el-timeline-item__timestamp.is-top) {
    display: flex;
    font-size: 12px;
    color: #2b2525;
}

p {
    text-align: left;
}

:deep(.el-timeline-item__tail) {
    border-left: 2px solid #368ffc;
}

:deep(.bottomLine .el-timeline-item__tail) {
    opacity: 0;
}

:deep(.bottomLine .block .el-timeline-item__tail) {
    opacity: 1;
}

.topTitle {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 39px;
}

.opacityShow {
    opacity: 0;
}
</style>