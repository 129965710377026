<template>
    <!-- 案件状态 -->

    <div class="box">
        <div class="container">
            <div class="containerLeft">
                <div class="item">
                    <span>收文函号</span>
                    <p>{{ codeDate.receiveLetterNo }}</p>
                </div>
                <div class="item">
                    <span>案件编号</span>
                    <p>{{ codeDate.caseNo }}</p>
                </div>
                <div class="item">
                    <span>来函人单位</span>
                    <p>{{ codeDate.senderCompany }}</p>
                </div>
            </div>
            <div class="containerRight">
                <div class="imgBox">
                    <img :src="QRCodeUrl" />
                </div>
            </div>
        </div>
        <div class="bottomBut">
            <el-button type="primary" round @click="goCopyDetail"
                >复制</el-button
            >
            <el-button type="primary" round @click="goModifyDetail"
                >修改</el-button
            >
            <el-button type="primary" round @click="goAddDetail(printObj)"
                >新建</el-button
            >
            <el-button type="primary" round @click="handlePrinter"
                >打印二维码</el-button
            >
        </div>
    </div>
</template>


<script >
import printJS from 'print-js'
// import QRCode from 'qrcode'
export default {
    name: 'ReceiveType',
    props: {
        codeURLData: {
            type: [Object, Array, String]
        }
    },
    data() {
        return {
            codeDate: '',
            QRCodeUrl: '',
            // 这里是打印的配置项
            printObj: {
                goAdd: true
            },
            // 打印的内容
            printable: []
        }
    },
    methods: {
        // 点击批量打印按钮
        handlePrinter() {
            this.printable[0] =
                'data:image/png;base64,' + this.codeDate.base64QRCode

            printJS({
                // 要打印的内容 放在了data里
                printable: this.printable,
                // 打印类型 目前为图片样式 可以根据上面的网址进行修改
                type: 'image',
                // 二维码样式 可以自己进行修改
                imageStyle:
                    'width:80px; height:80px; display: block; margin: 0px 0px 0px -13px;'
                // 也可以设置以下参数 继承所有css样式 没试过image的 html的效果不错
                // targetStyles:['*']
            })
        },
        // 点击复制
        async goCopyDetail() {
            const res = await this.getDetail()
            res.receiveLetterId = null
            // console.log(res.receiveLetterId);
            this.$emit('copyDetail', res)
        },
        // 点击修改
        async goModifyDetail() {
            const res = await this.getDetail()
            // console.log(res,'修改');
            this.$emit('copyDetail', res)
        },
        // 点击新建
        goAddDetail(printObj) {
            this.$listener.$emit('goAddDetail', printObj)
        },
        // 公用请求方法
        async getDetail() {
            let param = {
                req: this.codeDate.receiveLetterNo
            }
            const res = await this.$API.sendRequest(
                'ReceoveCopyAndUpdInfo',
                param
            )
            // console.log(res);
            return res.data
        }
    },

    watch: {
        codeURLData: function () {
            this.codeDate = this.codeURLData.data
            this.QRCodeUrl =
                'data:image/png;base64,' + this.codeDate.base64QRCode
        }
    }
}
</script>

<style scoped lang="scss">
@media print {
    @page {
        size: 28mm 18mm;
        /* 设置纸张尺寸 */
    }
}

.box {
    border: 1px solid #0000f4;
    padding: 2vw 1vw;
}

.container {
    margin-bottom: 2vw;
    display: flex;

    .containerLeft {
        flex: 3;
    }

    .containerLeft .item {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-bottom: 0;
        width: 100%;
        height: 3vw;

        &:nth-child(3) {
            border-bottom: 1px solid #ccc;
        }

        span {
            flex: 1;
            border-right: 1px solid #ccc;
        }

        p {
            flex: 3;
            overflow: hidden;
        }
    }

    .containerRight {
        margin-left: 1vw;
        flex: 1;

        .imgBox {
            width: 8vw;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

:deep(.el-button) {
    background-color: #79bbff;
}
</style>



