<template>
    <div v-loading="loading" class="partyComparisonBox">
        <!-- 当事人 -->
        <el-card v-if="itemObj.type === 1 || itemObj.type === 3">
            <div class="contrastDialogBox">
                <div class="left">
                    <h3>最新版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div class="mainText">
                                <div class="leftMainText">
                                    <!-- 自然人  -->
                                    <el-card
                                        v-if="
                                            newPartyList.partyClassName ===
                                            '自然人'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        newPartyList.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyCompany
                                                    "
                                                >
                                                </span>
                                            </span>
                                            <el-tag
                                                v-if="
                                                    !!newPartyList.partyClassName
                                                "
                                                >{{
                                                    newPartyList.partyClassName
                                                }}</el-tag
                                            >
                                        </p>
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.nationalityModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.nationalityName
                                                "
                                            ></span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="newPartyList.idTypeName"
                                                :class="{
                                                    redText:
                                                        newPartyList.idTypeModify ===
                                                        1
                                                }"
                                            ></span
                                            >:
                                            <span
                                                v-html="newPartyList.idNum"
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="
                                                    newPartyList.companyAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span
                                                v-html="newPartyList.phone"
                                            ></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span
                                                v-html="newPartyList.email"
                                            ></span>
                                        </p>
                                    </el-card>
                                    <!-- 法人  -->
                                    <el-card
                                        v-if="
                                            newPartyList.partyClassName ===
                                            '法人'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        newPartyList.partyClassName
                                                    }}</el-tag
                                                >
                                                <!-- &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyName ||
                                                        newPartyList.deputyName
                                                    "
                                                ></span> -->
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyCompany
                                                    "
                                                >
                                                </span>
                                            </span>
                                            <!-- <el-tag
                                                v-if="
                                                    !!newPartyList.partyClassName
                                                "
                                                >{{
                                                    newPartyList.partyClassName
                                                }}</el-tag
                                            > -->
                                        </p>
                                        <p>
                                            法人分类: &nbsp; &nbsp;
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.corporationTypeModify ===
                                                        1
                                                }"
                                            >
                                                <span
                                                    :class="{
                                                        redText:
                                                            newPartyList.corporationTypeModify ===
                                                            1
                                                    }"
                                                >
                                                    {{
                                                        newPartyList.corporationTypeName
                                                    }}
                                                </span>
                                                <span
                                                    :class="{
                                                        redText:
                                                            newPartyList.corporationType1Name ===
                                                            1
                                                    }"
                                                >
                                                    {{
                                                        newPartyList.corporationType1Name
                                                    }}
                                                </span>
                                                <span
                                                    :class="{
                                                        redText:
                                                            newPartyList.corporationType1Name ===
                                                            1
                                                    }"
                                                >
                                                </span>
                                                {{
                                                    newPartyList.investTypeName
                                                }}
                                            </span>

                                            &nbsp; &nbsp;
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.corporationType1Modify ===
                                                        1
                                                }"
                                                >{{
                                                    newPartyList.corporationType1Name
                                                }}</span
                                            >

                                            &nbsp; &nbsp;
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.investTypeModify ===
                                                        1
                                                }"
                                            >
                                                {{
                                                    newPartyList.investTypeName
                                                }}
                                            </span>
                                        </p>
                                        <!-- 统一社会信用代码  -->
                                        <p>
                                            <span>统一社会信用代码</span>:
                                            <span
                                                v-html="newPartyList.licenseNo"
                                            ></span>
                                        </p>
                                        <!-- 营业执照地址  -->
                                        <p>
                                            <span> 营业执照地址</span>:
                                            <span
                                                v-html="
                                                    newPartyList.licenseAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="
                                                    newPartyList.companyAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span
                                                v-html="newPartyList.phone"
                                            ></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span
                                                v-html="newPartyList.email"
                                            ></span>
                                        </p>
                                    </el-card>

                                    <!-- 个体工商户  -->
                                    <el-card
                                        class="box"
                                        v-if="
                                            newPartyList.partyClassName ===
                                            '个体工商户'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        newPartyList.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyCompany
                                                    "
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <!-- 国家 -->
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.nationalityModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.nationalityName
                                                "
                                            ></span>
                                        </p>
                                        <!-- 营业执照号   -->
                                        <p>
                                            <span>营业执照号</span>:
                                            <span
                                                v-html="newPartyList.licenseNo"
                                            ></span>
                                        </p>
                                        <!-- 经营者   -->
                                        <p>
                                            <span>经营者</span>:
                                            <span
                                                v-html="newPartyList.partyName"
                                            ></span>
                                        </p>
                                        <!-- 营业执照地址   -->
                                        <p>
                                            <span>营业执照地址</span>:
                                            <span
                                                v-html="
                                                    newPartyList.licenseAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="
                                                    newPartyList.companyAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span
                                                v-html="newPartyList.phone"
                                            ></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span
                                                v-html="newPartyList.email"
                                            ></span>
                                        </p>
                                    </el-card>

                                    <!-- 其他组织  -->
                                    <el-card
                                        class="box"
                                        v-if="
                                            newPartyList.partyClassName ===
                                            '其他组织'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        newPartyList.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyCompany
                                                    "
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <!-- 国家 -->
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.nationalityModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.nationalityName
                                                "
                                            ></span>
                                        </p>
                                        <!-- 组织名称   -->
                                        <p>
                                            <span>组织名称</span>:
                                            <span
                                                v-html="
                                                    newPartyList.partyCompany
                                                "
                                            ></span>
                                        </p>
                                        <!-- 营业执照号   -->
                                        <p>
                                            <span>纳税人识别号</span>:
                                            <span
                                                v-html="newPartyList.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            送达地址:
                                            <span
                                                v-html="
                                                    newPartyList.companyAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span
                                                v-html="newPartyList.phone"
                                            ></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span
                                                v-html="newPartyList.email"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
                <div class="iconContrasDialog"></div>
                <div class="right">
                    <h3>历史版本</h3>
                    <el-tabs type="card">
                        <div>
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in oldPartyList"
                                    :key="index"
                                >
                                    <!-- 自然人  -->
                                    <el-card
                                        class="box"
                                        v-if="item.partyClassName === '自然人'"
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        item.partyClassName
                                                    }}</el-tag
                                                >
                                                <!-- &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        item.partyName ||
                                                        newPartyList.deputyName
                                                    "
                                                ></span> -->
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                            <!-- <el-tag>{{
                                                item.partyClassName
                                            }}</el-tag> -->
                                        </p>
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                v-html="item.nationalityName"
                                            ></span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span
                                            >:
                                            <span v-html="item.idNum"></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>
                                    <!-- 法人  -->
                                    <el-card
                                        class="box"
                                        v-if="item.partyClassName === '法人'"
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        item.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                            <el-tag>{{
                                                item.partyClassName
                                            }}</el-tag>
                                        </p>
                                        <p>
                                            法人分类:
                                            <span>
                                                <span
                                                    :class="{
                                                        redText:
                                                            item.nationalityModify ===
                                                            1
                                                    }"
                                                >
                                                    {{ item.nationalityName }}
                                                </span>
                                                &nbsp; &nbsp;
                                                <span
                                                    :class="{
                                                        redText:
                                                            item.corporationType1Modify ===
                                                            1
                                                    }"
                                                >
                                                    {{
                                                        item.corporationType1Name
                                                    }}
                                                </span>
                                                &nbsp; &nbsp;
                                                <span
                                                    :class="{
                                                        redText:
                                                            item.investTypeModify ===
                                                            1
                                                    }"
                                                >
                                                    {{ item.investTypeName }}
                                                </span>
                                            </span>
                                        </p>
                                        <!-- 统一社会信用代码  -->
                                        <p>
                                            <span>统一社会信用代码</span>:
                                            <span
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>
                                        <!-- 营业执照地址  -->
                                        <p>
                                            <span>营业执照地址</span>:
                                            <span
                                                v-html="item.licenseAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>

                                    <!-- 个体工商户  -->
                                    <el-card
                                        class="box"
                                        v-if="
                                            item.partyClassName === '个体工商户'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        item.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <!-- 国家 -->
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                v-html="item.nationalityName"
                                            ></span>
                                        </p>
                                        <!-- 营业执照号   -->
                                        <p>
                                            <span>营业执照号</span>:
                                            <span
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>
                                        <!-- 经营者   -->
                                        <p>
                                            <span>经营者</span>:
                                            <span
                                                v-html="item.partyName"
                                            ></span>
                                        </p>
                                        <!-- 营业执照地址   -->
                                        <p>
                                            <span>营业执照地址</span>:
                                            <span
                                                v-html="item.licenseAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>

                                    <!-- 其他组织  -->
                                    <el-card
                                        class="box"
                                        v-if="
                                            item.partyClassName === '其他组织'
                                        "
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        item.partyClassName
                                                    }}</el-tag
                                                >

                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <!-- 国家 -->
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                v-html="item.nationalityName"
                                            ></span>
                                        </p>

                                        <!-- 组织名称   -->
                                        <p>
                                            <span>组织名称</span>:
                                            <span
                                                v-html="item.partyCompany"
                                            ></span>
                                        </p>
                                        <!-- 纳税人识别号   -->
                                        <p>
                                            <span>纳税人识别号</span>:
                                            <span
                                                v-html="item.licenseNo"
                                            ></span>
                                        </p>

                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
            </div>
        </el-card>

        <!-- 代理人 -->
        <el-card v-if="itemObj.type === 2 || itemObj.type === 4">
            <div class="contrastDialogBox">
                <div class="left">
                    <h3>最新版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div class="mainText">
                                <div class="leftMainText">
                                    <!-- 非法人  -->
                                    <el-card>
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <span
                                                    v-html="
                                                        newPartyList.partyName ||
                                                        newPartyList.deputyName
                                                    "
                                                ></span>
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        newPartyList.partyCompany
                                                    "
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.nationalityModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.nationalityName
                                                "
                                            ></span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="newPartyList.idTypeName"
                                                :class="{
                                                    redText:
                                                        newPartyList.idTypeModify ===
                                                        1
                                                }"
                                            ></span
                                            >:
                                            <span
                                                v-html="newPartyList.idNum"
                                            ></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="
                                                    newPartyList.companyAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span
                                                v-html="newPartyList.phone"
                                            ></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span
                                                v-html="newPartyList.email"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
                <div class="iconContrasDialog"></div>
                <div class="right">
                    <h3>历史版本</h3>
                    <el-tabs type="card">
                        <div>
                            <div class="mainText">
                                <div
                                    class="leftMainText"
                                    v-for="(item, index) in oldPartyList"
                                    :key="index"
                                >
                                    <el-card
                                        class="box"
                                        v-if="item.deputyClassName !== '法人'"
                                    >
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <span
                                                    v-html="item.deputyName"
                                                ></span>
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.deputyCompany"
                                                >
                                                </span>
                                            </span>
                                        </p>
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                v-html="item.nationalityName"
                                            ></span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span
                                            >:
                                            <span v-html="item.idNum"></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>
                                    <el-card class="box" v-else>
                                        <p
                                            style="
                                                display: flex;
                                                justify-content: space-between;
                                            "
                                        >
                                            <span>
                                                <el-tag
                                                    v-if="
                                                        !!newPartyList.partyClassName
                                                    "
                                                    >{{
                                                        item.partyClassName
                                                    }}</el-tag
                                                >
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="
                                                        item.partyName ||
                                                        newPartyList.deputyName
                                                    "
                                                ></span>
                                                &nbsp; &nbsp;
                                                <span
                                                    v-html="item.partyCompany"
                                                >
                                                </span>
                                            </span>
                                            <el-tag>{{
                                                item.partyClassName
                                            }}</el-tag>
                                        </p>
                                        <p>
                                            国家:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.nationalityModify ===
                                                        1
                                                }"
                                                v-html="item.nationalityName"
                                            ></span>
                                        </p>
                                        <!-- 身份证  -->
                                        <p>
                                            <span
                                                v-html="item.idTypeName"
                                                :class="{
                                                    redText:
                                                        item.idTypeModify === 1
                                                }"
                                            ></span
                                            >:
                                            <span v-html="item.idNum"></span>
                                        </p>
                                        <p>
                                            送达地址:
                                            <span
                                                v-html="item.companyAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            送达电话:
                                            <span v-html="item.phone"></span>
                                        </p>
                                        <p>
                                            送达邮箱:
                                            <span v-html="item.email"></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
            </div>
        </el-card>

        <!-- 仲裁依据、  -->
        <el-card v-if="itemObj.type === 5">
            <div class="contrastDialogBox">
                <div class="left">
                    <h3>最新版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div class="mainText">
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            合同名称:
                                            <span
                                                v-html="
                                                    newPartyList.contractName
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            仲裁条款:
                                            <span
                                                v-html="newPartyList.note1"
                                            ></span>
                                        </p>
                                        <p>
                                            签订日期:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.contractDateModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.contractDate
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            合同号:
                                            <span
                                                v-html="newPartyList.contractNo"
                                            ></span>
                                        </p>
                                        <p>
                                            签订地点:
                                            <span
                                                v-html="
                                                    newPartyList.contractPlace
                                                "
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
                <div class="iconContrasDialog"></div>
                <div class="right">
                    <h3>历史版本</h3>
                    <el-tabs type="card">
                        <div
                            class="box"
                            v-for="(item, index) in oldPartyList"
                            :key="index"
                        >
                            <div class="mainText">
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            合同名称:
                                            <span
                                                v-html="item.contractName"
                                            ></span>
                                        </p>
                                        <p>
                                            仲裁条款:
                                            <span v-html="item.note1"></span>
                                        </p>
                                        <p>
                                            签订日期:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.contractDateModify ===
                                                        1
                                                }"
                                                v-html="item.contractDate"
                                            ></span>
                                        </p>
                                        <p>
                                            合同号:
                                            <span
                                                v-html="item.contractNo"
                                            ></span>
                                        </p>
                                        <p>
                                            签订地点:
                                            <span
                                                v-html="item.contractPlace"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
            </div>
        </el-card>

        <!-- 仲裁请求  -->
        <!-- 仲裁请求  -->
        <el-card v-if="itemObj.type === 6">
            <div class="contrastDialogBox">
                <div class="left">
                    <h3>最新版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div class="mainText">
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            <strong> 仲裁请求:</strong>

                                            <el-tag
                                                type="danger"
                                                v-if="
                                                    newPartyList.arbClaimModify ===
                                                    1
                                                "
                                                >已修改</el-tag
                                            >
                                            <span
                                                v-html="newPartyList.arbClaim"
                                            ></span>
                                        </p>
                                        <p>
                                            <strong> 事实与理由:</strong>

                                            <el-tag
                                                type="danger"
                                                v-if="
                                                    newPartyList.factReasonModify ===
                                                    1
                                                "
                                                >已修改</el-tag
                                            >
                                            <span
                                                v-html="newPartyList.factReason"
                                            ></span>
                                        </p>
                                        <p>
                                            <strong> 争议金额:</strong>
                                            <span
                                                v-html="
                                                    newPartyList.coinTypeName
                                                "
                                                :class="{
                                                    redText:
                                                        newPartyList.coinTypeModify ===
                                                        1
                                                }"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
                <div class="iconContrasDialog"></div>
                <div class="right">
                    <h3>历史版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div
                                class="mainText"
                                v-for="(item, index) in oldPartyList"
                                :key="index"
                            >
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            <strong> 仲裁请求:</strong>

                                            <el-tag
                                                type="danger"
                                                v-if="item.arbClaimModify === 1"
                                                >已修改</el-tag
                                            >
                                            <span v-html="item.arbClaim"></span>
                                        </p>
                                        <p>
                                            <strong>事实与理由:</strong>

                                            <el-tag
                                                type="danger"
                                                v-if="
                                                    item.factReasonModify === 1
                                                "
                                                >已修改</el-tag
                                            >
                                            <span
                                                v-html="item.factReason"
                                            ></span>
                                        </p>
                                        <p>
                                            <strong>争议金额:</strong>
                                            <span
                                                v-html="item.coinTypeName"
                                                :class="{
                                                    redText:
                                                        item.coinTypeModify ===
                                                        1
                                                }"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
            </div>
        </el-card>

        <!-- 证据保全  -->
        <el-card v-if="itemObj.type === 8">
            <div class="contrastDialogBox">
                <div class="left">
                    <h3>最新版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div class="mainText">
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            保全类型:
                                            <span
                                                :class="{
                                                    redText:
                                                        newPartyList.preserveTypeModify ===
                                                        1
                                                }"
                                                v-html="
                                                    newPartyList.preserveTypeName
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            法院名称:
                                            <span
                                                v-html="newPartyList.courtName"
                                            ></span>
                                        </p>
                                        <p>
                                            法院地址:
                                            <span
                                                v-html="
                                                    newPartyList.courtAddress
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            保全内容:
                                            <span
                                                v-html="
                                                    newPartyList.preserveContent
                                                "
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
                <div class="iconContrasDialog"></div>
                <div class="right">
                    <h3>历史版本</h3>
                    <el-tabs type="card">
                        <div class="box">
                            <div
                                class="mainText"
                                v-for="(item, index) in oldPartyList"
                                :key="index"
                            >
                                <div class="leftMainText">
                                    <el-card>
                                        <p>
                                            保全类型:
                                            <span
                                                :class="{
                                                    redText:
                                                        item.preserveTypeModify ===
                                                        1
                                                }"
                                                v-html="item.preserveTypeName"
                                            ></span>
                                        </p>
                                        <p>
                                            法院名称:
                                            <span
                                                v-html="item.courtName"
                                            ></span>
                                        </p>
                                        <p>
                                            法院地址:
                                            <span
                                                v-html="item.courtAddress"
                                            ></span>
                                        </p>
                                        <p>
                                            保全内容:
                                            <span
                                                v-html="item.preserveContent"
                                            ></span>
                                        </p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>
            </div>
        </el-card>
    </div>
</template>
 
<script>
export default {
    name: 'partyCompanison',
    components: {},
    data() {
        return {
            // 加载效果
            loading: false,
            // 获取的数据
            itemObj: {},
            // 获取得到的数据
            newPartyList: [],
            oldPartyList: []
        }
    },
    async created() {
        if (this.$route.query.copyType) {
            this.itemObj.type = +this.$route.query.copyType
            await this.GetOneTimeToken(this.$route.query.token)
        }
    },
    methods: {
        async load(type, caseId, itemId, res) {
            this.loading = true
            if (res) {
                this.itemObj = {
                    type,
                    caseId,
                    itemId
                }

                this.newPartyList = res.data[0]
                this.oldPartyList = res.data.splice(1)

                this.loading = false
            }
        },
        async getList(copyType, caseId, itemId) {
            let param = {
                req: {
                    copyType,
                    caseId,
                    itemId
                }
            }
            let res = await this.$API.sendRequest('CaseComparison', param)
            this.newPartyList = res.data[0]
            this.oldPartyList = res.data.splice(1)
        },
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.getList(
                this.$route.query.copyType,
                this.$route.query.caseId,
                this.$route.query.itemId
            )
        }
    }
}
</script>
 
<style scoped lang="scss">
.contrastDialogBox {
    display: flex;
    .left {
        flex: 1;
    }
    .right {
        flex: 1;
    }
    .iconContrasDialog {
        border: 1px solid #234ba7;
        margin: 0 20px;
    }
}

.reasonInputBox {
    width: 100%;
}

.redText {
    color: red !important;
}
.partyComparisonBox {
    text-align: left;
}
</style>