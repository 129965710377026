<template>
    <div>
        <span></span>

    </div>
    
</template>


<script >
export default {
    data() {
        return {

        }
    },
    props:{
        text:String
    }
}
</script>

<style lang="scss" scoped>
div {
    display: flex;
    padding: 1px;
}
span {
    display: inline-block;
    width: 8px ;
    height: 15px ;
    background-color: rgb(235, 13, 13);
}

</style>

