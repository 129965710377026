<template>
    <el-container>
        <el-header>
            <Header />
        </el-header>
        <el-main>
            <div style="margin: 0 auto; width: 80%" v-loading="loading">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <!-- 个人信息 -->
                    <el-tab-pane :label="$t('msg.setting_personal')" name="1">
                        <div style="width: 60%">
                            <el-form
                                :model="register_form"
                                :rules="registerRules"
                                ref="register_form"
                                label-width="80px"
                                class="login_form"
                            >
                                <el-form-item
                                    prop="realName"
                                    :label="$t('msg.register_real_name')"
                                >
                                    <el-input
                                        v-model="register_form.realName"
                                        :placeholder="
                                            $t('msg.register_real_name_tip')
                                        "
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    prop="address"
                                    :label="$t('msg.register_address')"
                                >
                                    <el-input
                                        v-model="register_form.address"
                                        :placeholder="
                                            $t('msg.register_address_tip')
                                        "
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    prop="telNo"
                                    :label="$t('msg.register_tel_no')"
                                >
                                    <el-input
                                        v-model="register_form.telNo"
                                        :placeholder="
                                            $t('msg.register_tel_no_tip')
                                        "
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    :label="$t('msg.Wechatmessage')"
                                    v-if="register_form.bindWeixin"
                                >
                                    <el-card shadow="hover">
                                        <img
                                            :src="register_form.headImgUrl"
                                            style="width: 100px"
                                        />
                                        <div>
                                            <span>{{
                                                register_form.nickName
                                            }}</span>
                                            <div class="bottom clearfix">
                                                <el-button
                                                    type="primary"
                                                    @click="unbind"
                                                    >{{$t('msg.Clickunbind')}}</el-button
                                                >
                                            </div>
                                        </div>
                                    </el-card>
                                </el-form-item>
                                <el-form-item :label="$t('msg.BindWeChat')" v-else>
                                    <el-button type="primary" @click="bind"
                                        >{{$t('msg.ClickBind')}}</el-button
                                    >
                                </el-form-item>
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                    "
                                >
                                    <el-button
                                        color="#4578FF"
                                        @click="$router.back()"
                                        >{{ $t('msg.back') }}</el-button
                                    >
                                    <el-button color="#4578FF" @click="Save">{{
                                        $t('msg.register_confirm')
                                    }}</el-button>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>

                    <!-- 修改密码  -->
                    <el-tab-pane :label="$t('msg.setting_pass')" name="2">
                        <div style="width: 60%">
                            <el-form
                                :model="login_form"
                                :rules="loginRules"
                                ref="login_form"
                                label-width="80px"
                                class="login_form"
                            >
                                <el-form-item
                                    prop="oldPassword"
                                    :label="$t('msg.setting_pass_now')"
                                >
                                    <el-input
                                        v-model="login_form.oldPassword"
                                        type="password"
                                        show-password
                                        :placeholder="$t('msg.login_password')"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    prop="newPassword"
                                    :label="$t('msg.found_new')"
                                >
                                    <el-input
                                        v-model="login_form.newPassword"
                                        type="password"
                                        show-password
                                        :placeholder="$t('msg.login_password')"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    prop="confirm"
                                    :label="$t('msg.register_confirm')"
                                >
                                    <el-input
                                        v-model="login_form.confirm"
                                        type="password"
                                        show-password
                                        :placeholder="
                                            $t('msg.register_confirm_tip')
                                        "
                                    >
                                    </el-input>
                                </el-form-item>
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                    "
                                >
                                    <el-button
                                        color="#4578FF"
                                        @click="$router.back()"
                                        >{{ $t('msg.back') }}</el-button
                                    >
                                    <el-button
                                        color="#4578FF"
                                        @click="ChangePass"
                                        >{{
                                            $t('msg.register_confirm')
                                        }}</el-button
                                    >
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <!-- 仲裁员个人信息修改  -->
                    <el-tab-pane
                        :label="$t('msg.Modificationofarbitratorspersonalinformation')"
                        name="3"
                        v-if="$store.state.loginInfo.role === 2"
                    >
                        <div>
                            <arbitratorInfoModify
                                ref="arbitratorInfoModifyRef"
                            ></arbitratorInfoModify>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!-- 绑定微信弹出框 -->
            <el-dialog width="500px" v-model="visible">
                <div id="wx_login_form"></div>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import Header from '../components/Header'
import '../libs/wx'
import arbitratorInfoModify from './arb/arbitratorInfoModify.vue'

export default {
    name: 'Setting',
    components: {
        Header,
        arbitratorInfoModify
    },
    created() {
        this.GetUserInfo()
    },
    data() {
        const validPass = (rule, value, cb) => {
            this.lang = localStorage.getItem('lang')
            if (value !== this.login_form.newPassword) {
                return cb(
                    this.lang === 'en' ? 'not same' : '两次输入的密码不同'
                )
            }
            return cb()
        }

        return {
            activeName: '1',
            login_form: {
                oldPassword: '',
                newPassword: '',
                confirm: ''
            },
            loginRules: {
                oldPassword: [
                    { required: true, validator: this.$GF.formValidator }
                ],
                newPassword: [
                    { required: true, validator: this.$GF.formValidator },
                    { validator: this.$GF.passValidator }
                ],
                confirm: [
                    { required: true, validator: this.$GF.formValidator },
                    { validator: validPass }
                ]
            },
            register_form: {
                realName: '',
                address: '',
                telNo: '',
                // password: '',
                // confirm: '',
                bindWeixin: 0 // 0 未绑定 1 已绑定
            },
            registerRules: {
                // email: [
                //   {required: true, message: '请填写邮箱地址'},
                //   {validator: validateEmail, trigger: 'change'}
                // ],
                realName: [
                    { required: true, validator: this.$GF.formValidator }
                ],
                address: [
                    { required: true, validator: this.$GF.formValidator }
                ],
                telNo: [{ required: true, validator: this.$GF.formValidator }]
                // password: [
                //   { required: true, message: '请输入密码', trigger: 'blur' }
                // ],
                // confirm: [
                //   { required: true, message: '请再次输入密码', trigger: 'blur' },
                //   { validator: validPass }
                // ]
            },
            loading: false,
            visible: false
        }
    },
    methods: {
        handleClick(tab) {
            // console.log(tab, event)
            // console.log(tab.props.name)
            if (tab.props.name === '3') {
                this.$refs.arbitratorInfoModifyRef.load()
            }
        },
        async Save() {
            this.$refs.register_form.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: {
                            realName: this.register_form.realName,
                            address: this.register_form.address,
                            telNo: this.register_form.telNo
                            // "oldPassword": "1234",
                            // "newPassword": "123"
                        }
                    }
                    this.loading = true
                    const res = await this.$API.sendRequest(
                        'UpdateUserInfo',
                        param
                    )
                    if (res) {
                        this.loading = false
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error('登录失败')
                    }
                }
            })
        },
        ChangePass() {
            this.$refs.login_form.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: {
                            oldPassword: this.login_form.oldPassword,
                            newPassword: this.login_form.newPassword
                        }
                    }
                    this.loading = true
                    const res = await this.$API.sendRequest('ModifyPass', param)
                    if (res && res.success) {
                        this.$message.success(res.msg)
                    }
                    this.loading = false
                }
            })
        },
        async GetUserInfo() {
            this.loading = true
            const res = await this.$API.sendRequest('UserInfo')
            if (res) {
                this.loading = false
                this.register_form = { ...res.data }
            }
        },
        async getWechatInfo() {
            const res = await this.$API.sendRequest('GtWxLoginConfigAuth')
            if (res) {
                return res.data
            } else {
                this.$message.error('获取appid失败')
            }
        },
        async bind() {
            this.visible = true
            const info = await this.getWechatInfo()
            new window.WxLogin({
                id: 'wx_login_form',
                appid: info.appid,
                scope: info.scope,
                redirect_uri: info.redirect_uri,
                state: info.state,
                style: ''
            })
        },
        unbind() {
            this.$confirm('确定要解绑微信吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const res = await this.$API.sendRequest('UnbindWx')
                    if (res) {
                        this.$message.success('解绑成功')
                        await this.GetUserInfo()
                    }
                })
                .catch(() => {})
        }
    }
}
</script>

<style scoped lang="sass">
.el-header
    height: auto
</style>
