/**
 * Created by Leo
 * Date: 2022-05-20 08:52
 * Email: asdfpeng@qq.com
 */
import config from './index'

const apis = {
	GetGuestToken: {
		url: '/guest/getGuestToken',
		method: 'POST'
	},
	GuestHeartBeat: {
		url: '/guest/heartbeat',
		method: 'POST'
	},
	Captcha: {
		url: '/captcha/getCaptcha',
		method: 'POST'
	},
	Login: {
		url: '/login/login',
		method: 'POST'
	},
	// 看有没有code,如果有就是一站式平台跳转
	accessToCIETAC: {
		url: '/onestop/accessToCIETAC',
		method: 'POST'
	},
	// 校验手机号
	// reg/flagRegister
	FlagRegister: {
		url: '/reg/flagRegister',
		method: 'POST'
	},
	SendCode: {
		url: '/reg/sendSmsCode',
		method: 'POST'
	},
	SendEmailCode: {
		url: '/reg/sendEmailCode',
		method: 'POST'
	},
	Register: {
		url: '/reg/register',
		method: 'POST'
	},
	Logout: {
		url: '/auth/logout',
		method: 'POST'
	},
	UserInfo: {
		url: '/mine/info',
		method: 'POST'
	},
	UpdateUserInfo: {
		url: '/mine/update',
		method: 'POST'
	},
	SendPCode: {
		url: '/mine/sendSmsCode',
		method: 'POST'
	},
	SendPEmailCode: {
		url: '/mine/sendEmailCode',
		method: 'POST'
	},
	ResetPass: {
		url: '/mine/coverPass',
		method: 'POST'
	},
	ModifyPass: {
		url: '/mine/updateUserPassWord',
		method: 'POST'
	},
	TopMenu: {
		url: '/menu/listModules',
		method: 'POST'
	},
	SideMenu: {
		url: '/menu/listMenus',
		method: 'POST'
	},
	CaseTabs: {
		url: '/case/caseTabList',
		method: "POST"
	},
	CaseList: {
		url: '/case/caseList',
		method: "POST"
	},
	ArbitramentSignature: {
		url: '/case/signature',
		method: "POST"
	},
	DeclarationSignature: {
		url: '/case/signature',
		method: "POST"
	},
	ConfirmDeclarationSignature: {
		url: '/case/confirmDeclarationSignature',
		method: "POST"
	},
	ConfirmArbitramentSignature: {
		url: '/case/confirmArbitramentSignature',
		method: "POST"
	},
	CheckArbitramentSignature: {
		url: '/case/checkArbitramentSignature',
		method: "POST"
	},
	CaseTipList: {
		url: '/awoke/awokeList',
		method: 'POST'
	},
	ToDoList: {
		url: '/awoke/todoList',
		method: 'POST'
	},
	BindCase: {
		url: '/bindauth/bindCase',
		method: 'POST'
	},
	CaseProgress: {
		url: '/case/caseProgressList',
		method: 'POST'
	},
	FileOperation: {
		url: '/filemanager/connector',
		method: 'POST'
	},
	DownLoad: {
		url: '/filemanager/file',
		method: 'GET'
	},
	GetT: {
		url: '/filemanager/getT',
		method: 'POST'
	},
	GetFileViewT: {
		url: '/fileview/getT',
		method: 'POST'
	},
	SubmitList: {
		url: '/ajlibattachlog/page',
		method: 'POST'
	},
	GetOneTimeToken: {
		url: '/login/loginByOneTimeToken',
		method: 'POST'
	},
	arbiratorSelect: {
		url: '/arbitrator/arbiratorSelect',
		method: 'POST'
	},
	scanSignatureApprove: {
		url: '/arbitrator/scanSignatureApprove',
		method: 'POST'
	},
	// 微信绑定、登录
	GetWxLoginConfigGuest: {
		url: '/weixin/getWxLoginConfigGuest',
		method: 'POST'
	},
	GtWxLoginConfigAuth: {
		url: '/weixin/getWxLoginConfigAuth',
		method: 'POST'
	},
	UnbindWx: {
		url: '/weixin/unbinding',
		method: 'POST'
	},
	SignFileList: {
		url: '/scansignature/signList',
		method: 'POST'
	},
	GetPDF_T: {
		url: '/scansignature/getT',
		method: 'POST'
	},
	OpenPDF: {
		url: '/scansignature/iPdfOpen',
		method: 'POST'
	},
	SavePDF: {
		url: '/scansignature/iPdfSave',
		method: 'POST'
	},
	PreviewUrl: {
		url: '/scansignature/filePreview',
		method: 'POST'
	},

	/* 公共接口类开始 */
	// 获取四个数组   来函方式   地址   区县  材料类型    
	// 从数据字典中查询国别 
	// 4 从数据字典中查询省份
	//  34 从数据字典中查询来文方式
	//   33 从数据字典中查询材料类型
	//    30    
	//    24 金币种类
	//    277 保全类型
	// 275  全国地址
	// 法人分类下拉框  164
	// 163  当事人类型

	// 276 案件审批立案状态
	// 278申请人词条
	// 279 被申请人词条
	// 280 申请方代理人词条
	// 281被申请方代理人词条
	// 282申请人材料词条
	// 283保全材料词条
	// 284合同信息词条
	// 285仲裁请求词条
	// 288  全部-留言-消息

	// 290企业分类  
	// 219是否上市
	// 166 法人国别
	// 162  外商投资
	// 164   法人分类


	// 294 新法人分类

	// 295有限责任公司非外商投资企业
	// 296 股份有限公司外商投资企业
	// 297股份有限公司非外商投资企业
	// 298上市公司外商投资企业
	// 299上市公司非外商投资企业
	// 300其他企业
	//   费用种类 : 27


	styleTypeList: {
		url: '/receive/getDictionaryItems',
		method: 'POST'
	},
	// 公共接口类结束

	/* 收发管理模块专用开始 */
	// 收发登记
	DetailInput: {
		url: '/receive/detailInput',
		method: 'POST'
	},
	// 收发管理--基本信息
	SaveOrUpdate: {
		url: '/receive/saveOrUpdate',
		method: 'POST'
	},
	// 失焦后查重来函单位   receive/blurSenderCompany  
	BlurSenderCompany: {
		url: '/receive/blurSenderCompany',
		method: 'POST'
	},

	// 识别接口
	OcrConver: {
		url: '/ocrfile/ocrConver',
		method: 'POST'
	},
	// 上传页的列表
	OcrStatusList: {
		url: '/ocrUse/ocrStatusList',
		method: 'POST'
	},
	// 查看是否已经上传过---》如果上传过获取数据
	OcrFlagUpload: {
		url: '/ocrUse/ocrFlagUpload',
		method: 'POST'
	},
	// 上传那倒是数据后返给后端的接口
	OcrSaveUpload: {
		url: '/ocrUse/ocrSaveUpload',
		method: 'POST'
	},
	// 把从liunx获得的数据给后端
	OcRUpdDigital: {
		url: '/ocrUse/ocrUpdDigital',
		method: 'POST'
	},
	// 1.点击下载先向windows发送请求拿数据
	OcrDownFile: {
		url: '/ocrUse/ocrDownFile',
		method: 'POST'
	},
	// 点击收文登记状态页的修改或复制
	ReceoveCopyAndUpdInfo: {
		url: '/receive/receiveCopyAndUpdInfo',
		method: 'POST'
	},
	// 补打二维码   ocrUse/getQRCode
	GetQRCode: {
		url: '/ocrUse/getQRCode',
		method: 'POST'
	},
	// 收文查询
	ListReceive: {
		url: '/receive/listReceive',
		method: 'POST'
	},
	// 收文查询页点击详情
	GetReceiveDetail: {
		url: '/receive/getReceiveDetail',
		method: 'POST'
	},
	//收文统计
	ListReceiveStyle: {
		url: '/receive/listReceiveStyle',
		method: 'POST'
	},
	//收文统计明细列表
	listReceive: {
		url: '/receive/listReceive',
		method: 'POST'
	},
	//收文统计弹出明细窗口
	getReceiveDetail: {
		url: '/receive/getReceiveDetail',
		method: 'POST'
	},
	// 电子卷宗列表
	CaseListInternal: {
		url: '/receive/caseListInternal',
		method: 'POST'
	},
	// 电子卷宗---》根据id请求详情
	CaseTimeLenDetail: {
		url: '/receive/caseTimeLenDetail',
		method: 'POST'
	},
	// 电子卷宗---》根据id请求详情
	CaseDetail: {
		url: '/receive/caseDetail',
		method: 'POST'
	},
	// 电子卷宗中的原始图片pdf等ocrDownFilePathAndName
	ocrDownFilePathAndName: {
		url: '/ocrUse/ocrDownFilePathAndName',
		method: 'POST'
	},
	// 案件检索
	searchFileInfo: {
		url: '/ocrUse/searchFileInfo',
		method: 'POST'
	},
	// 案件检索  查看当前页 
	extractPage: {
		url: '/ocrUse/extractPage',
		method: 'POST'
	},
	// 电子卷宗页   电子程序
	CaseProgram: {
		url: '/receive/caseProgram',
		method: 'POST'
	},

	// caseInfo
	// 电子卷宗   案情
	CaseInfo: {
		url: '/receive/caseInfo',
		method: 'POST'
	},
	// 点击识别倒计时模糊计算
	ocrUploadCount: {
		url: '/ocrUse/ocrUploadCount',
		method: 'POST'
	},
	// OCR全文检索页-------选择分会下拉框  /ocrUse/searchOrganization
	SearchOrganization: {
		url: '/ocrUse/searchOrganization',
		method: 'POST'
	},
	// 快递单录入
	InsertEMS: {
		url: '/receive/insertEMS',
		method: 'POST'
	},
	// 快递单查询
	SearchEMS: {
		url: '/receive/searchEMS',
		method: 'POST'
	},
	DeleteEMS: {
		url: '/receive/deleteEMS',
		method: 'POST'
	},
	// 电子卷宗 --> 按类型排 --> 移交事件
	CaseTransfer: {
		url: '/receive/caseTransfer',
		method: 'POST'
	},
	// 案件查询
	CombinationQuery: {
		url: '/receive/combinationQuery',
		method: 'POST'
	},


	/* 收发管理模块专用结束 */



	/* 仲裁员管理模块专用开始 */
	// 仲裁员端(历史案件查询)   register/historicalCases 
	HistoricalCases: {
		url: '/arbitratorWeb/historicalCases',
		method: 'POST'
	},
	// 案件状态 receive/getDictionaryItems
	GetDictionaryItems: {
		url: '/receive/getDictionaryItems',
		method: 'POST'
	},
	// 开庭日期 register/trialCalenda
	TrialCalenda: {
		url: '/arbitratorWeb/trialCalendar',
		method: 'POST'
	},
	// 视频庭审   arbitrator/onHearing 
	OnHearing: {
		url: '/arbitratorWeb/onHearing',
		method: 'POST'
	},
	// 修改仲裁员个人信息 arbitrator/updArbitratorInfo
	UpdArbitratorInfo: {
		url: '/arbitratorWeb/updArbitratorInfo',
		method: 'POST'
	},

	// 查询仲裁员报酬 arbitratorWeb/arbRemuneration  
	ArbRemuneration: {
		url: '/arbitratorWeb/arbRemuneration',
		method: 'POST'
	},
	// 仲裁员披露  arbitratorWeb/arbPublish
	ArbPublish: {
		url: '/arbitratorWeb/arbPublish',
		method: 'POST'
	},
	// 文书模板下载 arbitratorWeb/docTemFile POST
	// DocTemFile: {
	//     url: '/arbitratorWeb/docTemFile',
	//     method:'POST'
	// },
	// 文书模版下载的列表
	docTemFileList: {
		url: '/arbitratorWeb/docTemFileList',
		method: 'POST'
	},
	// 文书模版文件的下载
	downLoadDocTemFile: {
		url: '/arbitratorWeb/downLoadDocTemFile',
		method: 'POST'
	},
	// 上传裁决书上传文件    arbitratorWeb/arbUploadMoreFiles    POST
	ArbUploadMoreFiles: {
		url: '/arbitratorWeb/arbUploadMoreFiles',
		method: 'POST'
	},
	// 预览下载 arbitratorWeb/arbPreFiles  POST  req:attId
	ArbPreFiles: {
		url: '/arbitratorWeb/arbPreFiles',
		method: 'POST'
	},
	//  申请延裁审批信息 /arbitratorWeb/examineApprove 
	ExamineApprove: {
		url: '/arbitratorWeb/examineApprove',
		method: 'POST'
	},
	//  申请开庭改期 /arbitratorWeb/dateHearingChange
	DateHearingChange: {
		url: '/arbitratorWeb/dateHearingChange',
		method: 'POST'
	},


	//
	/* 仲裁员管理模块专用结束 */


	/* 当事人管理模块专用开始 */
	//
	/* 当事人管理模块专用结束 */




	// 添加申请人----------------------------------------- 开始》
	// 省市区三级联动
	GetProvince: {
		url: '/register/getProvince',
		method: 'POST'
	},

	// 添加申请人选择非中国后选择国别
	GetCountries: {
		url: '/register/getCountries',
		method: 'POST'
	},
	// 添加申请人 
	SavePartyInfo: {
		url: "/register/savePartyInfo",
		method: "POST"
	},
	// 添加申请人代理人委托人

	GetShowPartyInfo: {
		url: '/register/getShowPartyInfo',
		method: 'POST'
	},
	//  register/saveDeputyInfo
	// 保存申请代理人
	SaveDeputyInfo: {
		url: '/register/saveDeputyInfo',
		method: 'POST'
	},
	// 点击申请仲裁
	ApplyArbitration: {
		url: '/register/applyArbitration',
		method: 'POST'
	},

	// 仲裁依据
	SaveCaseInfo: {
		url: '/register/saveCaseInfo',
		method: 'POST'
	},

	// 仲裁请求
	SaveAppend: {
		url: '/register/saveAppend',
		method: 'POST'
	},
	// 仲裁申请-----》保全
	SavePreserve: {
		url: '/register/savePreserve',
		method: 'POST'
	},


	// 展示申请人/被申请人register/showApplyPartyInfo
	ShowApplyPartyInfo: {
		url: '/register/showApplyPartyInfo',
		method: 'POST'
	},
	// 展示申请人代理人/被申请人代理人 register/showApplyDeputyInfo 
	ShowApplyDeputyInfo: {
		url: '/register/showApplyDeputyInfo',
		method: 'POST'
	},
	// 展示仲裁依据 register/showApplyCaseInfo
	ShowApplyCaseInfo: {
		url: '/register/showApplyCaseInfo',
		method: 'POST'
	},
	// 展示请求 register/showApplyAppendInfo
	ShowApplyAppendInfo: {
		url: '/register/showApplyAppendInfo',
		method: 'POST'
	},
	// 展示申请人材料register/showDigitalUploadInfo
	ShowDigitalUploadInfo: {
		url: '/register/showDigitalUploadInfo',
		method: 'POST'
	},
	// 回显申请人/ 被申请人  
	ShowPartyInfo: {
		url: '/register/showPartyInfo',
		method: 'POST'
	},
	// 回显申请代理人/ 被申请代理人 
	ShowDeputyInfo: {
		url: '/register/showDeputyInfo',
		method: 'POST'
	},
	//  回显电子送达接口
	ShowElectronicDelivery: {
		url: '/register/showElectronicDelivery',
		method: 'POST'
	},
	//  回显保全
	ShowApplyPreserveInfo: {
		url: '/register/showApplyPreserveInfo',
		method: 'POST'
	},
	//  仲裁依据列表点击删除
	DelCaseInfo: {
		url: '/register/delCaseInfo',
		method: 'POST'
	},
	// 获取分会列表
	ShowDepartment: {
		url: '/register/showDepartment',
		method: 'POST'
	},
	// 点击提交至仲裁委
	SubmitArbitration: {
		url: '/register/submitArbitration',
		method: 'POST'
	},
	// 删除申请人/被申请人接口
	DelPartyInfo: {
		url: '/register/delPartyInfo',
		method: 'POST'
	},
	// 删除代理人接口
	// /register/delDeputyInfo
	DelDeputyInfo: {
		url: '/register/delDeputyInfo',
		method: 'POST'
	},
	// 删除保全接口/register/delPreserveInfo
	DelPreserveInfo: {
		url: '/register/delPreserveInfo',
		method: 'POST'
	},
	// 删除申请人材料接口
	DelUploadInfo: {
		url: '/register/delUploadInfo',
		method: 'POST'
	},
	// 删除此案件接口----->判断是否可以删除
	ReturnDelFlag: {
		url: '/register/returnDelFlag',
		method: 'POST'
	},
	// 点击删除 /register/delArbitration
	DelArbitration: {
		url: '/register/delArbitration',
		method: 'POST'
	},
	// 申请人材料表格反显
	ShowPreserveInfo: {
		url: '/register/showPreserveInfo',
		method: 'POST'
	},
	// 查看日志
	// /register/showArbitrationLog
	ShowArbitrationLog: {
		url: '/message/showArbitrationLog',
		method: 'POST'
	},
	// 展示未读消息数量 /message/showMessageCount
	ShowMessageCount: {
		url: '/message/showMessageCount',
		method: 'POST'
	},
	// 留言板添加
	SaveMessageBoard: {
		url: '/message/saveMessageBoard',
		method: 'POST'
	},
	// 展示留言板添加成功后展示个人留言  /message/showMessageBoard POST req:caseId
	ShowMessageBoard: {
		url: '/message/showMessageBoard',
		method: 'POST'
	},
	// 我的消息 /message/showMeMessageBoard POST req:msgState
	// 我的消息获取列表
	ShowMeMessageBoard: {
		url: '/message/showMeMessageBoard',
		method: 'POST'
	},
	// 我的消息详情 
	ShowMeMessageDetailBoard: {
		url: '/message/showMeMessageDetailBoard',
		method: 'POST'
	},
	// 我的日志 /message/showMeLog
	ShowMeLog: {
		url: '/message/showMeLog',
		method: 'POST'
	},
	// 展示驳回消息  /register/repulseArbitration  POST req:caseId
	RepulseArbitration: {
		url: '/register/repulseArbitration',
		method: 'POST'
	},
	// 展示OR隐藏网上立案相关界面  /register/showOrNoneArbitration  POST 无需参数
	ShowOrNoneArbitration: {
		url: '/register/showOrNoneArbitration',
		method: 'POST'
	},

	// 添加申请人----------------------------------------- 结束》

	// 案件审批页--------------------开始
	// 获取数组
	CaseApproveList: {
		url: '/register/approve/list',
		method: 'POST'
	},

	// 获取详细信息
	CaseApproveDetail: {
		url: '/register/approve/caseDetail',
		method: 'POST'
	},
	// 对比接口尝试
	CaseComparison: {
		url: '/register/approve/caseComparison',
		method: 'POST'
	},
	// 词条汇总  /register/approve/caseShowEntry
	CaseShowEntry: {
		url: '/register/approve/caseShowEntry',
		method: 'POST'
	},

	// 电子送达保存
	UpdElectronicDeliveryParty: {
		url: '/register/updElectronicDeliveryParty',
		method: 'POST'
	},
	// pdf  /register/approve/generatePdf  

	// 撤案理由
	WithdrawCase: {
		url: '/register/withdrawCase',
		method: 'POST'
	},
	// 通知指令     register/displayReplyLetter
	DisplayReplyLetter: {
		url: '/register/displayReplyLetter',
		method: 'POST'
	},
	// 复函文件预览下载保存消息接口  /message/previewDownloadMessage 
	PreviewDownloadMessage: {
		url: '/message/previewDownloadMessage',
		method: 'POST'
	},
	// 根据id获取需要转换的富文本内容
	CaseInfoAppendGenerate: {
		url: '/register/approve/caseInfoAppendGenerate',
		method: 'POST'
	},
	// 处理富文本后，传入传输给后端转换为pdf
	GeneratePdf: {
		url: '/register/approve/generatePdf',
		method: 'POST'
	},

	// 下载word模版
	TemplateDownloadDoc: {
		url: '/register/templateDownloadDoc',
		method: 'POST'
	},

	// 个人案件页的  当事人 点击了向上仪  
	MoveParty: {
		url: '/register/moveParty',
		method: 'POST'
	},

	// 展示送达人复选框信息
	ElectronicDeliveryParty: {
		url: '/register/ElectronicDeliveryParty',
		method: 'POST'
	},

	// 保存送达信息 register/electronicDelivery 
	ElectronicDelivery: {
		url: '/register/electronicDelivery',
		method: 'POST'
	},

	// 回显送达信息 register/showElectronicDeliveryParty 
	ShowElectronicDeliveryParty: {
		url: '/register/showElectronicDeliveryParty',
		method: 'POST'
	},

	// 删除电子送达  register/delElectronicDeliveryParty
	DelElectronicDeliveryParty: {
		url: '/register/delElectronicDeliveryParty',
		method: 'POST'
	},

	// 在办案件需要统一文件传输到证据平台先调接口 /register/approve/unifiedFileTransfer
	UnifiedFileTransfer: {
		url: '/register/approve/unifiedFileTransfer',
		method: 'POST'
	},
	// 判断不允许两个草稿 register/flagApplyArbitration
	FlagApplyArbitration: {
		url: '/register/flagApplyArbitration',
		method: 'POST'
	},

	// 上传材料OCR修改文件描述   receive/updFileRemark 
	UpdFileRemark: {
		url: '/receive/updFileRemark',
		method: 'POST'
	},


	// 当事人管理送达记录/fileview/displayDeliveryRecords
	DisplayDeliveryRecords: {
		url: '/fileview/displayDeliveryRecords',
		method: 'POST'
	},

	// 查看案件查询中案件详情  /receive/caseDetailsInfo  
	CaseDetailsInfo: {
		url: '/receive/caseDetailsInfo',
		method: 'POST'
	},

	// 删除文件  ocrUse/delUploadFile 
	DelUploadFile: {
		url: '/ocrUse/delUploadFile',
		method: 'POST'
	},
	// 补录实名认证 register/realAuthentication  POST 
	RealAuthentication: {
		url: '/register/realAuthentication',
		method: 'POST'
	},

	// 发票申请 =====> 展示发票列表  register/receiptList 
	ReceiptList: {
		url: '/register/receiptList',
		method: 'POST'
	},

	// 发票申请 =====> 根据feeType查询开票金额   register/changeFeeTypeMoney 
	ChangeFeeTypeMoney: {
		url: '/register/changeFeeTypeMoney',
		method: 'POST'
	},
	// 发票申请 =====> 补全公司名称和税号   register/completionCompany 
	CompletionCompany: {
		url: '/register/completionCompany',
		method: 'POST'
	},
	// 发票申请 =====> 新增发票列表  register/insReceiptInfo  
	InsReceiptInfo: {
		url: '/register/insReceiptInfo',
		method: 'POST'
	},
	// 退费信息补录   register/refundSupplementList
	RefundSupplementList: {
		url: '/register/refundSupplementList',
		method: 'POST'
	},
	// 退费信息补录提交  register/updRefundSupplement
	UpdRefundSupplement: {
		url: '/register/updRefundSupplement',
		method: 'POST'
	},
	// 电子收据列表  后端接口:register/electronicReceiptList 
	ElectronicReceiptList: {
		url: '/register/electronicReceiptList',
		method: 'POST'
	},
	// 根据电子收据查询详情信息  register/eleReceiptInfo 
	EleReceiptInfo: {
		url: '/register/eleReceiptInfo',
		method: 'POST'
	},
	// 下载电子收据PDF  register/downloadReceiptPdf   POST
	DownloadReceiptPdf: {
		url: '/register/downloadReceiptPdf',
		method: 'POST'
	},
	// ocr识别中的任务队列ocrUse/getOcrGroup
	getOcrGroup: {
		url: '/ocrUse/getOcrGroup',
		method: 'POST'
	},
	// 处理单渲染
	getPaybackRecordTuess: {
		url: '/weChatIMMessage/getPaybackRecord',
		method: 'POST'
	},
	// 提交或者驳回处理单
	getupdPaybackRecordTuess: {
		url: '/weChatIMMessage/updPaybackRecord',
		method: 'POST'
    },
    
    // 获取电子收据 register/preReceiptPdfOut 
    preReceiptPdfOut: {
        url: '/register/preReceiptPdfOut',
         method:'POST'  
    },


	// qrcode/getCode
	GetCode: {
		url: "/qrcode/getCode",
		method: 'POST'
	}







}

Object.keys(apis).forEach(key => {
	if (apis[key].url === '/ocrfile/ocrUpload' || apis[key].url === '/ocrfile/OcrConver') {
		apis[key].url = `${config.apiOCR}${apis[key].url}`
		apis[key].method = apis[key].method.toUpperCase()
	} else {
		apis[key].url = `${config.apiBaseUrl}${apis[key].url}`
		apis[key].method = apis[key].method.toUpperCase()
	}

})

const axiosConfig = {
	timeout: 30000,
	apis,
}
export default axiosConfig;