<template>
    <!-- 案件查询 -->

    <div>
        <el-card>
            <!-- 搜索框开始 -->
            <!-- 第一行    案件编号    发文号  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                {{$t('msg.case')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleaseenterhecasenumber')"
                                    :clearable="true"
                                    v-model="formInput.caseNo"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                 {{$t('msg.Issuenumber')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleasenterthemessagenumber')"
                                    :clearable="true"
                                    v-model="formInput.letterCode"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="4"></el-col>
                </el-row>
            </div>
            <!-- 第二行    仲裁员    裁决书编号  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                 {{$t('msg.Arbitrator')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleaseenterarbitrator')"
                                    :clearable="true"
                                    v-model="formInput.arbitrator"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                {{$t('msg.Awardnumber')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleaseentertheawardnumber')"
                                    :clearable="true"
                                    v-model="formInput.decisionId"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="4"></el-col>
                </el-row>
            </div>
            <!-- 第三行    立案秘书    办案秘书  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                {{$t('msg.FilingSecretary')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleaseenterfilingecretary')"
                                    :clearable="true"
                                    v-model="formInput.registerUserName"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                {{$t('msg.CaseSecretary')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.Pleaseenterhecaseecretary')"
                                    :clearable="true"
                                    v-model="formInput.handleUserName"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="4"></el-col>
                </el-row>
            </div>
            <!-- 第四行    合同名称  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter">
                                {{$t('msg.ContractName')}}
                            </el-col>
                            <el-col :span="15">
                                <el-input
                                    :placeholder="$t('msg.PleaseEntertheContractName')"
                                    :clearable="true"
                                    v-model="formInput.contractName"
                                ></el-input>
                            </el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="10">
                        <el-row>
                            <el-col :span="4" class="textCenter"
                                >{{$t('msg.Caseyear')}}</el-col
                            >
                            <el-col :span="15">
                                <el-date-picker
                                    style="width: 100%"
                                    v-model="formInput.year"
                                    type="year"
                                    :placeholder="$t('msg.Optionalyear')"
                                >
                                </el-date-picker
                            ></el-col>
                            <el-col :span="5"></el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="4"></el-col>
                </el-row>
            </div>
            <!-- 第五行    当事人名称/单位  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="3" class="textCenter">
                       {{$t('msg.Partynameunit')}}
                    </el-col>
                    <el-col :span="15">
                        <el-input
                            :placeholder="$t('msg.Pleaseenterpartynamunit')"
                            :clearable="true"
                            v-model="formInput.partyCompany"
                        ></el-input>
                    </el-col>
                    <el-col :span="6"></el-col>
                </el-row>
            </div>
            <!-- 第六行    当事人名称/单位  -->
            <div class="inputBox">
                <el-row>
                    <el-col :span="3" class="textCenter">
                        {{$t('msg.Agentnameunit')}}
                    </el-col>
                    <el-col :span="15">
                        <el-input
                            :placeholder="$t('msg.Pleaseentergenameunit')"
                            :clearable="true"
                            v-model="formInput.deputyCompany"
                        ></el-input>
                    </el-col>
                    <el-col :span="6"></el-col>
                </el-row>
            </div>
            <!-- 搜索框结束 -->

            <!-- 按键开始 -->
            <div class="btnBox">
                <el-row>
                    <el-col :span="18">
                        <el-button type="primary" @click="getDate">
                            <el-icon class="el-icon--right">
                                <Search />
                            </el-icon>
                             {{$t('msg.finder_search')}}</el-button
                        >
                        <el-button
                            style="background-color: #fff"
                            @click="clearInput"
                            > {{$t('msg.case_reset')}}</el-button
                        >
                    </el-col>
                    <el-col :span="6"></el-col>
                </el-row>
            </div>
            <!-- 按键结束 -->

            <!-- 分割线 -->
            <el-divider></el-divider>

            <!-- 表格开始 -->
            <div class="tableBox" v-loading="loading">
                <el-table
                    :data="tableData"
                    style="width: 100%; font-size: 13px; color: #252323"
                    border
                    :summary-method="getSummaries"
                    :row-class-name="tableRowClassName"
                    show-summary
                    @cell-dblclick="handleCellDblClick"
                >
                    <!-- @click="caseNoDetailDialog(scope.row)" -->
                    <el-table-column
                        prop="caseNo"
                        :label="$t('msg.case')"
                        min-width="9%"
                        sortable
                        show-overflow-tooltip
                    >
                        <template #default="scope">
                            <div>
                                <span
                                    class="itemSpan"
                                    @click="caseNoDetailDialog(scope.row)"
                                >
                                    {{ scope.row.caseNo || '未立案' }}</span
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="caseName"
                        :label="$t('msg.case_name')"
                        min-width="10%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        :label="$t('msg.CaseSecretary')"
                        min-width="8%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="registerDate"
                        :label="$t('msg.Dateoffiling')"
                        min-width="8%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="transferDate"
                        :label="$t('msg.Handoverdate')"
                        min-width="8%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="groupCourtDate"
                        :label="$t('msg.DateofTribunalFormation')"
                        min-width="8%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="caseStateName"
                        :label="$t('msg.Status')"
                        min-width="7%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="projectQuote"
                        :label="$t('msg.Target')"
                        min-width="11%"
                        sortable
                        show-overflow-tooltip
                    >
                        <template #default="scope">
                            <div v-if="scope.row.projectQuote">
                                {{
                                    formatAmount(scope.row.projectQuote) ===
                                    'null'
                                        ? ''
                                        : formatAmount(scope.row.projectQuote)
                                }}
                            </div>
                            <div v-else>0</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="feeAmount"
                        :label="$t('msg.ArbitrationFees')"
                        min-width="11%"
                        sortable
                        show-overflow-tooltip
                    >
                        <template #default="scope">
                            <div v-if="scope.row.feeAmount">
                                {{
                                    formatAmount(scope.row.feeAmount) === 'null'
                                        ? ''
                                        : formatAmount(scope.row.feeAmount)
                                }}
                            </div>
                            <div v-else>0</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="balanceAmount"
                        :label="$t('msg.Amountofmoneyinvolvedcase')"
                        min-width="11%"
                        sortable
                        show-overflow-tooltip
                    >
                        <template #default="scope">
                            <div>
                                {{
                                    formatAmount(scope.row.balanceAmount) ===
                                    'null'
                                        ? ''
                                        : formatAmount(scope.row.balanceAmount)
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="branchName"
                        :label="$t('msg.Source')"
                        min-width="10%"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
            </div>
            <!-- 表格结束 -->

            <!-- 分页器开始 -->
            <div style="display: flex; justify-content: center">
                <el-pagination
                    :current-page="current"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
            <!-- 分页器结束 -->

            <!-- 基本信息弹窗 -->
            <el-dialog
                :title="$t('msg.BasicInfo')"
                v-model="caseNoDetailDialogType"
                width="80%"
            >
                <div v-loading="dialogLoading">
                    <el-card>
                        <h3>{{$t('msg.ReceiptInformation')}}</h3>
                        <!-- 第一行   收文日期   来函人单位 -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.DateofReceipt')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.receiveLetterDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.CorrespondenceAffiliation')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.letterCompany }}
                                </el-col>
                            </el-col>
                        </el-row>

                        <!-- 第二行   来文方式   来源 -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.ModeofCommunication')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.letterType
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.Source')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.source }}
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card style="margin: 20px 0">
                        <h3>{{$t('msg.CasefilingInformation')}}</h3>
                        <!-- 第一行   合同编号   合同名称  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.ContractNumber')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        strMontage(
                                            dialogDetailObj.caseInfos,
                                            'contractNo'
                                        )
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ContractName')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    <!-- {{ dialogDetailObj.caseInfos }} -->
                                    {{
                                        strMontage(
                                            dialogDetailObj.caseInfos,
                                            'contractName'
                                        )
                                    }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第二行   合同日期   仲裁条款内容  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.ContractDate')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        strMontage(
                                            dialogDetailObj.caseInfos,
                                            'contractDate'
                                        )
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ContentofArbitrationClause')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.arbitratorContent }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第三行   立案提示     -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.FilingReminder')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.registerMsg?.join(
                                            ', '
                                        ) || ''
                                    }}</el-col>
                                </div>
                            </el-col>
                            <!-- <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >仲裁条款内容 ：
                                </el-col>
                                <el-col :span="14"> xxxxxxxxx </el-col>
                            </el-col> -->
                        </el-row>
                        <!-- 第四行   当事人类型   涉外国家  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.PartyType')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.partyType?.join(', ') ||
                                        ''
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ForeignCountries')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.foreignCountries }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第四行   中条款受理地   大条款受理地  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.PlaceofAcceptanceofIntermediateClauses')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.middleTerm
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.PlaceofAcceptanceofMajorClauses')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.majorTerms }}
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card>
                        <h3>{{$t('msg.CaseInformationes')}} </h3>
                        <!-- 第一行   案件编号   案件名称  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.case')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.caseNo
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.case_name')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.caseName }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第二行   案件性质   争议类型(旧)  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.NatureoftheCase')}}  ：
                                    </el-col>
                                    <el-col :span="14">
                                        {{ dialogDetailObj.caseNature }}</el-col
                                    >
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DisputetypeOld')}}：
                                </el-col>
                                <el-col :span="14">
                                    {{
                                        dialogDetailObj.controversyType
                                    }}</el-col
                                >
                            </el-col>
                        </el-row>
                        <!-- 第三行   一级争议类型   二级/次级争议类型  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.TypeoffirstorderDispute')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.disputeType1
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.SecondarysecondarydisputeTypes')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.disputeType2 }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第四行   语言   仲裁员人数  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.Language')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.languageType
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.NumberofArbitrators')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.arbitratorNum }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第五行   适用规则   仲裁地  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.ApplicableRules')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.applicableRules
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.SeatofArbitration')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.placeArbitration }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第六行   收文日期   程序类型  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.DateofReceipt')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.receiveLetterDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ProgramType')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.proceduresType }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第七行   立案秘书   办案秘书  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.FilingSecretary')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.registerCaseName
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.CaseSecretary')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.handleCaseName }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第八行   移送日期   分办日期  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.DateofTransfer')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.moveDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.OfficeDate')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.branchDate }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第九行   仲裁通知日期   组庭日期  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.DateofnoticeofArbitration')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.noticeDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DateofTribunalFormation')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.formationDate }}
                                </el-col>
                            </el-col>
                        </el-row>
                        <!-- 第十行   开庭日期   裁决期限  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.LawfulDay')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.openSessionDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.TimelimitforAdjudication')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.ruleDate
                                }}</el-col>
                            </el-col>
                        </el-row>

                        <!-- 第十二行   结案日期   结案类型  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.ClosingDate')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.coseCaseDate
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.settlealawsuitType')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.closeCaseType }}
                                </el-col>
                            </el-col>
                        </el-row>

                        <!-- 第十四行   申请人   被申请人  -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.Applicant')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.partyNames1?.join(
                                            ', '
                                        ) || ''
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.TheDefendant')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{
                                        dialogDetailObj.partyNames2?.join(
                                            ', '
                                        ) || ''
                                    }}
                                </el-col>
                            </el-col>
                        </el-row>

                        <!-- 第十三行   胜诉方     申请人代理人-->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.AgentofTheApplicant')}} ：
                                </el-col>
                                <el-col :span="14">
                                    {{
                                        dialogDetailObj.deputyNames1?.join(
                                            ', '
                                        ) || ''
                                    }}
                                </el-col>
                            </el-col>
                            <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.AgentoftheRespondent')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{
                                        dialogDetailObj.deputyNames2?.join(
                                            ', '
                                        ) || ''
                                    }}
                                </el-col>
                            </el-col>
                        </el-row>

                        <!-- 第十二行   仲裁员     -->
                        <el-row :gutter="10" class="borderLine">
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.Arbitrator')}} ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.arbitratorName?.join(
                                            ', '
                                        ) || ''
                                    }}</el-col>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div>
                                    <el-col :span="10" class="fontWeight"
                                        >{{$t('msg.WinningParty')}}  ：
                                    </el-col>
                                    <el-col :span="14">{{
                                        dialogDetailObj.recover
                                    }}</el-col>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 表格列表
            tableData: [],
            // 页数
            current: 1,
            // 每页条数
            pageSize: 100,
            // 总条数
            total: 0,
            // 收集的数据
            formInput: {
                // 案件编号
                caseNo: '',
                // 发文号
                letterCode: '',
                // 仲裁员
                arbitrator: '',
                // 裁决书编号
                decisionId: '',
                // 立案秘书名字
                registerUserName: '',
                // 办案秘书名字
                handleUserName: '',
                // 合同名称
                contractName: '',
                // 当事人名称
                partyCompany: '',
                // 代理人名称
                deputyCompany: '',
                // 选择的年份
                year: ''
            },
            // 加载效果
            loading: false,
            // 点击案件编号的弹出窗
            caseNoDetailDialogType: false,
            // 弹窗展示的详情页的对象
            dialogDetailObj: {},
            // 弹出框基本信息的加载效果
            dialogLoading: false
        }
    },
    methods: {
        load() {
            this.current = 1
            this.pageSize = 100
            this.getDate()
        },
        // 请求事件
        async getDate() {
            this.loading = true
            let timeYear
            if (this.formInput.year) {
                timeYear = this.formInput.year.getFullYear()
            } else {
                timeYear = ''
            }

            let param = {
                req: {
                    q: {
                        ...this.formInput,
                        year: timeYear
                    },
                    current: this.current,
                    size: this.pageSize
                }
            }
            let { data } = await this.$API.sendRequest(
                'CombinationQuery',
                param
            )
            this.total = data.total || 0
            this.tableData = data.records
            this.loading = false
        },

        // 分页器功能 --- 每页条数发生变化时触发
        handleSizeChange(val) {
            this.pageSize = val
            this.current = 1
            this.getDate()
        },
        // 分页器功能 --- 当前页数发生变化时触发
        handleCurrentChange(val) {
            // this.pageSize = 1
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.total / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.pageSize > pageMax) {
                this.pageSize = pageMax
            }
            this.getDate()
        },

        // 清空搜索框
        clearInput() {
            this.formInput = {
                // 案件编号
                caseNo: '',
                // 发文号
                letterCode: '',
                // 仲裁员
                arbitrator: '',
                // 裁决书编号
                decisionId: '',
                // 立案秘书名字
                registerUserName: '',
                // 办案秘书名字
                handleUserName: '',
                // 合同名称
                contractName: '',
                // 当事人名称
                partyCompany: '',
                // 代理人名称
                deputyCompany: '',
                // 选择的年份
                year: ''
            }
            this.current = 1
            this.pageSize = 100
            this.getDate()
        },

        // elment的时间---》计算金额
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '本业合计'
                    return
                }

                // const values = data.map(item => Number(item[column.property]));

                let values = data.map((item) => {
                    return Number(item[column.property])
                })
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    if (index === 7 || index === 8 || index === 9) {
                        sums[index] = this.formatAmount(sums[index]) + ' 元'
                    } else {
                        sums[index] = ''
                    }
                } else {
                    sums[index] = ' '
                }
            })

            return sums
        },

        // 金额使用，号划分
        formatAmount(amount) {
            // 将金额转换为字符串，并去除可能存在的空格
            amount = String(amount).trim()
            // 使用正则表达式匹配最后一个小数点后的数字（如果有）
            const match = amount.match(/(\.\d+)$/)
            // 分割整数部分，每三位添加逗号
            const integerPart = amount
                .replace(/\.\d+$/, '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            // 如果存在小数部分，则将整数部分和小数部分拼接起来
            if (match) {
                return integerPart + match[1]
            } else {
                return integerPart
            }
        },

        // 表格颜色变化
        tableRowClassName({ row }) {
            let str = row.caseStateName
            if (
                str === '组庭' ||
                str === '归档' ||
                str === '结案' ||
                str === '入库'
            ) {
                return 'rowType'
            } else {
                return ''
            }
        },

        // 点击了案件编号,显示弹出窗
        async caseNoDetailDialog(row) {
            this.dialogLoading = true
            this.dialogDetailObj = {}
            this.caseNoDetailDialogType = !this.caseNoDetailDialogType
            let param = {
                req: row.caseId
            }
            let res = await this.$API.sendRequest('CaseDetailsInfo', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.dialogDetailObj = res.data
            this.dialogLoading = false
        },

        // 把数组里面的某一项提出来展示
        strMontage(arr, keyName) {
            if (!arr) return ''
            let newStr = []
            if (keyName === 'contractDate') {
                newStr = arr
                    .map((item) => {
                        return item['contractDate1'] || item['contractDate2']
                    })
                    .join(',')
            } else {
                newStr = arr
                    .map((item) => {
                        return item[keyName]
                    })
                    .join(',')
            }

            return newStr
        },

        // 双击复制收文函号
        async handleCellDblClick(row) {
            let value = row.caseNo
            await this.$copyText(value)
                .then(() => {
                    this.$message.success('已复制' + value)
                })
                .catch(() => {
                    this.$message.error('复制失败')
                })
        }
    }
}
</script>
 
<style scoped lang=scss>
.inputBox {
    text-align: left;
    line-height: 100%;
    margin: 20px 0;
    font-size: 14px;
}

.textCenter {
    display: flex;
    align-items: center;
}

:deep(.el-table .rowType) {
    background: #cb9b6c;
}

/* 鼠标滑过当前行不高亮 */
:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
    background-color: transparent !important;
}

.borderLine {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    &:nth-child(2n) {
        background-color: rgba(216, 213, 213, 0.4);
    }
}
.fontWeight {
    font-size: 16px;
    /* font-weight: 600; */
}

.itemSpan {
    color: blue;
    cursor: pointer;
}
h3 {
    color: #507aee;
}
</style>
    