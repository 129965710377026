/**
 * Created by Leo
 * Date: 2022-05-20 08:36
 * Email: asdfpeng@qq.com
 */
const globalFunction = {
    async Sleep (time) {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve({})
            },time)
        })
    },
    removeEmptyField (list, field) {
        list.forEach(v => v[field].length === 0 ? delete v[field] : this.removeEmptyField(v[field], field))
        return list
    },
    formatDataTree (data, id = 'id', fid = 'fid', children = 'children') {
        const tempTree = []
        const tree = []
        const items = []
        data.forEach(v => {
            if (!tempTree[v[id]]) {
                const temp = v
                temp[children] = []
                tempTree[v[id]] = temp
                items.push(temp)
            }
        })
        items.forEach(v => (tempTree[v[fid]] && tempTree[v[fid]][children].push(tempTree[v[id]])) || tree.push(tempTree[v[id]]))
        return this.removeEmptyField(tree, children)
    },
    /**
     * 获取指定的时间戳范围 今天 pos = 0   昨天 pos = -1  前天 pos = -2 明天 pos = 1
     * @return {{start: number, end: number}}
     */
    getTimeStampRange (pos = 0) {
        const dayTimeStamp = 86400000
        const start = new Date(Date.now() + pos * dayTimeStamp).setHours(0, 0, 0, 0)
        const end = new Date(Date.now() + pos * dayTimeStamp).setHours(23, 59, 59, 999)
        return {
            start,
            end
        }
    },
    /**
     * 获取指定的周时间戳范围 本周 pos = 0  上周 pos = -1  上上周 pos = -2  下周 pos = 1 下下周 pos = 2
     * @param pos
     * @return {{start: number, end: number}}
     */
    getWeekTimeStampRange (pos = 0) {
        const dayTimeStamp = 86400000
        const date = new Date()
        const today = date.getDay()
        let stepSunDay = -today + 1
        let stepMonday = 7 - today
        if (today === 0) {
            stepSunDay = -7
            stepMonday = 0
        }
        const start = new Date(Date.now() + stepSunDay * dayTimeStamp + 7 * dayTimeStamp * pos).setHours(0, 0, 0, 0)
        const end = new Date(Date.now() + stepMonday * dayTimeStamp + 7 * dayTimeStamp * pos).setHours(23, 59, 59, 999)
        return {
            start,
            end
        }
    },
    /**
     * 获取指定的月时间戳范围 本月 pos = 0  上月 pos = -1  上上月 pos = -2  下月 pos = 1 下下月 pos = 2
     * @param pos
     * @return {{start: number, end: number}}
     */
    getMonthTimeStampRange (pos = 0) {
        const d = new Date()
        const d1 = new Date(d.setMonth(d.getMonth() + pos))
        let t1 = 0
        let t2 = 0
        t1 = new Date(d1.setDate(1)).setHours(0, 0, 0, 0)
        const d2 = new Date(t1)
        t2 = new Date(d2.setMonth(d2.getMonth() + 1)).setMilliseconds(-1)
        return {
            start: t1,
            end: t2
        }
    },
    /**
     * 获取指定季度的时间戳范围 本季度 pos = 0 上季度 pos = -1 下季度 pos = 1
     * @param pos
     * @return {{start: number, end: number}}
     */
    getSeasonTimeStampRange (pos = 0) {
        const seasonMonths = [0, 3, 6, 9]
        let seasonIndex = 0
        const now = new Date()
        let seasonYear = now.getFullYear()
        const nowMonth = now.getMonth()
        if (nowMonth < 3) {
            seasonIndex = 0
        }

        if (nowMonth > 2 && nowMonth < 6) {
            seasonIndex = 1
        }

        if (nowMonth > 5 && nowMonth < 9) {
            seasonIndex = 2
        }
        if (nowMonth > 8) {
            seasonIndex = 3
        }
        seasonIndex += pos
        const diffYears = Math.floor(seasonIndex / 4)
        seasonYear += diffYears
        seasonIndex %= 4
        if (seasonIndex < 0) {
            seasonIndex += 4
        }
        // 获取季度开端月份 和 截止月份
        const startMonth = seasonMonths[seasonIndex]
        const endMonth = startMonth + 2
        const endMonthDays = this.getMonthDays(seasonYear, endMonth)
        return {
            start: new Date(seasonYear, startMonth, 1).getTime(),
            end: new Date(seasonYear, endMonth, endMonthDays).getTime()
        }
    },
    getYearTimeStampRange (pos = 0) {
        const curYear = new Date().getFullYear()
        let t1 = 0
        let t2 = 0
        // new Date(year, month, day, hours, minutes, seconds, milliseconds);
        t1 = new Date(curYear+pos,0,1,0,0,0,0).getTime()
        t2 = new Date(curYear+pos,11,31,23,59,59,999).getTime()
        return {
            start: t1,
            end: t2
        }
    },
    formatTime (time = Date.now(), formula = 'yyyy-MM-DD hh:mm:ss') {
        const date = new Date(time)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        month = month.toString().padStart(2, '0')
        let day = date.getDate()
        day = day.toString().padStart(2, '0')
        let hour = date.getHours()
        hour = hour.toString().padStart(2, '0')
        let minutes = date.getMinutes()
        minutes = minutes.toString().padStart(2, '0')
        let second = date.getSeconds()
        second = second.toString().padStart(2, '0')
        return formula.replace('yyyy', year).replace('MM', month).replace('DD', day).replace('hh', hour).replace('mm', minutes).replace('ss', second)
    },
    getMonthDays (year, month) {
        const monthStartDate = new Date(year, month, 1)
        const monthEndDate = new Date(year, month + 1, 1)
        return (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
    },
    /**
     * 检测是否是手机号
     * @param mobile
     * @return {boolean}
     */
    isMobile (mobile) {
        const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
        return reg.test(mobile);
    },
    checkArrayContains(array,item){
        if (!Array.isArray(item)) {
            return array.includes(item);
        }
        for (let i=0;i<item.length;i++) {
            let v = item[i];
            if (!array.includes(v)) {
                return false;
            }
        }
        return true;
    },
    /**
     * 首字母大写
     * @param text
     * @return {string}
     */
    firstUpperCase (text) {
        return text.split('').map((v,i)=>i&&v.toLowerCase()||v.toUpperCase()).join('')
    },
    getPlatform () {
        const u = navigator.userAgent;
        //是否是android浏览器
        let isAndroid = u.indexOf('Android') > -1;
        //是否是ios浏览器
        let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        let msg = '';
        if (isAndroid) {
            msg = "android";
        } else if (isIos) {
            msg = 'ios';
        } else {
            msg = 'pc';
        }
        return msg
    },
    genFileIdentifyName (f) {
        return `${f.name}|${f.size}|${f.type.toString().split("/").join('-')}|${f.lastModified}`
    },
    lazyText (txt,size=15) {
        let res = '';
        if (txt.length<=size) {
            res = txt;
        }else {
            res  = txt.substr(0,size)+'...'
        }
        return  res
    },
    brText (txt)  {
        if (txt.length<=15) {
            return txt;
        }
        let len =txt.length;
        let slice = 15;
        let num = Math.ceil(len/slice);
        let res  = [];
        for (let i=0;i<num;i++) {
            res.push(txt.substr(i*slice,slice));
        }
        return res.join('<br/>')
    },
    _removePad (arr,padStr) {
        let flag = false;
        let res = [];
        for (let i =0;i<arr.length;i++) {
            let v = arr[i];
            if (v!==padStr) {
                flag = true;
            }
            if (flag) {
                res.push(v);
            }
        }
        if (res.length===0) {
            res.push(padStr)
        }
        return res.join('')
    },
    padAppVersion (version) {
        let vs = version.split('.');
        vs = vs.map(v=>v.toString().padStart(4,'0'));
        return vs.join('.');
    },
    removePadAppVersion (version) {
        let vs = version.split('.');
        vs = vs.map(v=>{
            let vvs = v.split('');
            return this._removePad(vvs,'0')
        });
        return vs.join('.')
    },
    /**
     * 英文标点符号转换为中文
     * @param str
     */
    transformPunctuation(str) {
        const en = ['(',')','[',']','{','}','<','>',',','.','?','!']
        const zh = ['（','）','【','】','「','」','《','》','，','。','？','！']
        let newStr = "";
        str.split("").forEach(v => {
            if (en.indexOf(v)>=0) {
                newStr+=zh[en.indexOf(v)]
            } else {
                newStr+=v
            }
        })
        return newStr
    },
    transFromZhToEn(str) {
        const en = ['(',')','[',']','{','}','<','>',',','.','?','!']
        const zh = ['（','）','【','】','「','」','《','》','，','。','？','！']
        let newStr = "";
        str.split("").forEach(v => {
            if (zh.indexOf(v)>0) {
                newStr+=en[zh.indexOf(v)]
            } else {
                newStr+=v
            }
        })
        return newStr
    },
    formatAmount (amount) {
        return parseFloat(amount/100).toFixed(2)
    },
    formatSeconds(a) {
        let hh = parseInt(a / 3600);
        if(hh<10) hh = "0" + hh;
        let mm = parseInt((a - hh * 3600) / 60);
        if(mm<10) mm = "0" + mm;
        let ss = parseInt((a - hh * 3600) % 60);
        if(ss<10) ss = "0" + ss;
        const length = hh + "小时" + mm + "分" + ss + "秒";
        if(a>0){
            return length;
        }else{
            return "0秒";
        }
    },
    // 如果后端要求字段为int,前端需要把空字符串字段去掉,否则接口会报错
    trimData(model) {
        const obj = {}
        Object.keys(model).forEach(key => {
            if (model[key] !== '') {
                obj[key] = model[key]
            }
        })
        return obj
    },
    arr2map(arr) {
        const obj = {}
        arr.forEach(item => {
            obj[item.value] = item.label
        })
        return obj
    },
    // 权限检测相关
    checkLogin() {
        return !!localStorage.getItem('token')
    },
    langChange: (menus, th) => {
        const lang = localStorage.getItem('lang')
        let result = []
        if (lang === 'en') {
            menus.forEach(item => {
                if (item.url === '') th.$message.error('菜单url配置不合法')
                result.push({...item, menu_name: item.localeName&&item.localeName.en || item.name})
                if (item.children?.length) {
                    item.children.forEach(i => {
                        if (i.url === '') th.$message.error('菜单url配置不合法')
                        i.menu_name = i.localeName && i.localeName.en || i.name
                    })
                }
            })
        } else {
            menus.forEach(item => {
                if (item.url === '') th.$message.error('菜单url配置不合法')
                result.push({...item, menu_name: item.name})
                if (item.children?.length) {
                    item.children.forEach(i => {
                        if (i.url === '') th.$message.error('菜单url配置不合法')
                        i.menu_name = i.name
                    })
                }
            })
        }
        return result
    },
    langChangeForTab: (tabs) => {
        const lang = localStorage.getItem('lang')
        if (lang === 'en') {
            tabs.forEach(tab => {
                tab.name = tab.localeName.en || tab.name
            })
        } else {
            tabs.forEach(tab => {
                tab.name = tab.caseTabName
            })
        }
        return tabs
    },
    langChangeForProgress: (arr) => {
        const lang = localStorage.getItem('lang')
        if (lang === 'en') {
            arr.forEach(item => {
                item.date = item.nodeDateLocaleName.en || item.nodeDate
                item.name = item.nodeNameLocaleName.en || item.nodeName
            })
        } else {
            arr.forEach(item => {
                item.date = item.nodeDate
                item.name = item.nodeName
            })
        }
        return arr
    },
    langChangeForAny: (arr, en_key, zh_key) => {
        const lang = localStorage.getItem('lang')
        if (lang === 'en') {
            arr.forEach(item => {
                item.name = item[en_key].en || item[zh_key]
            })
        } else {
            arr.forEach(item => {
                item.name = item[zh_key]
            })
        }
        return arr
    },
    bytesToSize(bytes) {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = Math.floor(Math.log(bytes) / Math.log(k))
        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
        //toPrecision(3) 后面保留两位小数，如1.00GB
    },
    formValidator: (rule, value, cb) => {
   
        const lang = localStorage.getItem('lang')
        if (!value) {
            return cb( `${lang==='en'?`${rule.field} required`:'必填项'}`)
        }
        return cb()
    },
    passValidator: (rule,val,cb) =>  {
        const lang = localStorage.getItem('lang')
        if (val.length<8) {
            cb(new Error(`${lang==='en'?'Please enter a password larger than 8 digits':'请输入大于8位数的密码'}`));
        }
        if (/^\d{8,}$/.test(val)) {
            cb(new Error(`${lang==='en'?'Consists of two combinations of numbers, letters, and special characters':'由数字、字母和特殊字符中的两种混合组成'}`));
        }
        if (/^[a-zA-Z]{8,}$/.test(val)) {
            cb(new Error(`${lang==='en'?'Consists of two combinations of numbers, letters, and special characters':'由数字、字母和特殊字符中的两种混合组成'}`));
        }
        if (/^[?|!|-|=|+|_|.|,|;$]{8,}$/.test(val)) {
            cb(new Error(`${lang==='en'?'Consists of two combinations of numbers, letters, and special characters':'由数字、字母和特殊字符中的两种混合组成'}`));
        }
        return cb();
    },
    idCardValidator: (rule,val,cb) => {
        const lang = localStorage.getItem('lang')
        if(!/^\d{15}|\d{}18$/.test(val)) {
            cb(new Error(lang==='en'?'Invalid format':'身份证号不合法'))
        }
        return cb()
    },
    niceName: (name) => {
        return name.length>15?name.slice(0,10)+'...'+name.slice(-8):name
    },

    // 身份证校验--dyc 
     validateIDCard(idCard) {
        // 正则表达式匹配规则
        var pattern = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
        // 判断格式是否正确
        if (!pattern.test(idCard)) {
          return false;
        }
        // 判断校验位是否正确
        var checkCode = idCard.slice(-1); // 获取校验位
        var factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
        var sum = 0;
        for (var i = 0; i < 17; i++) {
          sum += parseInt(idCard.charAt(i)) * factors[i];
        }
        var remainder = sum % 11;
        var checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验位对应值
        if (checkCode.toUpperCase() !== checkCodes[remainder]) {
          return false;
        }
        return true;
    },
     
    //  手机号校验-dyc 
     validatePhoneNumber(phoneNumber) {
      var pattern = /^1[3456789]\d{9}$/; // 以1开头，第二位为3-9之间的数字，后面跟着9位数字
      
      return pattern.test(phoneNumber);
    },
     
    //  邮箱验证-dyc 
     validateEmail(email) {
        var pattern = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/; // 符合常见邮箱格式
        
        return pattern.test(email);
    },
     
    //  判断上传的材料是pdf1 jpg0 或是其他2
    pdfFlagFn(str) {
        let splitStrList =str.split('.')
        let fileSuffix = splitStrList[splitStrList.length - 1].toLowerCase()
        let type =fileSuffix ===  'pdf' ? 1 : fileSuffix === 'jpg' ? 0 : 2
        return type
    },
    
    // 收文登记下拉框地址的下拉框记忆功能
    localStorageSelectList(lsListName, searchStr) {
        let arr = []
        if (localStorage.getItem(lsListName)) {
            arr = JSON.parse(
                localStorage.getItem(lsListName)
            )
        }

        let type = arr.some(
            (item) => item.value === searchStr
        )
        if (!type) {
            arr.unshift({
                    value: searchStr
                }) 
       
            if (arr.length >= 200) {
                arr.pop();
            }
            localStorage.setItem(
                lsListName,
                JSON.stringify(arr)
            )
        }

    },

    // 给新打开的页面中预览的图片添加一个旋转按钮---DYC
    cretateRotateBtn(newWindow,button) {
       button.innerHTML = '旋转'
                button.style.color = '#fff'
                button.style.fontSize = '20px'
                button.style.position = 'fixed'
                button.style.bottom = '10%'
                button.style.right = '5%'
                button.style.width = '100px'
                button.style.height = '100px'
                button.style.borderRadius = '50%'
                button.style.border = 'none'
                button.style.backgroundColor = 'rgba(100,100,100,.4)'
                button.style.boxShadow = 'rgba(0,0,0,.3) 2px 8px 8px'
                newWindow.document.body.appendChild(button)
                let imgEdg = 0
                button.addEventListener('click', () => {
                    let newImg = newWindow.document.querySelector('img')
                    imgEdg += 90
                    newImg.style.transform = `rotateZ(${imgEdg}deg)`
                })
    },

    
    
      
    
};

export default {
    install(Vue) {
        Vue.config.globalProperties.$GF = globalFunction
    }
}
