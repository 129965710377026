<template>
	<div>
		<el-card>
			<div class="operateBox">
				<ul v-if="$store.state.loginInfo.role !== 2">
					<!-- 1 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="goRegisterCase({ caseId: '' })">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-shenqing"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.NewCase')}}</div>
						</el-card>
					</li>
					<!-- 2 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="openBind">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-bangding"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.AddaCase')}}</div>
						</el-card>
					</li>
					<!-- 3 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'todoList',
                                    ref: 'todoListRef'
                                })
                            ">
							<div class="iconBox" style="position: relative">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-chaxun"></use>
									</svg>
								</i>
								<div style="top: 1%; left: 63%" class="messageBox"
									v-show="showNumObj.letterCount !== 0">
									{{
                                        100 > showNumObj.letterCount
                                            ? showNumObj.letterCount
                                            : '99+'
                                    }}
								</div>
							</div>
							<div class="iconText" title="点击通知.指令查看复函">
								{{$t('msg.Inform')}}<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-dian"></use>
									</svg> </span>{{$t('msg.Directive')}}
							</div>
						</el-card>
					</li>
					<!-- 4 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'messageCenter',
                                    ref: 'messageCenterRef'
                                })
                            ">
							<div class="iconBox" style="position: relative">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-daiban"></use>
									</svg>
								</i>
								<div class="messageBox" v-show="showNumObj.messageCount !== 0">
									{{
                                        100 > showNumObj.messageCount
                                            ? showNumObj.messageCount
                                            : '99+'
                                    }}
								</div>
							</div>
							<div class="iconText">
								{{$t('msg.Notice')}}<span>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-dian"></use>
									</svg> </span>{{$t('msg.workbench_todo')}}
							</div>
						</el-card>
					</li>
					<!-- 5 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="goSearchGuidePages('立案指引')">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-xinshouyindao"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.OperationInstructions')}}</div>
						</el-card>
					</li>
					<!-- 6 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="goSearchGuidePages('费用快算')">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-calculation"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.FeeCalculator')}}</div>
						</el-card>
					</li>
				</ul>
				<ul v-else>
					<!-- 1 -->
					<!-- 历史案件查询 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'HistoryFileQuery',
                                    ref: 'HistoryFileQueryRef'
                                })
                            ">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-lishichaxun
"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.HistoricalCaseSearch')}}</div>
						</el-card>
					</li>
					<!-- 报酬查询 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'RemunerationQuery',
                                    ref: 'RemunerationQueryRef'
                                })
                            ">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-jiagefenxi
"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.RemunerationInquiry')}}</div>
						</el-card>
					</li>

					<!-- 开庭日历 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'TrialCalendar',
                                    ref: 'TrialCalendarRef'
                                })
                            ">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-renwurili-A"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.CalendarOfHearings')}}</div>
						</el-card>
					</li>

					<!-- 文书模版下载 -->
					<li>
						<el-card class="box-card" shadow="hover" @click="
                                goPages({
                                    type: 'DocumentTemplate',
                                    ref: 'DocumentTemplateRef'
                                })
                            ">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-template"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.InstrumentTemplateDownload')}}</div>
						</el-card>
					</li>
					<!-- 操作指引  -->
					<li>
						<el-card class="box-card" shadow="hover" @click="goSearchGuidePages('办案指引')">
							<div class="iconBox">
								<i>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-shenqing"></use>
									</svg>
								</i>
							</div>
							<div class="iconText">{{$t('msg.OperationInstructions')}}</div>
						</el-card>
					</li>
				</ul>
			</div>
			<el-divider></el-divider>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				dialogFormVisible: false
			}
		},
		props: {
			showNumObj: {
				type: Object
			}
		},

		methods: {
			// 调用外面的绑定案件弹窗
			openBind() {
				this.$emit('openBind')
			},
			// 去往查询复函指引
			goSearchGuidePages(text) {
				// this.right_type = 'searchGuidePagesVue'
				const {
					href
				} = this.$router.resolve({
					path: '/SearchGuidePages',
					query: {
						type: text
					}
				})
				window.open(href, '_blank')
			},

			// 去注册案件页
			goRegisterCase(row) {
				this.$confirm(
						this.$t('msg.Article21ofthecETACArbitranformation'),
						'提示', {
							confirmButtonText: this.$t('msg.Confirm'),
							cancelButtonText: this.$t('msg.case_bind_cancel'),
							type: 'warning',
							// modalOptions: {
							//     width: '800px' // 设置宽度为您想要的值
							// }
							customClass: 'my-message-box'
						}
					)
					.then(async () => {
						let res = await this.$API.sendRequest(
							'FlagApplyArbitration'
						)
						if (!res.success) {
							return this.$message.error(res.msg)
						} else {
							this.$emit('goRegisterCase', row)
						}
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: this.$t('msg.Cancelled')
						})
					})
			},

			// 想要跳转的页面
			goPages(obj) {
				this.$emit('goPages', obj)
			}
		}
	}
</script>

<style scoped lang="scss">
	.operateBox {
		margin-top: 20px;

		ul {
			padding: 0;
			display: flex;
			height: 100%;
			justify-content: space-between;

			li {
				cursor: pointer;
				flex: 1;
				height: 100%;
				margin: 0 20px;

				&:nth-child(1) {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				i {
					font-size: 70px;
				}

				.iconBox {
					padding-top: 10px;
				}

				.iconText {
					margin-top: 20px;
				}
			}
		}

		.messageBox {
			position: absolute;
			top: 7%;
			left: 54%;
			background-color: #f56c6c;
			padding: 5px;
			color: #fff;
			border-radius: 50%;
			width: 23px;
		}
	}

	:deep(.my-message-box) {
		width: 500px !important;
		color: red !important;
	}

	:deep(.el-message-box) {
		width: 1000px;
	}
</style>