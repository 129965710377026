<template>
    <div class="header_wrapper">
        <div class="top">
            <div class="left">
                <img src="../assets/image/home/logo.png" alt="logo" />
                <div class="company_name">
                    <div>{{ $t('msg.UnitName') }}</div>
                    <div>
                        {{
                            'China International Economic and Trade Arbitration Commission'.toUpperCase()
                        }}
                    </div>
                </div>
            </div>
            <div class="right">
                <span
                    ><img src="../assets/image/home/avatar.png" alt="" /><span
                        >{{ username || '暂无' }}
                    </span></span
                >

                <span
                    class="realName"
                    v-if="
                        $store.state.loginInfo.isRealName === 1 || realNameType
                    "
                    ><i>
                        {{ $t('msg.RealNameAuthentication') }}
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-shiming"></use>
                        </svg> </i
                ></span>

                <span class="realName" v-else @click="goIdentity"
                    ><i>
                        {{ $t('msg.NotYetAuthenticated') }}
                        <svg class="icon" aria-hidden="true">
                            <use
                                xlink:href="#icon-weishiming
"
                            ></use>
                        </svg> </i
                ></span>

                <el-divider direction="vertical" />
                <span @click="GoSetting"
                    ><img
                        src="../assets/image/home/setting.png"
                        alt=""
                    /><span>{{ $t('msg.header_setting') }}</span></span
                >
                <el-divider direction="vertical" />
                <span @click="HandleLogout"
                    ><img
                        src="../assets/image/home/logout.png"
                        alt=""
                    /><span>{{ $t('msg.header_logout') }}</span></span
                >
                <el-divider direction="vertical" />
                <!-- 切换中英文 -->

                <span>
                    <LangOption position="home" />
                </span>
            </div>
        </div>

        <el-dialog
            :title="$t('msg.RealNameAuthentication')"
            v-model="identityType"
            width="40%"
            center
            :before-close="clcikCancel"
        >
            <el-form
                ref="ruleFormRef"
                :rules="rules"
                :model="identityForm"
                style="max-width: 600px"
            >
                <el-row>
                    <el-form-item prop="name" style="width: 100%">
                        <el-input
                            style="max-width: 600px"
                            :placeholder="$t('msg.register_real_name_tip')"
                            size="large"
                            v-model="identityForm.name"
                            clearable
                        >
                            <template #prepend>{{
                                $t('msg.register_real_name')
                            }}</template>
                        </el-input>
                    </el-form-item>
                </el-row>
                <el-row style="margin: 20px 0">
                    <el-form-item prop="idNum" style="width: 100%">
                        <el-input
                            style="max-width: 600px"
                            :placeholder="$t('msg.register_tel_no_tip')"
                            size="large"
                            v-model="identityForm.idNum"
                            clearable
                        >
                            <!-- @blur="blurIdNumInput" -->
                            <template #prepend>{{
                                $t('msg.register_tel_no')
                            }}</template>
                        </el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <div class="bottomBtn">
                <el-button @click="clcikCancel">{{
                    $t('msg.case_bind_cancel')
                }}</el-button>
                <el-button type="primary" @click="submit">{{
                    $t('msg.case_bind_confirm')
                }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import LangOption from './LangOption.vue'
export default {
    name: 'Header',
    components: {
        LangOption
    },
    created() {
        // 处理微信登录成功提示和重定向
        if (this.$route.query['authToken']) {
            this.$message.success('登录成功')
            this.$router.replace({ path: '/' })
        }
        this.GetUserInfo()
    },
    data() {
        return {
            username: 'loading...',
            identityType: false,
            identityForm: {
                name: '',
                idNum: ''
            },
            rules: {
                // 身份证校验
                idNum: [
                    {
                        required: true,
                        validator: this.idCardNumRulesFn,
                        trigger: 'blur'
                    }
                ],
                name: [
                    { required: true, message: '姓名为必填', trigger: 'blur' }
                ]
            },

            // 是否实名
            realNameType: false
        }
    },
    methods: {
        // TODO 获取个人信息 vuex
        async GetUserInfo() {
            const res = await this.$API.sendRequest('UserInfo')
            this.$store.commit('getLoginInfo', res.data)
            if (res) {
                this.username = res.data.realName || 'unknown'
            }
        },
        HandleLogout() {
            const lang = localStorage.getItem('lang')
            this.$confirm(
                lang === 'en' ? 'Confirm Logout?' : '确认要退出登录？',
                lang === 'en' ? 'Tip' : '提示',
                {
                    confirmButtonText: lang === 'en' ? 'confirm' : '确认',
                    cancelButtonText: lang === 'en' ? 'cancel' : '取消',
                    type: 'warning'
                }
            )
                .then(async () => {
                    await this.$API.sendRequest('Logout')
                    localStorage.removeItem('c_auth_token')
                    await this.$router.replace({ name: 'Login' })
                })
                .catch(() => {})
        },
        GoSetting() {
            this.$router.push({ name: 'Setting' })
        },

        // 实名认证弹窗
        goIdentity() {
            this.identityType = true
        },

        // 身份证校验失焦
        // blurIdNumInput() {
        //     console.log(this.$GF.validateIDCard(this.identityForm.idNum))
        // },

        // 身份证校验自定义规则
        idCardNumRulesFn(rule, value, callback) {
            if (!value) {
                callback(new Error('身份证号为必填'))
            }
            let type = this.$GF.validateIDCard(value)
            if (!type) {
                callback(new Error('请输入正确的身份证'))
            }
            callback()
        },

        // 点击实名认证的取消
        clcikCancel() {
            this.identityType = false
            this.identityForm = {
                name: '',
                idNum: ''
            }
            this.$refs.ruleFormRef.clearValidate()
        },

        // 点击了确定上传
        submit() {
            this.$refs['ruleFormRef'].validate(async (valid) => {
                if (valid) {
                    // alert('submit!')
                    let param = {
                        req: {
                            idCardNum: this.identityForm.idNum,
                            realName: this.identityForm.name
                        }
                    }
                    let res = await this.$API.sendRequest(
                        'RealAuthentication',
                        param
                    )
                    if (!res.success) {
                        return this.$message.error(res.msg)
                    }
                    this.$message.success(res.msg)
                    this.identityType = false
                    this.realNameType = true
                }
            })
        }
    }
}
</script>

<style scoped lang="sass">
.header_wrapper
    background: url("../assets/image/home/header_bg.png") no-repeat
    background-size: cover
    width: 100%
    height: 107px
    color: #fff

.top
    display: flex
    height: 50%
    justify-content: space-between
    padding: 15px 10px 0 10px
    color: rgba(255, 255, 255, 0.8)
.left
    display: flex
    align-items: center
    margin-left: 14px
    img
        height: 100%
    .company_name
        text-align: left
        margin-left: 12px
    div:first-child
        font-size: 18px
        font-weight: 500
    div:last-child
        font-size: 12px
        font-weight: 400
.right
    display: flex
    align-items: center
    margin-top: -12px
    margin-right: 20px
    span
        font-size: 14px
        cursor: pointer
        display: flex
        align-items: center
        font-weight: 500
    img
        height: 15px
        position: relative
        margin-right: 5px
        margin-bottom: 2px

.el-divider--vertical
    height: 10px
    margin: 0 13px

a
    text-decoration: none
.link
    border: 1px solid #7F96CD
    padding: 1px 10px
    border-radius: 3px
    color: rgba(255, 255, 255, 0.8)
    font-size: 13px
    position: relative
    top: -1px
</style>


<style lang="scss" scoped>
.right {
    .realName {
        color: #353030;
        //width: 110px;

        height: 20px;
        padding: 1px 20px;
        border-radius: 30px;
        margin-left: 5px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-content: center;
        background: #fff;

        i {
            font-style: normal;
        }
    }
}
:deep(.el-form-item__error) {
    padding-left: 50%;
}
.bottomBtn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
}

:deep(.el-input__inner) {
    height: 38px !important;
}
</style>