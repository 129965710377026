<template>
    <div>
        <CaseFilePages_L1
            v-if="pageType"
            ref="CaseFilePages_L1Ref"
            @goCaseFilePages_L2="goCaseFilePages"
        ></CaseFilePages_L1>
        <CaseFilePages_L2
            ref="CaseFilePages_L2Ref"
            v-else
            @goCaseFilePages_L1="goCaseFilePages"
        ></CaseFilePages_L2>
    </div>
</template>
 
<script>
import CaseFilePages_L1 from '../CaseFilePages_L1.vue'
import CaseFilePages_L2 from '../CaseFilePages_L2.vue'
export default {
    name: '',
    components: {
        CaseFilePages_L1,
        CaseFilePages_L2
    },
    data() {
        return {
            // 切换的状态
            pageType: true,
            caseId: ''
        }
    },
    methods: {
        async load(caseId) {
            this.pageType = true
            this.caseId = caseId
            this.$nextTick(() => {
                this.$refs.CaseFilePages_L1Ref.load({ caseId })
            })
        },

        //两个页面的切换
        goCaseFilePages() {
            this.pageType = !this.pageType
            this.$nextTick(() => {
                if (this.pageType) {
                    this.$refs.CaseFilePages_L1Ref.load({ caseId: this.caseId })
                } else {
                    this.$refs.CaseFilePages_L2Ref.load({ caseId: this.caseId })
                }
            })
        }
    }
}
</script>
 
<style scoped >
</style>