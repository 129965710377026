<template>
    <div>
        <!-- <h1>历史案件查询</h1> -->
        <el-card>
            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.case')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="caseNo"
                                :placeholder="$t('msg.Pleaseenterhecasenumber')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.case_name')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="caseName"
                                :placeholder="$t('msg.Pleaseenterthecasename')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.Manager')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="caseHandleName"
                                :placeholder="$t('msg.Pleaseenterthehandler')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.case_status')}}</span></el-col>
                        <el-col :span="18">
                            <el-select
                                v-model="caseState"
                                :placeholder="$t('msg.PleaseSelect')"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in SelectionList"
                                    :key="item.dictionaryItemValue"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="16">
                    <el-button type="primary" @click="getTable">{{$t('msg.finder_search')}}</el-button>
                    <el-button @click="clcikResetting">{{$t('msg.case_reset')}}</el-button>
                    <el-button type="info" @click="clickGomyCaseList"
                        >{{$t('msg.back')}}</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <div class="tabsBox">
            <el-table
                :data="tableData"
                style="width: 100%"
                v-loading="tableLoading"
            >
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="caseNo" :label="$t('msg.case')">
                </el-table-column>
                <el-table-column prop="caseName" :label="$t('msg.case_name')" width="300">
                </el-table-column>
                <el-table-column prop="caseHandleName" :label="$t('msg.Manager')">
                </el-table-column>
                <el-table-column prop="caseHandlePhone" :label="$t('msg.TelephoneNumberofSecretary')">
                </el-table-column>
                <el-table-column prop="caseStateName" :label="$t('msg.case_status')">
                </el-table-column>
                <el-table-column label="详情">
                    <template #default="scope">
                        <div>
                            <el-button @click="clickDetail(scope.row)"
                                >{{$t('msg.View')}}</el-button
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页器开始 -->
        <div class="bottomPagination">
            <el-pagination
                :current-page="current"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="size"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
        <!-- 分页器结束 -->

        <!-- 基本信息弹窗 -->
        <el-dialog :title="$t('msg.BasicInfo')" v-model="detailDialogType" width="80%">
            <div v-loading="dialogLoading">
                <el-card>
                    <h3>{{$t('msg.ReceiptInformation')}}</h3>
                    <!-- 第一行   收文日期   来函人单位 -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DateofReceipt')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.receiveLetterDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.CorrespondenceAffiliation')}}：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.letterCompany }}
                            </el-col>
                        </el-col>
                    </el-row>

                    <!-- 第二行   来文方式   来源 -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ModeofCommunication')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.letterType
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.Source')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.source }}
                            </el-col>
                        </el-col>
                    </el-row>
                </el-card>

                <el-card style="margin: 20px 0">
                    <h3>{{$t('msg.CasefilingInformation')}}</h3>
                    <!-- 第一行   合同编号   合同名称  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ContractNumber')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    strMontage(
                                        dialogDetailObj.caseInfos,
                                        'contractNo'
                                    )
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.ContractName')}}  ：
                            </el-col>
                            <el-col :span="14">
                                <!-- {{ dialogDetailObj.caseInfos }} -->
                                {{
                                    strMontage(
                                        dialogDetailObj.caseInfos,
                                        'contractName'
                                    )
                                }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第二行   合同日期   仲裁条款内容  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ContractDate')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    strMontage(
                                        dialogDetailObj.caseInfos,
                                        'contractDate'
                                    )
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.ContentofArbitrationClause')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.arbitratorContent }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第三行   立案提示     -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.FilingReminder')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.registerMsg?.join(', ') ||
                                    ''
                                }}</el-col>
                            </div>
                        </el-col>
                        <!-- <el-col :span="12">
                                <el-col :span="10" class="fontWeight"
                                    >仲裁条款内容 ：
                                </el-col>
                                <el-col :span="14"> xxxxxxxxx </el-col>
                            </el-col> -->
                    </el-row>
                    <!-- 第四行   当事人类型   涉外国家  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.PartyType')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.partyType?.join(', ') || ''
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.ForeignCountries')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.foreignCountries }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第四行   中条款受理地   大条款受理地  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.PlaceofAcceptanceofIntermediateClauses')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.middleTerm
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.PlaceofAcceptanceofMajorClauses')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.majorTerms }}
                            </el-col>
                        </el-col>
                    </el-row>
                </el-card>

                <el-card>
                    <h3>{{$t('msg.CaseInformationes')}} </h3>
                    <!-- 第一行   案件编号   案件名称  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.case')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.caseNo
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.case_name')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.caseName }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第二行   案件性质   争议类型(旧)  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.NatureoftheCase')}}  ：
                                </el-col>
                                <el-col :span="14">
                                    {{ dialogDetailObj.caseNature }}</el-col
                                >
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.DisputetypeOld')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.controversyType }}</el-col
                            >
                        </el-col>
                    </el-row>
                    <!-- 第三行   一级争议类型   二级/次级争议类型  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.TypeoffirstorderDispute')}}：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.disputeType1
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.SecondarysecondarydisputeTypes')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.disputeType2 }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第四行   语言   仲裁员人数  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.Language')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.languageType
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.NumberofArbitrators')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.arbitratorNum }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第五行   适用规则   仲裁地  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ApplicableRules')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.applicableRules
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.SeatofArbitration')}}   ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.placeArbitration }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第六行   收文日期   程序类型  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DateofReceipt')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.receiveLetterDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.ProgramType')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.proceduresType }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第七行   立案秘书   办案秘书  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.FilingSecretary')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.registerCaseName
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.CaseSecretary')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.handleCaseName }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第八行   移送日期   分办日期  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DateofTransfer')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.moveDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.OfficeDate')}} ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.branchDate }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第九行   仲裁通知日期   组庭日期  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.DateofnoticeofArbitration')}} ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.noticeDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.DateofTribunalFormation')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.formationDate }}
                            </el-col>
                        </el-col>
                    </el-row>
                    <!-- 第十行   开庭日期   裁决期限  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.LawfulDay')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.openSessionDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.TimelimitforAdjudication')}}  ：
                            </el-col>
                            <el-col :span="14">{{
                                dialogDetailObj.ruleDate
                            }}</el-col>
                        </el-col>
                    </el-row>

                    <!-- 第十二行   结案日期   结案类型  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.ClosingDate')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.coseCaseDate
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.settlealawsuitType')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{ dialogDetailObj.closeCaseType }}
                            </el-col>
                        </el-col>
                    </el-row>

                    <!-- 第十四行   申请人   被申请人  -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.Applicant')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.partyNames1?.join(', ') ||
                                    ''
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.TheDefendant')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{
                                    dialogDetailObj.partyNames2?.join(', ') ||
                                    ''
                                }}
                            </el-col>
                        </el-col>
                    </el-row>

                    <!-- 第十三行   胜诉方     申请人代理人-->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.AgentofTheApplicant')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{
                                    dialogDetailObj.deputyNames1?.join(', ') ||
                                    ''
                                }}
                            </el-col>
                        </el-col>
                        <el-col :span="12">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.AgentoftheRespondent')}}  ：
                            </el-col>
                            <el-col :span="14">
                                {{
                                    dialogDetailObj.deputyNames2?.join(', ') ||
                                    ''
                                }}
                            </el-col>
                        </el-col>
                    </el-row>

                    <!-- 第十二行   仲裁员     -->
                    <el-row :gutter="10" class="borderLine">
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.Arbitrator')}}  ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.arbitratorName?.join(
                                        ', '
                                    ) || ''
                                }}</el-col>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div>
                                <el-col :span="10" class="fontWeight"
                                    >{{$t('msg.WinningParty')}}   ：
                                </el-col>
                                <el-col :span="14">{{
                                    dialogDetailObj.recover
                                }}</el-col>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </el-dialog>
    </div>
</template>
 
<script>
export default {
    name: 'historyFileQuery',
    components: {},
    data() {
        return {
            tableData: [],

            // 案件详情的弹窗
            detailDialogType: false,
            // 页数
            current: 1,
            // 当前页条数
            size: 100,
            // 总条数
            total: 0,

            // 案件状态
            caseState: 0,
            // 案件名称
            caseName: '',
            // 经办人
            caseHandleName: '',
            // 案件编号
            caseNo: '',
            // 表格的加载
            tableLoading: false,
            // 案件状态数组
            SelectionList: [],
            // 弹窗
            dialogLoading: false,
            // 弹窗详情页
            dialogDetailObj: {}
        }
    },

    methods: {
        async load() {
            await this.getSelectList()
            this.clcikResetting()
        },

        // 获取下拉框列表
        async getSelectList() {
            let param = {
                req: 5
            }
            let res = await this.$API.sendRequest('GetDictionaryItems', param)
            if (res.success) {
                return this.$message.error(res.msg)
            }

            this.SelectionList = res.data.materialStyleList
            this.SelectionList.unshift({
                dictionaryItemValue: 0,
                dictionaryItemName: '全部'
            })
        },

        // 获取表格列表
        async getTable() {
            this.tableLoading = true
            let param = {
                req: {
                    q: {
                        caseState: this.caseState,
                        caseName: this.caseName.trim(),
                        caseHandleName: this.caseHandleName.trim(),
                        caseNo: this.caseNo.trim()
                    },
                    current: this.current,
                    size: this.size
                }
            }
            let res = await this.$API.sendRequest('HistoricalCases', param)
            this.tableLoading = false
            if (!res.success) {
                return this.$message.error('请求失败')
            }
            let data = res.data
            this.total = data.total
            this.tableData = data.records
        },

        // 点击表格中查看
        async clickDetail(row) {
            this.detailDialogType = true
            this.dialogLoading = true
            this.dialogDetailObj = {}
            let param = {
                req: row.caseId
            }
            let res = await this.$API.sendRequest('CaseDetailsInfo', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.dialogDetailObj = res.data
            this.dialogLoading = false
            console.log(row)
        },

        // 关闭详细信息页弹窗
        detailDialogClose() {
            this.detailDialogType = false
        },

        // 点击了返回
        clickGomyCaseList() {
            this.$emit('gomyCaseList')
        },

        // 分页器功能 --- 每页条数发生变化时触发
        handleSizeChange(val) {
            this.pageSize = val
            this.current = 1
        },
        // 分页器功能 --- 当前页数发生变化时触发
        handleCurrentChange(val) {
            // this.pageSize = 1
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.total / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.pageSize > pageMax) {
                this.pageSize = pageMax
            }
        },

        // 点击了重置
        clcikResetting() {
            this.caseNo = ''
            this.caseName = ''
            this.caseHandleName = ''
            this.caseState = 0
            this.getTable()
        },

        // 把数组里面的某一项提出来展示
        strMontage(arr, keyName) {
            if (!arr) return ''
            let newStr = []
            if (keyName === 'contractDate') {
                newStr = arr
                    .map((item) => {
                        return item['contractDate1'] || item['contractDate2']
                    })
                    .join(',')
            } else {
                newStr = arr
                    .map((item) => {
                        return item[keyName]
                    })
                    .join(',')
            }

            return newStr
        }
    }
}
</script>
 
<style scoped lang="scss">
.el-row {
    margin: 10px 0;
    span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.tabsBox {
    margin-top: 20px;
}

.borderLine {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    &:nth-child(2n) {
        background-color: rgba(216, 213, 213, 0.4);
    }
}

.fontWeight {
    font-size: 16px;
    /* font-weight: 600; */
}

.bottomPagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>