<template>
    <div class="container" v-loading="loading">
        <div class="tabsBox">
            <el-table :data="list" style="width: 100%">
                <el-table-column :label="$t('msg.MessageStatus')">
                    <template #default="scope">
                        <el-tag
                            type="success"
                            disable-transitions
                            v-if="scope.row.readStatus === 1"
                            >{{ scope.row.readStatusName }}</el-tag
                        >
                        <el-tag type="info" disable-transitions v-else>{{
                            scope.row.readStatusName
                        }}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="receiveNo" :label="$t('msg.ApplicationNumber')">
                </el-table-column>

                <el-table-column prop="msgTitle" :label="$t('msg.MessageHeader')">
                </el-table-column>

                <el-table-column prop="submitTime" :label="$t('msg.case_create_time')">
                </el-table-column>
                <el-table-column prop="address" :label="$t('msg.Operate')">
                    <template #default="scope">
                        <el-button @click="deleteClick(scope.row)"
                            >{{$t('msg.View')}}</el-button
                        >
                        <el-button
                            @click="goPage(scope.row)"
                            v-if="!showBtn(scope.row.msgTitle)"
                            >{{$t('msg.Gotonotifynstruction')}}{{
                        }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 留言板      弹窗开始  -->
        <el-dialog
            v-model="showDialog"
            :show-close="false"
            width="80%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.NotificationMessage')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="closeDialog">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>
            <div class="messageBox">
                <header>
                    <h2>{{ showObj.msgTitle }}</h2>
                </header>
                <main>
                    <p>{{$t('msg.Dearpartyconcerned')}}：</p>
                    <div></div>
                    <p v-html="showObj.msgContent"></p>
                </main>
                <footer>
                    <p>{{ showObj.userName }}</p>
                    <p>
                        {{
                            showObj.organizationName ||
                            $t('msg.Notsubmittedorganizatiyet')
                        }}
                    </p>
                    <p>{{ showObj.submitTime }}</p>
                </footer>
            </div>
        </el-dialog>
        <!-- 分页器 -->
        <div class="footerPagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="defaultpageSize"
                :current-page="currentPage"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'myMessagePage',
    components: {},
    data() {
        return {
            list: [],
            loading: false,
            // 点击查看弹窗
            showDialog: false,
            // 需要展示的对象
            showObj: {},
            messageType: false,
            // 总条数
            total: 0,
            size: 10,
            current: 1,
            defaultpageSize: 10,
            currentPage: 1
        }
    },
    methods: {
        async load(dateInfo) {
            this.loading = true
            this.dateInfo = dateInfo
            this.size = 10
            this.current = 1
            await this.getShowMeMessageBoardList(dateInfo)
            this.loading = false
        },
        // 页面进入调取消息列表
        async getShowMeMessageBoardList(dateInfo) {
            this.currentPage = this.current
            let param = {
                req: {
                    current: this.current,
                    size: this.size,
                    q: {
                        startDate:
                            this.$GF.formatTime(
                                dateInfo.startDate,
                                'yyyy-MM-DD'
                            ) === 'NaN-NaN-NaN'
                                ? null
                                : this.$GF.formatTime(
                                      dateInfo.startDate,
                                      'yyyy-MM-DD'
                                  ),
                        endDate:
                            this.$GF.formatTime(
                                dateInfo.endDate,
                                'yyyy-MM-DD'
                            ) === 'NaN-NaN-NaN'
                                ? null
                                : this.$GF.formatTime(
                                      dateInfo.endDate,
                                      'yyyy-MM-DD'
                                  ),
                        msgType: dateInfo.msgStateValue,
                        msgState: dateInfo.typeValue
                    }
                }
            }
            let res = await this.$API.sendRequest('ShowMeMessageBoard', param)
            if (!res.success) this.$message.error('获取留言或消息失败')
            this.total = res.data.total
            // return res.data
            this.list = res.data.records
        },
        // 点击查看
        async deleteClick(row) {
            let param = {
                req: {
                    msgId: row.msgId,
                    readStatus: row.readStatus
                }
            }
            let res = await this.$API.sendRequest(
                'ShowMeMessageDetailBoard',
                param
            )
            this.showObj = res.data[0]
            this.getShowMeMessageBoardList(this.dateInfo)
            this.showDialog = true
        },

        goRegisterCase(item) {
            this.$listener.$emit('goRegisterCaseListener', item)
        },
        // 关闭弹窗
        async closeDialog() {
            this.showDialog = false
        },
        async clickSearch() {
            this.current = 1
            await this.getShowMeMessageBoardList(this.dateInfo)
        },
        handleSizeChange(size) {
            this.defaultpageSize = size
            this.size = size
            this.current = 1
            this.getShowMeMessageBoardList(this.dateInfo)
        },
        handleCurrentChange(current) {
            this.current = current
            this.getShowMeMessageBoardList(this.dateInfo)
        },

        // 根据申请号前面是不是含有WS去显示按钮
        showBtn(str) {
            const targetStr = '立案复函'
            return !str.includes(targetStr)
        },

        goPage() {
            let obj = {
                type: 'todoList'
            }
            this.$emit('goPage', obj)
        }
    }
}
</script>
 
<style scoped lang="scss">
.container {
    text-align: left;
    :deep(.tabsBox .el-tabs__item) {
        min-width: auto;
        min-height: auto;
    }
    :deep(.el-tabs__content) {
        padding: 0 !important;
    }
    .el-tab-pane {
        padding-top: 0;
    }

    .my-header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        .but {
            display: flex;
            align-self: center;
        }
        .titleClass {
            font-size: 20px;
            font-weight: 500;
        }
    }
    :deep(.el-dialog__body) {
        padding-top: 0;
    }
    .messageBox {
        padding: 0 20px;
        background-color: #f5f5f5;
        header {
            text-align: center;
        }
        footer {
            text-align: right;
        }
    }
}

.footerPagination {
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>