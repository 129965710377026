
<template>
    <div class="containerBox">
        <!-- <h1>智慧庭审</h1> -->
        <div class="nullTextBox">
            <div class="glass-container" id="glass">
                <div class="contentBox">
                    <h4>{{ $t('msg.OnlineCourtHearing') }}</h4>
                    <div class="contentInput">
                        <el-input
                            :placeholder="$t('msg.PleaseEnteraCourtroomCode')"
                            style="width: 100%"
                            v-model="hearCode"
                            ref="inputRef"
                            @keydown.enter="getOnHearing"
                        >
                            <template #append>
                                <div
                                    v-if="hearCode.trim()"
                                    @click="getOnHearing"
                                    class="inputRightText"
                                    aria-disabled=""
                                >
                                    {{ $t('msg.AccessToTheCourtroom') }}
                                </div>
                                <div
                                    v-else
                                    @click="clickCleanUp"
                                    class="inputRightText"
                                    aria-disabled=""
                                >
                                    {{ $t('msg.AccessToTheCourtroom') }}
                                </div>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 庭审码
            caseId: '',
            hearCode: '',
            messageType: false
        }
    },
    methods: {
        load(caseId) {
            this.messageType = false
            this.caseId = caseId
            this.hearCode = ''

            // 进入自动聚焦
            this.$refs.inputRef.focus()
            this.getOnHearing()
        },

        // 调用接口
        async getOnHearing() {
            let param = {
                req: {
                    caseId: this.caseId,
                    hearCode: this.hearCode.trim()
                }
            }
            let res = await this.$API.sendRequest('OnHearing', param)

            if (this.messageType) {
                if (!res.success) {
                    return this.$message.error(res.msg)
                }
            }
            this.messageType = true
            if (!res.errorCode) {
                return this.$message.error(res.msg)
            }
            if (res.errorCode === '30001') {
                return this.$message.error(res.msg)
            } else if (res.errorCode === '10001' || res.errorCode === '20001') {
                return window.open(res.data, '_black')
            } else {
                if (!this.messageType) {
                    return this.$message.error(res.msg)
                }
                return
            }
        },

        // 在没有输入庭审码的时候点击这个事件
        clickCleanUp() {
            this.hearCode = ''
            this.$message.info('请输入庭审码')
        }
    }
}
</script>
 
<style scoped lang="scss">
.containerBox {
    height: 100%;
    .nullTextBox {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .glass-container {
            width: 90%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            border-radius: 10px;
            backdrop-filter: blur(5px);
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
            border-right: 2px rgba(40, 40, 40, 0.35) solid;
            transition: all 0.5s;
            .contentBox {
                height: 100%;
                h4 {
                    font-size: 40px;
                    font-weight: 500;
                    font-family: cursive;
                    // margin-bottom: 0;
                }
                .contentInput {
                    width: 500px;
                    :deep(.el-input__wrapper) {
                        height: 50px !important;
                    }
                }
                :deep(.el-input__inner)::placeholder {
                    color: rgb(69, 105, 247) !important;
                    font-style: italic;
                    font-size: 20px;
                    letter-spacing: 0.2em;
                }
                :deep(.el-input__inner) {
                    font-size: 20px !important;
                    letter-spacing: 0.2em;
                }

                :deep(.el-input-group__append) {
                    height: 50px !important;
                    color: #fff;
                    font-size: 20px;
                    background: linear-gradient(
                        90deg,
                        #74ebd5 0%,
                        #9face6 100%
                    );
                    cursor: pointer;
                }
            }
        }

        .glass-container:hover {
            box-shadow: rgba(0, 0, 0, 0.3) 22px 20px 8px;
        }
        .inputRightText {
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            padding: 0 20px;
            align-items: center;
        }
        :deep(.el-input-group__append) {
            padding: 0;
        }
    }
}
</style>
