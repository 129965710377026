<template>
    <div>
        <!-- <h1>开庭日历</h1> -->
        <el-row style="margin: 20px">
            <el-date-picker
                v-model="selectDateValue"
                type="month"
                :placeholder="$t('msg.Optionmonth')"
            >
            </el-date-picker>
            <el-button @click="goDate" type="primary" style="margin: 0 20px">
                {{$t('msg.Jumptotecifmonth')}}
            </el-button>
            <el-button type="info" @click="clickGomyCaseList"> {{$t('msg.back')}}</el-button>
        </el-row>

        <div class="calendarBox" v-loading="loadingTyep">
            <div class="calendarItemBox">
                <el-calendar v-model="calendarVlaue">
                    <template #dateCell="{ data }">
                        <!-- 有鼠标滑过事件的 -->
                        <el-popover
                            placement="top-start"
                            :width="500"
                            trigger="hover"
                            v-if="currentShowDate(data.day)"
                        >
                            <template #reference>
                                <div class="calendarItem">
                                    <!-- {{ data }} -->
                                    <!-- 日和节气 -->
                                    <div
                                        class="calendarItem"
                                        :class="{
                                            BC7bf097: currentShowDate(data.day)
                                        }"
                                        @click="clickCalendarItem(data.day)"
                                    >
                                        <p class="calendarItemTitle">
                                            <!-- 公立 -->
                                            <span style="color: #2c3e50">
                                                {{
                                                    data.day
                                                        .split('-')
                                                        .slice(-1)
                                                        .join()
                                                }}
                                            </span>
                                            <!-- 农历 -->
                                            <span
                                                style="
                                                    font-size: 14px;
                                                    color: #2c3e50;
                                                "
                                            >
                                                {{
                                                    lunarDateStr(data.day)
                                                        .lunarDayName
                                                }}
                                            </span>
                                            <span class="calendarItemAb">
                                                {{
                                                    lunarDateStr(data.day)
                                                        .lunarFestival
                                                }}
                                            </span>
                                        </p>

                                        <p
                                            class="two-lines"
                                            v-for="(item, index) in dateList"
                                            :key="index"
                                        >
                                            <span
                                                class="textSpan"
                                                v-if="
                                                    item.openCourtDate ===
                                                    data.day
                                                "
                                            >
                                                案号
                                                <i>{{ item.caseNo }}</i> ，于
                                                <i>{{
                                                    item.startTime.slice(0, -3)
                                                }}</i>
                                                到
                                                <i>{{
                                                    item.endTime?.slice(0, -3)
                                                }}</i>
                                                在
                                                <i>{{ item.openCourtPlace }}</i>
                                                ，经办人{{
                                                    item.caseHandleName
                                                }}(未开)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <!-- 鼠标划过的下拉 -->
                            <template #default>
                                <div class="calendarDetailBox">
                                    <h5>{{ data.day }}开庭室一览</h5>
                                    <p
                                        v-for="(item, index) in dateList"
                                        :key="index"
                                    >
                                        <span
                                            class="textSpan"
                                            v-if="
                                                item.openCourtDate === data.day
                                            "
                                        >
                                            案号
                                            <i>{{ item.caseNo }}</i> ，将于该日
                                            <i>{{
                                                item.startTime.slice(0, -3)
                                            }}</i>
                                            到
                                            <i>{{
                                                item.endTime?.slice(0, -3)
                                            }}</i>
                                            在
                                            <i>{{ item.openCourtPlace }}</i>
                                            ，经办人
                                            <i>{{ item.caseHandleName }}</i>
                                            (
                                            <i>{{
                                                enumCourtSession(
                                                    item.openCourtFlag
                                                )
                                            }}</i>
                                            )
                                        </span>
                                    </p>
                                </div>
                            </template>
                        </el-popover>

                        <!-- 不需要下拉框的时候的日历空格 -->
                        <div
                            v-else
                            class="calendarItem"
                            @click="clickCalendarItem(data.day)"
                        >
                            <p class="calendarItemTitle">
                                <span
                                    v-if="isWeek(data.day)"
                                    class="spanWeekAb"
                                >
                                    休
                                </span>
                                <!-- 公立 -->
                                <span>
                                    {{ data.day.split('-').slice(-1).join() }}
                                </span>
                                <!-- 农历 -->
                                <span style="font-size: 14px">
                                    {{ lunarDateStr(data.day).lunarDayName }}
                                </span>
                                <!-- 节日 -->
                                <span
                                    class="calendarItemAb"
                                    v-if="lunarDateStr(data.day).lunarFestival"
                                >
                                    {{ lunarDateStr(data.day).lunarFestival }}
                                </span>
                                <!-- 节气 -->
                                <span
                                    class="solarTermsAb"
                                    v-if="lunarDateStr(data.day).term"
                                >
                                    {{ lunarDateStr(data.day).term }}
                                </span>
                            </p>
                        </div>
                    </template>
                </el-calendar>
            </div>
        </div>
    </div>
</template>
 
<script>
// 引入识别农历的插件
import LunarCalendar from 'lunar-calendar'
export default {
    name: 'TrialCalendar',
    components: {},
    data() {
        return {
            dateList: [],
            // 年
            year: '',
            // 月
            month: '',
            // 今天的日期
            calendarVlaue: new Date(),
            // 日历加载效果
            loadingTyep: false,
            // 选择的日期
            selectDateValue: ''
        }
    },
    methods: {
        async load() {
            this.selectDateValue = ''
            this.year = new Date().getFullYear()
            this.month = new Date().getMonth() + 1
            this.getCalendar()
        },

        // 点击了返回
        clickGomyCaseList() {
            this.$emit('gomyCaseList')
        },

        // 请求有事件的时间
        async getCalendar() {
            this.loadingTyep = true
            let param = {
                req: {
                    year: this.year,
                    month: this.month
                }
            }
            let res = await this.$API.sendRequest('TrialCalenda', param)
            this.dateList = res.data
            this.loadingTyep = false
        },

        // 返回农历的方法
        lunarDateStr(date) {
            let dateStr = new Date(date)
            const lunarDate = LunarCalendar.solarToLunar(
                dateStr.getFullYear(),
                dateStr.getMonth() + 1,
                dateStr.getDate()
            )
            return lunarDate
        },

        // 判断状态颜色
        currentShowDate(date) {
            let findWorkDay = null
            findWorkDay = this.dateList.find((f) => f.openCourtDate == date)
            return findWorkDay
        },

        // 去掉时间后面的秒
        deleteSecondStr(str) {
            return str.slice(0, -3)
        },

        // 点击了日期
        clickCalendarItem(date) {
            console.log(date)
        },

        // 判断是否周六日
        isWeek(date) {
            // 1. if else普通写法
            // if (date.getDay() === 6 || date.getDay() === 0) {
            //   return true
            // } else {
            //   return false
            // }
            // 2.优化：三元表达式写法
            let newDate = new Date(date)
            return newDate.getDay() === 6 || newDate.getDay() === 0
        },

        // 点击顶部的跳转指定日期
        goDate() {
            this.year = this.selectDateValue.getFullYear()
            this.month = this.selectDateValue.getMonth() + 1
            this.calendarVlaue = new Date(this.selectDateValue)
            this.getCalendar()
        },

        // 案件的状态
        enumCourtSession(num) {
            if (num === 1) {
                return '已开庭'
            } else if (num === 2) {
                return '未开庭'
            } else if (num === 3) {
                return '延期'
            } else if (num === 4) {
                return '无限延期'
            } else if (num === 5) {
                return '取消开庭'
            }
        }
    },
    watch: {
        // 监听如果年月发生变化就重新请求
        calendarVlaue(newValue) {
            this.selectDateValue = ''
            if (
                this.month === newValue.getMonth() + 1 &&
                this.year === newValue.getFullYear()
            ) {
                return
            }
            this.year = newValue.getFullYear()
            this.month = newValue.getMonth() + 1
            this.getCalendar()
        }
    }
}
</script>
 
<style scoped lang="scss">
.is-selected {
    color: #1989fa;
}
.calendarBox {
    .calendarItemBox {
        p {
            margin: 0;
        }
        .pTextFont {
            font-size: 14px;
        }
        .calendarItem {
            height: 100%;
            position: relative;
            .calendarItemTitle {
                display: flex;
                justify-content: space-evenly;
                padding-top: 5px;

                .calendarItemAb {
                    position: absolute;
                    right: 1%;
                    top: 1%;
                    font-size: 12px;
                    color: brown;
                }

                .solarTermsAb {
                    position: absolute;
                    right: 1%;
                    // top: 20%;
                    margin-top: 1px;
                    font-size: 12px;
                    color: brown;
                }
                .spanWeekAb {
                    position: absolute;
                    right: 1%;
                    bottom: 1%;
                    font-size: 16px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    color: #fff;
                    background-color: #ff8989;
                }
            }
        }
    }
    :deep(.el-calendar-day) {
        padding: 0 !important;
    }

    .textSpan {
        color: #2c3e50;
        font-size: 13px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
}
.calendarDetailBox {
    h5 {
        text-align: center;
        font-size: 20px;
    }
    p {
        font-size: 15px;
    }
    i {
        color: blue;
        font-style: normal;
    }
}

.BC7bf097 {
    background-color: rgba(217, 183, 100, 0.5);
}
.BCffff00 {
    background-color: #ffff00;
}
.BCff8989 {
    background-color: #ff8989;
}
.BC928282 {
    background-color: #928282;
}

.two-lines {
    padding: 0 2px 0 2px;
    white-space: nowrap; /* 让文本不换行 */
    overflow: hidden; /* 隐藏溢出部分的内容 */
    text-overflow: ellipsis; /* 用省略号展示溢出部分的内容 */
    i {
        color: blue;
        font-style: normal;
    }
}
</style>