<template>
    <div>
        <div class="registerCaseBox" v-loading="receiptLoading">
            <div class="showBox">
                <!-- 案件信息  -->
                <div class="titleBox" ref="top">
                    <h3>{{$t('msg.CaseInformation')}}</h3>
                </div>
                <el-card class="box-card">
                    <header>
                        <el-row>
                            <el-col :span="14">
                                <h2>
                                    {{$t('msg.case')}}：<span>{{ obj.caseNo }}</span>
                                </h2>
                            </el-col>
                            <el-col :span="10"> </el-col>
                        </el-row>
                        <el-row>
                            <h2 style="font-size: 16px">
                                {{$t('msg.finder_table_name')}}：<span>{{ obj.caseName }}</span>
                            </h2>
                        </el-row>
                        <el-row>
                            <h2 style="font-size: 16px">
                                {{$t('msg.Applicant')}}：<span>{{ obj.partyName }}</span>
                            </h2>
                        </el-row>
                        <el-row>
                            <h2 style="font-size: 16px">
                                {{$t('msg.TheDefendant')}}：<span>{{ obj.respondentName }}</span>
                            </h2>
                        </el-row>
                    </header>
                    <div class="btnBox">
                        <el-button type="success" @click="openDrawerType">
                           {{$t('msg.ApplyForInvoices')}}
                        </el-button>
                    </div>
                </el-card>
            </div>
        </div>

        <div class="registerCaseBox" v-loading="receiptLoading">
            <div class="showBox">
                <!-- 案件信息  -->
                <div class="titleBox" ref="top">
                    <h3>{{$t('msg.InvoiceInformation')}}</h3>
                </div>
                <el-card class="box-card">
                    <el-table :data="tableList" style="width: 100%">
                        <el-table-column
                            prop="submitDate"
                            :label="$t('msg.Date')"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="caseNo"
                            :label="$t('msg.case')"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column prop="moneySum" :label="$t('msg.AmountOfMoney')">
                        </el-table-column>
                        <el-table-column
                            prop="withdrawStateString"
                            :label="$t('msg.Status')"
                        >
                            <template #default="scope">
                                <div>
                                    <el-tag
                                        type="danger"
                                        v-if="
                                            scope.row.withdrawStateString ===
                                            '驳回'
                                        "
                                    >
                                        {{ scope.row.withdrawStateString }}
                                    </el-tag>
                                    <el-tag
                                        type="info"
                                        v-else-if="
                                            scope.row.withdrawStateString ===
                                            '未审核'
                                        "
                                    >
                                        {{ scope.row.withdrawStateString }}
                                    </el-tag>
                                    <el-tag type="success" v-else>
                                        {{ scope.row.withdrawStateString }}
                                    </el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.Operate')">
                            <template #default="scope">
                                <div>
                                    <el-button @click="clickDetail(scope.row)"
                                        >{{$t('msg.View')}}</el-button
                                    >
                                    <el-button @click="clickDownloadPdf(scope.row.receiptPdfurl)" v-if="scope.row.receiptPdfurl"
                                        >{{$t('msg.Download')}}</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </div>

        <!-- 左边输入框 -->
        <!-- 侧边栏 -->
        <el-drawer
            v-model="drawerType"
            :title="$t('msg.DetailsofInvoiceApplication')"
            direction="rtl"
            :before-close="drawerTypeClose"
            size="35%"
        >
            <div class="formBox" v-loading="loadingLeft">
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <div v-for="(item, index) in formForList" :key="index">
                        <!-- 不是汇率转人民币的情况 -->
                        <el-form-item
                            :label="specialInputLabel(item.name)"
                            :prop="item.modelType"
                            v-if="
                                item.modelType !== 'moneySumRMB' &&
                                item.modelType !== 'receiptPostAddr' &&
                                item.modelType !== 'account'
                            "
                        >
                            <el-input
                                :type="
                                    numberTypeList.includes(item.modelType)
                                        ? 'number'
                                        : ''
                                "
                                v-model="ruleForm[item.modelType]"
                                v-if="
                                    item.type === 'input' &&
                                    !disabledTypeInput(item.name)
                                "
                                :disabled="disabledTypeInput(item.modelType)"
                            >
                            </el-input>

                            <div
                                class="selectSearchPartyCompanyBox"
                                v-if="
                                    item.type === 'input' &&
                                    item.name === $t('msg.Applicantts')
                                "
                            >
                                <el-input v-model="ruleForm[item.modelType]">
                                    <template #append>
                                        <div
                                            @click="clickSearchPartyCompany"
                                            class="searchPartyCompanyBtn"
                                        >
                                           {{$t('msg.case_search')}}
                                        </div>
                                    </template>
                                </el-input>
                                <div class="selectItemSearch">
                                    <div
                                        v-for="(item, index) in getCompanyList"
                                        :key="index"
                                        @click="clcikCompanyListItem(item)"
                                    >
                                        <span>{{ item.nsrmc }}</span>
                                        <span style="margin-left: 20px">{{
                                            item.nsrsbh
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                            <el-select
                                v-model="ruleForm[item.modelType]"
                                :placeholder="$t('msg.PleaseSelect')"
                                v-if="
                                    item.type === 'select' &&
                                    item.name === $t('msg.TypeOfTicket')
                                "
                                style="width: 100%"
                            >
                                <el-option
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                    v-for="(item, index) in selectListB"
                                    :key="index"
                                >
                                </el-option>
                            </el-select>

                            <el-select
                                v-model="ruleForm[item.modelType]"
                                :placeholder="$t('msg.PleaseSelect')"
                                v-if="
                                    item.type === 'select' &&
                                    item.name === $t('msg.TypeOfExpense')
                                "
                                style="width: 100%"
                                @change="feeTypeChange"
                            >
                                <el-option
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                    v-for="(item, index) in selectListA"
                                    :key="index"
                                >
                                </el-option>
                            </el-select>

                            <el-select
                                v-model="ruleForm[item.modelType]"
                                :placeholder="$t('msg.PleaseSelect')"
                                v-if="
                                    item.type === 'select' &&
                                    item.name === '是否邮寄'
                                "
                                style="width: 100%"
                            >
                                <el-option
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                    v-for="(item, index) in selectListC"
                                    :key="index"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 相反 -->
                        <el-form-item
                            :label="item.name"
                            :prop="item.modelType"
                            v-else-if="
                                item.modelType === 'moneySumRMB' &&
                                currencyObj.currency !== '1'
                            "
                        >
                            <el-input
                                v-model="ruleForm[item.modelType]"
                                v-if="item.type === 'input'"
                                :disabled="disabledTypeInput(item.modelType)"
                            >
                            </el-input>
                        </el-form-item>
                        <!-- 邮寄地址 -->
                        <el-form-item
                            :label="$t('msg.Emailaddress')"
                            :prop="item.modelType"
                            v-else-if="item.modelType === 'receiptPostAddr'"
                        >
                            <el-input
                                v-model="ruleForm[item.modelType]"
                                v-if="item.type === 'input'"
                                :disabled="disabledTypeInput(item.modelType)"
                            >
                            </el-input>
                        </el-form-item>
                        <!-- 邮寄地址 -->
                        <el-form-item
                            :label="$t('msg.TaxCode')"
                            :prop="accountType"
                            v-else-if="item.modelType === 'account'"
                        >
                            <el-input
                                v-model="ruleForm[item.modelType]"
                                v-if="item.type === 'input'"
                                :disabled="disabledTypeInput(item.modelType)"
                            >
                            </el-input>
                        </el-form-item>
                    </div>

                    <el-form-item :label="$t('msg.InvoicingInstructions')">
                        <div class="uploadBox">
                            <input
                                v-show="false"
                                type="file"
                                ref="inputFileRef"
                                @change="fileChange"
                                accept=".png, .jpeg, .jpg"
                            />

                            <div class="imgBox" v-show="srcList[0]">
                                <el-image
                                    style="width: 103px; height: 115px"
                                    :src="url"
                                    :preview-src-list="srcList"
                                >
                                </el-image>
                            </div>
                            <el-button @click="clickModifyImg" type="primary">
                                {{$t('msg.Addadescriptionofmakeoutaninvoice')}}
                            </el-button>
                        </div>
                        <span style="color: red"> {{$t('msg.Theuploadformatis')}}jpg,jpeg,png</span>
                    </el-form-item>

                    <el-form-item
                        :label="$t('msg.Groundsforrejection')"
                        v-if="
                            +ruleForm.withdrawState === 4 &&
                            ruleForm.rejectReason
                        "
                    >
                        <div class="rejectReasonBox">
                            {{ ruleForm.rejectReason }}
                        </div>
                    </el-form-item>

                    <el-form-item :label="$t('msg.BillingInstructionsPrecautions')">
                        <div style="text-align: left">
                            {{$t('msg.Billinginstretheattachmentshauploathefol')}}:
                            <br />
                            1、{{$t('msg.Ifthedrawhangeitshalldregistration')}}
                            <br />
                            2、{{$t('msg.Anawardpagetheamounofthrbitrfeaded')}}
                        </div>
                    </el-form-item>

                    <div class="bottomBox">
                        <div
                            class="bottomBtn"
                            v-if="+ruleForm.withdrawState === 4"
                        >
                            <el-button
                                v-if="ruleForm.moneySumAll > 0"
                                type="primary"
                                @click="submitForm"
                                style="padding: 19px 30px"
                                >{{$t('msg.ApplyForInvoices')}}</el-button
                            >
                            <!-- <el-button @click="resetForm">重置</el-button> -->
                            <p v-else>{{$t('msg.NoteThereisnoinvoicingquota')}}</p>
                        </div>
                    </div>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>
 
<script>
import axios from 'axios';

export default {
    name: '',
    components: {},
    data() {
        return {
            drawerType: false,
            // 查看详情侧边栏
            drawerTypeRtl: false,
            ruleForm: {
                receiptType: 1, //票种
                feeType: 1, //费用种类
                moneySumAll: 0, //应开金额
                moneySum: 0, //申请金额
                moneySumRMB: 0, //申请金额(汇率转人民币)
                partyCompany: '', //申领方
                account: '', //税号
                gatherAddr: '', //公司地址
                tel: '', //电话
                bank: '', //开户行
                bankNo: '', //银行账号
                moneySumtui: 0, //退费金额(人民币)
                receiptPost: 1, //是否邮寄
                recipientName: '', //收件人姓名
                recipientTel: '', //收件人电话
                receiptPostAddr: '', //邮寄地址
                fileName: '', //开票说明----------tupian----base64
                withdrawState: 4
            },
            rules: {
                // 申请金额(人民币)
                moneySum: [
                    {
                        required: true,
                        validator: this.maxMoney,
                        trigger: 'blur'
                    }
                ],
                // 申领方
                partyCompany: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 税号
                account: [
                    {
                        required: true,
                        message: '此项为必填',
                        trigger: 'blur'
                    }
                ],
                // 公司地址
                gatherAddr: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 电话
                // tel: [
                //     { required: true, message: '此项为必填', trigger: 'blur' }
                // ],
                // // 开户行
                // bank: [
                //     { required: true, message: '此项为必填', trigger: 'blur' }
                // ],
                // 银行账号
                bankNo: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 退费金额(人民币)
                // moneySumtui: [
                //     { required: true, message: '此项为必填', trigger: 'blur' }
                // ],
                // 是否邮寄
                receiptPost: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 收件人姓名
                recipientName: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 收件人电话
                recipientTel: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ],
                // 邮寄地址
                receiptPostAddr: [
                    { required: true, message: '此项为必填', trigger: 'blur' }
                ]
                // 开票说明
                // fileName: [
                //     { required: true, message: '此项为必填', trigger: 'blur' }
                // ]
            },

            // 表单列表循环用
            formForList: [
                {
                    name: this.$t('msg.TypeOfTicket'),
                    type: 'select',
                    modelType: 'receiptType'
                },
                {
                    name: this.$t('msg.TypeOfExpense'),
                    type: 'select',
                    modelType: 'feeType'
                },
                {
                    name: this.$t('msg.AmountToBeInvoiced'),
                    type: 'input',
                    modelType: 'moneySumAll'
                },
                {
                    name: this.$t('msg.AmountApplied'),
                    type: 'input',
                    modelType: 'moneySum'
                },
                {
                    name: this.$t('msg.ApplicationAmountExchangeRatetoRMB'),
                    type: 'input',
                    modelType: 'moneySumRMB'
                },
                {
                    name: this.$t('msg.Applicantts'),
                    type: 'input',
                    modelType: 'partyCompany'
                },
                {
                    name: '税号',
                    type: 'input',
                    modelType: 'account'
                },
                {
                    name: this.$t('msg.CompanyRegisteredAddress'),
                    type: 'input',
                    modelType: 'gatherAddr'
                },
                {
                    name: this.$t('msg.InvoiceHeaderPhone'),
                    type: 'input',
                    modelType: 'tel'
                },
                {
                    name: this.$t('msg.AccountOpeningBank'),
                    type: 'input',
                    modelType: 'bank'
                },
                {
                    name: this.$t('msg.BankAccount'),
                    type: 'input',
                    modelType: 'bankNo'
                },

                {
                    name: this.$t('msg.RefundamountRMB'),
                    type: 'input',
                    modelType: 'moneySumtui'
                },

                // {
                //     name: '是否邮寄',
                //     type: 'select',
                //     modelType: 'receiptPost'
                // },

                // {
                //     name: '收件人姓名',
                //     type: 'input',
                //     modelType: 'recipientName'
                // },

                {
                    name: this.$t('msg.Telephonenumberofrecipient'),
                    type: 'input',
                    modelType: 'recipientTel'
                },

                {
                    name: this.$t('msg.Emailaddress'),
                    type: 'input',
                    modelType: 'receiptPostAddr'
                }
            ],

            // 获取的数据对象
            obj: {},
            // 获取的列表数组
            tableList: [],
            // 全局的loading效果
            receiptLoading: false,
            // 下拉框数据
            selectListA: [], //费用种类
            selectListB: [], //是否邮寄
            selectListC: [], //票种
            caseId: '',
            getCompanyList: [],
            // 上传材料说明
            fileList: [],
            // 上传的文件格式
            acceptedFormats: '.pdf,.jpg,',
            // 左边侧边弹出窗的loading效果
            loadingLeft: false,
            currencyObj: {
                currency: '',
                currencyName: ''
            },
            // 那些输入框需要是数字
            numberTypeList: [
                'moneySumAll',
                'moneySum',
                'moneySumRMB',
                'bankNo',
                'moneySumtui'
            ],
            // 小头像的展示
            url: '',
            // 头像预览的路径
            srcList: []
        }
    },
    computed: {
        accountType() {
            let str = this.ruleForm.receiptType === 1 ? 'account' : ''
            return str
        }
    },
    methods: {
        load(caseId) {
            this.caseId = caseId
            this.clearFormItem()
            this.getReceiptList(caseId)
            // this.feeTypeChange()
        },

        // 打开侧边栏---点击申请
        async openDrawerType() {
            this.feeTypeChange()
            this.storageListFn()
            this.drawerType = true
        },

        // 关闭侧边栏
        drawerTypeClose() {
            this.$nextTick(() => {
                this.resetForm()
            })
            this.drawerType = false
        },

        // 获取表格
        async getReceiptList(caseId) {
            this.receiptLoading = true
            let param = {
                req: caseId
            }
            let res = await this.$API.sendRequest('ReceiptList', param)
            if (!res.success) {
                this.receiptLoading = false
                return this.$message.error(res.msg)
            }
            this.obj = res.data
            this.tableList = res.data.payReceiptInfoVos
            this.receiptLoading = false
        },

        // 点击查看
        clickDetail(row) {
            this.storageListFn()
            this.ruleForm = {
                ...row,
                detailRecordId: row.detailRecordId
            }
            this.url = row.fileName
            this.feeTypeChange()
            // this.drawerTypeRtl = true
            this.drawerType = true
        },
        // 点击关闭
        drawerTypeRtlClose() {
            this.drawerTypeRtl = false
        },

        // 点击立即创建
        async submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let param = {
                        req: {
                            ...this.ruleForm,
                            incomeSum: this.currencyObj.incomeSum,
                            caseId: this.caseId,
                            withdrawState: 2
                        }
                    }
                    let res = await this.$API.sendRequest(
                        'InsReceiptInfo',
                        param
                    )
                    if (!res.success) {
                        return this.$message.error(res.msg)
                    }
                    this.drawerType = false
                    this.$message.success(res.msg)
                    this.getReceiptList(this.caseId)
                    this.clearFormItem()
                }
            })
        },

        // 点击重置按钮
        resetForm() {
            this.clearFormItem()
            this.$refs.ruleForm.resetFields()
        },

        // 清空表单
        clearFormItem() {
            this.getCompanyList = []
            this.ruleForm = {
                receiptType: 1, //票种
                feeType: 1, //费用种类
                moneySum: 0, //应开金额
                moneySumAll: 0, //申请金额
                moneySumRMB: 0, //申请金额(汇率转人民币)
                partyCompany: '', //申领方
                account: '', //税号
                gatherAddr: '', //公司地址
                tel: '', //电话
                bank: '', //开户行
                bankNo: '', //银行账号
                moneySumtui: 0, //退费金额(人民币)
                receiptPost: 1, //是否邮寄
                recipientName: '', //收件人姓名
                recipientTel: '', //收件人电话
                receiptPostAddr: '', //邮寄地址
                fileName: '', //开票说明----------tupian
                withdrawState: 4
            }
            this.srcList = []
        },

        // 费用种类  改变改变金额
        async feeTypeChange() {
            this.loadingLeft = true
            let param = {
                req: {
                    feeType: this.ruleForm.feeType,
                    caseId: this.caseId,
                    detailRecordId: this.ruleForm.detailRecordId
                        ? this.ruleForm.detailRecordId
                        : null
                }
            }
            let res = await this.$API.sendRequest('ChangeFeeTypeMoney', param)
            // 应开金额
            this.ruleForm.moneySumAll = res.data.moneySumAll
                ? res.data.moneySumAll
                : 0
            // 申请金额(汇率转人民币)
            this.ruleForm.moneySumRMB = res.data.moneySumRMB
                ? res.data.moneySumRMB
                : 0
            //退费金额(人民币)
            this.ruleForm.moneySumtui = res.data.moneySumtui
                ? res.data.moneySumtui
                : 0

            // 金额币种的字段
            this.currencyObj = {
                currency: res.data.currency,
                currencyName: res.data.currencyName,
                incomeSum: res.data.incomeSum
            }
            let urlText = res.data.fileName
                ? 'data:image/jpg;base64,' + res.data.fileName
                : ''
            this.url = urlText
            this.srcList[0] = urlText
            this.loadingLeft = false
        },

        // 输入申领方后点击查询
        async clickSearchPartyCompany() {
            let name = this.ruleForm.partyCompany
            if (!name.trim()) {
                return this.$message.info('请先输入')
            }
            let param = {
                req: name
            }
            let res = await this.$API.sendRequest('CompletionCompany', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }

            let JsonArr = JSON.parse(res.data)
            this.getCompanyList = JsonArr.map((item) => {
                return JSON.parse(item)
            })
        },

        // 选择文件上传
        fileListChange() {
            if (this.fileList.length > 1) {
                this.fileList.splice(0, 1) // 从 fileList 中移除不满足条件的文件
            }
        },

        // 公司选中的某一个项
        clcikCompanyListItem(item) {
            this.ruleForm.partyCompany = item.nsrmc
            this.ruleForm.account = item.nsrsbh
            this.getCompanyList = []
        },

        // 判断不让输入的input框
        disabledTypeInput(str) {
            let typeList = [
                'moneySumAll',
                'moneySumRMB',
                'moneySumtui',
                this.$t('msg.ApplicationAmountExchangeRatetoRMB'),
                this.$t('msg.Applicantts')
            ]
            let type = typeList.includes(str)
            return type
        },

        // 动态的Label值
        specialInputLabel(str) {
            let typeList = [this.$t('msg.AmountToBeInvoiced'), this.$t('msg.AmountApplied')]
            let type = typeList.includes(str)
            let currencyStr = this.currencyObj.currencyName
                ? '(' + this.currencyObj.currencyName + ')'
                : '(人民币)'
            if (type) {
                let newStr = str + currencyStr
                return newStr
            } else {
                return str
            }
        },

        //金额不能大于九百万校验规则
        async maxMoney(rule, value, callback) {
            if (!value) {
                callback(new Error('此项为必填'))
            }
            if (+value > +this.ruleForm.moneySumAll) {
                callback(
                    new Error('开票金额不能大于' + this.ruleForm.moneySumAll)
                )
            } else if (value > 9000000) {
                callback(new Error('开票金额不能大于900万'))
            } else {
                callback()
            }
        },

        // 存储本地下拉数组的方法
        async storageListFn() {
            this.loadingLeft = true
            let storageType = localStorage.getItem(
                'receiptApplicationListStorage'
            )
            if (storageType) {
                let res = JSON.parse(storageType)
                this.selectListA = res[0].data.materialStyleList
                this.selectListB = res[1].data.materialStyleList
                this.selectListC = res[2].data.materialStyleList
            } else {
                let promiseList = [
                    this.$API.sendRequest('styleTypeList', { req: 27 }),
                    this.$API.sendRequest('styleTypeList', { req: 292 }),
                    this.$API.sendRequest('styleTypeList', { req: 293 })
                ]

                let res = await Promise.all(promiseList)
                this.selectListA = res[0].data.materialStyleList
                this.selectListB = res[1].data.materialStyleList
                this.selectListC = res[2].data.materialStyleList

                localStorage.setItem(
                    'receiptApplicationListStorage',
                    JSON.stringify(res)
                )
            }
            this.loadingLeft = false
        },

        // 点击修改图片  弹出选择文件
        clickModifyImg() {
            let inputRef = this.$refs.inputFileRef
            inputRef.click()
        },

        // 选择文件后,对头像的处理
        fileChange() {
            let inputRef = this.$refs.inputFileRef
            let oFReader = new FileReader()
            oFReader.readAsDataURL(inputRef.files[0])
            oFReader.onloadend = (oFRevent) => {
                let src = oFRevent.target.result
                this.url = src
                this.srcList[0] = src
                this.ruleForm.fileName = src
            }
        },

        // 状态为审核  点击下载pdf
        async clickDownloadPdf(urlStr) {
            // let {data} = await axios.get(urlStr)
            let { data } = await axios({
                url: urlStr,
                method: 'get',
                    responseType :'arraybuffer'
            })
            console.log(data)
            let blob = new Blob([data], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = '电子收据'
            link.click()
            window.URL.revokeObjectURL(link.href)

        }
    }
}
</script>
 
<style scoped lang='scss'>
.registerCaseBox {
    position: relative;
    left: 0;
    text-align: left;
    padding: 0 10px;
    .ToppingBox {
        position: fixed;
        bottom: 50px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s;
        &.activeToppingBox {
            opacity: 1 !important;
        }
    }
}

.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #070707;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
}

header {
    h2 {
        font-weight: 500;
        font-size: 18px;
    }
    .rightBtnBox {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.box-card {
    .mainText {
        font-size: 15px;
        span {
            color: #827878;
        }
        .leftMainText {
            width: 100%;
        }
        .elseBoxSpan {
            span {
                color: #8788a3;
            }
        }
    }
    .btnBox {
        display: flex;
        justify-content: center;
    }
}

:deep(.el-form-item__label) {
    width: 175px !important;
}

:deep(.el-input-group__append) {
    height: 35px !important;
    padding: 0;
}

.searchPartyCompanyBtn {
    width: 60px;
    height: 100%;
    cursor: pointer;
}

:deep(.selectSearchPartyCompanyBox) {
    width: 100% !important;
    .selectItemSearch {
        padding-left: 20px;
        max-height: 300px;
        background-color: #e2e2e2;
        text-align: left;
        cursor: pointer;
        overflow: auto;
        & > div:hover {
            background: #ccc;
        }
    }
}

.uploadBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bottomBox {
    p {
        font-size: 20px;
        color: red;
    }
}

.rejectReasonBox {
    font-size: 20px;
    color: red;
}
.imgBox {
    width: 100%;
    height: 100%;
}
</style>