<template>
    <!--  留言板弹框 -->
    <div v-loading="loading">
        <el-form class="partyForm" ref="partyFormRef">
            <!-- 留言板展示内容 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>{{$t('msg.LeaveAMessage')}}</h4>
                    <el-divider></el-divider>
                    <!-- 材料名称  -->
                    <div class="msgBoxs">
                        <el-timeline style="margin-top: 20px">
                            <el-timeline-item
                                v-for="(item, index) in list"
                                :key="index"
                                :timestamp="item.submitTime"
                                placement="top"
                            >
                                <el-card>
                                    <h3>{{$t('msg.Party')}}：</h3>
                                    <p style="text-indent: 2em">
                                        {{ item.msgContent }}
                                    </p>
                                    <el-row>
                                        <el-col :span="18"></el-col>
                                        <el-col :span="6"
                                            ><span
                                                >{{$t('msg.Time')}}：{{
                                                    item.submitTime
                                                }}</span
                                            >
                                            &nbsp;&nbsp;&nbsp;
                                            <span
                                                >{{$t('msg.Publisher')}}：{{
                                                    item.userName
                                                }}</span
                                            >
                                        </el-col>
                                    </el-row>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>

            <!-- 留言板添加 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>{{$t('msg.AddaMessage')}}</h4>
                    <el-divider></el-divider>
                    <!-- 合同名称  -->
                    <el-row style="margin-top: 20px">
                        <el-col :span="4"><p>{{$t('msg.Content')}}</p></el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-input
                                    v-model="msgContent"
                                    type="textarea"
                                    :placeholder="$t('msg.PleaseentertheMessageContent')"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button
                        type="primary"
                        :loading="butLoading"
                        @click="saveMessageBoard"
                        >{{$t('msg.Add')}}</el-button
                    >
                </div>
            </el-row>
        </el-form>
    </div>
</template>
 
<script>
export default {
    name: 'leaveMessageDialog',
    data() {
        return {
            // 页面加载
            loading: false,
            // 表单对象
            msgContent: '',
            // 按钮加载效果
            butLoading: false,
            // 保留的的obj
            myObj: {},
            list: []
        }
    },
    methods: {
        load(obj) {
            this.myObj = obj
            this.getShowMessageBoard()
        },
        // 点击保存
        async saveMessageBoard() {
            if (!this.msgContent.trim()) {
                return this.$message.info('内容填写后可提交！')
            }
            let param = {
                req: {
                    caseId: this.myObj.caseId,
                    msgContent: this.msgContent,
                    receiveLetterNo: this.myObj.receiveNo
                }
            }
            let res = await this.$API.sendRequest('SaveMessageBoard', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getShowMessageBoard()
            this.msgContent = ''
        },
        // 展示页面对话框
        async getShowMessageBoard() {
            let param = {
                req: this.myObj.caseId
            }
            let res = await this.$API.sendRequest('ShowMessageBoard', param)
            this.list = res.data
        }
    }
}
</script>
 



<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-items: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
            margin-top: 0;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cardTextBox {
    margin-top: 20px;
    a {
        text-decoration: none;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;
        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}
:deep(.el-date-editor--date) {
    width: 100% !important;
}

.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.tableBox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.msgBoxs {
    p {
        font-size: 16px;
    }
    span {
        font-size: 14px;
    }
}

:deep(.el-timeline-item__wrapper) {
    padding-left: 50px;
}
</style>