<template>
    <div>
        <h1>{{$t('msg.OpenaCourtSessionInformation')}}</h1>
        <div class="courtInfoBox" v-if="dataList.length !== 0">
            <div
                class="courtInfoItem"
                v-for="(item, index) in dataList"
                :key="index"
            >
                <el-card>
                    <el-row>
                        <el-col :span="8">{{$t('msg.case')}}</el-col>
                        <el-col :span="16">{{ item.caseNo }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="8">{{$t('msg.case_name')}}</el-col>
                        <el-col :span="16">{{ item.caseName }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="8">{{$t('msg.LawfulDay')}}</el-col>
                        <el-col :span="16">{{ item.openCourtDate }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="8">{{$t('msg.PlaceOfHearing')}}</el-col>
                        <el-col :span="16">{{ item.openCourtPlace }}</el-col>
                    </el-row>
                    <el-divider></el-divider>

                    <el-row>
                        <el-col :span="8">{{$t('msg.SittingRoom')}}</el-col>
                        <el-col :span="16">{{ item.roomName }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="8">{{$t('msg.HearingStatus')}}</el-col>
                        <el-col :span="16">{{ item.openCourtFlagName }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="8">{{$t('msg.CaseManager')}}</el-col>
                        <el-col :span="16">{{ item.secretaryName }}</el-col>
                    </el-row>
                </el-card>
            </div>
        </div>
        <h1 v-else>{{$t('msg.finder_no_data')}}</h1>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            dataList: []
        }
    },
    methods: {
        async load(caseId) {
            console.log(caseId)
            // 16384 caseId
            let param = {
                req: 16384
            }
            let res = await this.$API.sendRequest('DateHearingChange', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.dataList = res.data.dateHearingInfoVoList
        }
    }
}
</script>
 
<style scoped lang="scss">
.courtInfoBox {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    .courtInfoItem {
        width: 30%;
    }
}
.el-col-16 {
    text-align: left;
}
</style>