<template>
    <div v-loading="submitLoading" style="width: 99%">
        <el-form ref="upload_form" label-width="70px">
            <el-form-item prop="realName" :label="$t('msg.SelectFile')">
                <el-upload
                    accept=".pdf,.jpg,.jpeg,.png,.word,.docx"
                    style="width: 400px; text-align: left"
                    action="xxx"
                    :http-request="() => {}"
                    :file-list="file_list"
                    :on-change="fileListChange"
                >
                    <div>
                        <el-button size="default">{{$t('msg.SelectFile')}}</el-button>
                        <p class="promptText">
                            <span
                                >{{$t('msg.Theuploadformatis')}}:word,pdf,png,jpg,jpeg大小不超过10M</span
                            >
                        </p>
                    </div>
                </el-upload>
            </el-form-item>
        </el-form>

        <div style="text-align: right; margin-bottom: 10px; margin-right: 10px">
            <el-button
                color="#4578FF"
                type="primary"
                size="default"
                @click="OpenUpload"
                >{{$t('msg.finder_upload')}}</el-button
            >
        </div>
        <!-- 进度条  -->
        <!-- 上传文件进度  percentageNumType-->
        <el-row
            v-if="percentageNumType"
            style="display: flex; align-items: center"
        >
            <p style="text-align: right; padding-right: 10px">{{$t('msg.Uploadfileprogress')}}</p>

            <div class="progressBox">
                <el-progress :percentage="percentageNum" style="width: 80%" />
            </div>
        </el-row>

        <!-- 表格   -->
        <el-table
            v-loading="loading"
            :data="list"
            border
            :stripe="true"
            style="width: 100%"
        >
            <el-table-column :label="$t('msg.Nameofculture')" prop="attName" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.DateofUpload')" prop="subDate" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.finder_table_type')" prop="libTypeName" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.Operate')" align="center">
                <template #default="scope">
                    <div>
                        <el-button
                            :disabled="!fileType(scope.row.attName)"
                            @click="axiosFile(1, scope.row.attId, 'preview')"
                        >
                            {{$t('msg.Preview')}}
                        </el-button>
                        <el-button
                            @click="
                                axiosFile(
                                    1,
                                    scope.row.attId,
                                    'dowload',
                                    scope.row.attName
                                )
                            "
                        >
                            {{$t('msg.Download')}}
                        </el-button>
                        <el-button
                            @click="clickDelete(2, scope.row.attId, 'delete')"
                        >
                             {{$t('msg.Delete')}}
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'MaterialUpload',
    data() {
        return {
            list: [],
            loading: false,

            file_list: [],
            title: '',
            case_id: '',
            caseNo: '',
            submitLoading: false,
            // 进度条进度
            percentageNum: 0,
            // 进度条是否展示
            percentageNumType: false
        }
    },
    methods: {
        load(case_id, caseNo) {
            this.clearData()
            this.case_id = case_id
            this.caseNo = caseNo
            this.GetFileList(0)
        },

        // 点击上传文件的时候判断有没有选择好文件
        OpenUpload() {
            if (this.file_list.length === 0) {
                this.$message.error('您还没有选择文件')
                return
            }
            this.ConfirmUpload()
        },

        // 上传文件
        async ConfirmUpload() {
            const file = this.file_list[0].raw
            let formData = new FormData()
            formData.append('file', file)
            formData.append('req.caseNo', this.caseNo)
            formData.append('req.caseId', this.case_id)
            formData.append('req.saveAttName', this.file_list[0].name)
            let myThis = this
            this.percentageNumType = true
            let res = await axios({
                url: '/api/arbitratorWeb/arbUploadMoreFiles',
                method: 'POST',
                data: formData,
                headers: {
                    c_Auth_token: localStorage.getItem('c_Auth_token')
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    if (percentCompleted < 100) {
                        myThis.percentageNum = percentCompleted
                    } else {
                        myThis.percentageNum = 99
                    }
                }
            })
            if (!res.data.success) {
                this.percentageNumType = false
                this.percentageNum = 0
                this.$message.error(res.data.msg)
            } else {
                this.file_list = []
                this.$message.success('上传成功')
                this.percentageNumType = false
                this.percentageNum = 0
                await this.GetFileList(0)
            }
            this.submitLoading = false
        },

        // 清空表格
        clearData() {
            this.file_list = []
            this.percentageNumType = false
            this.percentageNum = 0
        },

        // 获取列表  fileType:0----展示数据    fileType:1----预览下载
        async GetFileList(fileType, attId = null) {
            this.loading = true
            let param = {
                req: {
                    fileType,
                    caseId: this.case_id,
                    attId
                }
            }
            let res = await this.$API.sendRequest('ArbUploadMoreFiles', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.list = res.data
            this.loading = false
        },

        // 上传的change事件，fileList更改触发
        fileListChange() {
            for (let index = 0; index < this.file_list.length; index++) {
                // 限制大小10M
                const mySize = this.file_list[index].size / 1024 / 1024 < 10
                if (mySize) {
                    if (this.file_list.length >= 2) {
                        this.file_list.splice(0, 1)
                        break
                    }
                } else {
                    this.$message.error('上传文件大小不能超过' + 10 + 'MB!')
                    this.file_list.splice(index, 1) // 从 fileList 中移除不满足条件的文件
                    index-- // 因为移除了一个元素，所以需要将 index 减 1
                    continue
                }
            }
        },

        // 点击了预览
        // clickPreview() {
        //     this.GetFileList
        // }
        async axiosFile(fileType, attId = null, type, attName = null) {
            this.loading = true
            let param = {
                req: attId
            }
            let res = await axios({
                url: '/api/arbitratorWeb/arbPreFiles',
                method: 'POST',
                data: param,
                headers: {
                    c_Auth_token: localStorage.getItem('c_Auth_toke')
                },
                responseType: 'arraybuffer'
            })

            let contentType = res.headers['content-type'].split(';')[0]
            console.log(attName)
            // 预览
            if (type === 'preview') {
                let blob = new Blob([res.data], { type: contentType })
                console.log(window.URL.createObjectURL(blob))
                window.open(window.URL.createObjectURL(blob), '_blank')
            } else if (type === 'dowload') {
                let blob = new Blob([res.data], {
                    type: contentType
                })
                let url = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.href = url
                link.download = attName
                link.click()
                window.URL.revokeObjectURL(link.href)
            } else if (type === 'delete') {
                this.GetFileList(0)
            }

            this.loading = false
        },

        clickDelete(fileType, attId = null, type, attName = null) {
            if (type === 'delete') {
                this.$confirm('确认删除文件吗?', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(async () => {
                        this.loading = true
                        let param = {
                            req: {
                                fileType,
                                caseId: this.case_id,
                                attId
                            }
                        }
                        let res = await axios({
                            url: '/api/arbitratorWeb/arbUploadMoreFiles',
                            method: 'POST',
                            data: param,
                            headers: {
                                c_Auth_token:
                                    localStorage.getItem('c_Auth_toke')
                            },
                            responseType: 'arraybuffer'
                        })

                        // 预览
                        if (type === 'preview') {
                            let blob = new Blob([res.data], {
                                type: 'application/pdf'
                            })
                            window.open(
                                window.URL.createObjectURL(blob),
                                '_blank'
                            )
                        } else if (type === 'dowload') {
                            let blob = new Blob([res.data], {
                                type: 'application/pdf'
                            })
                            let url = window.URL.createObjectURL(blob)
                            let link = document.createElement('a')
                            link.href = url
                            link.download = attName
                            link.click()
                            window.URL.revokeObjectURL(link.href)
                        } else if (type === 'delete') {
                            this.GetFileList(0)
                        }

                        this.loading = false
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: this.$t('msg.Undelete')
                        })
                    })
            }
        },

        fileType(text) {
            let type = text.split('.')[text.split('.').length - 1]
            return (
                type === 'pdf' ||
                type === 'png' ||
                type === 'jpg' ||
                type === 'jpeg'
            )
        }
    }
}
</script>

<style scoped lang='scss'>
.drawerContainerBox {
    text-align: left;
    font-size: 16px;

    span {
        font-weight: 600;
        white-space: nowrap;
        display: inline-block;
    }
    :deep(.el-col-12) {
        display: flex;
    }

    i {
        font-style: normal;
    }
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 80%;
}
.progressTextColor {
    color: red;
}

.promptText {
    margin: -1% 0 0 0;
    text-align: left;
    font-size: 14px;
    color: red;
}
</style>
