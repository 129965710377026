<template>
    <!-- 案情  -->
    <div v-loading="loading">
        <el-row style="margin: 10px">
            <el-col :span="8" :class="[caseNo ? 'topTitle' : 'opacityShow']">
                <div ref="topBox">
                    <span> {{ caseNo }}号案情 </span>
                </div>
            </el-col>

            <el-col :span="7">
                <div class="radio">
                    排序：
                    <el-radio-group v-model="reverse">
                        <el-radio :label="true">倒序</el-radio>
                        <el-radio :label="false">正序</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
            <el-col :span="4">
                <el-button round @click="clickExport">
                    <svg
                        class="icon"
                        aria-hidden="true"
                        style="font-size: 20px; margin: 10px"
                    >
                        <use xlink:href="#icon-file-word-export"></use>
                    </svg>
                </el-button>
            </el-col>
        </el-row>

        <!-- 分割线 -->
        <el-divider></el-divider>
        <!-- 分割线 -->

        <div v-show="!loading">
            <el-card class="box-card">
                <div class="top">
                    <div class="left" ref="exportContent">
                        <p>
                            申 请 人：<span>{{
                                userObj.partyCompanyParty || '暂无'
                            }}</span>
                        </p>
                        <p>
                            仲裁人代理人：<span>{{
                                userObj.deputyCompanyParty || '暂无'
                            }}</span>
                        </p>
                        <p>
                            被 申 请 人：<span>{{
                                userObj.partyCompanyDeputy || '暂无'
                            }}</span>
                        </p>
                        <p>
                            仲裁代理人：<span>{{
                                userObj.deputyCompanyDeputy || '暂无'
                            }}</span>
                        </p>
                    </div>
                </div>
                <!-- 分割线 -->
                <el-divider></el-divider>
                <!-- 分割线 -->

                <el-timeline>
                    <el-timeline-item
                        timestamp="当事人材料"
                        placement="top"
                        color="skyblue"
                    >
                        <!-- 分割线 -->
                        <el-divider></el-divider>
                        <!-- 分割线 -->
                        <div class="block">
                            <el-timeline>
                                <el-timeline-item
                                    :timestamp="item.submitDateStr"
                                    placement="top"
                                    v-for="(item, i) in sotedItem"
                                    :key="i"
                                    color="skyblue"
                                >
                                    <el-card>
                                        <div class="box" ref="boxText">
                                            <el-collapse v-model="activeNames">
                                                <div
                                                    class="containerPre"
                                                    ref="preBox"
                                                >
                                                    <el-collapse-item
                                                        :title="demoText(item)"
                                                        :disabled="
                                                            !(
                                                                item.contentPdf !==
                                                                    null &&
                                                                item.contentPdf
                                                                    .length !==
                                                                    0
                                                            )
                                                        "
                                                        color="skyblue"
                                                    >
                                                        <div
                                                            class="textBoxPre"
                                                            v-if="
                                                                item.contentPdf !==
                                                                null
                                                            "
                                                        >
                                                            <pre
                                                                v-for="(
                                                                    reslut,
                                                                    index
                                                                ) in item.contentPdf"
                                                                :key="index"
                                                            >
  {{ reslut }}</pre
                                                            >
                                                        </div>
                                                        <pre v-else> 无</pre>
                                                    </el-collapse-item>
                                                </div>
                                            </el-collapse>
                                        </div>
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </el-timeline-item>
                    <div class="container">
                        <el-timeline-item
                            timestamp="经办人发文"
                            placement="top"
                            color="skyblue"
                        >
                            <el-card>
                                <div class="block">
                                    <el-timeline>
                                        <el-timeline-item
                                            :timestamp="item.sendDateStr"
                                            placement="top"
                                            v-for="(item, i) in sotedItemTwo"
                                            :key="i"
                                            color="skyblue"
                                        >
                                            <el-card>
                                                <div class="box" ref="boxText">
                                                    <el-collapse
                                                        v-model="activeNames"
                                                    >
                                                        <div
                                                            class="containerPre"
                                                            ref="preBox"
                                                        >
                                                            <el-collapse-item
                                                                :title="
                                                                    outgoingText(
                                                                        item
                                                                    )
                                                                "
                                                                :disabled="
                                                                    !(
                                                                        item.contentPdf !==
                                                                            null &&
                                                                        item
                                                                            .contentPdf
                                                                            .length !==
                                                                            0
                                                                    )
                                                                "
                                                            >
                                                                <div
                                                                    class="textBoxPre"
                                                                    v-if="
                                                                        item.contentPdf !==
                                                                        null
                                                                    "
                                                                >
                                                                    <pre
                                                                        v-for="(
                                                                            reslut,
                                                                            index
                                                                        ) in item.contentPdf"
                                                                        :key="
                                                                            index
                                                                        "
                                                                    >
  {{ reslut }}</pre
                                                                    >
                                                                </div>
                                                                <pre v-else>
 无</pre
                                                                >
                                                            </el-collapse-item>
                                                        </div>
                                                    </el-collapse>
                                                </div>
                                            </el-card>
                                        </el-timeline-item>
                                    </el-timeline>
                                </div>
                            </el-card>
                        </el-timeline-item>

                        <el-timeline-item
                            timestamp="发文"
                            placement="top"
                            style="opacity: 0"
                        >
                        </el-timeline-item>
                    </div>
                </el-timeline>
            </el-card>
        </div>
    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 正序倒序
            reverse: true,
            // 数据列表
            activity: [],
            // 加载效果
            loading: false,
            // 文件列表
            caseInfoVosList: [],
            caseInfoVosDocumentList: [],
            // 申请人相关对象
            userObj: {},
            caseNo: '',
            // 点击打开的抽屉
            activeNames: []
        }
    },
    created() {
        if (this.$route.query.caseId) {
            this.GetOneTimeToken(this.$route.query.token)
        }
    },
    methods: {
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.CaseInfo({ caseId: this.$route.query.caseId })
        },

        load(row) {
            this.caseNo = row.caseNo
            this.CaseInfo(row)
        },
        // 根据id获取详细信息
        async CaseInfo(row) {
            this.loading = true
            let param = {
                req: row.caseId
            }

            let res = await this.$API.sendRequest('CaseInfo', param)
            this.loading = false
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.caseNo = res.data.caseNo
            this.caseInfoVosList = res.data.caseInfoVos
            this.caseInfoVosDocumentList = res.data.caseInfoVosDocument
            this.userObj = res.data.caseInfoTopDetailVos
        },
        // 点击导出导出为word
        clickExport() {
            const container = this.$refs.exportContent
            const paragraphs = container.getElementsByTagName('p')
            const preBox = this.$refs.preBox
            // const preBoxDiv = preBox.getElementsByTagName('div')
            // console.log(preBoxDiv, 'preBoxDiv');
            const paragraphContents = []
            for (let i = 0; i < paragraphs.length; i++) {
                const paragraph = paragraphs[i]
                paragraphContents.push(paragraph.innerText)
                if (i === paragraphs.length - 1) {
                    paragraphContents.push('\n')
                }
                // paragraphContents.push(this.textBoxList[i].innerText)
            }
            for (let i = 0; i < preBox.length; i++) {
                // console.log(preBox[i].textContent);
                paragraphContents.push(preBox[i].textContent)
                paragraphContents.push('\n')
            }

            let blob = new Blob([paragraphContents.join('\n')], {
                type: 'application: msword'
            })
            this.saveAs(blob, this.caseNo + '.doc')
        },
        // 导出的执行事件
        saveAs(blob, fileName) {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display:none'
            let url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
        },
        // 计算返回的模版
        demoText(item) {
            let num = 1
            if (num === 1) {
                return (
                    item.submitDateStr +
                    ',仲裁院收到' +
                    item.partyTypeName +
                    '提交的' +
                    (item.evidenceDesc
                        ? item.evidenceDesc
                        : item.receiveLetterContent) +
                    '。(' +
                    (item.contentPdf === null ? '0' : item.contentPdf.length) +
                    ')'
                )
            } else {
                return '暂无，待编辑'
            }
        },
        outgoingText(item) {
            let num = 1
            if (num === 1) {
                return (
                    '仲裁院于' +
                    item.sendDateStr +
                    '寄送' +
                    (item.evidenceDesc
                        ? item.evidenceDesc
                        : item.letterTitle
                        ? item.letterTitle
                        : '')
                )
            } else {
                return '暂无，待编辑'
            }
        }
    },

    computed: {
        // Element排序
        sotedItem() {
            let items = [...this.caseInfoVosList]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.submitDate) - new Date(b.submitDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.submitDate) - new Date(a.submitDate)
                )
            }
            return items
        },

        sotedItemTwo() {
            let items = [...this.caseInfoVosDocumentList]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.submitDate) - new Date(b.submitDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.submitDate) - new Date(a.submitDate)
                )
            }
            return items
        }
    }
}
</script>
 
<style scoped lang="scss">
:deep(.el-timeline-item__timestamp) {
    display: flex;
}

.cardLayout {
    display: flex;
    width: 95%;

    :deep(.el-card__body) {
        width: 100%;
        display: flex;
        min-height: 50px;

        // height: 30px;
        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            padding-right: 10px;

            p {
                width: 100%;
                text-align: left;
                letter-spacing: 0.1em;
                line-height: 18px;
                text-indent: 2em;
                margin-top: -5px;
            }
        }

        .right {
            flex: 1;
            border-left: 1px dashed #ccc;
            padding-left: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;

            &:hover {
                cursor: pointer;
            }

            .el-dropdown-link {
                display: flex;
                align-items: center;

                div {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: red;
                    }
                }

                p {
                    // margin-left: 15px;
                    font-size: 14px;
                    max-width: 190px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.rightItem {
    &:hover {
        pointer-events: auto;
    }
}

.dropdownBox {
    display: flex;
    align-items: center;
}

:deep([class='circular']) {
    margin-top: 10%;
}

:deep(.el-tooltip__trigger) {
    display: flex;
    align-items: center;
}

:deep(.el-card__body .circular) {
    margin-top: 0;
}

.dropdownBoxRight {
    display: flex;

    .dropdownBoxRightItem {
        display: flex;

        strong {
            display: flex;
            align-items: center;
        }

        span {
            margin: 0 15px 0 4px;
            display: flex;
            align-content: center;
        }
    }
}

:deep(.butSpan .el-dropdown-link) {
    // cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep([class='el-dropdown-menu__item']) {
    display: flex;
    justify-content: space-between;
}

:deep(.el-collapse-item__header) {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    top: 0;
    padding: 0 20px;

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }

    // &::after {
    //     content: '';
    //     display: block;
    //     width: 0px;
    //     height: 2px;
    //     position: absolute;
    //     top: 100%;
    //     left: 15px;
    //     background-color: #50da4d;
    //     transition: all .6s;
    // }
}

.topTitle {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 40px;
}

:deep(.el-collapse-item__content) {
    padding: 20px;
}

.opacityShow {
    opacity: 0;
}

:deep(.el-card__body) {
    text-align: left;
}

.box-card {
    .top {
        padding: 0 5%;
        font-weight: 600;

        .box {
            padding: 0 1%;
        }
    }
}

.textPreStyle {
    padding-left: 2%;
    line-height: 20px;
    font-size: 18px;
    font-weight: 400;
}

pre {
    font-size: 18px;
}

.el-collapse {
    border-top: none;
}

:deep(.el-timeline-item__timestamp.is-top) {
    display: flex;
    font-size: 20px;
    color: #000;
}

:deep(.block .el-timeline-item__timestamp.is-top) {
    display: flex;
    font-size: 14px;
    color: #2b2525;
}

:deep(.el-collapse-item.is-disabled .el-collapse-item__header) {
    font-size: 14px;
}

:deep(.el-collapse-item__header) {
    font-size: 14px;
}

:deep(.el-timeline-item__tail) {
    border-left: 2px solid #368ffc;
}

:deep(.container .el-timeline-item__tail) {
    opacity: 0;
}

// :deep(.container .el-timeline-item__tail) {
//     opacity: 0;
// }

:deep(.block .el-timeline-item__tail) {
    opacity: 1;
}
</style>