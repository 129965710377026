<template>
    <div>
        <h1>NewDepartmengSet</h1>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    methods: {

    },
    created() { },
    mounted() { }
};
</script>
 
<style scoped ></style>