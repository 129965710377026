<template>
  <div>
    <HeaderForSign/>
    <div class="fl_wrapper">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('msg.not_sign')" name="1">
          <Common ref="c1" type="1"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.have_sign')" name="2">
          <Common ref="c2" type="2"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Common from "@/pages/scansignature/Common";
import HeaderForSign from "@/components/HeaderForSign";

export default {
  name: "FileList",
  components: {
    Common,
    HeaderForSign
  },
  created() {
    // 处理微信登录成功提示和重定向
    if (this.$route.query['authToken']) {
      this.$message.success('登录成功')
      this.$router.replace({path: '/sign_file_list'})
    }
    this.GetUserInfo()
    this.$nextTick(() => {
      this.$refs.c1.load('to_be_signed')
    })
  },
  data() {
    return {
      activeName: '1',
      username: 'loading...',
    }
  },
  methods: {
    handleClick(tab) {
      if (tab.index === '0') {
        this.$refs.c1.load('to_be_signed')
      }
      if (tab.index === '1') {
        this.$refs.c2.load('signed')
      }
    },
    async GetUserInfo() {
      const res = await this.$API.sendRequest('UserInfo')
      if (res) {
        this.username = res.data.realName || 'unknown'
      }
    },
  }
}
</script>

<style scoped lang="sass">
.fl_wrapper
  padding: 20px
  .el-form--inline
    text-align: left

</style>
