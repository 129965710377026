<template>
    <div class="CaseAudition">
        <el-table
            v-loading="loading"
            :data="list"
            border
            :stripe="true"
            style="width: 100%"
        >
            <el-table-column :label="$t('msg.case')" prop="caseNo" align="center">
            </el-table-column>

            <el-table-column :label="$t('msg.ApplicationNumber')" prop="applyNo" align="center">
            </el-table-column>

            <el-table-column :label="$t('msg.Applicant')" prop="partyCompany" align="center">
            </el-table-column>

            <el-table-column :label="$t('msg.case_create_time')" prop="submitTime" align="center">
            </el-table-column>

            <el-table-column :label="$t('msg.finder_table_update')" prop="updateTime" align="center">
            </el-table-column>

            <el-table-column
                :label="$t('msg.Acceptanceof')"
                prop="acceptOrganization"
                align="center"
            >
            </el-table-column>

            <el-table-column :label="$t('msg.Status')" prop="status" align="center">
            </el-table-column>

            <el-table-column :label="$t('msg.Operate')" prop="operation" align="center">
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <!-- <el-pagination
            style="margin-top: 10px; display: flex; justify-content: center"
            :total="pagination.total"
        >
        </el-pagination> -->
    </div>
</template>

<script>
export default {
    name: 'CaseAudition',
    data() {
        return {
            loading: false,
            case_id: '',
            list: [],
            pagination: {
                page: 1,
                pages: 0,
                limit: 20,
                sizes: [10, 20, 50, 100],
                total: 0
            }
        }
    },
    methods: {
        load(case_id) {
            this.case_id = case_id
            // this.GetArbitramentSignature()
        }

        // async GetArbitramentSignature() {
        //     const param = {
        //         "req": {
        //             "caseId": this.case_id,
        //         }
        //     }
        //     this.loading = true
        //     const res = await this.$API.sendRequest('ArbitramentSignature', param)
        //     this.loading = false
        //     if (res.success) {

        //     } else {
        //         this.$message.error(res.msg)
        //     }
        // },
    }
}
</script>

<style scoped></style>



