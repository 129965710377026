<template>
  <div class="header_wrapper">
    <div class="top">
      <div class="left">
        <img src="../assets/image/home/logo.png" alt="logo">
        <div class="company_name">
          <div>中国国际经济贸易仲裁委员会</div>
          <div>{{'China International Economic and Trade Arbitration Commission'.toUpperCase()}}</div>
        </div>
      </div>
      <div class="right">
        <span><img src="../assets/image/home/avatar.png" alt=""><span>{{username}}</span></span>
        <el-divider direction="vertical"/>
        <span @click="HandleLogout"><img src="../assets/image/home/logout.png" alt=""><span>{{ $t('msg.header_logout') }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  created() {
    this.GetUserInfo()
  },
  data() {
    return {
      username: 'loading...'
    }
  },
  methods: {
    // TODO 获取个人信息 vuex
    async GetUserInfo() {
      const res = await this.$API.sendRequest('UserInfo')
      if (res) {
        this.username = res.data.realName || 'unknown'
      }
    },
    HandleLogout() {
      const lang = localStorage.getItem('lang')
      this.$confirm(lang==='en'?'Confirm Logout?':'确认要退出登录？', lang==='en'?'Tip':'提示',
          {
            confirmButtonText: lang==='en'?'confirm':'确认',
            cancelButtonText: lang==='en'?'cancel':'取消',
            type: 'warning',
          }
      ).then(async () => {
        await this.$API.sendRequest('Logout')
        localStorage.removeItem('c_auth_token')
        await this.$router.replace({name: 'SignatureLogin'})
      }).catch(() => {})
    },
    GoSetting() {
      this.$router.push({name: 'Setting'})
    },
  }
}
</script>

<style scoped lang="sass">
.header_wrapper
  background: url("../assets/image/home/header_bg.png") no-repeat
  background-size: cover
  width: 100%
  height: 107px
  color: #fff

.top
  display: flex
  height: 50%
  justify-content: space-between
  padding: 15px 10px 0 10px
  color: rgba(255, 255, 255, 0.8)
  .left
    display: flex
    align-items: center
    margin-left: 14px
    img
      height: 100%
    .company_name
      text-align: left
      margin-left: 12px
      div:first-child
        font-size: 18px
        font-weight: 500
      div:last-child
        font-size: 12px
        font-weight: 400
  .right
    display: flex
    align-items: center
    margin-top: -12px
    margin-right: 20px
    span
      font-size: 14px
      cursor: pointer
      display: flex
      align-items: center
      font-weight: 500
      img
        height: 15px
        position: relative
        margin-right: 5px
        margin-bottom: 2px

.el-divider--vertical
  height: 10px
  margin: 0 13px

a
  text-decoration: none
.link
  border: 1px solid #7F96CD
  padding: 1px 10px
  border-radius: 3px
  color: rgba(255, 255, 255, 0.8)
  font-size: 13px
  position: relative
  top: -1px
</style>
