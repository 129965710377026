<template>
    <div>
        <div v-loading="submitLoading">
            <el-form ref="upload_form" label-width="70px">
                <el-form-item label="案件编号">
                    <el-input
                        v-model="caseNo"
                        size="default"
                        placeholder="请输入案件编号"
                        style="width: 400px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="文件名">
                    <el-input
                        v-model="saveAttName"
                        size="default"
                        placeholder="请输入文件名"
                        style="width: 400px"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form-item>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-button
                    type="primary"
                    @click="arbiratorSelect"
                    >查询</el-button
                >
            </el-form-item>
        </div>

        <el-table :data="tableData" border style="width: 100%">
            <el-table-column type="index" :index="indexMethod" label="序号">
            </el-table-column>
            <el-table-column label="签章日期" width="180">
                <template v-slot="scope">
                    <div v-if="scope.row.signatureDate != null">
                        {{ getDate(scope.row.signatureDate) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="caseNo" label="案件编号"> </el-table-column>
            <el-table-column prop="evidenceType" label="盖章文件内容概要">
            </el-table-column>
            <el-table-column prop="signType" label="用印类型">
            </el-table-column>
            <el-table-column prop="approveUserName" label="审批人">
            </el-table-column>
            <el-table-column prop="beginPage" label="份数"> </el-table-column>
            <el-table-column prop="departName" label="部门"> </el-table-column>
            <el-table-column prop="secName" label="经办人签名">
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column prop="saveAttName" label="文件名">
            </el-table-column>
            <el-table-column fixed="right" label="操作" key="slot">
                <template v-slot="scope">
                    <el-button
                        @click="handleClick(scope.row)"
                        type="text"
                        size="small"
                        >打开</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column prop="subDate" label="提交日期"> </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            style="margin-top: 10px; display: flex; justify-content: center"
            small="small"
            @size-change="paginationSizeChange"
            @current-change="paginationCurrentChange"
            :current-page="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size="pagination.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
        >
        </el-pagination>
        <!--
  <el-button type="primary" icon="el-icon-edit" circle @click="arbiratorSelect" >测试按钮</el-button>
  -->
    </div>
</template>

<script>
export default {
    name: 'ArbitratorSelect', //组件的名称
    created() {
        this.arbiratorSelect() //要执行的方法
    },
    data() {
        return {
            tableData: [],
            options: [],
            value: '',
            pagination: {
                page: 1,
                pages: 0,
                limit: 10,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            caseNo: '',
            saveAttName: ''
        }
    },
    methods: {
        paginationSizeChange(val) {
            this.pagination.limit = val
            this.arbiratorSelect()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.arbiratorSelect()
        },
        indexMethod(index) {
            return index + 1
        },
        getDate(v) {
            let oDate = new Date(v)
            console.log('BBBBBBB', oDate.getFullYear())
            return (
                oDate.getFullYear() +
                '-' +
                (oDate.getMonth() + 1) +
                '-' +
                oDate.getDate()
            )
        },
        handleClick(row) {
            console.log(row)
            //row.
        },
        async arbiratorSelect() {
            const res = await this.$API.sendRequest('scanSignatureApprove', {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: { caseNo: this.caseNo }
                }
            })
            //console.log("aaaaaaaaaa")
            console.log(res.data)
            if (res.success) {
                this.tableData = res.data.records || []
                this.pagination.total = res.data.total
            } else {
                this.$message.error(res.msg)
            }
            //this.options=res.data
        }
    }
}
</script>
