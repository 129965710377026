<template>
    <div class="cp_wrapper">
        <div class="sticky_bar">
            <!--      nodeDate// 中文日期-->
            <!--      nodeDateLocaleName// 英文日期-->
            <!--      info// 描述-->
            <!--      nodeState// 状态 1-已经过（绿色），2-正在进行（蓝色），3-未经过（灰色）-->
            <div v-for="(item, index) in list" :key="index">
                <span v-if="item.nodeState === 1" class="item success">{{
                    index + 1 + ' ' + item.name
                }}</span>
                <span v-if="item.nodeState === 2" class="item primary">{{
                    index + 1 + ' ' + item.name
                }}</span>
                <span v-if="item.nodeState === 3" class="item info">{{
                    index + 1 + ' ' + item.name
                }}</span>
            </div>
        </div>
        <div class="bottom">
            <div class="step_item" v-for="(item, index) in list" :key="index">
                <template v-if="item.nodeState === 1">
                    <strong class="left">{{ item.date }}</strong>
                    <span class="right">
                        <div class="line">
                            <span class="circle success">{{ index + 1 }}</span>
                            <span class="dash border_success"></span>
                        </div>
                        <div class="r_wrapper">
                            <div class="title font_success">
                                <h3>{{ item.name }}</h3>
                            </div>
                            <!-- 
                <div>{{item.info}} </div> 
                -->
                            <div v-html="item.info"></div>
                        </div>
                    </span>
                </template>
                <template v-if="item.nodeState === 2">
                    <strong class="left">{{ item.date }}</strong>
                    <span class="right">
                        <div class="line">
                            <span class="circle primary">{{ index + 1 }}</span>
                            <span class="dash border_primary"></span>
                        </div>
                        <div class="r_wrapper active">
                            <div class="title font_primary">
                                <h3>{{ item.name }}</h3>
                                <h5>{{ $t('msg.case_in_progress') }}</h5>
                            </div>
                            <!--
                <div>{{item.info}}</div>
                -->
                            <div v-html="item.info"></div>
                        </div>
                    </span>
                </template>
                <template v-if="item.nodeState === 3">
                    <strong class="left">{{ item.date }}</strong>
                    <span class="right">
                        <div class="line">
                            <span class="circle info">{{ index + 1 }}</span>
                            <span class="dash border_info"></span>
                        </div>
                        <div class="r_wrapper">
                            <div class="title font_info">
                                <h3>{{ item.name }}</h3>
                            </div>
                            <div>{{ item.info }}</div>
                        </div>
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>
  
  <script>
export default {
    name: 'CaseProgress',
    created() {
        this.$listener.$on('lang', () => {
            this.list = this.$GF.langChangeForProgress(this.list)
        })
    },
    data() {
        return {
            list: [],
            lang: 'zh',
            stlObj: {}
        }
    },
    methods: {
        load(case_id) {
            this.GetCP(case_id)
        },
        async GetCP(id) {
            const param = {
                req: {
                    caseId: id
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('CaseProgress', param)
            this.loading = false
            if (res && res.success) {
                this.list = this.$GF.langChangeForProgress(res.data)
            } else {
                this.$message.error(res.msg)
            }
        }
    }
}
</script>
  
  <style scoped lang="sass">
.cp_wrapper
    height: calc(100vh - 145px)
    overflow-y: scroll

.sticky_bar
    position: sticky
    top: 0
    margin-left: 22px
    text-align: left
    .item
        display: inline-block
        width: 150px
        color: #ffffff
        font-size: 14px
        padding: 5px
        margin: 0 1px 1px 0
        text-align: center
        height: 25px
        line-height: 25px

.bottom
    margin: 30px 20px
    padding-bottom: 30px
.primary
    background-color: rgb(68,120,255)
.success
    background-color: rgb(23,185,77)
.info
    background-color: rgb(219,219,219)

.step_item
    display: flex
.left
    min-width: 130px
    padding-top: 2px
.right
    display: flex
    width: 100%
    .active
        border: 1px solid rgb(68,120,255)
    .line
        display: flex
        flex-direction: column
        align-items: center
        margin-right: 15px
    .circle
        display: inline-block
        width: 22px
        height: 22px
        line-height: 22px
        border-radius: 50%
        color: #fff
    .dash
        height: 100%
        border-style: dashed
        border-width: 0
        border-left-width: 1px
        margin: 5px 0
    .title
        display: flex
        justify-content: space-between
    .r_wrapper
        background-color: rgb(248,250,255)
        padding: 0 15px 15px 15px
        text-align: left
        font-size: 14px
        margin-bottom: 10px
        border-radius: 3px
        width: 100%
    h3
        margin-block-start: 5px

.border_primary
    border-left-color: rgb(68,120,255)
.border_success
    border-left-color: rgb(23,185,77)
.border_info
    border-left-color: rgb(219,219,219)
.font_primary
    color: rgb(68,120,255)
.font_success
    color: rgb(23,185,77)
.font_info
    color: rgb(219,219,219)
</style>