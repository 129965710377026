<template>
    <div class="ds_wrapper" v-on:keyup.enter="submit">
        <div class="titleText">{{$t('msg.Registerofreceipt')}}</div>
        <div class="boxBorder">
            <div class="inputLayout">
                <div class="inputItem">
                    <span> {{$t('msg.case')}} </span>
                    <el-input
                        class="inputStyle"
                        v-model="inputFrom.caseNo"
                        :placeholder="$t('msg.Pleaseenterhecasenumber')"
                    ></el-input>
                </div>
                <br />
                <div class="inputBottom">
                    <span class="demonstration">{{$t('msg.DateofReceipt')}} </span>
                    <el-date-picker
                        style="width: 300px"
                        class="inputStyle"
                        v-model="inputFrom.receiveLetterDate"
                        type="date"
                        :placeholder="$t('msg.OptionDate')"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="inputLayout">
                <div class="inputItem">
                    <span> {{$t('msg.Subjectofreceipt')}} </span>
                    <el-input
                        class="inputStyle"
                        v-model="inputFrom.receiveLetterTitle"
                        :placeholder="$t('msg.Pleaseentertherecetitle')"
                    ></el-input>
                </div>
                <br />
                <div class="inputAnjian">
                    <span> {{$t('msg.Numberofdocumentsreceived')}}  </span>
                    <el-input
                        class="inputStyle"
                        @input="handleEdit"
                        v-model="inputFrom.receiveLetterAmount"
                        :placeholder="$t('msg.Pleaseenterthenumbereceived')"
                    ></el-input>
                </div>
            </div>
        </div>
        <div class="submit">
            <el-button type="primary" @click="submit">{{$t('msg.Confirm')}} </el-button>
        </div>
        <el-button @click="goDemoPages" style="display: none">
            路由跳转传参</el-button
        >
    </div>
</template>

<script>
export default {
    name: 'Receive',
    data() {
        return {
            inputFrom: {
                caseNo: '',
                receiveLetterTitle: '',
                receiveLetterDate: new Date(),
                receiveLetterAmount: '5'
            }
        }
    },
    methods: {
        goDemoPages() {
            // console.log(1)
            this.$router.push({
                name: 'DemoPages',
                params: {
                    code: 12,
                    text: '就离谱'
                }
            })
        },
        load() {
            this.inputFrom = {
                caseNo: '',
                receiveLetterTitle: '',
                receiveLetterDate: new Date(),
                receiveLetterAmount: '5'
            }
        },
        submit() {
            function add0(m) {
                return m < 10 ? '0' + m : m
            }
            function format(shijianchuo) {
                //shijianchuo是整数，否则要parseInt转换
                var time = new Date(shijianchuo) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var y = time.getFullYear()
                var m = time.getMonth() + 1
                var d = time.getDate()
                return y + '-' + add0(m) + '-' + add0(d)
            }
            // console.log(format(this.inputFrom.receiveLetterDate));
            const newDate = format(this.inputFrom.receiveLetterDate)
            this.inputFrom.receiveLetterDate =
                newDate === 'NaN-NaN-NaN' ? null : newDate
            let param = {
                req: {
                    caseNo: this.inputFrom.caseNo,
                    receiveLetterTitle: this.inputFrom.receiveLetterTitle,
                    receiveLetterDate: this.inputFrom.receiveLetterDate,
                    receiveLetterAmount: this.inputFrom.receiveLetterAmount
                }
            }
            const res = this.$API.sendRequest('DetailInput', param)
            res.then((result) => {
                if (result.success) {
                    // 正确结果 传递获取的参数
                    this.$emit('data', result.data)
                } else {
                    // 错误 提示错误信息
                    this.$message.error(result.msg)
                }
            })
        },
        // 在 Input 值改变时触发
        handleEdit(e) {
            let value = e.replace(/[^\d]/g, '') // 只能输入数字
            value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
            this.inputFrom.receiveLetterAmount = value
        }
    }
}
</script>

<style scoped >
.ds_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #0000f4; */
    overflow: hidden;
}

.boxBorder {
    display: flex;
    margin-top: 20px;
}

.inputStyle {
    width: 300px;
}

.inputAnjian,
.inputItem,
.inputBottom {
    width: 500px;
    height: 30px;
    line-height: 30px;
}

span {
    color: black;
    font-size: 15px;
    margin-right: 10px;
}

.submit {
    width: 100%;
    border-top: 1px solid #e5e5e5;
    margin-top: 30px;
    padding: 10px 20px 10px;
    text-align: center;
}

:deep(.el-button) {
    background-color: #79bbff;
}

/* .ds_wrapper {
  font-size: 14px;
}
.boxBorder {
  width: 100%;
  height: 300px;
  border: 1px solid red;
}
.titleText {
     margin-top: 10px;
    background-color: aqua;
    border-bottom: 1px solid #ccc;
  }

  .inputLayout {
    display: flex;
  }
  .inputLayout .inputItem {
    display: flex;
    flex: 1;
  }
  .inputLayout .inputItem span {
    width: 100px;
  }

  .inputAnjian {
    display: flex;
  } */
</style>



