<template>
    <div class="table_wrapper">
        <el-table
            v-loading="loading"
            :data="list"
            border
            :stripe="true"
            style="width: 100%"
        >
            <el-table-column
                :label="$t('msg.case_num')"
                prop="caseNo"
                align="center"
            ></el-table-column>
            <el-table-column
                :label="$t('msg.case_name')"
                prop="caseName"
                align="center"
            >
                <template v-slot="scope">
                    <el-link type="primary" @click="GoFinder(scope.row)">{{
                        scope.row.caseName
                    }}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('msg.case_status')"
                prop="caseStateStr"
                align="center"
            ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            style="margin-top: 10px; display: flex; justify-content: center"
            small="small"
            @size-change="paginationSizeChange"
            @current-change="paginationCurrentChange"
            :current-page="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size="pagination.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'TabList',
    data() {
        return {
            list: [],
            pagination: {
                page: 1,
                pages: 0,
                limit: 20,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            loading: false,
            searchModel: {
                case_num: ''
            }
        }
    },
    methods: {
        load(val) {
            this.searchModel.case_num = val
            this.GetCaseList()
        },
        paginationSizeChange(val) {
            this.pagination.limit = val
            this.GetCaseList()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.GetCaseList()
        },
        doSearch() {
            this.pagination.page = 1
            this.GetCaseList()
        },
        reset() {
            this.searchModel = {
                case_num: ''
            }
            this.list = []
        },
        async GetCaseList() {
            this.loading = true
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        caseId: null,
                        caseNo: this.searchModel.case_num,
                        state: null,
                        totalCount: 0
                    }
                }
            }
            const res = await this.$API.sendRequest('CaseList', param)
            if (res) {
                this.list = res.data.records
                this.pagination.total = res.data.total
                this.loading = false
            }
        },
        GoFinder(row) {
            this.$listener.$emit('case_change', row) // 触发MainContent case_change
        }
    }
}
</script>

<style scoped>
.table_wrapper {
    padding-bottom: 20px;
}
</style>
