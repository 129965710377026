<template>
    <!-- 申请人材料弹框 -->
    <div v-loading="loading">
        <el-form
            :model="info"
            class="partyForm"
            ref="partyFormRef"
            :rules="infoRules"
        >
            <!-- 添加申请人材料 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>{{$t('msg.AddApplicantMaterials')}}</h4>
                    <div class="cardTextBox">
                        <p>
                            1.
                            {{$t('msg.Whensubmittingevidensnotsubmphoto')}}
                        </p>
                        <p>
                            2.
                            {{$t('msg.Whenuploadingevidencefromevidencetoevidence')}}
                        </p>
                        <p>
                            3.
                           {{$t('msg.Iftheoriginalequiredinthearbitrationproceedings')}}
                        </p>
                    </div>

                    <el-divider></el-divider>
                    <!-- 材料名称  -->
                    <el-row style="margin-top: 20px">
                        <el-col :span="4"><p>{{$t('msg.EvidenceName')}}</p></el-col>
                        <el-col :span="20">
                            <el-form-item prop="profile">
                                <el-input
                                    v-model.trim="info.profile"
                                    placeholder="请输入证据名称！"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 证明内容 -->
                    <el-row>
                        <el-col :span="4"><p>{{$t('msg.EvidencePurpose')}}</p></el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <!-- 富文本编辑器  -->
                                <TinymceEditor
                                    :boxHeight="400"
                                    style="z-index=999"
                                    ref="TinymceEditorRef"
                                />
                                <!-- 富文本编辑器  -->
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-divider></el-divider>
                    <!-- 查看附件 -->
                    <el-row
                        style="margin-top: 20px"
                        v-if="fileNameDspType !== ''"
                    >
                        <el-col :span="4"><p>{{$t('msg.ViewAttachments')}}</p></el-col>
                        <el-col :span="20">
                            <el-button
                                type="text"
                                style="margin-top: -1.5vh"
                                @click="viewClick(fileInfoObj)"
                            >
                                {{ fileNameDspType }}</el-button
                            >
                        </el-col>
                    </el-row>

                    <el-divider></el-divider>
                    <!-- 上传按钮  -->
                    <el-row
                        style="margin-top: 20px"
                        v-if="itemInfo === undefined"
                    >
                        <el-col :span="4">
                            <p>{{$t('msg.UploadAttachment')}}</p>
                        </el-col>
                        <el-col :span="20">
                            <div style="display: flex; align-items: center">
                                <el-upload
                                    :file-list="fileList"
                                    class="upload-demo"
                                    action="#"
                                    :multiple="false"
                                    :auto-upload="false"
                                    :on-change="partyUploadProgress"
                                    :accept="acceptedFormats"
                                >
                                    <el-button>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-shenqing"
                                            ></use></svg
                                        >{{$t('msg.AddAttachment')}}</el-button
                                    >
                                </el-upload>
                                <span style="font-size: 14px; font-weight: 500">
                                    ({{$t('msg.Thesizeoftheuploadedfiledoenotexceed')}}{{ uploadSize }}MB)</span
                                >
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 上传文件进度 percentageNumType -->
            <el-row v-if="percentageNumType">
                <el-col :span="4">
                    <p>{{$t('msg.Uploadfileprogress')}}</p>
                </el-col>
                <el-col :span="20">
                    <div class="progressBox">
                        <el-progress
                            :percentage="percentageNum"
                            style="width: 50%"
                        />
                    </div>
                </el-col>
            </el-row>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn" v-if="itemInfo === undefined">
                    <el-button
                        type="primary"
                        @click="addPartySubmit"
                        :loading="butLoading"
                        >{{$t('msg.Save')}}</el-button
                    >
                </div>
            </el-row>
        </el-form>
    </div>
</template>
 
<script>
import axios from 'axios'
import TinymceEditor from '../../components/TinymceEditor.vue'
export default {
    name: 'materialDialog',
    components: {
        TinymceEditor
    },
    data() {
        return {
            // 表格数据
            tableData: [],
            // 表单对象
            info: {
                // 材料名称
                profile: ''
            },
            // 校验规则
            infoRules: {
                profile: [
                    {
                        required: true,
                        message: '请输入材料名称！',
                        trigger: 'blur'
                    }
                ]
            },

            myObj: {},

            // 上传文件数组
            fileList: [],
            // 自己选择上传的文件列表数组
            myMaterialList: [],
            // 文件上传格式
            acceptedFormats:
                '.git,.jpg,.jpeg,.bmp,.doc,.xls,.docx,.xlsx,.pdf,.zip,.rar',

            // 上传加载效果
            butLoading: false,

            // 展示附件内容行
            fileNameDspType: '',

            // 下载枚举
            downEnum: {
                '.gif': 'image/gif',
                '.jpg': 'image/jpeg',
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
                '.doc': 'application/msword',
                '.docx':
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                '.xlsx':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                '.pdf': 'application/pdf',
                '.rar': 'application/x-rar-compressed',
                '.zip': 'application/zip'
            },
            // 文件对象
            fileInfoObj: {},
            // 页面加载
            loading: false,
            // 当前文件状态
            itemInfo: {},
            // 上传进度条
            percentageNum: 0,
            percentageNumType: false,
            // 上传大小
            uploadSize: 500
        }
    },
    methods: {
        async load(caseObj, obj) {
            this.loading = true
            this.myObj = caseObj
            this.itemInfo = obj

            if (obj !== undefined) {
                let res = await this.getShowPreserveInfo(obj)
                this.$refs.TinymceEditorRef.myValue = res.remarks
                this.info = {
                    ...res
                }
            }
            this.loading = false
            this.fileNameDspType = ''
        },
        // 点击了反显
        async getShowPreserveInfo(obj) {
            let param = {
                req: obj.id
            }
            let res = await this.$API.sendRequest('ShowPreserveInfo', param)
            return res.data
        },

        // 点击保存
        addPartySubmit() {
            this.info.caseId = this.myObj.caseId
            this.info.remarks = this.$refs.TinymceEditorRef.myValue
            this.$refs.partyFormRef.validate(async (valid) => {
                if (valid) {
                    if (!this.info.remarks) {
                        return this.$message.info('请填写证明内容')
                    }
                    if (
                        this.itemInfo === undefined &&
                        this.fileList.length < 1
                    ) {
                        return this.$message.error('上传材料为必传')
                    }
                    // this.butLoading = true
                    let res = await this.uploadFile()
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.tableData = res.data
                        this.emitClose()
                        this.butLoading = false
                    } else {
                        this.$message.error(res.msg)
                        this.butLoading = false
                    }
                } else {
                    this.$message.error('请先填写完整表单！')
                }
            })
        },

        // 清空表单
        clearForm() {
            this.info = {}
            this.fileList = []
            this.myMaterialList = []
            this.$refs.partyFormRef.resetFields()
            this.$refs.TinymceEditorRef.myValue = ''
            this.percentageNum = 0
            this.percentageNumType = false
        },

        // 清空表单和校验
        emitClose() {
            this.$emit('materialDialogClose')
        },

        // 上传的change事件，fileList更改触发
        partyUploadProgress() {
            let uploadSize = this.uploadSize
            let type = this.fileList[0].size / 1024 / 1024 < uploadSize
            if (this.fileList.length === 1) {
                if (!type) {
                    this.fileList.splice(0, 1)
                    return this.$message.error(
                        '上传文件大小不能超过' + uploadSize + ' MB!'
                    )
                }
            }
            if (this.fileList.length > 1) {
                if (!type) {
                    this.fileList.splice(1, 1)
                    return this.$message.error(
                        '上传文件大小不能超过' + uploadSize + 'MB!'
                    )
                }
            }

            let newFileList = this.fileList
            this.fileList = []
            this.fileList = newFileList

            if (this.fileList.length > 1) {
                this.fileList.splice(0, 1)
            }
        },

        // 点击请求中-----》上传文件
        // 上传材料
        async uploadFile() {
            console.log(this.fileList[0].raw, 'ssssssssssss')
            this.percentageNumType = true
            let pdfType = this.$GF.pdfFlagFn(this.fileList[0].name)
            const formData = new FormData()
            formData.append('req.abbr', 'WS')
            formData.append('req.typeName', '')
            formData.append('req.profile', this.info.profile)
            formData.append('req.remarks', this.info.remarks)
            formData.append('req.caseId', this.myObj.caseId)
            formData.append('file', this.fileList[0].raw)
            formData.append('req.flagPdf', pdfType)
            formData.append('req.oldFileName', this.fileList[0].name)
            formData.append('req.typeId', 2)
            formData.append('req.receiveLetterNo', this.myObj.receiveNo)
            formData.append('req.partyType', 1)
            formData.append('req.materialFlag', 6)

            let myThis = this
            let res = await axios({
                url: '/api/ocrUse/ocrSaveUpload',
                method: 'POST',
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    if (percentCompleted < 100) {
                        // 可以在这里更新用户界面以显示上传进度
                        myThis.percentageNum = percentCompleted
                    } else {
                        // 可以在这里更新用户界面以显示上传进度
                        myThis.percentageNum = 99
                    }
                },
                headers: {
                    c_Auth_token: localStorage.getItem('c_Auth_toke')
                },
                data: formData
            })

            if (res.data.success) {
                return res.data
            } else {
                return res.data
            }
        },

        // 点击预览
        async viewClick(obj) {
            let param = {
                req: {
                    fileNameDsp: obj.fileNameDsp,
                    filePath: obj.filePath,
                    flagPdf: obj.flagPdf
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            const filename = obj.fileNameDsp
            let fileType = filename.substring(filename.lastIndexOf('.'))
            for (let key in this.downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: this.downEnum[key] })
                    let url = window.URL.createObjectURL(blob)
                    window.open(url)
                }
            }
        }
    }
}
</script>
 



<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-items: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
            margin-top: 0;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cardTextBox {
    margin-top: 20px;
    font-size: 15px;
    a {
        text-decoration: none;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;
        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}
:deep(.el-date-editor--date) {
    width: 100% !important;
}

.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.tableBox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

:deep(.el-icon--close) {
    display: none !important;
}
:deep(.el-upload-list__item-status-label) {
    display: none !important;
}

.cardTextBox {
    margin-top: 20px;
    a {
        text-decoration: none;
    }
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
}
</style>