<template>
    <div>
        <div class="container">
            <header>
                <el-row>
                    <el-col :span="3"> {{$t('msg.FileName')}} </el-col>
                    <el-col :span="6">
                        <el-input
                            :placeholder="$t('msg.PleaseenteraFileName')"
                            v-model="form.fileName"
                            @keydown.enter="clickSearch"
                        ></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3"> {{$t('msg.StartTime')}} </el-col>
                    <el-col :span="6">
                        <el-date-picker
                            style="width: 100%"
                            v-model="form.startDate"
                            type="date"
                            :placeholder="$t('msg.OptionDate')"
                        >
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3"> {{$t('msg.EndTime')}} </el-col>
                    <el-col :span="6">
                        <el-date-picker
                            style="width: 100%"
                            v-model="form.endDate"
                            type="date"
                            :placeholder="$t('msg.OptionDate')"
                        >
                        </el-date-picker>
                    </el-col>
                </el-row>

                <div class="searchBtn">
                    <el-button type="primary" @click="clickSearch"
                        >{{$t('msg.finder_search')}}</el-button
                    >
                </div>
            </header>
            <div class="contentBox">
                <el-table
                    :data="fileDetailList"
                    style="width: 100%"
                    v-loading="loading"
                    class="no-hover"
                    @row-click="clickRow($event)"
                >
                    <el-table-column
                        prop="attName"
                        :label="$t('msg.FileName')"
                        align="center"
                        style="min-heiht: 100px"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="actionLists"
                        :label="$t('msg.finder_upload')"
                        align="left"
                    >
                        <template #default="scope">
                            <div class="itemTable">
                                <el-table
                                    :show-header="false"
                                    :data="scope.row.actionLists['1']"
                                    style="width: 100%"
                                    class="no-hover"
                                >
                                    <el-table-column
                                        :label="$t('msg.FileName')"
                                        align="center"
                                        style="min-heiht: 100px"
                                    >
                                        <template #default="itemScope">
                                            <div
                                                v-html="itemScope.row.doDesc"
                                            ></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('msg.Review')" align="left">
                        <template #default="scope">
                            <div class="itemTable">
                                <el-table
                                    :show-header="false"
                                    :data="scope.row.actionLists['2']"
                                    style="width: 100%"
                                    class="no-hover"
                                >
                                    <el-table-column
                                        :label="$t('msg.FileName')"
                                        align="center"
                                        style="min-heiht: 100px"
                                    >
                                        <template #default="itemScope">
                                            <div
                                                v-html="itemScope.row.doDesc"
                                            ></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('msg.Download')" align="left">
                        <template #default="scope">
                            <div class="itemTable">
                                <el-table
                                    :show-header="false"
                                    :data="scope.row.actionLists['3']"
                                    style="width: 100%"
                                    class="no-hover"
                                >
                                    <el-table-column
                                        :label="$t('msg.FileName')"
                                        align="center"
                                        style="min-heiht: 100px"
                                    >
                                        <template #default="itemScope">
                                            <div
                                                v-html="itemScope.row.doDesc"
                                            ></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        prop=""
                        label="删除"
                        align="left"
               
                    >
                        <template #default>
                         
                            <el-table
                                :show-header="false"
                                :data="fileDetailList"
                                style="width: 100%"
                                v-loading="loading"
                                class="no-hover"
                            >
                                <el-table-column
                                    label="文件名称"
                                    align="center"
                                    style="min-heiht: 100px"
                                >
                                    <template #default="itemScope">
                                        <div
                                            v-if="itemScope.row.type === 1"
                                            class="tableItemSpan"
                                        >
                                            申:申请人<span>{{
                                                itemScope.row.name
                                            }}</span
                                            >于<span>{{
                                                itemScope.row.date
                                            }}</span
                                            >在ip为<span>{{
                                                itemScope.row.ipName
                                            }}</span
                                            >删除了一个文件名为<span>{{
                                                itemScope.row.fileName
                                            }}</span>
                                        </div>
                                        <div v-else class="tableItemSpan">
                                            被: 被申请人<span>{{
                                                itemScope.row.name
                                            }}</span
                                            >于<span
                                                >{{
                                                    itemScope.row.date
                                                }}在ip为{{
                                                    itemScope.row.ipName
                                                }}</span
                                            >删除了一个文件名为<span>{{
                                                itemScope.row.fileName
                                            }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column> -->
                </el-table>

                <!-- 分页 -->
                <!-- <el-pagination
                    style="
                        margin: 20px 0 20px 0;
                        display: flex;
                        justify-content: center;
                    "
                    small="small"
                    @size-change="paginationSizeChange"
                    @current-change="paginationCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size="pagination.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total"
                >
                </el-pagination> -->
            </div>
        </div>

        <!-- 从右往左开的抽屉 -->
        <el-drawer
            title="文件详情"
            v-model="drawerType"
            direction="rtl"
            :before-close="drawerClose"
            size="40%"
        >
            <div class="drawerContainerBox">
                <!-- 文件名 ip地址 -->
                <el-row>
                    <el-col :span="12">
                        <p>
                            <span>文件名：</span><i>{{ drawerObj.attName }}</i>
                        </p>
                    </el-col>
                    <el-col :span="12">
                        <p><span>上传IP：</span>http://192.168.100.215:8080/</p>
                    </el-col>
                </el-row>

                <!-- 上传人 上传时间 -->
                <el-row>
                    <el-col :span="12">
                        <p><span>上传人：</span>xxxxxxxxxx</p>
                    </el-col>
                    <el-col :span="12">
                        <p>
                            <span>上传时间：</span>http://192.168.100.215:8080/
                        </p>
                    </el-col>
                </el-row>

                <!-- 浏览人 浏览时间 -->
                <el-row>
                    <el-col :span="12">
                        <p><span>浏览人：</span>xxxxxxxxxx</p>
                    </el-col>
                    <el-col :span="12">
                        <p>
                            <span>浏览时间：</span>http://192.168.100.215:8080/
                        </p>
                    </el-col>
                </el-row>

                <!-- 、下载人 下载时间 -->
                <el-row>
                    <el-col :span="12">
                        <p><span>下载人：</span>xxxxxxxxxx</p>
                    </el-col>
                    <el-col :span="12">
                        <p>
                            <span>下载时间：</span>http://192.168.100.215:8080/
                        </p>
                    </el-col>
                </el-row>

                <!-- 删除人 删除时间 -->
                <el-row>
                    <el-col :span="12">
                        <p><span>删除人：</span>xxxxxxxxxx</p>
                    </el-col>
                    <el-col :span="12">
                        <p>
                            <span>删除时间：</span>http://192.168.100.215:8080/
                        </p>
                    </el-col>
                </el-row>
            </div>
        </el-drawer>
    </div>
</template>
 
<script>
export default {
    name: 'receiveLetterDisplay',

    data() {
        return {
            loading: false,
            list: [],
            fileDetailList: [],

            // 分页器的参数
            pagination: {
                page: 1,
                pages: 0,
                limit: 10,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            // 抽屉状态
            drawerType: false,
            // 点击得到的对象
            drawerObj: {},
            // 开始时间
            value1: 0,
            form: {
                fileName: '',
                startDate: '',
                endDate: ''
            }
        }
    },
    methods: {
        async load(caseId) {
            this.case_id = caseId
            this.form = {
                fileName: '',
                startDate: '',
                endDate: ''
            }
            this.GetFileList(caseId)
        },

        // 请求表格
        async GetFileList(caseId) {
            this.form.startDate = this.form.startDate
                ? this.$GF.formatTime(this.form.startDate, 'yyyy-MM-DD')
                : null
            this.form.endDate = this.form.endDate
                ? this.$GF.formatTime(this.form.endDate, 'yyyy-MM-DD')
                : null
            const param = {
                req: {
                    caseId,
                    ...this.form
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest(
                'DisplayDeliveryRecords',
                param
            )
            this.loading = false
            if (!res.success) {
                this.$message.error(res.msg)
            }

            this.fileDetailList = res.data
        },

        // paginationSizeChange(val) {
        //     this.pagination.limit = val
        //     this.GetFileList()
        // },
        // paginationCurrentChange(val) {
        //     this.pagination.page = val
        //     this.GetFileList()
        // },

        // 点击了表格的某一行
        clickRow(e) {
            this.drawerObj = e
            this.drawerType = false
        },
        // 关闭弹窗方法
        drawerClose() {
            this.drawerType = false
        },

        clickSearch() {
            this.GetFileList(this.case_id)
        }
    }
}
</script>
 
<style scoped lang='scss'>
.el-col-3 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchBtn {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.el-col {
    margin: 10px 0;
}

.el-col-6 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.el-col-24 {
    display: flex;
}

// 去掉eltable的hover效果
:deep(.el-table) tbody tr:hover > td {
    background-color: transparent !important;
}
:deep(.el-table) tbody tr > td {
    height: 50px;
}

.tableItemSpan {
    span {
        color: #409eff;
    }
}

:deep(.itemTable .el-table__inner-wrapper)::before {
    background-color: #fff;
}
</style>    