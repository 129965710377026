<template>
    <!-- 收文查询 -->

    <div>
        <!-- 顶部搜索开始 -->
        <div class="search" v-loading="searchLoadng">
            <el-form
                :inline="true"
                :model="receiveForm"
                class="demo-form-inline"
            >
                <el-row>
                    <el-col :span="5">
                        <!-- 收文函号 -->
                        <el-form-item :label="$t('msg.ReceiptletterNumber')" class="marginRight">
                            <el-input
                                :placeholder="$t('msg.ReceiptletterNumber')"
                                v-model="receiveForm.receiveLetterNo"
                            ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="5"
                        ><!-- 案件编号 -->
                        <el-form-item :label="$t('msg.case')">
                            <el-input
                                :placeholder="$t('msg.case')"
                                v-model="receiveForm.caseNo"
                            ></el-input> </el-form-item
                    ></el-col>
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="12">
                                {{$t('msg.StartTime')}}&nbsp;&nbsp;<el-date-picker
                                    v-model="startTime"
                                    type="date"
                                    :placeholder="$t('msg.OptionDate')"
                                    :disabled-date="disabledDateFn"
                                ></el-date-picker>
                            </el-col>
                            <el-col :span="12">
                                {{$t('msg.EndTime')}}&nbsp;&nbsp;<el-date-picker
                                    v-model="endTime"
                                    type="date"
                                    :placeholder="$t('msg.OptionDate')"
                                    :disabled-date="disabledDateFn"
                                ></el-date-picker
                            ></el-col>
                        </el-row>
                        <!-- 时间 -->
                        <el-form-item> </el-form-item>
                    </el-col>
                    <el-col :span="4"></el-col>
                </el-row>

                <el-row>
                    <el-col :span="5">
                        <!-- 待立案编号 -->
                        <el-form-item :label="$t('msg.Tobefiledno')">
                            <el-input
                                :placeholder="$t('msg.Tobefiledno')"
                                v-model="receiveForm.otherNo"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <!-- 来函人单位 -->
                        <el-form-item :label="$t('msg.CorrespondenceAffiliation')">
                            <el-input
                                :placeholder="$t('msg.CorrespondenceAffiliation')"
                                v-model="receiveForm.senderCompany"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <!-- 来函方式 -->
                        <el-form-item :label="$t('msg.Methodofletter')">
                            <el-select
                                :placeholder="$t('msg.Pleasechooseheetter')"
                                v-model="receiveForm.receiveType"
                            >
                                <el-option
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                    v-for="(item, index) in receiveTypeList"
                                    :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <!-- 函件种类 -->
                        <el-form-item :label="$t('msg.Typeofletter')">
                            <el-select
                                :placeholder="$t('msg.Pleaseselectthetyetter')"
                                v-model="receiveForm.materialStyle"
                            >
                                <el-option
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                    v-for="(item, index) in materialStyleList"
                                    :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4"></el-col>
                </el-row>

                <!-- 操作按钮 -->
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="queryClick"
                        style="background-color: #79bbff"
                    >
                        <el-icon class="el-icon--right"> <Search /> </el-icon
                        >{{$t('msg.case_search')}}
                    </el-button>
                    <el-button @click="resettingClick"> {{$t('msg.case_reset')}} </el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 顶部搜索结束 -->
        <el-divider content-position="right"></el-divider>

        <!-- 表格开始 -->
        <el-card class="box-card">
            <div v-loading="tableLoading">
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    @cell-dblclick="handleCellDblClick"
                >
                    <el-table-column
                        prop="receiveLetterNo"
                        :label="$t('msg.ReceiptletterNumber')"
                        min-width="11%"
                    >
                        <template #default="scope">
                            <div>
                                <span
                                    class="itemSpan"
                                    @click="receiveNoClick(scope.row)"
                                    >{{ scope.row.receiveLetterNo }}</span
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="caseNo"
                        :label="$t('msg.case')"
                        sortable
                        min-width="11%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="receiveLetterDate"
                        :label="$t('msg.DateofReceipt')"
                        sortable
                        min-width="8%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="secretaryName"
                        :label="$t('msg.FilingSecretary')"
                        min-width="10%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        :label="$t('msg.CaseSecretary')"
                        min-width="10%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="senderCompany"
                        :label="$t('msg.Unitofletter')"
                        min-width="20%"
                    >
                        <template #default="scope">
                            <div>
                                {{ scope.row.senderCompany }}
                                {{ scope.row.otherNo }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="dictionaryItemName"
                        :label="$t('msg.Typeofcorrespondence')"
                        min-width="20%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="setting"
                        :label="$t('msg.Operate')"
                        min-width="10%"
                    >
                        <template #default="scope">
                            <div class="butItemColor">
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="goReceiveDetailForm(scope.row)"
                                    >{{$t('msg.Revamp')}}</el-button
                                >
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="paginationStyle">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
        <!-- 表格结束 -->

        <!-- 点击案件编号显示弹出框开始 -->
        <el-dialog
            v-model="dialogFormVisible"
            title="收文详情"
            width="60%"
            center
        >
            <el-form
                :model="receiveDetail"
                label-width="100px"
                style="paddig-right: 50px"
            >
                <!-- 第一行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <div style="width: 100%; height: 100%">
                            <el-col :span="10" class="fontWeight"
                                >{{$t('msg.ReceiptletterNumber')}} ：
                            </el-col>
                            <el-col :span="14">{{
                                receiveDetail.receiveLetterNo
                            }}</el-col>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.case')}} ：
                        </el-col>
                        <el-col :span="14">{{ receiveDetail.caseNo }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第二行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.Subjectofreceipt')}}  ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.receiveLetterTitle
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.Numberofdocumentsreceived')}} ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.receiveLetterAmount
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第三行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.DateofReceipt')}} ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.receiveLetterDate
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.MaterialType')}}  ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.materialStyleName
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第四行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.ModeofCommunication')}}  ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.receiveTypeName
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="8" class="fontWeight"
                            >{{$t('msg.Nameofthewriter')}}  ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderName
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第五行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.CorrespondenceAffiliation')}} ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderCompany
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.PostalCode')}} ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderZipcode
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第六行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Nationality')}} ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderNationalityName
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.PhoneNumber')}}  ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderPhone
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第七行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Province')}}  ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderProvinceName
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Fax')}}  ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderFax
                        }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第八行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.register_address')}} ： </el-col>
                        <el-col :span="14">{{
                            receiveDetail.senderAddress
                        }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Secretary')}} ： </el-col>
                        <el-col :span="14">{{ receiveDetail.secName }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第九行 -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="24">
                        <el-col :span="10" class="fontWeight"
                            >{{$t('msg.FileDescription')}} ：
                        </el-col>
                        <el-col :span="14">{{
                            receiveDetail.letterContent
                        }}</el-col>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="closeDialog">{{$t('msg.back')}}</el-button>
            </template>
        </el-dialog>
        <!-- 点击案件编号显示弹出框结束 -->
    </div>
</template>

<script>
// import {Plus} from '@element-plus/icons-vue'
export default {
    data() {
        return {
            // 来函方式数组
            receiveTypeList: [],
            // 函件种类数组
            materialStyleList: [],
            // 时间选择器日期快捷

            // 时间
            // pickerDate: [],

            // 表格列表
            tableData: [],
            startTime: '',
            endTime: '',
            // 收集数据
            receiveForm: {
                materialStyle: 0, //函件类型     全部是0
                receiveLetterNo: '', //收文函号
                caseNo: '', //案件编号
                otherNo: '', //待立案编号
                receiveType: 0, // 来函人方式
                senderCompany: '' // 来函人单位
            },
            // 表格loading效果
            tableLoading: false,
            // 搜索框的loading效果
            searchLoadng: false,
            // 当前页数
            current: 1,
            // 分页器默认显示当前条数
            pageSize: 100,
            // 总条数
            total: 0,
            // 弹出框状态
            dialogFormVisible: false,
            receiveDetail: {}
        }
    },
    methods: {
        load() {
            this.tableLoading = true
            this.searchLoadng = true
            ;(this.receiveForm = {
                materialStyle: 0, //函件类型     全部是0
                receiveLetterNo: '', //收文函号
                caseNo: '', //案件编号
                otherNo: '', //待立案编号
                receiveType: 0, // 来函人方式
                senderCompany: '', // 来函人单位
                startTime: '',
                endTime: ''
            }),
                this.styleTypeList()
            this.listReceive()
        },
        // 获取下拉框数据
        async styleTypeList() {
            let param = {
                req: '30'
            }
            let res = await this.$API.sendRequest('styleTypeList', param)
            let param1 = {
                req: '33'
            }
            let res1 = await this.$API.sendRequest('styleTypeList', param1)
            this.materialStyleList = res.data.materialStyleList
            this.materialStyleList.unshift({
                dictionaryItemValue: 0,
                dictionaryItemName: '全部'
            })
            this.receiveTypeList = res1.data.materialStyleList
            this.receiveTypeList.unshift({
                dictionaryItemValue: 0,
                dictionaryItemName: '全部'
            })
            this.searchLoadng = false
        },
        // 根据参数获取列表数据
        async listReceive() {
            this.tableLoading = true
            let param = {
                req: {
                    current: this.current,
                    size: this.pageSize,
                    q: {
                        startDate:
                            this.$GF.formatTime(
                                this.startTime,
                                'yyyy-MM-DD'
                            ) === 'NaN-NaN-NaN'
                                ? '1000-01-01'
                                : this.$GF.formatTime(
                                      this.startTime,
                                      'yyyy-MM-DD'
                                  ),
                        endDate:
                            this.$GF.formatTime(this.endTime, 'yyyy-MM-DD') ===
                            'NaN-NaN-NaN'
                                ? ''
                                : this.$GF.formatTime(
                                      this.endTime,
                                      'yyyy-MM-DD'
                                  ),
                        ...this.receiveForm
                    }
                }
            }
            const res = await this.$API.sendRequest('ListReceive', param)
            this.tableData = res.data.records
            this.total = res.data.total
            this.tableLoading = false
        },
        // 点击查询
        async queryClick() {
            this.tableLoading = true
            this.current = 1
            await this.listReceive()
            this.tableLoading = false
        },
        // 点击重置按钮
        async resettingClick() {
            this.receiveForm = {
                materialStyle: 0, //函件类型     全部是0
                receiveLetterNo: '', //收文函号
                caseNo: '', //案件编号
                otherNo: '', //待立案编号
                receiveType: 0, // 来函人方式
                senderCompany: '' // 来函人单位
            }
            this.startTime = ''
            this.endTime = ''
            this.current = 1
            this.pageSize = 100
            this.listReceive()
        },
        // 弹出框关闭
        closeDialog() {
            this.dialogFormVisible = false
        },
        // 点击案件编号弹出框
        async receiveNoClick(row) {
            let param = {
                req: row.receiveLetterId
            }
            let res = await this.$API.sendRequest('GetReceiveDetail', param)
            this.receiveDetail = res.data
            this.dialogFormVisible = true
        },

        // 分页器功能 --- 每页条数发生变化时触发
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val
            this.current = 1
            this.listReceive()
        },
        // 分页器功能 --- 当前页数发生变化时触发
        handleCurrentChange(val) {
            // console.log(`当前页 ${val} 条`);
            // this.pageSize = 1
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.total / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.pageSize > pageMax) {
                this.pageSize = pageMax
            }
            this.listReceive()
        },
        // 点击修改跳到详情页
        goReceiveDetailForm(row) {
            this.$emit('modifyDetail', row.receiveLetterNo)
        },
        // 时间组件  默认打开是当前月份和上一月
        changeInitCalendarRange() {
            let leftBut = document.querySelectorAll('.arrow-left')
            leftBut[6].click()
            this.$nextTick(() => {
                leftBut[7].click()
            })
        },
        // 时间组件设置当天以后不能选择
        disabledDateFn(time) {
            if (time.getTime() > Date.now()) {
                return true
            } else {
                return false
            }
        },

        // 双击复制收文函号
        async handleCellDblClick(row) {
            let value = row.receiveLetterNo
            await this.$copyText(value)
                .then(() => {
                    this.$message.success('已复制' + value)
                })
                .catch(() => {
                    this.$message.error('复制失败')
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.marginRight {
    :deep(label) {
        margin-right: 12px;
    }
}

.paginationStyle {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.borderLine {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.fontWeight {
    font-weight: 600;
}

:deep(.butItemColor .el-button) {
    background-color: #79bbff;
}

.el-col-12 {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
}

.itemSpan {
    color: blue;
    cursor: pointer;
}
</style>