<template>
    <div class="case_wrapperReceiveConfigList">
        <div class="left">
            <SideMenu
                :menu_tree="menu_tree"
                @change="handleMenuChange"
                @toggle="handleToggle"
            />
        </div>
        <!-- 面包屑 -->
        <el-breadcrumb
            id="breadReceiveConfigList"
            separator-icon="ArrowRight"
            style="
                position: absolute;
                left: 256px;
                background-color: rgb(236, 236, 236);
                padding: 10px 5px;
                width: 100%;
            "
        >
            <el-breadcrumb-item :to="{ path: '/' }" @click="BackToCase"
                >当前位置：{{ currentPath }}</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item>{{ $t('msg.bread_current')}}：{{ $t('msg.case_list')}}</el-breadcrumb-item> -->
            <el-breadcrumb-item v-if="caseNo">{{
                $t('msg.case') + ':' + caseNo
            }}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 收发管理 开始-->
        <div
            class="right_wrapper"
            id="receive"
            v-show="right_type === 'receive'"
        >
            <Receive ref="rs" @data="handleData" />
        </div>
        <div
            class="right_wrapper"
            id="receiveDetail"
            v-show="right_type === 'receiveDetail'"
        >
            <ReceiveDetail
                :datas="receiveDetailInfo"
                ref="receiveDetail"
                @data="receiveData"
                @goQuery="goQuery"
            />
        </div>
        <!-- 收文登记 -->
        <div
            class="right_wrapper"
            id="ReceiveType"
            v-show="right_type === 'receiveType'"
        >
            <ReceiveType
                :codeURLData="receiveTypeData"
                @copyDetail="copyDetail"
            ></ReceiveType>
        </div>
        <!-- 电子卷宗 -->
        <div
            class="right_wrapper"
            id="CaseFiles"
            v-show="right_type === 'caseFiles'"
        >
            <CaseFiles ref="CaseFiles" @goPage="goPage"></CaseFiles>
        </div>
        <!-- 时间流程图页 -->
        <div
            class="right_wrapper"
            id="CaseFiles_L2"
            v-show="right_type === 'timeLine'"
        >
            <CaseFiles_L2 ref="timeLine" @goPage="goPage"></CaseFiles_L2>
        </div>
        <!-- 时间流程图页 -->
        <div
            class="right_wrapper"
            id="CaseFiles_L3"
            v-show="right_type === 'CaseFiles_L3'"
        >
            <CaseFiles_L3 ref="CaseFiles_L3" @goPage="goPage"></CaseFiles_L3>
        </div>
        <!-- 事件流程图页 -->
        <div
            class="right_wrapper"
            id="CaseFiles_L1"
            v-show="right_type === 'eventGraph'"
        >
            <CaseFiles_L1 ref="eventGraph" @goPage="goPage"></CaseFiles_L1>
        </div>
        <!-- 事件详情日志页 -->
        <div
            class="right_wrapper"
            id="CaseDetail"
            v-show="right_type === 'CaseDetail'"
        >
            <CaseDetail ref="CaseDetail"></CaseDetail>
        </div>
        <!-- OCR识别Demo -->
        <div
            class="right_wrapper"
            id="OCRTemp"
            v-show="right_type === 'OCRTemp'"
        >
            <OCRTemp ref="OCRTemp"></OCRTemp>
        </div>
        <!-- 收发查询 -->
        <div
            class="right_wrapper"
            id="receiveQuery"
            v-show="right_type === 'receiveQuery'"
        >
            <ReceiveQuery
                ref="receiveQuery"
                @modifyDetail="modifyDetail"
            ></ReceiveQuery>
        </div>
        <!-- 收发统计 -->
        <div
            class="right_wrapper"
            id="receiveStatistics"
            v-show="right_type === 'receiveStatistics'"
        >
            <ReceiveStatistics
                ref="receiveStatistics"
                @ListMaterialdata="ListMaterialdata"
            ></ReceiveStatistics>
        </div>
        <div
            class="right_wrapper"
            id="receiveStatisticsL1"
            v-show="right_type === 'receiveStatisticsL1'"
        >
            <ReceiveStatisticsL1
                ref="receiveStatisticsL1"
                :listMaterialListRtn="listMaterialListRtn"
            ></ReceiveStatisticsL1>
        </div>
        <!-- 案件检索 -->
        <div
            class="right_wrapper"
            id="CaseSearch"
            v-show="right_type === 'CaseSearch'"
        >
            <CaseSearch ref="CaseSearch"></CaseSearch>
        </div>
        <!-- 快递单录入 -->
        <div
            class="right_wrapper"
            id="ReceiveScanCode"
            v-show="right_type === 'ReceiveScanCode'"
        >
            <ReceiveScanCode ref="ReceiveScanCode"></ReceiveScanCode>
        </div>
        <!-- 扫码检索 -->
        <div
            class="right_wrapper"
            id="scanCodeSearch"
            v-show="right_type === 'scanCodeSearch'"
        >
            <ScanCodeSearch ref="scanCodeSearch"></ScanCodeSearch>
        </div>
        <!-- 案件查询 -->
        <div
            class="right_wrapper"
            id="caseSearchQuery"
            v-show="right_type === 'caseSearchQuery'"
        >
            <CaseSearchQuery ref="caseSearchQuery"></CaseSearchQuery>
        </div>

        <!-- 收发管理 结束-->

        <div class="right_wrapper" id="empty" v-show="right_type === 'empty'">
            <el-empty description="没有数据" style="margin: 50px auto" />
        </div>
    </div>
</template>

<script>
//新增菜单，第一步在template中曾加div显示菜单。第二步在import此处导入自己新建的组件，第三步，在mothods增加方法
import SideMenu from '@/components/SideMenu'
// 点击登录识别
import Receive from '@/pages/receive/Receive'
// 登记成功
import ReceiveType from '@/pages/receive/ReceiveType.vue'
// 电子卷宗
import CaseFiles from '@/pages/receive/CaseFiles.vue'
// 时间流程图页面页面
import CaseFiles_L2 from '@/pages/receive/CaseFiles_L2.vue'
// 事件流程图事件
import CaseFiles_L1 from '@/pages/receive/CaseFiles_L1.vue'
// 电子卷宗---》案情
import CaseFiles_L3 from '../pages/receive/CaseFiles_L3.vue'
// 电子卷宗页进入事件日志
import CaseDetail from '@/pages/receive/CaseDetail.vue'
// 登记的详情输入页面
import ReceiveDetail from '@/pages/receive/ReceiveDetail.vue'
// demo页面
import ReceiveQuery from '@/pages/receive/ReceiveQuery.vue'
// ocr临时使用页面
import OCRTemp from '@/pages/receive/OCRTemp.vue'
//收文统计
import ReceiveStatistics from '@/pages/receive/ReceiveStatistics.vue'
//收文统计明细列表
import ReceiveStatisticsL1 from '@/pages/receive/ReceiveStatistics_L1.vue'
// 案件检索
import CaseSearch from '@/pages/receive/CaseSearch.vue'
//快递单录入
import ReceiveScanCode from '@/pages/receive/ReceiveScanCode.vue'
// 扫码检索
import ScanCodeSearch from '@/pages/receive/ScanCodeSearch.vue'
// 案件查询
import CaseSearchQuery from '@/pages/receive/CaseSearchQuery.vue'

//import ArbitratorSelect from "@/components/arbitrator/ArbitratorSelect";
//在export中别忘了新加的菜单项
export default {
    name: 'ReceiveConfigList',
    // 注册组件
    components: {
        SideMenu,
        Receive,
        ReceiveDetail,
        ReceiveType,
        CaseFiles,
        ReceiveQuery,
        OCRTemp,
        ReceiveStatistics,
        ReceiveStatisticsL1,
        CaseFiles_L2,
        CaseFiles_L1,
        CaseFiles_L3,
        CaseSearch,
        CaseDetail,
        ReceiveScanCode,
        ScanCodeSearch,
        CaseSearchQuery
    },
    created() {
        // this.GetCaseTabs()
        // 监听点击案件名称事件 设置当前case_id

        // 事件总线$listener  意识是其他地方使用this.$listener.$emit（'case_change'）就会触发这个事件
        this.$listener.$on('case_change', (row) => {
            this.right_type = 'file'
            this.$nextTick(() => {
                this.$refs.finder?.load(row.caseId)
            })
            this.case_id = row.caseId
            this.caseNo = row.caseNo
        }),
            this.$listener.$on('lang', () => {
                this.case_tabs = this.$GF.langChangeForTab(this.case_tabs)
                this.activeName = '0'
            }),
            this.$listener.$on('goAddDetail', () => {
                // this.right_type = 'receiveDetail'
                // this.$refs.receiveDetail.load()
                // this.$refs.receiveDetail.clickUpdataData()
                // this.$refs.receiveDetail.receiveLetterInfo.receiveLetterAmount = 5
                // this.$refs.receiveDetail.materialStyleText = '申请书及证据材料'
                // this.$refs.receiveDetail.checkListText = []
                this.right_type = 'receive'
                this.$nextTick(() => {
                    this.$refs.rs.load()
                })
                // this.load()
                //  this.$refs.receiveDetail.load()
            })
    },
    props: {
        menu_tree: {
            type: Array,
            default() {
                return []
            }
        },
        activeId: {
            type: String
        }
    },
    data() {
        return {
            cur: '1',
            case_num: '',
            activeName: '我的案件',
            dialogFormVisible: false,
            bindModel: {
                bindCode: '',
                nation: '',
                register_type: ''
            },
            bindRules: {
                bindCode: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ]
            },
            case_tabs: [],
            tab_loading: false,
            show_result: false,
            right_type: 'case', // case、file、submit、progress、uploadOperationLog、arbitramentSignature、declarationSignature、empty
            finderOptions: {
                lang: false
            },
            case_id: '',
            refList: [],
            caseNo: '',
            receiveDetailInfo: {},
            // 登记状态页+二维码页面
            receiveTypeData: '',
            currentPath: '收文登记',
            listMaterialListRtn: []
        }
    },
    methods: {
        load() {
            // this.GetCaseTabs()
            this.right_type = 'receive'
            this.caseNo = ''
        },
        handleMenuChange(menu_index) {
            console.log(menu_index)
            // 点击案件后的菜单
            //经办人菜单
            if (menu_index === 'materialSubmitLog') {
                // 材料提交
                this.right_type = 'submit'
                this.$refs.mu.load(this.case_id)
            } else if (menu_index === 'materialManage') {
                // 文件管理器
                this.right_type = 'file'
                this.$refs.finder.load(this.case_id)
            } else if (menu_index === 'myCaseList') {
                // 案件列表
                this.right_type = 'case'
            } else if (menu_index === 'caseProgress') {
                // 案件进程
                this.right_type = 'progress'
                this.$refs.cp.load(this.case_id)
            } else if (menu_index === 'uploadOperationLog') {
                // 上传操作日志
                this.right_type = 'uploadOperationLog'
                this.$refs.up.load(this.case_id)
            }

            //收发菜单
            else if (menu_index === 'receive') {
                //收文登记
                this.right_type = 'receive'
                this.$refs.rs.load(this.case_id)
                this.$refs.receiveDetail.goQuery = false
                this.$refs.receiveDetail.load()
            } else if (menu_index === 'caseFiles') {
                //电子卷宗
                this.currentPath = '电子卷宗'
                this.right_type = 'caseFiles'
                this.$refs.CaseFiles.load()
            } else if (menu_index === 'receiveQuery') {
                //收发查询
                this.right_type = 'receiveQuery'
                this.$refs.receiveQuery.load(this.case_id)
                this.currentPath = '收文查询'
            } else if (menu_index === 'OCRTemp') {
                //OCR弹窗demo
                this.right_type = 'OCRTemp'
                this.$refs.OCRTemp.load(this.case_id)
                this.currentPath = 'OCR弹窗'
            } else if (menu_index === 'receiveStatistics') {
                //收文统计
                this.right_type = 'receiveStatistics'
                this.$refs.receiveStatistics.load(this.case_id)
                this.currentPath = '收文统计'
            } else if (menu_index === 'caseSearch') {
                this.right_type = 'CaseSearch'
                this.$refs.CaseSearch.load(this.case_id) //案件检索
                this.currentPath = '案件检索'
            } else if (menu_index === 'ReceiveScanCode') {
                this.right_type = 'ReceiveScanCode'
                this.$refs.ReceiveScanCode.load(this.case_id) //快递单录入
                this.currentPath = '快递单录入'
            } else if (menu_index === 'scanCodeSearch') {
                this.right_type = 'scanCodeSearch'
                this.$refs.scanCodeSearch.load(this.case_id) // 扫码检索
                this.currentPath = '扫码检索'
            } else if (menu_index === 'caseSearchQuery') {
                this.right_type = 'caseSearchQuery'
                this.$refs.caseSearchQuery.load(this.case_id) //案件查询
                this.currentPath = '案件查询'
            } else {
                this.$message.warning('资源不存在')
                this.right_type = 'empty'
            }
        },
        handleData(data) {
            // 子传参到父组件
            this.receiveDetailInfo = data
            this.right_type = 'receiveDetail'
            this.$refs.receiveDetail.load()
        },
        receiveData(data) {
            // 子传参到父组件
            this.receiveTypeData = data
            // console.log(this.receiveData);
            this.right_type = 'receiveType'
        },
        ListMaterialdata(data) {
            this.listMaterialListRtn = data
            this.right_type = 'receiveStatisticsL1'
            this.$nextTick(() => {
                this.$refs.receiveStatisticsL1.load()
            })
        },
        openBind() {
            this.dialogFormVisible = true
        },
        async GetCaseTabs() {
            this.tab_loading = true
            const res = await this.$API.sendRequest('CaseTabs')
            if (res) {
                this.case_tabs = this.$GF.langChangeForTab(res.data)
                this.activeName = '0'
                await this.$nextTick(() => {
                    this.refList[0].load(
                        this.case_tabs[0].sign,
                        this.case_tabs[0].caseCount
                    )
                })
            }
            this.tab_loading = false
        },
        cancel() {
            this.dialogFormVisible = false
            this.$refs.bindModel.resetFields()
        },
        confirm() {
            this.$refs.bindModel.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: {
                            bindCode: this.bindModel.bindCode
                        }
                    }
                    const res = await this.$API.sendRequest('BindCase', param)
                    if (res && res.success) {
                        this.$message.success('绑定成功')
                        this.cancel()
                        // this.refList[0].load(1)
                        this.load()
                    } else {
                        this.$message.error(res.msg)
                    }
                }
            })
        },
        handleSearch() {
            const lang = localStorage.getItem('lang')
            if (!this.case_num)
                return this.$message.warning(
                    lang === 'en'
                        ? 'Please enter the case number'
                        : '请输入案件编号'
                )
            this.show_result = true
            this.$refs.sr.load(this.case_num)
        },
        hideResult() {
            this.show_result = false
            this.$refs.sr.reset()
        },
        // 右侧宽度自适应
        handleToggle(val) {
            console.log(val, '右侧宽度')
            const p_width = document.querySelector(
                '.case_wrapperReceiveConfigList'
            ).offsetWidth
            const id = `#${this.right_type}`
            if (val) {
                document.querySelector(id).style.width = p_width - 10 + 'px'
                document.querySelector('#breadReceiveConfigList').style.width =
                    p_width - 10 + 'px'
                document.querySelector('#breadReceiveConfigList').style.left =
                    '70px'
            } else {
                document.querySelector(id).style.width = p_width - 258 + 'px'
                document.querySelector('#breadReceiveConfigList').style.width =
                    p_width - 258 + 'px'
                document.querySelector('#breadReceiveConfigList').style.left =
                    '256px'
            }
        },
        setItemRef(el) {
            this.refList.push(el)
        },
        // 顶上面包屑点击事件
        BackToCase() {
            this.$emit('back')
            this.load()
            this.caseNo = ''
        },
        // 收文详情复制
        copyDetail(res) {
            this.right_type = 'receiveDetail'
            this.$refs.receiveDetail.copyDetail(res)
        },
        // 在收文统计中进入收文查询详情页
        modifyDetail(res) {
            this.$refs.receiveDetail.goQuery = true
            this.right_type = 'receiveDetail'
            this.$refs.receiveDetail.load()
            this.$refs.receiveDetail.partyCompanyObj = {
                success: true,
                msg: ''
            }
            this.$refs.receiveDetail.formLoading = true
            this.$refs.receiveDetail.modifyDetail(res)
        },
        goQuery() {
            this.$refs.receiveQuery.listReceive()
            this.right_type = 'receiveQuery'
        },
        // 根据事件跳转到指定的页面-----》重要   row:传递参数   start:要跳转的right_type
        // 根据事件跳转到指定的页面-----》重要   row:传递参数
        // 根据事件跳转到指定的页面-----》重要   row:传递参数
        goPage(row = '', start) {
            this.right_type = start
            this.$refs[start].load(row)
        }
    },
    watch: {
        case_num: function (val) {
            if (val === '') this.show_result = false
        },
        activeId: function (val) {
            if (val === '15') {
                this.right_type = 'case'
            } else if (val === '16') {
                this.right_type = 'receive'
            }
        }
    }
}
</script>

<style scoped lang="sass">
.case_wrapperReceiveConfigList
    display: flex
    gap: 5px
    padding: 2px
.right_wrapper
    width: 100%
    //height: calc(100vh - 145px)
    margin-top: 40px
    ::v-deep(.el-breadcrumb__inner)
        color: #7F96CD!important
    .search
        height: 80px
        box-shadow: 0 0 2px 1px #E1EAFA
        display: flex
        font-size: 13px
        align-items: center
        padding: 0 20px 0 10px
    img
        width: 24px
        height: 24px
    span
        width: 180px
    .btn_group
        width: 360px
        img
            width: 13px
            height: 13px
    ::v-deep(.el-input__wrapper)
        height: 35px!important
::v-deep(.el-tabs__item)
    color: #434A54!important
    border-radius: 3px 3px 0 0
    border-left: 1px solid #e4e7ed!important
    border-bottom: none!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__item:first-child)
    border-left: none!important
::v-deep(.el-tabs__item.is-active)
    color: #fff!important
    background-color: #4578FF!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__nav)
    border: 1px solid #e4e7ed!important
.search_result
    margin-top: 10px
</style>
