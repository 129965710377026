/**
 * Created by Leo
 * Date: 2022-05-20 08:40
 * Email: asdfpeng@qq.com
 */
import axios from 'axios'
import qs from 'qs'
import app from '../main'

let responseTypeArray =  ['OcrDownFile','ocrDownFilePathAndName','extractPage','GeneratePdf','TemplateDownloadDoc','DownloadReceiptPdf','downLoadDocTemFile','preReceiptPdfOut']
class NetworkHandler {

    constructor(options) {
        this.name = 'NetworkHandler';
        this.apis = options.apis;
        this.config = { 
            timeout: 1500000,
            headers: options.headers,
            url: '',
            method: 'GET',
            params: {},
            data: {},
            withCredentials: false,
            paramsSerializer: function (params) {
                return qs.stringify(params, {arrayFormat: 'brackets'})
            }
        }
    

    }

    /**
     * 获取token等信息添加到header
     */
    beforeFunction() {
        // post请求头
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        // 请求拦截器
        axios.interceptors.request.use(
            config => {
                if (localStorage.getItem('c_auth_token')) {
                    config.headers['c-auth-token'] = localStorage.getItem('c_auth_token')
                }
                // TODO 语言参数
                return config
            },
            error => {
                return Promise.reject(error)
            })
    }

    afterFunction() {
        // 响应拦截器
        axios.interceptors.response.use(
            async (response) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                // console.log('interceptors response', response)
                if (response.data?.success) {
                    return Promise.resolve(response)
                }
                // 下面可能在一个请求中触发多次，不适合在这里抛出错误
                if (!response.data?.success) {
                    // app.config.globalProperties.$message.error(response.data.msg)
                    return Promise.resolve(response)
                }
            },
            error => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.log('error:', error)
                if (error.message.includes('timeout')) {
                    app.config.globalProperties.$message.error('接口请求超时')
                }
                if (error.response.status === 500) {
                    if (error.config.url === '/api/reg/register') {
                        app.config.globalProperties.$message({
                            type: 'error',
                            message: error.response.data.msg,
                            duration: 10000
                        })
                     
                    } else {
                        app.config.globalProperties.$message.error(error.response.data.msg)
                    }
                    if (error.response.data.msg === '登录已过期') {
                        app.config.globalProperties.$listener.$emit('expire')
                        return
                    }
                  
                }
                if (error.response.status === 400) {
                    app.config.globalProperties.$message.error(error.response.data.msg)
                }
                return Promise.resolve(error)
            }
        )
    }

    sendRequest(name, params, headers, timeout) {
        if (!name || !Object.keys(this.apis).includes(name)) {
            console.error(`[${this.name}] No such API:${name}`)
            return null
        }
        // todo 参数处理
        this.beforeFunction()
        this.afterFunction()
        this.config.url = this.apis[name].url
        this.config.method = this.apis[name].method

        if (timeout) this.config.timeout = timeout
        // console.log("this.config.headers['Content-Type']", this.config.headers['Content-Type'])
        if (['GET','DELETE'].includes(this.apis[name].method)) this.config.params = params
        if (['POST', 'PUT'].includes(this.apis[name].method)) this.config.data = params
        if (headers) {
            this.config.headers = { ...this.config.headers, ...headers }
        }
        // console.log(name);

        // DYC   请求头新填
        if (responseTypeArray.some((item ) =>  item === name)) {
            this.config.responseType = 'arraybuffer'
        } else {
            this.config.responseType = ''
        }
 

        return new Promise((resolve, reject) => {
            axios.request({
                ...this.config,
            })
                .then(response => {
                    // console.log('=====response.data======', response.data)
                    if (name === 'OcrDownFile') {
                        resolve(response)
                    } else {
                        resolve(response.data)
                    }
                    
                })
                .catch(err => {
                    console.log(err)
                    reject(err.data)
                })
        })
    }
}

export default {
    install: (Vue, options) => {
        Vue.config.globalProperties.$API = new NetworkHandler(options)
    }
}
