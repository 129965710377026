<template>
    <!-- 富文本插件 -->
    <main style="width: 100%">
        <editor v-model="myValue" :init="init" :id="tinymceId"></editor>
    </main>
</template>
<script setup>
import {
    reactive,
    ref,
    onMounted,
    watch,
    defineEmits,
    defineProps,
    defineExpose
} from 'vue'

import tinymce from 'tinymce/tinymce'
import 'tinymce/skins/content/default/content.css'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default'
import 'tinymce/models/dom'

import 'tinymce/icons/default/icons'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'

const emits = defineEmits(['getContent'])
const props = defineProps({
    boxHeight: {
        type: Number,
        default: 800
    }
})
//用于接收外部传递进来的富文本
const myValue = ref(props.value)

const tinymceId = ref(
    'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
)
//定义一个对象 init初始化
const init = reactive({
    selector: '#' + tinymceId.value, //富文本编辑器的id,
    language_url: '/tinymce/langs/zh_CN.js', // 语言包的路径，具体路径看自己的项目，文档后面附上中文js文件
    language: 'zh_CN', //语言
    skin_url: '/tinymce/skins/ui/oxide', // skin路径，具体路径看自己的项目
    height: props.boxHeight.value, //编辑器高度
    branding: false, //是否禁用“Powered by TinyMCE”
    menubar: true, //顶部菜单栏显示
    image_dimensions: false, //去除宽高属性
    plugins: props.plugins, //这里的数据是在props里面就定义好了的
    toolbar: props.toolbar, //这里的数据是在props里面就定义好了的
    font_formats:
        'Arial=arial,helvetica,sans-serif; 宋体=SimSun; 微软雅黑=Microsoft Yahei; Impact=impact,chicago;', //字体
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 64px 72px', //文字大小
    // paste_convert_word_fake_lists: false, // 插入word文档需要该属性
    paste_webkit_styles: 'all',
    paste_merge_formats: true,
    nonbreaking_force_tab: false,
    paste_auto_cleanup_on_paste: false,
    file_picker_types: 'file',
    content_css: '/tinymce/skins/content/default/content.css', //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入

    // 文件上传
    file_picker_callback: (callback, value, meta) => {
        // Provide file and text for the link dialog
        if (meta.filetype == 'file') {
            callback('mypage.html', { text: 'My text' })
        }

        // Provide image and alt text for the image dialog
        if (meta.filetype == 'image') {
            callback('myimage.jpg', { alt: 'My alt text' })
        }

        // Provide alternative source and posted for the media dialog
        if (meta.filetype == 'media') {
            callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' })
        }
    }
})
//监听外部传递进来的的数据变化
watch(
    () => props.value,
    () => {
        myValue.value = props.value
        emits('getContent', myValue.value)
    }
)
//监听富文本中的数据变化
watch(
    () => myValue.value,
    () => {
        emits('getContent', myValue.value)
    }
)
//在onMounted中初始化编辑器
onMounted(() => {
    tinymce.init({})
})

// 对父组件暴露
defineExpose({ myValue })
</script>

<style lang="scss">
.tox-promotion {
    display: none;
}
</style>