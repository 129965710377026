<template>
<el-tabs v-model="editableTabsValue" type="card" closable @edit="handleTabsEdit">
  <el-tab-pane
    v-for="(item,index) in editableTabs"
    :key="index"
    :label="item.title"
    :name="item.name"
  >
  <component :is="item.content"></component>
  
    
  
  <TabList v-if="item.name ==2" :ref="setItemRef"/>
  <TabList1 v-if="item.name ==1" :ref="setItemRef"/>

  </el-tab-pane>
</el-tabs>
</template>
<script>
import TabList from "@/pages/ArbitratorSelect.vue";
import TabList1 from "@/pages/scansignature/ScanSignatureList_L1.vue";
  export default {
    name: "CaseList",
  components: {
    TabList,TabList1
  },
    data() {
      return {
        editableTabsValue: '1',
        editableTabs: [{
          title: '待签章',
          name: '1',
          content:() => import('@/pages/ArbitratorSelect.vue')
        }, {
          title: '已签章',
          name: '2',
          content:() => import('@/pages/scansignature/ScanSignatureList_L1.vue')
        }],
        tabIndex: 2
      }
    },
    methods: {
      handleTabsEdit(targetName, action) {
        console.log("aaaa",action,targetName)
        if (action === 'add') {
          let newTabName = ++this.tabIndex + '';
          this.editableTabs.push({
            title: 'New Tab',
            name: newTabName,
            content: 'New Tab content'
          });
          this.editableTabsValue = newTabName;
        }
        if (action === 'remove') {
          let tabs = this.editableTabs;
          let activeName = this.editableTabsValue;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }
          
          this.editableTabsValue = activeName;
          this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        }
      }
    }
  }
</script>