<template>
    <div class="container" v-loading="tableDataLoading">
        <!-- 顶部搜索框开始 -->
        <div class="header" style="margin: 10px 0 10px 10px" v-if="OCRType">
            <el-row>
                <el-col :span="6">
                    <el-input
                        v-model="inputContent"
                        :placeholder="$t('msg.PleasentertheReceivingNumber')"
                        @keyup.enter="serachClick"
                        :clearable="true"
                    ></el-input>
                </el-col>

                <el-col :span="6" style="margin-left: 20px">
                    <el-input
                        v-model="inputCaseNum"
                        :placeholder="$t('msg.Pleaseenterhecasenumber')"
                        @keyup.enter="serachClick"
                        :clearable="true"
                    ></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="serachClick">
                        <el-icon class="el-icon--right"> <Search /> </el-icon
                        >{{$t('msg.finder_search')}}
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="clcikClearInput">
                        <el-icon class="el-icon--right"> <Search /> </el-icon
                        >{{$t('msg.case_reset')}}
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <!-- <el-button type="primary" @click="identifyDialogBoxClick">
                        识别进度
                    </el-button> -->
                    <el-popover
                        placement="bottom"
                        :width="650"
                        trigger="click"
                        @hide="identifyDialogHidn"
                    >
                        <template #reference>
                            <el-button
                                style="margin-right: 16px; color: #ffffff"
                                @click="getOcrGroup"
                                >{{$t('msg.IdentificationQueue')}}</el-button
                            >
                        </template>
                        <div class="identifyDialogBox">
                            <div class="identifyDialogBoxItem">
                                <div class="left title">{{$t('msg.ReceiptletterNumber')}}</div>
                                <div class="left title">{{$t('msg.StartrecognitionTime')}}</div>
                                <div class="left title">{{$t('msg.CurrentState')}}</div>
                            </div>
                            <div v-loading="identifyDialogLoading">
                                <div
                                    class="identifyDialogBoxItem"
                                    v-for="(item, index) in identifyDialogList"
                                    :key="index"
                                >
                                    <div class="left">{{ item.receiveNo }}</div>
                                    <div class="left">{{ item.ocrDate }}</div>
                                    <div class="left">{{$t('msg.Identifying')}}...</div>
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </el-col>
                <el-col :span="8"> </el-col>
            </el-row>
        </div>

        <!-- 第一页数组列表开始 -->
        <div class="OCRList" v-if="OCRType">
            <div class="table">
                <el-table
                    :data="getDetailCase"
                    v-loading="OCRListLoading"
                    :row-class-name="tableRowClassName"
                >
                    <!-- 1.函号 -->
                    <el-table-column
                        prop="receiveLetterNo"
                        :label="$t('msg.ReceiptletterNumber')"
                        sortable
                        min-width="8%"
                    >
                    </el-table-column>

                    <!-- 2.编号 -->
                    <el-table-column
                        prop="caseNo"
                        :label="$t('msg.case')"
                        sortable
                        min-width="8%"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.caseNo">{{
                                scope.row.caseNo
                            }}</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>

                    <!-- 3.类型 -->
                    <el-table-column
                        prop="evidenceTypeName"
                         :label="$t('msg.MaterialType')"
                        show-overflow-tooltip
                        min-width="8%"
                    >
                    </el-table-column>

                    <!-- 4.介绍 -->
                    <el-table-column
                        prop="evidenceDesc"
                        :label="$t('msg.FileDescription')"
                        show-overflow-tooltip
                        min-width="10%"
                    >
                        <template #default="scope">
                            <span
                                class="spanHoverText"
                                v-if="scope.row.evidenceDesc"
                                @click="modifyEvidenceDesc(scope.row)"
                                >{{ scope.row.evidenceDesc }}</span
                            >
                            <span v-else>无</span>
                        </template>
                    </el-table-column>

                    <!-- 5.单位 -->
                    <el-table-column
                        prop="senderCompany"
                        :label="$t('msg.CorrespondenceAffiliation')"
                        show-overflow-tooltip
                        min-width="10%"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.senderCompany">{{
                                scope.row.senderCompany
                            }}</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>

                    <!--6.日期 -->
                    <el-table-column
                        prop="receiveLetterDate"
                       :label="$t('msg.DateofReceipt')"
                        sortable
                        min-width="8%"
                    >
                    </el-table-column>

                    <!-- 7 -->
                    <el-table-column
                        prop="userName"
                        :label="$t('msg.Submitter')"
                        min-width="6%"
                    >
                        <template #default="scope">
                            <div>
                                <span v-html="scope.row.userName"></span>
                            </div>
                        </template>
                    </el-table-column>

                    <!-- 8 -->
                    <el-table-column :label="$t('msg.Operate')" min-width="15%">
                        <template #default="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="goTwoList(scope.row)"
                                >{{$t('msg.finder_upload')}}</el-button
                            >
                            <el-button
                                type="primary"
                                size="small"
                                :loading="scope.row.loading"
                                :disabled="
                                    scope.row.ocrStatus === 0 ? true : false
                                "
                                @click="identifyClick(scope.row, scope.$index)"
                                auto-insert-space
                                >{{$t('msg.Identify')}}
                            </el-button>
                            <el-button
                                type="primary"
                                size="small"
                                @click="downloadPDF(scope.row)"
                                :disabled="
                                    scope.row.ocrStatus === 0
                                        ? true
                                        : scope.row.ocrStatus === 1
                                        ? true
                                        : false
                                "
                                >{{$t('msg.Download')}}</el-button
                            >
                            <el-button
                                type="primary"
                                size="small"
                                v-if="!determineWS(scope.row.receiveLetterNo)"
                                @click="
                                    clickSupplementDialog(
                                        scope.row.receiveLetterNo
                                    )
                                "
                                >{{$t('msg.Reprint')}}</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination" v-if="caseIdTotal > 1">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="caseIdSize"
                    layout=" sizes, prev, pager, next,total"
                    :total="caseIdTotal"
                >
                </el-pagination>
            </div>
        </div>
        <!-- 第一页数组列表结束 -->

        <!-- 动态切换显示else -->
        <div class="OCRIdentify" v-else>
            <!-- 顶部两按钮开始 -->
            <el-row class="elRowMargin">
                <el-col
                    :span="6"
                    style="display: flex; justify-content: flex-start"
                >
                    <el-button
                        @click="isShowDialog"
                        style="color: #fff"
                        class="backColorButBlue"
                        v-if="selectButColor"
                    >
                        <el-icon class="el-icon--center">
                            <UploadFilled />
                        </el-icon>
                        {{$t('msg.SelectFile')}}
                    </el-button>
                    <el-button
                        @click="isShowDialog"
                        style="color: #606266; background: #fff"
                        v-else
                    >
                        <el-icon class="el-icon--center">
                            <UploadFilled />
                        </el-icon>
                        {{$t('msg.SelectFile')}}
                    </el-button>
                </el-col>

                <el-col
                    :span="6"
                    style="display: flex; justify-content: flex-end"
                >
                    <el-button
                        type="primary"
                        @click="fileClick"
                        v-if="!selectButColor"
                    >
                        <el-icon class="el-icon--right">
                            <Upload />
                        </el-icon>
                        {{$t('msg.finder_upload')}}
                    </el-button>
                    <el-button
                        type="primary"
                        @click="fileClick"
                        v-else
                        style="background-color: #fff; color: #606266"
                    >
                        <el-icon class="el-icon--right">
                            <Upload />
                        </el-icon>
                       {{$t('msg.finder_upload')}}
                    </el-button>
                </el-col>
            </el-row>
            <!-- 顶部两按钮结束 -->

            <!-- 展示表格开始 -->
            <el-table
                ref="singleTable"
                :data="tableData"
                highlight-current-row
                style="width: 100%"
            >
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column property="fileName" :label="$t('msg.FileName')" width="160">
                    <template #default="scope">
                        <div v-if="scope.row.filePath">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    {{ scope.row.fileName }}
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            v-if="
                                                scope.row.pdfFlag === 0 ||
                                                scope.row.pdfFlag === 1
                                            "
                                            @click="clickDropdown(scope.row)"
                                            >{{$t('msg.Preview')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            @click="clickDownLoad(scope.row)"
                                            >{{$t('msg.Download')}}</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                        <div v-else>{{ scope.row.fileName }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    property="fileType"
                    :label="$t('msg.MaterialType')"
                    width="160"
                >
                </el-table-column>
                <el-table-column property="fileText" :label="$t('msg.Describe')">
                </el-table-column>
                <el-table-column property="uploadDate" :label="$t('msg.UploadTime')">
                </el-table-column>
                <el-table-column>
                    <template #default="scope">
                        <el-button
                            type="primary"
                            @click="deleteFileItem(scope.$index, scope)"
                            v-if="scope.row.type !== false"
                        >
                            <el-icon class="el-icon--center">
                                <Delete />
                            </el-icon>
                        </el-button>
                        <el-icon
                            class="el-icon--center"
                            v-else
                            style="margin-left: 15px"
                        >
                            <Check />
                        </el-icon>
                    </template>
                </el-table-column>
                <!-- 27上面需要删除按钮  域名上面不要 -->
                <el-table-column :label="$t('msg.DeletetheuploadedFile')">
                    <template #default="scope">
                        <el-button
                            v-if="scope.row.uploadDate"
                            type="danger"
                            style="background-color: #ee4848"
                            @click="deleteUploadFile(scope.row)"
                            >{{$t('msg.DeletetheuploadedFile')}}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>

            <!-- 上传文件进度  percentageNumType-->
            <el-row
                v-if="percentageNumType"
                style="display: flex; align-items: center"
            >
                <p style="text-align: right; padding-right: 10px">
                    {{$t('msg.Uploadfileprogress')}}
                </p>

                <div class="progressBox">
                    <el-progress
                        :percentage="percentageNum"
                        style="width: 80%"
                    />
                    <span
                        :class="{
                            progressTextColor: trueNum !== newFileList.length
                        }"
                    >
                        {{ trueNum }}</span
                    >/{{ newFileList.length }}
                </div>
            </el-row>

            <!-- 展示表格结束 -->

            <!-- 第二个弹出框开始-----》上传弹出框 -->
            <el-dialog
                :title="dictionaryForm.dictionaryItemName"
                v-model="dialogVisibleTwo"
                width="40%"
            >
                <el-card class="box-card">
                    <el-row>
                        <el-col :span="20"
                            >{{$t('msg.UploadDirectory')}}：
                            <el-button @click="selectFile" style="color: #fff">
                                {{$t('msg.SelectFile')}}
                                <el-icon class="el-icon--FolderOpened">
                                    <FolderOpened />
                                </el-icon>
                            </el-button>
                        </el-col>
                        <el-col :span="4" style="padding-top: 10px">
                            <span style="font-size: 12px; color: red; zoom: 0.9"
                                >{{$t('msg.PleaseSelect')}}PDF / JPG</span
                            >
                        </el-col>
                    </el-row>
                    <el-card>
                        <el-row>
                            <el-col :span="24">
                                <div
                                    @dragenter="handleDragEnter"
                                    @dragover="handleDrageOver"
                                    @drop="handleDrop"
                                    :class="[
                                        'directoryMain',
                                        scrollType ? 'scroll' : ''
                                    ]"
                                >
                                    <el-upload
                                        class="upload-demo"
                                        action="#"
                                        multiple
                                        :limit="100"
                                        :file-list="fileList"
                                        :auto-upload="false"
                                        :accept="acceptedFormats"
                                        @change="fileListChange"
                                    >
                                        <el-button
                                            size="small"
                                            type="primary"
                                            v-show="false"
                                            ref="uploadButRef"
                                            >{{$t('msg.ClicktoUpload')}}</el-button
                                        >
                                    </el-upload>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card>
                        <el-form
                            :model="dictionaryForm"
                            :rules="dictionaryRules"
                            ref="ruleForm"
                            class="demo-ruleForm"
                        >
                            <el-form-item
                                :label="$t('msg.FileDescription')"
                                prop="dictionaryText"
                            >
                                <el-input
                                    style="width: 100%"
                                    type="textarea"
                                    v-model="dictionaryForm.dictionaryText"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </el-card>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary" @click="getFileListArr"
                                >{{$t('msg.Confirm')}}</el-button
                            >
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                @click="dialogVisibleTwoClose"
                                style="background: #fff"
                                >{{$t('msg.finder_cancel')}}</el-button
                            >
                        </el-col>
                    </el-row>
                </el-card>
            </el-dialog>
            <!-- 第二个弹出框结束 -->
        </div>
        <!-- 修改描述弹窗 / -->
        <el-dialog
            :title="$t('msg.ModifyDescription')"
            v-model="modifyEvidenceDescDialog"
            width="50%"
            :before-close="modifyEvidenceDescDialogClose"
        >
            <div>
                <el-row>
                    <el-col :span="4">
                        <span>{{$t('msg.FileDescription')}}</span>
                    </el-col>
                    <el-col :span="20">
                        <el-input
                            type="textarea"
                            v-model="modifyEvidenceDialogInputValue"
                        ></el-input>
                    </el-col>
                </el-row>
            </div>
            <template #footer>
                <div class="dialogFooterBtn">
                    <el-button
                        style="color: #fff"
                        @click="modifyEvidenceDescDialogClose"
                        >{{$t('msg.finder_cancel')}}</el-button
                    >
                    <el-button
                        type="primary"
                        @click="modifyEvidenceDialogSubmit"
                        >{{$t('msg.Confirm')}}{{$t('msg.Revamp')}}</el-button
                    >
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :title="$t('msg.PrintQRcode')"
            v-model="supplementDialogType"
            width="30%"
            :before-close="supplementDialogTypeClose"
        >
            <div class="codeImgBox">
                <div class="codeImg">
                    <img :src="receiveNoImgCodeImgUrl" alt="" />
                </div>
                <div class="codeBtn">
                    <el-button type="primary" round @click="getOcrCode">
                        {{$t('msg.Print')}}
                    </el-button>
                </div>
            </div>
            <template #footer>
                <div>
                    <el-button
                        style="color: #fff"
                        @click="supplementDialogTypeClose"
                    >
                        {{$t('msg.finder_cancel')}}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>


<script>
import axios from 'axios'
import printJS from 'print-js'
// import axios from 'axios'
export default {
    data() {
        return {
            dictionaryForm: {
                // 被选中的材料类型
                dictionaryItemName: '',
                dictionaryText: '',
                dictionaryItemId: ''
            },
            inputText: '',

            // 第二个弹出框状态
            dialogVisibleTwo: false,
            // 文件备注
            dictionaryText: '',
            // 数组数据
            tableData: [],
            tableDataLoading: false,
            // 校验数据规则
            dictionaryRules: {
                dictionaryText: [
                    {
                        required: true,
                        message: '文件描述为必填',
                        trigger: 'blur'
                    }
                ]
            },
            // 上传列表
            fileList: [],
            // 累计上传列表
            newFileList: [],
            // 被隐藏的上传按钮
            uploadButRef: null,

            // 进入哪一个页面
            OCRType: true,

            // 需要存储本地数组
            fileListLocal: [],
            //  识别得loading效果
            identifyLoading: [],

            // 返回下载得base64
            base64Data: '',
            // 第一页数组显示列表
            getDetailCase: [],

            // ocr列表首页等待效果
            OCRListLoading: false,
            // 第一页表格分页器数据
            caseIdTotal: 0,
            // 分页器当前页数
            current: 1,
            // 分页器当前条数
            caseIdSize: 100,

            // 什么区  比如北京分区。。
            abbr: '',
            // 需要的id
            caseId: '',
            // 收文编号
            receiveLetterNo: '',
            // 上传的文件格式
            acceptedFormats: '.pdf,.jpg,',
            // partyType字段
            partyType: '',

            // 需要搜索的收文函号
            inputContent: '',
            // 需要搜索的案件编号
            inputCaseNum: '',
            // 进度条
            percentageNum: 0,
            // 进度条是否显示
            percentageNumType: false,
            // 上传的个数
            trueNum: 0,
            // 修改描述弹窗
            modifyEvidenceDescDialog: false,
            // 修改描述弹窗的输入框的值
            modifyEvidenceDialogInputValue: '',
            // 修改描述弹窗的receiveLetterNo
            modifyEvidenceDialogReceiveLetterNo: '',
            // 补打弹出框
            supplementDialogType: false,
            // 二维码图片的收文函号的图片
            receiveNoImgCodeImgUrl: '',
            // 下载枚举
            downEnum: this.$store.state.downEnum,
            // list加载效果
            identifyDialogLoading: false,
            // 识别的数组列表
            identifyDialogList: [],
            // 清除ocr的定时器
            clearIdentifyNo: ''
        }
    },
    methods: {
        load() {
            this.inputContent = ''
            this.inputCaseNum = ''
            this.current = 1
            this.caseIdSize = 100
            this.getCaseIdList()
            this.OCRType = true
        },

        // 页面一点击获取数据展示列表---》caseID
        async getCaseIdList() {
            this.OCRListLoading = true
            const param = {
                req: {
                    current: this.current,
                    size: this.caseIdSize,
                    q: {
                        receiveNo: this.inputContent,
                        caseNo: this.inputCaseNum
                    }
                }
            }
            const { data } = await this.$API.sendRequest('OcrStatusList', param)
            this.OCRListLoading = false
            this.getDetailCase = data.records.map((item) => {
                return {
                    ...item,
                    loading: false,
                    date: 0
                }
            })
            this.caseIdTotal = data.total
        },

        // 第一个弹出框的先显示
        isShowDialog() {
            this.dialogVisibleTwo = true
            this.dictionaryForm.dictionaryText = this.inputText
        },
        // 点击关闭弹窗，清空表单
        closeDialog() {
            this.fileList = []
        },

        // 显示的点击选择文件按钮
        selectFile() {
            this.uploadButRef = this.$refs.uploadButRef.ref
            this.uploadButRef.click()
        },

        // 点击上传
        async fileClick() {
            if (!this.newFileList.length) {
                return this.$message.error('请先选择文件')
            }
			console.log(this.newFileList,'上传文件88888888');

            this.percentageNumType = true
            // 保存其他信息
            this.$message.info('正在上传文件请勿退出')

            let trueNum = 0
            this.trueNum = 0
            this.percentageNum = 0
            let myThis = this
            for (let i = 0; i < this.newFileList.length; i++) {
                const formData = new FormData()
                formData.append('req.abbr', this.newFileList[i].abbr)
                formData.append('req.caseId', this.newFileList[i].caseId)
                formData.append('file', this.newFileList[i].file)
                formData.append('req.flagPdf', this.newFileList[i].flagPdf)
                formData.append('req.oldFileName', this.newFileList[i].name)
                formData.append('req.typeName', this.newFileList[i].typeName)
                formData.append('req.profile', this.newFileList[i].text || '无')
                formData.append(
                    'req.typeId',
                    this.dictionaryForm.dictionaryItemId
                )
                formData.append(
                    'req.materialFlag',
                    this.partyType === null
                        ? 0
                        : this.partyType === 1
                        ? 10
                        : this.partyType === 2
                        ? 11
                        : 13
                )
                formData.append('req.remarks', null)
                formData.append('req.receiveLetterNo', this.receiveLetterNo)
                formData.append(
                    'req.partyType',
                    this.partyType === null ? 0 : this.partyType
                )
                // let res = await this.$API.sendRequest('OcrSaveUpload', formData)

                let res = await axios({
                    url: '/api/ocrUse/ocrSaveUpload',
                    method: 'POST',
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )

                        if (percentCompleted < 100) {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = percentCompleted
                        } else {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = 99
                        }
                    },
                    headers: {
                        c_Auth_token: localStorage.getItem('c_Auth_toke')
                    },
                    data: formData
                })
                if (res.data.success) {
                    trueNum++
                    this.trueNum = trueNum
                    if (trueNum === this.newFileList.length) {
                        this.$message.success(res.data.msg)
                        // this.clickSynchronous()
                        this.getCaseIdList()
                        setTimeout(() => {
                            this.OCRType = true
                            this.percentageNum = 0
                            this.percentageNumType = false
                        }, 500)
                    } else {
                        myThis.percentageNum = 0
                    }
                } else {
                    this.percentageNumType = false
                    this.$message.error('上传文件失败，请刷新后重试！')
                }
            }
            this.newFileList = []
        },

        // 上传同步数据
        clickSynchronous() {
            let param = {
                req: {
                    caseId: this.caseId,
                    userId: this.$store.state.loginInfo.userId,
                    regUserId: null
                }
            }
            this.$API.sendRequest('UnifiedFileTransfer', param)
        },

        //  点击第二次确定收集数据
        getFileListArr() {
            if (this.fileList.length === 0) {
                return this.$message.error('文件不能为空')
            }

            if (!this.dictionaryForm.dictionaryText) {
                return this.$message.error('描述不能为空')
            }

            for (let i = 0; i < this.fileList.length; i++) {
                if (!this.fileList[i].raw) {
                    const obj = {}
                    obj['typeName'] = this.dictionaryForm.dictionaryItemName
                    obj['caseId'] = this.caseId
                    obj['name'] = this.fileList[i].name
                    obj['abbr'] = this.abbr
                    obj['flagPdf'] =
                        this.fileList[i].type === 'application/pdf' ? 1 : 0
                    obj['file'] = this.fileList[i]
                    obj['text'] = this.dictionaryForm.dictionaryText
                    this.newFileList.push(obj)
                } else {
                    const obj = {}
                    obj['typeName'] = this.dictionaryForm.dictionaryItemName
                    obj['caseId'] = this.caseId
                    obj['name'] = this.fileList[i].raw.name
                    obj['abbr'] = this.abbr
                    obj['flagPdf'] =
                        this.fileList[i].raw.type === 'application/pdf' ? 1 : 0
                    obj['file'] = this.fileList[i].raw
                    obj['text'] = this.dictionaryForm.dictionaryText
                    this.newFileList.push(obj)
                }
            }

            // 给页面返回数据进行渲染查看
            for (let i = 0; i < this.fileList.length; i++) {
                const obj = {}
                obj.fileName = this.fileList[i].name
                obj.fileType = this.dictionaryForm.dictionaryItemName
                obj.fileText = this.dictionaryForm.dictionaryText
                this.tableData.push(obj)
            }
            this.dialogVisibleTwo = false
            // 清空输入的内容
            this.dictionaryForm.dictionaryText =
                this.dictionaryForm.dictionaryItemNam
            this.closeDialog()
        },

        // 删除数据某一项
        deleteFileItem(scope) {
            this.tableData.splice(scope, 1)
            this.newFileList.splice(scope, 1)
        },

        // 点击识别   ->>获取后端返回数据后立即转发
        async identifyClick(row) {
            row.loading = true
            const param = {
                req: row.receiveLetterNo
            }
            // 先从windows去取当前数据的参数
            const paramRes = await this.$API.sendRequest('OcrFlagUpload', param)
            let paramList = {
                req: paramRes.data
            }

            if (paramRes.errorCode === '10001') {
                const res1 = await this.$API.sendRequest(
                    'OcRUpdDigital',
                    paramList
                )
                if (res1) {
                    if (res1.success) {
                        this.$message.success(res1.msg)
                        this.getCaseIdList()
                        this.getDetailCase.forEach((item) => {
                            if (
                                item.receiveLetterNo ===
                                paramRes.data[0].receiveLetterNo
                            ) {
                                item.ocrStatus = 2
                                item.loading = false
                            }
                        })
                    } else {
                        this.$message.error(res1.msg)
                        row.loading = false
                    }
                } else {
                    row.loading = false
                }
            } else {
                row.loading = false
                return this.$message.error('识别失败')
            }
        },
        isLoading(index) {
            return this.identifyLoading.includes(index)
        },

        // 点击下载pdf
        async downloadPDF(row) {
            this.OCRListLoading = true
            let param = {
                req: row.receiveLetterNo
            }
            let res = await this.$API.sendRequest('OcrDownFile', param)
            const blob = new Blob([res.data], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            let fileName = res.headers['content-disposition']
            let index = fileName.indexOf('=')
            let result = fileName.substring(index + 1, fileName.length)
            let link = document.createElement('a')
            link.href = url
            link.download = decodeURIComponent(result) + '.pdf'
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.OCRListLoading = false
        },

        // 点击第一页数组某一项去第二页数组
        async goTwoList(row) {
            this.tableDataLoading = true
            this.caseId = row.caseId
            this.abbr = row.abbr
            this.receiveLetterNo = row.receiveLetterNo
            this.dictionaryForm.dictionaryItemName = row.evidenceTypeName
            this.dictionaryForm.dictionaryItemId = row.evidenceTypeId
            this.inputText = row.evidenceDesc
            this.partyType = row.partyType
            this.tableData = []
            this.OCRType = false
            const param = {
                req: row.receiveLetterNo
            }
            const res = await this.$API.sendRequest('OcrFlagUpload', param)
            if (res.errorCode === '10001') {
                this.tableData = res.data.map((item) => {
                    const obj = {}
                    obj.fileName = item.fileName
                    obj.fileType = item.materialTypeName
                    obj.fileText = item.profile
                    obj.uploadDate = item.uploadDate
                    obj.filePath = item.filePath
                    obj.pdfFlag = item.pdfFlag
                    obj.id = item.id
                    obj.receiveLetterNo = item.receiveLetterNo
                    obj.type = false
                    return obj
                })
            }
            this.tableDataLoading = false
        },

        // 分页器事件  第几页
        handleSizeChange(val) {
            this.current = 1
            this.caseIdSize = val
            this.getCaseIdList()
        },
        // 分页器事件  每页多少条
        handleCurrentChange(val) {
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.caseIdTotal / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.caseIdSize > pageMax) {
                this.caseIdSize = pageMax
            }
            this.getCaseIdList()
        },

        // 表格颜色事件
        tableRowClassName({ row }) {
            if (row.ocrStatus === 0) {
                return 'ocrStatus0'
            } else if (row.ocrStatus === 1) {
                return 'ocrStatus1'
            } else if (row.ocrStatus === 2) {
                return 'ocrStatus2'
            }
            return ''
        },

        // 点击搜索按事件函号搜索
        serachClick() {
            this.current = 1
            this.caseIdSize = 100
            this.getCaseIdList()
        },

        // 以下三个为拖拽上传
        // 将文件拖到地方上传的方法
        handleDragEnter(e) {
            // 阻止浏览器默认拖拽行为
            e.preventDefault()
        },
        // 阻止浏览器默认拖拽行为
        handleDrageOver(e) {
            e.preventDefault()
        },
        handleDrop(e) {
            e.preventDefault()
            const filesList = e.dataTransfer.files
            this.fileList.push(...filesList)
        },

        // 清空表单
        clcikClearInput() {
            this.inputCaseNum = ''
            this.inputContent = ''
            this.getCaseIdList()
        },

        // 修改文件描述弹窗开启
        modifyEvidenceDesc(row) {
            this.modifyEvidenceDescDialog = true
            this.modifyEvidenceDialogInputValue = row.evidenceDesc
            this.modifyEvidenceDialogReceiveLetterNo = row.receiveLetterNo
        },

        // 关闭文件描述弹窗
        modifyEvidenceDescDialogClose() {
            this.modifyEvidenceDescDialog = false
        },

        // 点击文件描述弹窗的确定按钮
        async modifyEvidenceDialogSubmit() {
            let param = {
                req: {
                    receiveNo: this.modifyEvidenceDialogReceiveLetterNo,
                    remark: this.modifyEvidenceDialogInputValue
                }
            }
            let res = await this.$API.sendRequest('UpdFileRemark', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.modifyEvidenceDescDialog = false
            this.getCaseIdList()
        },

        // 判断是不是WS网上立案
        determineWS(str) {
            let newStr = str.substr(0, 2)
            if (newStr === 'WS') {
                return true
            } else {
                false
            }
        },

        // 点击了补打--打开弹窗
        async clickSupplementDialog(receiveNo) {
            let param = {
                req: receiveNo
            }
            let res = await this.$API.sendRequest('GetQRCode', param)

            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.receiveNoImgCodeImgUrl = 'data:image/png;base64,' + res.data
            this.supplementDialogType = true
        },

        // 关闭补打弹窗
        supplementDialogTypeClose() {
            this.supplementDialogType = false
        },

        // 点击了补打里面打印
        async getOcrCode() {
            printJS({
                // 要打印的内容 放在了data里
                printable: this.receiveNoImgCodeImgUrl,
                // 打印类型 目前为图片样式 可以根据上面的网址进行修改
                type: 'image',
                // 二维码样式 可以自己进行修改
                imageStyle:
                    'width:80px; height:80px; display: block; margin: 0px 0px 0px -13px;'
                // 也可以设置以下参数 继承所有css样式 没试过image的 html的效果不错
                // targetStyles:['*']
            })
        },

        // 点击删除已上传文件按钮.
        deleteUploadFile(row) {
            this.$confirm('确认删除当前文件吗,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    this.tableDataLoading = true
                    let param = {
                        req: {
                            id: row.id,
                            receiveNo: row.receiveLetterNo
                        }
                    }
                    let res = await this.$API.sendRequest(
                        'DelUploadFile',
                        param
                    )
                    if (!res.success) {
                        this.tableDataLoading = false
                        return this.$message.error(res.msg)
                    }
                    this.$message.success(res.msg)
                    this.tableData = this.tableData.filter((item) => {
                        return item.id !== row.id
                    })

                    this.tableDataLoading = false
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message:this.$t('msg.Undelete')
                    })
                })
        },

        // 预览影印版事件
        async clickDropdown(row) {
            this.tableDataLoading = true
            this.$message.success('正在打开预览文件,请稍等')
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                this.tableDataLoading = false
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                this.tableDataLoading = false
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击下载影印版事件
        async clickDownLoad(row) {
            this.tableDataLoading = true
            this.$message.success('正在准备下载文件,请稍等')
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else if (row.pdfFlag === 0) {
                blob = new Blob([res], { type: 'image/jpeg' })
            } else {
                blob = new Blob([res], { type: 'image/jpeg' })
                const filename = row.fileName
                let fileType = filename.substring(filename.lastIndexOf('.'))
                for (let key in this.downEnum) {
                    if (key === fileType) {
                        let blob = new Blob([res], { type: this.downEnum[key] })
                        let url = window.URL.createObjectURL(blob)
                        let link = document.createElement('a')
                        link.href = url
                        link.download = row.fileName
                        link.click()
                        window.URL.revokeObjectURL(link.href)
                    }
                }
                return
            }
            this.tableDataLoading = false
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

        // 上传文件的时候需要判断大小
        fileListChange(fileList) {
            let type = fileList.size / 1024 / 1024 < 500
            if (!type) {
                this.fileList.splice(this.fileList.length - 1, 1)
                this.$message.error('上传文件大小不能超过500' + 'MB!')
            }
        },

        // 关闭第二个弹窗
        dialogVisibleTwoClose() {
            this.fileList = []
            this.dialogVisibleTwo = false
        },

        // 打开队列
        async getOcrGroup() {
            this.identifyDialogLoading = true
            // let res = await this.$API.sendRequest('getOcrGroup')

            // if (!res.success) {
            //     this.identifyDialogLoading = false
            //     return this.$message.error(res.msg)
            // }
            // console.log(res.data)
            // this.identifyDialogList = res.data
            this.getOcrGroupFn()
            this.clearIdentifyNo = setInterval(() => {
                this.getOcrGroupFn()
            }, 2000)
            this.identifyDialogLoading = false
        },

        // 循环调用队列接口
        async getOcrGroupFn() {
            let res = await this.$API.sendRequest('getOcrGroup')
            if (!res.success) {
                this.identifyDialogLoading = false
                clearTimeout(this.clearIdentifyNo)
                return this.$message.error(res.msg)
            }
            this.identifyDialogList = res.data
        },

        // 识别队列中的清空定时器
        identifyDialogHidn() {
            console.log(111111111)
            clearTimeout(this.clearIdentifyNo)
        }
    },
    computed: {
        // 滚动条是否显示出来
        scrollType() {
            return this.fileList.length > 8 ? true : false
        },

        // 选择按钮颜色监听
        selectButColor() {
            return this.newFileList.length === 0
        }
    },
    watch: {
        inputContent(newValue) {
            if (newValue === '') {
                this.getCaseIdList()
            }
        }
    }
}
</script>


<style scoped lang="scss">
:deep(.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default) {
    display: flex !important;
}

.OCRList {
    width: 100%;
}

.marginBottom {
    margin-bottom: 20px;
}

.el-col-6 {
    display: flex;
    justify-content: center;
    align-content: center;
}

.el-input__wrapper {
    width: 100%;
}

.el-row {
    margin-bottom: 10px;
}

.el-dialog__body {
    margin-top: -20px;
}

.directoryMain {
    height: 200px;
}

.scroll {
    overflow-x: hidden;
    overflow-y: scroll;
}

.elRowMargin {
    margin: 10px 0;
}

.el-col.el-col-12 {
    margin-top: 20px;
}

:deep(.el-upload-list__item .el-icon--upload-success) {
    display: none !important;
}

:deep(.el-upload-list__item.is-success .el-upload-list__item-name:focus) {
    color: none !important;
    cursor: text !important;
}

:deep(.el-upload-list__item.is-success .el-upload-list__item-name:hover) {
    color: #606266 !important;
    cursor: text !important;
}

:deep(.el-table__inner-wrapper::before) {
    width: auto;
}

:deep(.el-table .ocrStatus0) {
    background: #fff;
}

:deep(.el-table .ocrStatus1) {
    background: #ebe3c3;
}

:deep(.el-table .ocrStatus2) {
    background: #ebd682;
}

.table {
    margin-bottom: 20px;
}

.pagination {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

:deep(.el-button) {
    background-color: #79bbff;
}

:deep(.el-loading-spinner) {
    top: 200px;
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 80%;
}
.progressTextColor {
    color: red;
}
.spanHoverText {
    color: rgb(64, 49, 233);
    cursor: pointer;
}

.dialogFooterBtn {
    display: flex;
    justify-content: center;
    color: #fff;
}

.codeImgBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .codeImg {
        margin-bottom: 20px;
        width: 8vw;
        img {
            width: 100%;
        }
    }
}

.el-dropdown-link {
    color: #0074ec;
}

.identifyDialogBox {
    height: 400px;
    overflow: auto;
    .identifyDialogBoxItem {
        display: flex;
        font-size: 16px;
        margin: 10px;
        &:first-child {
            border-bottom: 2px solid #606266;
        }

        .left {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        .title {
            font-size: 18px;
            font-weight: 600;
        }
    }
}
</style>