<template>
    <el-menu
        class="menu_wrapper"
        :collapse="isCollapse"
        active-text-color="#4578FF"
        background-color="#fff"
        :default-active="active"
        text-color="#6B778D"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
    >
        <!-- 左侧菜单列表 -->
        <template v-if="type === 'menu'">
            <div v-for="item in menu_tree" :key="item.id">
                <el-sub-menu v-if="item.children" :index="item.url">
                    <template #title>
                        <!--
            <el-icon><location /></el-icon>
            -->
                        <img
                            class="l1-icon"
                            src="../assets/image/side_menu/icon.png"
                            alt=""
                        />
                        <span>{{ item.menu_name }}</span>
                    </template>

                    <!-- 左侧菜单下的子菜单 -->
                    <el-menu-item-group
                        v-for="(sub, i) in item.children"
                        :key="i"
                    >
                        <el-menu-item :index="sub.url ? sub.url : 'receive'">{{
                            sub.menu_name
                        }}</el-menu-item>
                    </el-menu-item-group>
                </el-sub-menu>
                <el-menu-item v-else :index="item.url">
                    <!--
          <el-icon><home-filled /></el-icon>-->

                    <template #title>{{ item.menu_name }}</template>
                    <div>{{ item.menu_name }}</div>
                </el-menu-item>
            </div>
        </template>

        <template v-if="type === 'tree'">
            <el-tree
                class="tree"
                node-key="id"
                :allow-drop="allowDrop"
                :allow-drag="allowDrag"
                :data="menu_tree"
                :props="defaultProps"
                lazy
                highlight-current
                :default-expanded-keys="[1]"
                :load="loadNode"
                @node-click="loadData"
                @node-drag-start="handleDragStart"
                @node-drag-enter="handleDragEnter"
                @node-drag-leave="handleDragLeave"
                @node-drag-over="handleDragOver"
                @node-drag-end="handleDragEnd"
                @node-drop="handleDrop"
            >
                <template v-slot="scope">
                    <img
                        v-if="scope.node.expanded"
                        class="tree_icon"
                        src="../assets/image/finder/folder-open.png"
                        alt=""
                    />
                    <img
                        v-else
                        class="tree_icon"
                        src="../assets/image/finder/folder-fill.png"
                        alt=""
                    />
                    <span class="folder_name">{{ scope.data.label }}</span>
                </template>
            </el-tree>
        </template>
        <div class="menu-top" @click="toggleMenu" v-if="btn">
            <div class="top-btn" v-if="isCollapse">
                <img
                    src="../assets/image/side_menu/c_btn.png"
                    alt=""
                    style="transform: rotate(180deg)"
                />
            </div>
            <div class="top-btn" v-else>
                <img src="../assets/image/side_menu/c_btn.png" alt="" />
            </div>
        </div>
    </el-menu>
</template>

<script>
export default {
    name: 'SideMenu',
    created() {
        this.$listener.$on('set_active', (val) => {
            this.active = val
        })
    },
    props: {
        menu_tree: {
            type: Array,
            default() {
                return []
            }
        },
        type: {
            type: String,
            default() {
                return 'menu' // menu or tree
            }
        },
        case_id: {
            type: Number
        },
        btn: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    data() {
        return {
            isCollapse: false,
            arr: [
                {
                    id: 1,
                    label: '一级 1',
                    children: [
                        {
                            id: 4,
                            label: '二级 1-1'
                        }
                    ]
                }
            ],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            loaded: false,
            active: '0-0',
            x: '',
            target: null
        }
    },
    methods: {
        handleOpen() {},
        handleClose() {},
        handleSelect(val) {
            this.$emit('change', val)
        },
        toggleMenu() {
            this.isCollapse = !this.isCollapse
            this.$emit('toggle', this.isCollapse) // 处理CaseList右侧宽度自适应
            this.$listener.$emit('toggle')
        },
        // tree 相关方法
        handleDragStart(node, ev) {
            console.log('drag start', node, ev)
        },
        handleDragEnter(draggingNode, dropNode, ev) {
            console.log('tree drag enter: ', dropNode.label, ev)
        },
        handleDragLeave(draggingNode, dropNode, ev) {
            console.log('tree drag leave: ', dropNode.label, ev)
        },
        handleDragOver(draggingNode, dropNode, ev) {
            console.log('tree drag over: ', dropNode.label, ev)
        },
        handleDragEnd(draggingNode, dropNode, dropType, ev) {
            console.log(
                'tree drag end: ',
                dropNode && dropNode.label,
                dropType,
                ev
            )
        },
        handleDrop(draggingNode, dropNode, dropType, ev) {
            console.log('tree drop: ', dropNode.label, dropType, ev)
        },
        allowDrop(draggingNode, dropNode, type) {
            if (dropNode.data.label === '二级 3-1') {
                return type !== 'inner'
            } else {
                return true
            }
        },
        allowDrag(draggingNode) {
            return draggingNode.data.label.indexOf('三级 3-2-2') === -1
        },

        async loadNode(node, resolve) {
            if (!this.loaded) {
                // tree第一次加载
                this.loaded = true
                return resolve([])
            }
            const data = await this.fetchData(node.data)
            return resolve(data)
        },
        async fetchData(node_data) {
            const param = {
                req: {
                    caseId: this.case_id,
                    cmd: 'tree',
                    args: {
                        target: node_data.hash
                    }
                }
            }
            const res = await this.$API.sendRequest('FileOperation', param)
            if (res.success) {
                let data = []
                res.data.tree.forEach((item) => {
                    data.push({
                        label: item.name,
                        hash: item.hash,
                        extension: item.extension,
                        size: item.size,
                        children: []
                    })
                })
                this.$emit('set_c', node_data)
                return data
            }
        },
        async loadData(data) {
            await this.fetchData(data)
        }
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
    display: none;
}

.menu_wrapper {
    min-height: 400px;
    height: 100%;
}

.el-menu {
    text-align: left;
    overflow-y: scroll;
    box-shadow: -0.5px 0 2px 2px rgba(153, 148, 178, 0.3);
    border-radius: 5px;
}

.el-menu:not(.el-menu--collapse) {
    width: 250px;
}

/* .el-menu>>>.el-sub-menu.is-active .el-sub-menu__title {
  background-color: rgba(69, 120, 255, 0.2)!important;
} */
:deep(.el-menu .el-sub-menu.is-active .el-sub-menu__title) {
    background-color: rgba(69, 120, 255, 0.2) !important;
}

/* .el-menu>>>.el-sub-menu.is-active::before {
  content: url("../assets/image/side_menu/active_bar.png");
  position: absolute;
  transform: scale(0.5);
  top: -4px;
} */
:deep(.el-menu .el-sub-menu.is-active::before) {
    content: url('../assets/image/side_menu/active_bar.png');
    position: absolute;
    transform: scale(0.5);
    top: -4px;
}

/* .el-menu>>>.el-menu-item::before {
  content: '•';
  position: relative;
  left: -20px;
} */
:deep(.el-menu .el-menu-item::before) {
    content: '•';
    position: relative;
    left: -20px;
}

/* .el-menu>>>.el-menu-item.is-active::before {
  color: #4578FF;
} */
:deep(.el-menu .el-menu-item.is-active::before) {
    color: #4578ff;
}

/* .el-menu>>>.el-menu-item:hover {
  background: rgba(69, 120, 255, 0.1);
} */
:deep(.el-menu .el-menu-item:hover) {
    background: rgba(69, 120, 255, 0.1);
}

/* .el-menu>>>.el-sub-menu__title span {
  color: #4578FF;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
} */
:deep(.el-menu .el-sub-menu__title span) {
    color: #4578ff;
    font-size: 16px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
}

.l1-icon {
    width: 16px;
    margin-right: 10px;
}

/* .el-menu>>>.el-menu-item-group__title {
  padding: 2px 0 2px 20px;
} */
:deep(.el-menu .el-menu-item-group__title) {
    padding: 2px 0 2px 20px;
}

.menu-top {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
}

.top-btn {
    cursor: pointer;
}

.top-btn img {
    width: 40px;
    height: 40px;
}

.top-btn svg {
    color: #1b2539;
    opacity: 0.5;
    font-weight: bold;
}

.top-btn:hover svg {
    opacity: 1;
}

.tree_icon {
    width: 20px;
}

.folder_name {
    font-size: 14px;
    margin: 1px 0 0 5px;
}

.tree {
    /*width: 100%;*/
    width: 270px;
    overflow-x: scroll;
    padding-top: 15px;
}
</style>
