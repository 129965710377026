<template>
    <div class="registerCaseBox" v-loading="registerLoading">
        <div class="showBox" v-show="!registerLoading">
            <header>
                <el-row>
                    <el-col :span="10">
                        <h2>{{$t('msg.Case')}}：<span>{{$t('msg.NoCaseNumberisAvailable')}}</span></h2>
                    </el-col>
                    <el-col :span="14">
                        <div class="rightBtnBox">
                            <!-- 查看错误按钮 -->
                            <!-- v-if="btnTypeShow.repulseArbitration" -->

                            <el-button
                                @click="showErrorBtn"
                                type="danger"
                                v-if="false"
                                >{{$t('msg.ViewingErrors')}}</el-button
                            >
                            <el-button
                                @click="showArbitrationLog"
                                type="primary"
                                v-if="false"
                                >{{$t('msg.viewLog')}}</el-button
                            >

                            <el-button type="primary" @click="leaveMessageShow"
                                >{{$t('msg.MessageBoard')}}</el-button
                            >
                            <el-button
                                type="danger"
                                v-if="btnTypeShow.delArbitration"
                                @click="clickDelArbitration"
                                >{{$t('msg.DeleteTheCurrentCase')}}</el-button
                            >

                            <el-button
                                v-if="deleteMaxBtn?.data?.submitArbitration"
                                @click="clickSubmitArbitration"
                                type="primary"
                                >{{$t('msg.SubmittoCIETAC')}}</el-button
                            >
                            <el-button @click="goCasePges" type="primary"
                                >{{$t('msg.back')}}</el-button
                            >
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="15">
                        <h2 style="font-size: 16px">
                            {{$t('msg.ApplicationNumber')}}：<span>{{
                                caseObj.caseState === 1 ? '' : caseObj.receiveNo
                            }}</span>
                        </h2>
                    </el-col>
                    <el-col :span="9"> </el-col>
                </el-row>
            </header>
            <div class="alert" v-if="deleteMaxBtn.type !== '无'">
                <div>
                    <el-alert
                        :type="
                            deleteMaxBtn.type === '#green' ? 'success' : 'error'
                        "
                        :closable="false"
                        style="width: 100%"
                    >
                        <div
                            v-html="deleteMaxBtn.msg"
                            style="font-size: 20px"
                        ></div>

                        <div class="moreBtn">
                            <el-button @click="goPages" type="primary" plain
                                >{{$t('msg.workbench_more')}}</el-button
                            >
                        </div>
                    </el-alert>
                </div>
            </div>
            <!-- 当事人  -->
            <div class="titleBox">
                <h3>{{$t('msg.Party')}}</h3>
            </div>
            <div class="partyBox">
                <div class="left">
                    <!-- 申请人 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>{{$t('msg.Applicant')}}</span>
                                </div>
                                <div class="button">
                                    <el-button
                                        type="text"
                                        @click="showPartyDialog"
                                        v-if="allType"
                                        >[ {{$t('msg.Add')}} ]</el-button
                                    >
                                </div>
                            </div>
                        </template>
                        <div
                            class="box"
                            v-if="showApplyPartyInfoListA.length !== 0"
                        >
                            <div
                                class="mainText"
                                v-for="(item, index) in showApplyPartyInfoListA"
                                :key="item"
                                :draggable="true"
                            >
                                <div
                                    class="leftMainText"
                                    @click="partyDetailA(item)"
                                >
                                    <p>
                                        {{ index + 1 }}.<span>
                                            {{ item.partyCompany }}</span
                                        >
                                    </p>
                                </div>
                                <div class="rightMainText">
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            {{$t('msg.Operate')}}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="partyDetailA(item)"
                                                    >{{$t('msg.Edit')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    v-if="allType"
                                                    @click="
                                                        clickMoveUp(
                                                            index,
                                                            'showApplyPartyInfoListA'
                                                        )
                                                    "
                                                    >{{$t('msg.MoveUp')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="delParty(item, 1)"
                                                    v-if="allType"
                                                    >{{$t('msg.Delete')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>

                        <!-- 申请人代理人/法定代表人 -->
                        <div class="elseBoxSpan" v-else>
                            <span>{{$t('msg.ApplicantInformationHasnotBeenAdded')}}</span>
                        </div>
                    </el-card>

                    <!-- 申请人代理人/法定代表人 开始-->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>{{$t('msg.AgentofTheApplicant')}}</span>
                                </div>
                                <div class="button">
                                    <el-button
                                        type="text"
                                        v-if="allType"
                                        @click="showAgentDialog"
                                        >[ {{$t('msg.Add')}} ]</el-button
                                    >
                                </div>
                            </div>
                        </template>
                        <div
                            class="box"
                            v-if="showApplyDeputyInfoListA.length !== 0"
                        >
                            <div
                                class="mainText"
                                v-for="(
                                    item, index
                                ) in showApplyDeputyInfoListA"
                                :key="item"
                            >
                                <div
                                    class="leftMainText"
                                    @click="agentDetailA(item, 1)"
                                >
                                    <p>
                                        {{ index + 1 }}.<span>{{
                                            item.deputyName
                                        }}</span>
                                    </p>
                                </div>
                                <div class="rightMainText">
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                           {{$t('msg.Operate')}}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        agentDetailA(item, 1)
                                                    "
                                                    >{{$t('msg.Edit')}}</el-dropdown-item
                                                >

                                                <el-dropdown-item
                                                    @click="delAgent(item, 2)"
                                                    v-if="allType"
                                                    >{{$t('msg.Delete')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="elseBoxSpan" v-else>
                            <span>{{$t('msg.ApplicantAgentInformationHasNotBeenAdded')}}</span>
                        </div>
                    </el-card>

                    <!-- 申请人代理人/法定代表人 结束-->
                </div>
                <div class="center"></div>
                <div class="right">
                    <!-- 被申请人 -->
                    <el-card class="box-card" shadow="hover">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>{{$t('msg.TheDefendant')}}</span>
                                </div>
                                <div class="button">
                                    <el-button
                                        type="text"
                                        v-if="allType"
                                        @click="showRegisterDialog"
                                        >[ {{$t('msg.Add')}} ]</el-button
                                    >
                                </div>
                            </div>
                        </template>
                        <div
                            class="box"
                            v-if="showApplyPartyInfoListB.length !== 0"
                        >
                            <div
                                class="mainText"
                                v-for="(item, index) in showApplyPartyInfoListB"
                                :key="item"
                            >
                                <div
                                    class="leftMainText"
                                    @click="partyDetailB(item)"
                                >
                                    <p>
                                        {{ index + 1 }}.<span>{{
                                            item.partyCompany
                                        }}</span>
                                    </p>
                                </div>
                                <div class="rightMainText">
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            {{$t('msg.Operate')}}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="partyDetailB(item)"
                                                    >{{$t('msg.Edit')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    v-if="allType"
                                                    @click="
                                                        clickMoveUp(
                                                            index,
                                                            'showApplyPartyInfoListB'
                                                        )
                                                    "
                                                    >{{$t('msg.MoveUp')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="delParty(item, 3)"
                                                    v-if="allType"
                                                    >{{$t('msg.Delete')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="elseBoxSpan" v-else>
                            <span> {{$t('msg.ApplicantAgentInformationHasNotBeenAdded')}}</span>
                        </div>
                    </el-card>
                    <!-- 被申请人代理人/法定代表人 -->
                    <el-card class="box-card" shadow="hover" v-if="false">
                        <template #header>
                            <div class="card-header">
                                <div class="text">
                                    <span>{{$t('msg.AgentoftheRespondent')}}</span>
                                </div>
                                <div class="button">
                                    <el-button
                                        type="text"
                                        v-if="allType"
                                        @click="isAgentDialogShow"
                                        >[ {{$t('msg.Add')}} ]</el-button
                                    >
                                </div>
                            </div>
                        </template>

                        <div
                            class="box"
                            v-if="showApplyDeputyInfoListB.length !== 0"
                        >
                            <div
                                class="mainText"
                                v-for="(
                                    item, index
                                ) in showApplyDeputyInfoListB"
                                :key="item"
                            >
                                <div
                                    class="leftMainText"
                                    @click="agentDetailA(item, 2)"
                                >
                                    <p>
                                        {{ index + 1 }}.<span>{{
                                            item.deputyName
                                        }}</span>
                                    </p>
                                </div>
                                <div class="rightMainText">
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                           {{$t('msg.Operate')}}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        agentDetailA(item, 2)
                                                    "
                                                    > {{$t('msg.Edit')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="delAgent(item, 4)"
                                                    v-if="allType"
                                                    >{{$t('msg.Delete')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="elseBoxSpan" v-else>
                            <span> {{$t('msg.TheInformationofTheRespondentIsAgentHasNotBeenAdded')}}</span>
                        </div>
                    </el-card>
                </div>
            </div>

            <!-- 送达地址 -->
            <div class="titleBox">
                <h3>{{$t('msg.AddressForService')}}</h3>
                <div class="butBox">
                    <el-button
                        type="text"
                        @click="addressDialogShow"
                        v-if="allType"
                        >[ {{$t('msg.Add')}} ]</el-button
                    >
                </div>
            </div>
            <div class="addressBox">
                <el-card v-if="showElectronicDeliveryList.length !== 0">
                    <el-table
                        :data="showElectronicDeliveryList"
                        stripe
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="date"
                            :label="$t('msg.PersonServingaDocument')"
                            min-width="15%"
                        >
                            <template #default="scope">
                                <div style="display: flex">
                                    <el-tag type="success">{{
                                        scope.row.partyType === 1
                                            ? '申请人'
                                            : '被申请人'
                                    }}</el-tag>
                                    <div>
                                        <!-- {{ scope.row.partyName }}&nbsp;&nbsp; -->
                                        {{ scope.row.partyCompany }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="partyName"
                            :label="$t('msg.Recipients')"
                            min-width="10%"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="phone"
                            :label="$t('msg.TelephoneNumberforDelivery')"
                            min-width="10%"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="email"
                            :label="$t('msg.Email')"
                            min-width="20%"
                        >
                        </el-table-column>
                        <el-table-column  :label="$t('msg.DetailedAddress')" min-width="35%">
                            <template #default="scope">
                                <p
                                    v-for="(item, index) in scope.row
                                        .electronicAddresses"
                                    :key="index"
                                >
                                    {{ item.companyAddress }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.Operate')" min-width="10%">
                            <template #default="scope">
                                <div>
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            {{$t('msg.Operate')}}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        addressView(scope.row)
                                                    "
                                                    >{{$t('msg.Edit')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="
                                                        addressDel(scope.row)
                                                    "
                                                    v-if="allType"
                                                    >{{$t('msg.Delete')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card v-else>
                    <div class="nullAddressItem">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-bianji"></use>
                        </svg>
                        <p>{{$t('msg.TheCurrentDataisEmpty')}}</p>
                    </div>
                </el-card>
            </div>

            <!-- 仲裁依据 -->
            <div class="titleBox">
                <h3>{{$t('msg.ArbitrationBasis')}}</h3>
                <div class="butBox">
                    <el-button
                        type="text"
                        @click="arbitrationDialogShow"
                        v-if="allType"
                        >[ {{$t('msg.Add')}} ]</el-button
                    >
                </div>
            </div>
            <div class="materialBox">
                <!-- 有数据 展示 -->
                <el-card v-if="true">
                    <el-table :data="showApplyCaseInfoList" style="width: 100%">
                        <el-table-column prop="contractName" :label="$t('msg.ContractName')">
                        </el-table-column>
                        <el-table-column :label="$t('msg.SigningDate')">
                            <template #default="scope">
                                <div>
                                    {{
                                        scope.row.contractDate ||
                                        scope.row.note2
                                    }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="contractNo" :label="$t('msg.ContractNo')">
                        </el-table-column>
                        <el-table-column :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button
                                    link
                                    type="primary"
                                    size="small"
                                    @click="repeatShow(scope.row)"
                                    >{{$t('msg.View')}}</el-button
                                >
                                <el-button
                                    link
                                    type="danger"
                                    size="small"
                                    @click="repeatDelete(scope.row)"
                                    v-if="allType"
                                    >{{$t('msg.Delete')}}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <!-- 没数据展示 -->
                <el-card v-else class="materialBoxItem"> {{$t('msg.TheCurrentDataisEmpty')}} </el-card>
            </div>

            <!-- 仲裁请求 -->
            <div class="titleBox">
                <h3>{{$t('msg.ArbitrationClaims')}}</h3>
                <div class="butBox">
                    <el-button
                        type="text"
                        v-if="allType"
                        @click="claimsDialogShow(showApplyAppendInfo)"
                        >[
                        {{ showApplyAppendInfo.arbClaim ? $t('msg.Revamp') : $t('msg.Add') }}
                        ]</el-button
                    >
                </div>
            </div>
            <div class="applyForBox">
                <el-card>
                    <el-row>
                        <el-col :span="5"> <p>{{$t('msg.ArbitrationClaims')}}</p> </el-col>
                        <el-col :span="2"></el-col>
                        <el-col
                            :span="17"
                            style="display: flex; align-items: center"
                        >
                            <div v-if="this.showApplyAppendInfo.arbClaim">
                                <el-button
                                    @click="drawerapplyForItemA"
                                    type="text"
                                    style="font-size: 16px"
                                >
                                    {{$t('msg.ViewArbitrationClaim')}}
                                </el-button>

                                <el-drawer
                                    :title="$t('msg.ArbitrationClaims')"
                                    v-model="drawerTypeA"
                                    direction="rtl"
                                    :before-close="drawerTypeAClose"
                                >
                                    <div
                                        class="applyForItemA"
                                        ref="applyForItemARef"
                                    ></div>
                                </el-drawer>
                            </div>
                            <div v-else> {{$t('msg.ThereIsNoRequestForArbitration')}}</div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="5"> <p>{{$t('msg.FactsandReasons')}}</p> </el-col>
                        <el-col :span="2"></el-col>
                        <el-col
                            :span="17"
                            style="display: flex; align-items: center"
                        >
                            <div v-if="this.showApplyAppendInfo.arbClaim">
                                <el-button
                                    @click="drawerapplyForItemB"
                                    type="text"
                                    style="font-size: 16px"
                                >
                                    {{$t('msg.ViewFactsandReasons')}}
                                </el-button>

                                <el-drawer
                                    :title="$t('msg.FactsandReasons')"
                                    v-model="drawerTypeB"
                                    direction="rtl"
                                    :before-close="drawerTypeBClose"
                                >
                                    <div
                                        class="applyForItemB"
                                        ref="applyForItemBRef"
                                    ></div>
                                </el-drawer>
                            </div>
                            <div v-else>{{$t('msg.ThereArenoFactsorReasons')}}</div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>

            <!-- 保全 -->
            <div class="titleBox">
                <h3>{{$t('msg.ConservatoryMeasures')}}</h3>
                <div class="butBox">
                    <el-button
                        type="text"
                        @click="protectDialogShow('')"
                        v-if="allType"
                        >[ {{$t('msg.Add')}} ]</el-button
                    >
                </div>
            </div>
            <div class="applyForBox">
                <el-card>
                    <el-table :data="protectList" style="width: 100%">
                        <el-table-column
                            prop="preserveTypeName"
                            :label="$t('msg.PreservationType')"
                        >
                        </el-table-column>
                        <el-table-column prop="courtName" :label="$t('msg.CourtName')">
                        </el-table-column>
                        <el-table-column prop="courtAddress" :label="$t('msg.CourtAddress')">
                        </el-table-column>
                        <el-table-column
                            prop="preserveContent"
                            :label="$t('msg.Application')"
                        >
                        </el-table-column>
                        <el-table-column :label="$t('msg.Operate')" width="150px">
                            <template #default="scope">
                                <el-button
                                    link
                                    type="primary"
                                    size="small"
                                    @click="protectDialogShow(scope.row)"
                                    >{{ allType ? $t('msg.Revamp') : $t('msg.View') }}</el-button
                                >
                                <el-button
                                    link
                                    type="danger"
                                    size="small"
                                    @click="protectItemDel(scope)"
                                    v-if="allType"
                                    >{{$t('msg.Delete')}}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>

            <!-- 申请人材料 -->
            <div class="titleBox">
                <h3>{{$t('msg.ApplicantsEvidentiaryMaterials')}}</h3>
                <div class="butBox">
                    <el-button
                        type="text"
                        @click="materiaDialogShow"
                        v-if="allType"
                        >[ {{$t('msg.Add')}} ]</el-button
                    >
                </div>
            </div>
            <div class="materialBox" v-loading="materialBoxLoading">
                <el-card v-if="true">
                    <el-table
                        :data="showDigitalUploadInfoList"
                        style="width: 100%"
                    >
                        <el-table-column type="index" :label="$t('msg.S_N')" width="60px">
                        </el-table-column>
                        <el-table-column prop="profile" :label="$t('msg.NameoftheMaterial')">
                        </el-table-column>
                        <el-table-column prop="materialFlag" :label="$t('msg.Claimant')" >
                            <template #default="scope">
                                <div>
                                    {{ uploadEnum[scope.row.materialFlag] }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('msg.Annex')">
                            <template #default="scope">
                                <div>
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            {{ scope.row.fileName }}
                                            <el-icon class="el-icon--right">
                                                <arrow-down />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    v-if="
                                                        !(
                                                            scope.row
                                                                .flagPdf === 2
                                                        )
                                                    "
                                                    @click="
                                                        viewFileMaterial(
                                                            scope.row
                                                        )
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="
                                                        downLoadFileMaterial(
                                                            scope.row
                                                        )
                                                    "
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="address" :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button
                                    link
                                    size="small"
                                    type="primary"
                                    @click="clickViewPreview(scope)"
                                    >{{$t('msg.View')}}</el-button
                                >
                                <el-button
                                    link
                                    type="danger"
                                    size="small"
                                    @click="deleteMyMaterialList(scope.row)"
                                    v-if="allType"
                                    >{{$t('msg.Delete')}}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card v-else> {{$t('msg.NoMaterialatPresent')}} </el-card>
            </div>

            <el-divider style="margin: 20px 0 20px 0"></el-divider>

            <!-- 提交至仲裁委  -->
            <div class="footerBtnBox">
                <el-button
                    v-if="deleteMaxBtn?.data?.submitArbitration"
                    @click="clickSubmitArbitration"
                    type="primary"
                    >{{$t('msg.SubmittoCIETAC')}}</el-button
                >
            </div>
        </div>

        <!-- 添加申请人弹窗开始        被申请人弹窗 -->
        <el-dialog
            v-model="partyDialog"
            :show-close="false"
            :close-on-click-modal="false"
            top="2vh"
            width="80%"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">
                            {{
                                partyTextTitle === 1
                                    ? $t('msg.AddanApplicant')
                                    : $t('msg.AddTheDefendant')
                            }}
                        </h4>
                        <div class="but">
                            <el-button type="danger" @click="partyClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div>
                <partyDialog
                    ref="partyDialogRef"
                    @patyDialogClose="partyClose"
                ></partyDialog>
            </div>
        </el-dialog>
        <!-- 添加申请人弹窗结束              被申请人弹窗 -->

        <!-- 添加申请人代理人弹窗 开始                添加被申请人代理人弹窗 -->
        <el-dialog
            v-model="agentDialogShow"
            :show-close="false"
            top="2vh"
            width="80%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">
                            {{
                                agentTextTitle === 1
                                    ? $t('msg.AddAgentofTheApplicant')
                                    : $t('msg.AddAgentoftheRespondent')
                            }}
                        </h4>
                        <div class="but">
                            <el-button type="danger" @click="agentClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div>
                <agentDialog
                    ref="agentDialogRef"
                    @agentDialogClose="agentClose"
                ></agentDialog>
            </div>
        </el-dialog>
        <!-- 添加申请人代理人弹窗           添加被申请人代理人弹窗 结束 -->

        <!-- 仲裁依据弹窗开启 -->
        <el-dialog
            v-model="arbitrationDialog"
            :show-close="false"
            width="60%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.ArbitrationBasis')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="arbitrationClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                               {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div>
                <arbitrationDialogVue
                    ref="arbitrationDialogRef"
                    @arbitrationDialogClose="arbitrationClose"
                ></arbitrationDialogVue>
            </div>
        </el-dialog>
        <!-- 仲裁依据弹窗结束 -->

        <!-- 仲裁请求       弹窗开启 -->
        <el-dialog
            v-model="claimsDialog"
            :show-close="false"
            width="60%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.AddarbitrateRequests')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="claimsClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                               {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div>
                <claimsDialog
                    ref="claimsDialogRef"
                    @claimsDialogClose="claimsClose"
                ></claimsDialog>
            </div>
        </el-dialog>
        <!-- 仲裁请求       弹窗结束  -->

        <!-- 申请材料       弹窗开启 -->
        <el-dialog
            v-model="materialDialogType"
            :show-close="false"
            width="80%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.AddApplicantMaterials')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="materialClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div>
                <materialDialog
                    ref="materialRef"
                    @materialDialogClose="materialDialogClose"
                ></materialDialog>
            </div>
        </el-dialog>
        <!-- 申请材料       弹窗结束  -->

        <!-- 地址第一选择材料       弹窗开启 -->
        <el-dialog
            v-model="addressDialog"
            :show-close="false"
            width="60%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.AddressForService')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="addressClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <div class="addressCardBox">
                <el-card
                    class="box-card"
                    shadow="hover"
                    @click="goAddressDialog(1)"
                >
                    1.
                    <el-button
                        type="text"
                        :disabled="showApplyPartyInfoListA.length < 1"
                        >{{$t('msg.ApplicantsAddressofService')}}>></el-button
                    >
                </el-card>
                <el-card
                    class="box-card"
                    shadow="hover"
                    @click="goAddressDialog(2)"
                >
                    2.
                    <el-button
                        type="text"
                        :disabled="showApplyPartyInfoListB.length < 1"
                        >{{$t('msg.ServiceAddressoftheRespondent')}}>></el-button
                    >
                </el-card>
            </div>
        </el-dialog>
        <!-- 地址第一选择材料     弹窗结束  -->

        <!-- 日志      弹窗开始  -->
        <el-dialog
            v-model="caseTimeLineShow"
            :show-close="false"
            width="80%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.ViewLog')}}</h4>
                        <div class="but">
                            <el-button
                                type="danger"
                                @click="caseTimeLineShowClose"
                            >
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                               {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <CaseTimeLineLogDialog
                ref="CaseTimeLineLogDialogRef"
            ></CaseTimeLineLogDialog>
        </el-dialog>
        <!-- 日志       弹窗结束  -->

        <!-- 留言板      弹窗开始  -->
        <el-dialog
            v-model="leaveMessageType"
            :show-close="false"
            width="80%"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.MessageBoard')}}</h4>
                        <div class="but">
                            <el-button type="danger" @click="leaveMessageClose">
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>

            <el-divider></el-divider>

            <leaveMessageDialog ref="leaveMessageRef"></leaveMessageDialog>
        </el-dialog>
        <!-- 留言板       弹窗结束  -->

        <!-- 查看错误左侧弹出窗 -->
        <div
            :class="{
                myDrawer: true,
                myDrawerHide: errorType
            }"
        >
            <div class="drawerBox">
                <el-row>
                    <el-col :span="20"></el-col>
                    <el-col :span="4">
                        <div
                            :class="{
                                errorIconBox: true
                            }"
                            @click="showErrorBtn"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-guanbi"></use>
                            </svg>
                        </div>
                    </el-col>
                </el-row>
                <el-tabs type="card" :stretch="true">
                    <el-tab-pane
                        :label="item.title"
                        :name="index"
                        v-for="(item, index) in errorObj"
                        :key="index"
                    >
                        <!-- 当事人  -->
                        <div class="titleBox">
                            <h3>{{ item.title }}</h3>
                        </div>
                        <div class="errorBoxMask">
                            <p
                                v-for="(text, index) in item.remindMark"
                                :key="index"
                            >
                                {{ index + 1 }}.{{ text }}
                            </p>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <div class="abLogoBox"></div>
            </div>
        </div>

        <!-- 查看错误左侧弹出窗 -->

        <!-- 保全 弹 窗 开启 -->
        <el-dialog
            :title="$t('msg.AddConservatoryMeasures')"
            v-model="protectDialogType"
            width="60%"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <template #title>
                <div class="partyDialogStyle">
                    <div class="my-header">
                        <h4 class="titleClass">{{$t('msg.AddConservatoryMeasures')}}</h4>
                        <div class="but">
                            <el-button
                                type="danger"
                                @click="protectDialogClose"
                            >
                                <el-icon class="el-icon--left"
                                    ><CircleCloseFilled
                                /></el-icon>
                                {{$t('msg.back')}}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>
            <protectDialog
                ref="protectDialogRef"
                @emitClose="protectDialogClose"
            >
            </protectDialog>
        </el-dialog>
        <!-- 保全 弹 窗 关闭 -->

        <!-- 提交至贸仲委   开启-->
        <el-dialog
            :title="$t('msg.SubmittoCIETAC')"
            v-model="submitAllDialog"
            width="50%"
            :before-close="submitAllDialogClose"
        >
            <div class="contentBox">
                <el-row>
                    <el-col :span="5"
                        ><span><strong> {{$t('msg.SelectSubmittoHeadquartersChapters')}}：</strong></span>
                    </el-col>
                    <el-col :span="19">
                        <div
                            style="marign: 10px"
                            v-if="deleteMaxBtn?.data?.submitArbitration"
                        >
                            <el-select
                                v-model="unitValue"
                                filterable
                                placeholder="请选择"
                                style="width: 100%"
                                :disabled="
                                    !deleteMaxBtn.data.flagSubmitArbitration
                                "
                            >
                                <el-option
                                    v-for="(item, index) in unitList"
                                    :key="index"
                                    :label="item.organizationName"
                                    :value="item.organizationId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
                <p>
                    注：请按照仲裁条款上所写名称来选择总会或其他分会，如约定贸仲，仲裁地在其他城市，请选择贸仲总会受理。
                </p>
                <p>
                    贸仲现行《仲裁规则》（2024版）第二十一条规定“电子文本与纸质文本不一致的，以电子文本为准，除非当事人另有约定”。因此，请申请人慎重、准确录入相关信息。
                </p>
                <el-divider></el-divider>
                <div class="promptBox">
                    <p>
                        <span>{{$t('msg.AddanApplicant')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="this.showApplyPartyInfoListA.length === 0"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use>
                            </svg>
                        </span>
                    </p>
                    <el-divider></el-divider>

                    <p>
                        <span>{{$t('msg.AddTheDefendant')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="this.showApplyPartyInfoListB.length === 0"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                    <el-divider></el-divider>

                    <p>
                        <span>{{$t('msg.ApplicantsAddressofService')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="!partyAddressA"
                            >
                                <!-- this.showElectronicDeliveryList.length === 0 -->

                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                    <el-divider></el-divider>
                    <p>
                        <span>{{$t('msg.ServiceAddressoftheRespondent')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="!partyAddressB"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                    <el-divider></el-divider>

                    <p>
                        <span>{{$t('msg.ArbitrationBasis')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="this.showApplyCaseInfoList.length === 0"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                    <el-divider></el-divider>

                    <p>
                        <span>{{$t('msg.ArbitrationClaims')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="!this.showApplyAppendInfo.arbClaim"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                    <el-divider></el-divider>

                    <p>
                        <span>{{$t('msg.ApplicantsEvidentiaryMaterials')}}</span
                        ><span>
                            <svg
                                class="icon"
                                aria-hidden="true"
                                v-if="!this.hasProfileWithValue"
                            >
                                <use xlink:href="#icon-cuowu"></use>
                            </svg>
                            <svg class="icon" aria-hidden="true" v-else>
                                <use xlink:href="#icon-dui"></use></svg
                        ></span>
                    </p>
                </div>
            </div>
            <div class="btnBox">
                <el-button @click="submitAllDialog = false">{{$t('msg.back')}}</el-button>
                <el-button type="primary" @click="clickSubmitAll"
                    >{{$t('msg.SubmittoCIETAC')}}</el-button
                >
            </div>
        </el-dialog>

        <!-- 提交至贸仲委   关闭-->

        <!-- 提交至贸仲委提示文字弹窗   开启  -->
        <el-dialog
            title="提示"
            v-model="submitAllText"
            width="40%"
            :before-close="submitAllTextClose"
        >
            <p class="submitAllTextStyle">
                {{$t('msg.YourCaseNumberIs')}}<span>{{ caseObj.receiveNo }}</span
                >，{{$t('msg.KeepinMind')}}。
            </p>
            <template #footer>
                <div style="display: flex; justify-content: center">
                    <el-button @click="submitAllTextClose">{{$t('msg.ShutDown')}}</el-button>
                    <el-button @click="clickSubmitAll" type="primary"
                        >{{$t('msg.Confirm')}}</el-button
                    >
                </div>
            </template>
        </el-dialog>
        <!-- 提交至贸仲委提示文字弹窗   关闭  -->
    </div>
</template>
 
<script>
import partyDialog from './partyDialog.vue'
import agentDialog from './agentDialog.vue'
import arbitrationDialogVue from './arbitrationDialog.vue'
import claimsDialog from './claimsDialog.vue'
import materialDialog from './materialDialog.vue'
import CaseTimeLineLogDialog from './CaseTimeLineLogDialog.vue'
import leaveMessageDialog from './leaveMessageDialog.vue'
import { ElMessageBox } from 'element-plus'
import protectDialog from './protectDialog.vue'
export default {
    name: 'registerCase',
    components: {
        partyDialog,
        agentDialog,
        arbitrationDialogVue,
        claimsDialog,
        materialDialog,
        CaseTimeLineLogDialog,
        leaveMessageDialog,
        protectDialog
    },
    data() {
        return {
            tableData: [],
            // 加载效果
            registerLoading: false,
            // 申请人窗口
            partyDialog: false,

            // 申请人代理人窗口
            agentDialogShow: false,

            // 判断申请人是  申还是被
            partyTextTitle: 0,

            // 判断申请人是  申还是被
            agentTextTitle: 0,

            // 案件编号等等
            caseObj: {},

            // 仲裁依据弹窗状态
            arbitrationDialog: false,

            // 仲裁申请弹框
            claimsDialog: false,

            // 申请人材料弹框
            materialDialogType: false,

            // 地址页弹窗
            addressDialog: false,

            // 申请人
            showApplyPartyInfoListA: [],
            // /被申请人
            showApplyPartyInfoListB: [],

            // 申请人代理人
            showApplyDeputyInfoListA: [],
            // /被申请人代理人
            showApplyDeputyInfoListB: [],
            // 仲裁依据
            showApplyCaseInfoList: [],
            // 仲裁请求
            showApplyAppendInfo: {
                arbClaim: '',
                factReason: ''
            },
            // 申请人材料
            showDigitalUploadInfoList: [],
            // 回显电子送达信息
            showElectronicDeliveryList: [],
            // 下载枚举

            downEnum: this.$store.state.downEnum,
            // 申请人材料表格的loading效果
            materialBoxLoading: false,
            // 分会列表
            unitList: [],
            // 选中的分会
            unitValue: 1,
            // 申请人点击的
            partyItem: {},
            // 申请人点击的对象
            agentItem: {},
            agentFlag: 0,
            // 上传人的枚举
            uploadEnum: {
                1: '申请人',
                2: '申请代理人',
                3: '被申请人',
                4: '被申请人',
                5: '保全',
                6: '申请人材料'
            },
            // 删除当前按钮是否可以删除
            deleteMaxBtn: {},
            // 日志时间线
            caseTimeLineShow: false,
            // 仲裁请求页的右侧框
            drawerTypeA: false,
            // 仲裁请求页的右侧框
            drawerTypeB: false,
            // 留言板弹窗状态
            leaveMessageType: false,
            // 错误显示左侧弹出框状态
            errorType: true,
            btnTypeShow: {},

            // 错误需要更改的数组
            errorObj: [],
            // 我的receiveNo
            myReceiveNo: '',

            // 保全数组
            protectList: [],
            // 保全弹窗
            protectDialogType: false,

            // 提交至仲裁委的弹窗
            submitAllDialog: false,

            // 申请人材料是不是没有选择自己写的
            hasProfileWithValue: false,

            // 提交至仲裁委需要的提示文字
            submitAllText: false
        }
    },
    methods: {
        async load(row) {
            // 清空数据
            this.clearArr()
            this.registerLoading = true
            this.unitValue = 1
            if (row.caseId !== '') {
                this.caseObj = row
            } else {
                // 新增一个caseID的请求
                let res = await this.$API.sendRequest('ApplyArbitration')
                this.caseObj = res.data
            }
            this.$store.commit('setCaseObj', this.caseObj)
            this.$store.commit('removePartyList')
            this.myReceiveNo = this.caseObj.receiveNo
            this.deleteMaxBtn = await this.returnDelFlag(this.caseObj)
            let promises = [
                this.getShowApplyPartyInfo(1),
                this.getShowApplyPartyInfo(2),
                this.getShowApplyDeputyInfo(1),
                this.getShowApplyDeputyInfo(2),
                this.getShowApplyCaseInfo(),
                this.getShowApplyAppendInfo(),
                this.getShowDigitalUploadInfo(),
                this.getShowElectronicDelivery(),
                this.getShowprotectList()
            ]
            let type = await Promise.all(promises)
            this.showApplyPartyInfoListA = type[0]
            this.showApplyPartyInfoListB = type[1]
            this.showApplyDeputyInfoListA = type[2]
            this.showApplyDeputyInfoListB = type[3]
            this.showApplyCaseInfoList = type[4]
            this.showApplyAppendInfo = type[5] || {
                arbClaim: '',
                factReason: ''
            }
            this.showDigitalUploadInfoList = type[6]
            this.showElectronicDeliveryList = type[7]
            this.protectList = type[8]

            await this.getShowDepartment()

            // 删除本地存储的当事人申请的下拉框数组,为了免于多于的请求
            this.$store.commit('removestyleTypePartyList')
            this.registerLoading = false
        },

        // 所有数据清空
        clearArr() {
            this.showApplyPartyInfoListA = []
            this.showApplyPartyInfoListB = []
            this.showApplyDeputyInfoListA = []
            this.showApplyDeputyInfoListB = []
            this.showApplyCaseInfoList = []
            this.showApplyAppendInfo = {
                arbClaim: '',
                factReason: ''
            }
            this.showDigitalUploadInfoList = []
            this.showElectronicDeliveryList = []
            this.protectList = []
        },

        async getRepulseArbitration() {
            let param = {
                req: this.caseObj.caseId
            }
            let { data } = await this.$API.sendRequest(
                'RepulseArbitration',
                param
            )
            // this.errorObj = data
            // this.errorObj =
            // this.errorObj =
            for (let key in data) {
                if (data[key].remindMark.length !== 0) {
                    this.errorObj.push(data[key])
                }
            }
        },

        // 判断当前案件是否可删除
        async returnDelFlag(row) {
            let param = {
                req: {
                    caseId: row.caseId,
                    receiveNo: row.receiveNo
                }
            }
            let res = await this.$API.sendRequest('ReturnDelFlag', param)
            if (res.msg === null) {
                this.btnTypeShow = res.data
                return {
                    type: '无',
                    msg: '无',
                    data: { ...res.data }
                }
            } else {
                this.btnTypeShow = res.data
            }

            // let parts = await res.msg.split('#')
            // let beforeHash = parts[0]
            // let afterHash = parts[parts.length - 1]

            let indexCount = res.msg.lastIndexOf('#')
            let firstStr = res.msg.substring(0, indexCount)
            let lastStr = res.msg.substring(indexCount, res.msg.length) // red green
            return {
                type: lastStr,
                msg: firstStr,
                data: { ...res.data }
            }
        },
        // 展示申请人/被申请人
        async getShowApplyPartyInfo(type) {
            let param = {
                req: {
                    caseId: this.caseObj.caseId,
                    partyType: type
                }
            }
            let res = await this.$API.sendRequest('ShowApplyPartyInfo', param)
            return res.data
        },

        // 展示申请人代理人/被申请人代理人
        async getShowApplyDeputyInfo(type) {
            let param = {
                req: {
                    caseId: this.caseObj.caseId,
                    partyType: type
                }
            }
            let res = await this.$API.sendRequest('ShowApplyDeputyInfo', param)
            return res.data
        },

        // 展示仲裁依据
        async getShowApplyCaseInfo() {
            let param = {
                req: this.caseObj.caseId
            }
            let res = await this.$API.sendRequest('ShowApplyCaseInfo', param)
            return res.data
        },

        // 展示请求
        async getShowApplyAppendInfo() {
            let param = {
                req: this.caseObj.caseId
            }
            let res = await this.$API.sendRequest('ShowApplyAppendInfo', param)
            return res.data
        },

        // 展示申请人材料
        async getShowDigitalUploadInfo() {
            let param = {
                req: this.caseObj.caseId
            }
            let res = await this.$API.sendRequest(
                'ShowDigitalUploadInfo',
                param
            )
            return res.data
        },
        // 展示电子送达信息
        async getShowElectronicDelivery() {
            let param = {
                req: this.caseObj.caseId
            }
            let res = await this.$API.sendRequest(
                'ShowElectronicDelivery',
                param
            )
            return res.data
        },

        // 展示保全的数组
        async getShowprotectList() {
            let param = {
                req: this.caseObj.caseId
            }
            let res = await this.$API.sendRequest(
                'ShowApplyPreserveInfo',
                param
            )
            return res.data
        },

        // 添加申请人弹窗弹窗开启
        showPartyDialog() {
            this.partyDialog = true
            this.partyTextTitle = 1
            this.$nextTick(() => {
                this.$refs.partyDialogRef.load(
                    1,
                    this.caseObj,
                    undefined,
                    this.allType
                )
            })
        },

        // 被申请人弹窗开启
        async showRegisterDialog() {
            this.partyDialog = true
            this.partyTextTitle = 2

            this.$nextTick(() => {
                this.$refs.partyDialogRef.load(
                    2,
                    this.caseObj,
                    undefined,
                    this.allType
                )
            })
        },

        // 申请人代理人弹窗开启
        showAgentDialog() {
            if (this.showApplyPartyInfoListA.length >= 1) {
                this.agentDialogShow = true
                this.agentTextTitle = 1
                this.$nextTick(() => {
                    this.$refs.agentDialogRef.load(
                        1,
                        this.caseObj,
                        undefined,
                        this.allType
                    )
                })
            } else {
                this.$message.info('请先填写申请人！')
            }
        },

        // 被申请人代理人弹窗
        async isAgentDialogShow() {
            if (this.showApplyPartyInfoListB.length >= 1) {
                this.agentDialogShow = true
                this.agentTextTitle = 2
                this.showApplyDeputyInfoListB =
                    await this.getShowApplyDeputyInfo(2)
                this.$nextTick(() => {
                    this.$refs.agentDialogRef.load(
                        2,
                        this.caseObj,
                        undefined,
                        this.allType
                    )
                })
            } else {
                this.$message.info('请先填写被申请人')
            }
        },

        //添加申请人弹窗弹窗关闭
        async partyClose() {
            this.partyDialog = false
            let newArrA = await this.getShowApplyPartyInfo(1)
            let newArrB = await this.getShowApplyPartyInfo(2)
            this.showApplyPartyInfoListA = newArrA
            this.showApplyPartyInfoListB = newArrB
            this.showElectronicDeliveryList =
                await this.getShowElectronicDelivery()
            this.materialClose()
            this.$refs.partyDialogRef.clickResetFields()
        },

        // 申请人代理人弹窗关闭
        async agentClose() {
            this.agentDialogShow = false
            let newArrA = await this.getShowApplyDeputyInfo(1)
            let newArrB = await this.getShowApplyDeputyInfo(2)
            this.showApplyDeputyInfoListA = newArrA
            this.showApplyDeputyInfoListB = newArrB
            this.materialClose()
            this.$refs.agentDialogRef.clickResetFields()
        },

        // 仲裁依据弹窗开启
        arbitrationDialogShow() {
            this.arbitrationDialog = true
            this.$nextTick(() => {
                this.$refs.arbitrationDialogRef.load(
                    this.caseObj,
                    this.allType,
                    this.myReceiveNo
                )
            })
        },

        // 仲裁依据弹窗关闭
        async arbitrationClose() {
            this.arbitrationDialog = false
            this.showApplyCaseInfoList = await this.getShowApplyCaseInfo()
            this.$refs.arbitrationDialogRef.clearForm()
        },

        // 仲裁申请页开启
        claimsDialogShow(obj) {
            this.claimsDialog = true
            this.$nextTick(() => {
                this.$refs.claimsDialogRef.load(
                    this.caseObj,
                    obj,
                    this.myReceiveNo,
                    this.allType
                )
            })
        },

        // 仲裁申请页关闭
        async claimsClose() {
            this.claimsDialog = false
            this.showApplyAppendInfo =
                (await this.getShowApplyAppendInfo()) || {}
            this.showDigitalUploadInfoList =
                await this.getShowDigitalUploadInfo()
            this.$refs.claimsDialogRef.clearForm()
        },

        // 添加申请材料弹窗 ----开启
        materiaDialogShow() {
            this.materialDialogType = true
            this.$nextTick(() => {
                this.$refs.materialRef.load(this.caseObj)
            })
        },

        // 添加申请材料弹窗--------关闭
        async materialClose() {
            this.materialDialogType = false
            this.showDigitalUploadInfoList =
                await this.getShowDigitalUploadInfo()
            if (!this.$refs.materialRef) {
                return
            }
            this.$refs.materialRef.clearForm()
        },

        // 地址页弹窗开启
        addressDialogShow() {
            if (this.showApplyPartyInfoListA < 1) {
                return this.$message.info('请至少添加一位当事人后确认地址！')
            }
            this.addressDialog = true
        },

        // 地址弹框关闭
        addressClose() {
            this.addressDialog = false
        },

        // 送达弹出框开启
        goAddressDialog(type) {
            this.addressDialog = false
            this.$listener.$emit('goService', {
                partyType: type,
                caseId: this.caseObj.caseId,
                receiveNo: this.caseObj.receiveNo,
                allType: this.allType
            })
        },

        // 地址送达弹出框关闭
        confirmClose() {
            this.confirmDialog = false
        },

        // 申请材料点击查看
        clickViewPreview(scope) {
            this.materialDialogType = true
            this.$nextTick(() => {
                this.$refs.materialRef.load(this.caseObj, scope.row)
            })
        },

        // 申请材料关闭
        async materialDialogClose() {
            this.materialDialogType = false
            this.showDigitalUploadInfoList =
                await this.getShowDigitalUploadInfo()
            this.$refs.materialRef.clearForm()
        },

        // 申请材料列表下载
        async downLoadFileMaterial(row) {
            this.materialBoxLoading = true
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.materialBoxLoading = false
            const filename = row.fileName
            let fileType = filename.substring(filename.lastIndexOf('.'))
            for (let key in this.downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: this.downEnum[key] })
                    let url = window.URL.createObjectURL(blob)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = row.fileName
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            }
        },

        // 申请材料列表预览
        async viewFileMaterial(row) {
            this.materialBoxLoading = true
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.materialBoxLoading = false
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 仲裁依据点击列表中的查看
        repeatShow(row) {
            this.arbitrationDialog = true
            this.$nextTick(() => {
                this.$refs.arbitrationDialogRef.load(
                    row,
                    this.allType,
                    this.myReceiveNo
                )
            })
        },

        // 申请人点击操作
        async partyDetailA(item) {
            this.partyItem = item
            this.partyTextTitle = 1
            let param = {
                req: {
                    partyId: item.partyId,
                    materialFlag: 1
                }
            }
            let res = await this.$API.sendRequest('ShowPartyInfo', param)
            this.partyDialog = true

            this.$nextTick(() => {
                this.$refs.partyDialogRef.load(
                    1,
                    this.caseObj,
                    res.data,
                    this.allType
                )
            })
        },

        // 被申请人点击操作
        async partyDetailB(item) {
            this.partyItem = item
            this.partyTextTitle = 2
            let param = {
                req: {
                    partyId: item.partyId,
                    materialFlag: 3
                }
            }
            let res = await this.$API.sendRequest('ShowPartyInfo', param)
            this.partyDialog = true

            this.$nextTick(() => {
                this.$refs.partyDialogRef.load(
                    2,
                    this.caseObj,
                    res.data,
                    this.allType
                )
            })
        },

        // 申请人代理人点击操作
        async agentDetailA(item, flag) {
            this.agentItem = item
            this.agentFlag = flag
            let param = {
                req: {
                    deputyId: item.deputyId,
                    materialFlag: flag === 1 ? 2 : 4
                }
            }
            let res = await this.$API.sendRequest('ShowDeputyInfo', param)
            if (this.showApplyPartyInfoListA.length >= 1) {
                this.agentDialogShow = true
                this.agentTextTitle = flag
                this.$nextTick(() => {
                    this.$refs.agentDialogRef.load(
                        flag,
                        this.caseObj,
                        res.data,
                        this.allType
                    )
                })
            } else {
                this.$message.info('请先填写申请人！')
            }
        },

        // 仲裁依据列表点击删除
        repeatDelete(row) {
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    let param = {
                        req: {
                            note3: row.note3,
                            caseId: row.caseId
                        }
                    }
                    let res = await this.$API.sendRequest('DelCaseInfo', param)
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.showApplyCaseInfoList =
                            await this.getShowApplyCaseInfo()
                    } else {
                        this.$message.error('删除失败')
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },

        // 请求分会列表
        async getShowDepartment() {
            let res = await this.$API.sendRequest('ShowDepartment')
            this.unitList = res.data
        },

        // 点击提交至仲裁委
        async clickSubmitArbitration() {
            // 打开弹窗
            this.submitAllDialog = true
        },
        // 点击了提交至仲裁委了
        async clickSubmitAll() {
            if (this.showApplyPartyInfoListA.length === 0) {
                return this.$message.error('申请人还未填写！')
            }
            if (this.showApplyPartyInfoListB.length === 0) {
                return this.$message.error('被申请人还未填写！')
            }
            if (!this.partyAddressA) {
                return this.$message.error('申请人送达地址还未填写！')
            }
            if (!this.partyAddressB) {
                return this.$message.error('被申请人送达地址还未填写！')
            }

            if (this.showApplyCaseInfoList.length === 0) {
                return this.$message.error('仲裁依据还未填写！')
            }

            if (
                this.showApplyAppendInfo.arbClaim === '' &&
                this.showApplyAppendInfo.factReason === ''
            ) {
                return this.$message.error('仲裁请求还未填写！')
            }

            if (this.showDigitalUploadInfoList.length == 0) {
                return this.$message.error('申请人材料还未填写！')
            } else {
                const hasProfileWithValue = this.showDigitalUploadInfoList.some(
                    (obj) => obj.profile !== ''
                )
                if (!hasProfileWithValue) {
                    return this.$message.error('申请人材料还未填写！')
                }
            }

            let param = {
                req: {
                    caseId: this.caseObj.caseId,
                    receiveNo: this.caseObj.receiveNo,
                    organizationId: this.unitValue
                }
            }

            // 第一次需要提醒
            if (this.deleteMaxBtn.data.flagSubmitArbitration) {
                this.submitAllText = true
                this.deleteMaxBtn.data.flagSubmitArbitration = false
            } else {
                let res = await this.$API.sendRequest(
                    'SubmitArbitration',
                    param
                )
                // 不是第一次提交不需要提醒
                if (res.success) {
                    this.$message.success(res.msg)
                    this.submitAllText = false
                    setTimeout(() => {
                        this.submitAllDialog = false
                        this.$emit('goCasePges')
                    }, 500)
                } else {
                    this.submitAllDialog = false
                    this.$message.error(res.msg)
                }
            }
            // this.submitAllText = false
        },

        // 提交至仲裁委弹窗关闭
        submitAllDialogClose() {
            this.submitAllDialog = false
        },

        // 点击申请人/被申请人是----------删除
        delParty(item, type) {
            ElMessageBox.confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    const fn = async () => {
                        let param = {
                            req: {
                                caseId: this.caseObj.caseId,
                                partyId: item.partyId,
                                materialFlag: type === 1 ? 1 : 3,
                                copyType: type === 1 ? 1 : 3
                            }
                        }

                        let res = await this.$API.sendRequest(
                            'DelPartyInfo',
                            param
                        )
                        if (!res.success) this.$message.error('删除失败')
                        //添加申请人弹窗弹窗关闭
                        this.partyClose()
                        this.$message.success(res.msg)
                        this.$refs.partyDialogRef.clickResetFields()
                    }
                    fn()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },

        // 点击被申请人代理人/被申请人代理人---------删除
        delAgent(item, type) {
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    const fn = async () => {
                        let param = {
                            req: {
                                caseId: this.caseObj.caseId,
                                deputyId: item.deputyId,
                                materialFlag: type === 2 ? 2 : 4,
                                copyType: type === 2 ? 2 : 4
                            }
                        }
                        let res = await this.$API.sendRequest(
                            'DelDeputyInfo',
                            param
                        )

                        if (!res.success) this.$message.error('删除失败')
                        await this.agentClose()
                        this.$message.success(res.msg)
                        setTimeout(async () => {
                            this.$refs.agentDialogRef.clickResetFields()
                        })
                    }
                    fn()
                })
                .catch(async () => {
                    await this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },

        // 删除申请人证据材料表格
        async deleteMyMaterialList(scope) {
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const fn = async () => {
                        let param = {
                            req: {
                                id: scope.id,
                                caseId: this.caseObj.caseId
                            }
                        }
                        let res = await this.$API.sendRequest(
                            'DelUploadInfo',
                            param
                        )
                        if (!res.success) this.$message.error(res.msg)
                        this.$message.success(res.msg)
                        this.showDigitalUploadInfoList =
                            await this.getShowDigitalUploadInfo()
                    }
                    fn()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },

        // 点击删除当前的所有
        clickDelArbitration() {
            this.$confirm('此操作将删除当前案件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.registerLoading = true
                    const fn = async () => {
                        let param = {
                            req: {
                                caseId: this.caseObj.caseId,
                                receiveNo: this.caseObj.receiveNo
                            }
                        }
                        let res = await this.$API.sendRequest(
                            'DelArbitration',
                            param
                        )
                        if (!res.success) this.$message.error(res.msg)
                        this.$message.success(res.msg)
                        setTimeout(() => {
                            this.registerLoading = false
                            this.$emit('goCasePges')
                        }, 500)
                    }
                    fn()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message:this.$t('msg.Undelete')
                    })
                })
        },

        // ShowArbitrationLog
        async showArbitrationLog() {
            this.caseTimeLineShow = true
            this.$nextTick(() => {
                this.$refs.CaseTimeLineLogDialogRef.load(this.caseObj)
            })
        },
        // 点击关闭日志弹窗
        caseTimeLineShowClose() {
            this.caseTimeLineShow = false
        },

        // 仲裁请求右侧打开弹窗
        drawerapplyForItemA() {
            this.drawerTypeA = true
            this.$nextTick(() => {
                this.$refs.applyForItemARef.innerHTML =
                    this.showApplyAppendInfo.arbClaim
            })
        },
        drawerTypeAClose() {
            this.drawerTypeA = false
        },
        // 事实与理由右侧打开弹窗
        drawerapplyForItemB() {
            this.drawerTypeB = true
            this.$nextTick(() => {
                this.$refs.applyForItemBRef.innerHTML =
                    this.showApplyAppendInfo.factReason
            })
        },
        drawerTypeBClose() {
            this.drawerTypeB = false
        },

        // 留言板
        leaveMessageShow() {
            this.leaveMessageType = true
            this.$nextTick(() => {
                this.$refs.leaveMessageRef.load(this.caseObj)
            })
        },

        // 关闭留言板
        leaveMessageClose() {
            this.leaveMessageType = false
        },

        // 左侧弹出框事件
        showErrorBtn() {
            this.errorType = !this.errorType
            if (!this.errorType) {
                this.errorObj = []
            }

            this.getRepulseArbitration()
        },

        // 保全弹框开启
        async protectDialogShow(row) {
            this.protectDialogType = true
            this.$nextTick(() => {
                this.$refs.protectDialogRef.load(
                    this.allType,
                    row,
                    this.caseObj
                )
            })
        },
        // 关闭保全弹窗
        async protectDialogClose() {
            this.protectDialogType = false
            this.protectList = await this.getShowprotectList()
            this.$refs.protectDialogRef.closeDilalogClear()
        },

        // 删除保全列表表格
        protectItemDel(scope) {
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    let res = await this.protectItemDelFn(scope)

                    if (res.success) {
                        this.protectList.splice(scope.$index, 1)
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Undelete')
                    })
                })
        },
        // 删除保全列表中的方法
        async protectItemDelFn(scope) {
            let param = {
                req: {
                    caseId: scope.row.caseId,
                    id: scope.row.id,
                    materialFlag: 5
                }
            }
            let res = await this.$API.sendRequest('DelPreserveInfo', param)
            return res
        },

        // 点击上移
        async clickMoveUp(index, type) {
            if (index < 1) {
                return this.$message.info('第一位不能往上移')
            }
            let param = {
                req: {
                    partyId: this[type][index].partyId,
                    partyIdTop: this[type][index - 1].partyId,
                    caseId: this.caseObj.caseId
                }
            }
            let res = await this.$API.sendRequest('MoveParty', param)
            if (!res.success) this.$message.error(res.msg)
            if (index > 0 && index < this[type].length) {
                let temp = this[type][index - 1]
                this[type][index - 1] = this[type][index]
                this[type][index] = temp
            }
            this.$message.success(res.msg)
        },

        // 点击地址的查看
        async addressView(row) {
            setTimeout(() => {
                this.$listener.$emit('goService', {
                    caseId: row.caseId,
                    partyId: row.partyId,
                    partyType: row.partyType,
                    receiveNo: this.caseObj.receiveNo,
                    allType: this.allType
                })
            }, 200)
        },

        // 点击地址上的删除
        async addressDel(row) {
            let param = {
                req: {
                    caseId: row.caseId,
                    partyId: row.partyId,
                    partyType: row.partyType
                }
            }
            let res = await this.$API.sendRequest(
                'DelElectronicDeliveryParty',
                param
            )
            if (!res.success) this.$message.error(res.msg)
            this.showElectronicDeliveryList =
                await this.getShowElectronicDelivery()
            this.$message.success(res.msg)
        },

        // 返回上一页
        goCasePges() {
            this.$emit('goCasePges')
        },

        // 关闭了弹窗提交至仲裁委的提示弹窗
        submitAllTextClose() {
            this.submitAllText = false
            this.deleteMaxBtn.data.flagSubmitArbitration = true
        },

        // 点击跳转到消息中心
        goPages() {
            this.$emit('goMessageCenterPages', {
                caseObj: this.caseObj
            })
        }
    },
    computed: {
        allType() {
            return this.btnTypeShow.editArbitration
        },
        // 申请人送达地址
        partyAddressA() {
            return this.showElectronicDeliveryList.some(
                (item) => item.partyType === 1
            )
        },
        // 被申请人送达地址
        partyAddressB() {
            return this.showElectronicDeliveryList.some(
                (item) => item.partyType === 2
            )
        }
    },
    watch: {
        showDigitalUploadInfoList(newValue) {
            this.hasProfileWithValue = newValue.some(
                (obj) => obj.profile !== ''
            )
        }
    }
}
</script>
 
<style lang="scss" scoped>
.registerCaseBox {
    text-align: left;
    padding: 0 10px;
}
header {
    h2 {
        font-weight: 500;
        font-size: 18px;
    }
    .rightBtnBox {
        height: 100%;
        display: flex;

        .el-button {
            margin: 0 20px;
        }
    }
}

.titleBox {
    display: flex;
    // justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #ff0000;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
    .butBox {
        margin-left: 20px;
        display: flex;
        align-self: center;
    }
}
.partyBox {
    display: flex;
    .left {
        flex: 1;
    }
    .center {
        width: 20px;
    }
    .right {
        flex: 1;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        .text {
            display: flex;
            align-items: center;
            color: #8788a3;
        }
    }
    :deep(.el-card__header) {
        background-color: #d9ecf6;
        padding: 10px 20px;
    }
    .box-card {
        .mainText {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            span {
                color: #629fd3;
            }
            .leftMainText {
                display: flex;
                align-content: center;
                cursor: pointer;
            }
            .rightMainText {
                display: flex;
                align-items: center;
            }
            .elseBoxSpan {
                span {
                    color: #8788a3;
                }
            }
        }
    }
    :deep(.el-col-20) {
        align-items: center;
    }
}

.materialBoxItem {
    background-color: #fbf9e5;
}

.applyForBox {
    p {
        text-align: right;
        font-weight: 600;
    }
    .applyForItem {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.addressBox {
    .nullAddressItem {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        p {
            margin: 0 10px;
        }
    }
    .addressBoxItem {
        width: 100%;
    }
    p {
        font-size: 16px;
    }
    span {
        margin-right: 20px;
        font-size: 14px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
    }
}

.my-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .but {
        display: flex;
        align-self: center;
    }
    .titleClass {
        font-size: 20px;
        font-weight: 500;
    }
}
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-content: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.addressCardBox {
    .box-card {
        cursor: pointer;
    }
    .box-card:nth-child(1) {
        margin: 20px 0;
    }
}

.elseBoxSpan {
    span {
        color: #8788a3;
    }
}

.myDrawer {
    display: block;
    z-index: 4;
    position: fixed;
    top: 0;
    width: 17%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px #000;
    transition: 1s;
    left: 0;

    .drawerBox {
        width: 100%;
        .errorIconBox {
            font-size: 20px;
            padding: 10px 10px;
            cursor: pointer;
        }
    }
}

.myDrawerHide {
    left: -18%;
    transition: 0.8s;
}
.drawerBox {
    height: 99%;
    width: 99%;
    background-image: url(../../assets/image/home/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    :deep(.el-tabs__nav-next) {
        font-size: 20px;
    }
    :deep(.el-tabs__nav-prev) {
        font-size: 20px;
    }
}

.btnBox {
    display: flex;
    justify-content: center;
}
.contentBox {
    margin: 20px 0;
    padding-left: 20px;
    p {
        font-size: 14px;
    }
    span {
        font-size: 16px;
    }
}

.footerBtnBox {
    display: flex;
    justify-content: center;
}

.promptBox {
    p {
        display: flex;
        justify-content: space-between;
    }
}

.submitAllTextStyle {
    font-size: 20px;
    span {
        font-size: 24px;
        color: red;
    }
}

:deep(.el-alert__content) {
    width: 100%;
    .moreBtn {
        font-size: 16px;
        display: flex;
        flex-direction: row-reverse;
    }
}
</style>