<template>
    <div>
        <el-card>
            <div class="containerHomePage"> 
                <!-- 画图 -->
                <div class="topHeaderContainer" v-if="OrType">
                    <div class="guideBox">
                        <div class="guideLeft">
                            <h4>{{$t('msg.GuidelinesforFilingArbitrationCases')}}</h4>
                            <p>
                                {{$t('msg.Apartymaysubmitanapplicationforarbitrationinthreeways')}}
                            </p>
                            <p>
                                {{$t('msg.Thepartieschooseofflinefilingandmailingfilingpleasereferto')}}
                                <span
                                    @click="goSearchGuidePages('仲裁申请须知')"
                                    >【{{$t('msg.Instructionsforarbitration')}}】</span
                                >
                                {{$t('msg.PrepareyourfilingForonlinefilingguidelinespleasereferto')}}
                                <span @click="goSearchGuidePages('立案指引')"
                                    >【{{$t('msg.Onlinefilingguide')}}】</span
                                > 
                                。
                            </p>
                        </div>

                        <div class="guideRight">
                            <div
                                class="goCreateCasePagesBox"
                                @click="clickgoCreateCasePagesBox"
                            >
                                <el-card
                                    shadow="hover"
                                    v-if="$store.state.loginInfo.role === 1"
                                >
                                    <div
                                        style="
                                            display: flex;
                                            align-items: center;
                                            height: 100%;
                                        "
                                    >
                                        <i>
                                            <svg
                                                class="icon"
                                                aria-hidden="true"
                                            >
                                                <use
                                                    xlink:href="#icon-shenqing"
                                                ></use>
                                            </svg>
                                        </i>
                                        <span
                                            style="
                                                font-size: 26px;
                                                font-weight: 500;
                                                color: blue;
                                            "
                                            class="bottonLine"
                                        >
                                            {{$t('msg.Submitanapplicationforarbitration')}}</span
                                        >
                                    </div>
                                </el-card>
                            </div>
                            <ul>
                                <li @click="goSearchGuidePages('常见问题')">
                                    <span>{{$t('msg.Summaryoffrequentlyaskedquestions')}}</span>
                                    <span class="moreText">[ {{$t('msg.workbench_more')}} ]</span>
                                </li>
                                <li @click="goSearchGuidePages('立案指引')">
                                    <i>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-bangzhu"
                                            ></use>
                                        </svg>
                                    </i>
                                    <span class="bottonLine">
                                        {{$t('msg.Documentsrequiredtoapplyforarbitration')}}</span
                                    >
                                </li>

                                <li @click="goSearchGuidePages('申请退费')">
                                    <i>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-huo"></use>
                                        </svg>
                                    </i>
                                    <span class="bottonLine">
                                        {{$t('msg.RefundofArbitrationFeesforWithdrawalofCase')}}</span
                                    >
                                </li>
                                <li @click="goSearchGuidePages('庭审指引')">
                                    <i>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-huo"></use>
                                        </svg>
                                    </i>
                                    <span class="bottonLine">
                                        {{$t('msg.GuidelinesforVideoTrial')}}</span
                                    >
                                </li>
                                <li @click="goSearchGuidePages('申请开票')">
                                    <i>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-bangzhu"
                                            ></use>
                                        </svg>
                                    </i>

                                    <span class="bottonLine">
                                        {{$t('msg.Issueaninvoiceforthearbitrationfee')}}</span
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
 
<script>
export default {
    name: 'parayHomePage',
    components: {},
    data() {
        return {
            OrType: true
        }
    },
    methods: {
        // 去往查询复函指引
        goSearchGuidePages(text) {
            // this.right_type = 'searchGuidePagesVue'
            const { href } = this.$router.resolve({
                path: '/SearchGuidePages',
                query: {
                    type: text
                }
            })
            window.open(href, '_blank')
        },

        // 跳去案件新增
        clickgoCreateCasePagesBox() {
            this.$confirm(
               this.$t('msg.Article21ofthecETACArbitranformation'),
                '提示',
                {
                    confirmButtonText: this.$t('msg.Confirm'),
                    cancelButtonText: this.$t('msg.case_bind_cancel'),
                    type: 'warning'
                }
            )
                .then(async () => {
                    let res = await this.$API.sendRequest(
                        'FlagApplyArbitration'
                    )
                    if (!res.success) {
                        this.$message.error(res.msg)
                    } else {
                        this.$listener.$emit('goRegisterCaseListener', {
                            caseId: ''
                        })
                        this.$listener.$emit('topNavupload')
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('msg.Cancelled')
                    })
                })
        }
    }
}
</script>
 

<style scoped lang="scss">
.containerHomePage {
    padding: 20px;
}
.topHeaderContainer {
    width: 100%;
    .guideBox {
        display: flex;
        .guideLeft {
            font-size: 20px;
            padding: 20px;
            text-align: left;
            flex: 5;
            background-color: #85bef3;
            color: #fff;
            p {
                font-size: 18px;
                span {
                    color: #2448a8;
                    cursor: pointer;
                }
            }
        }
        .guideRight {
            margin-left: 30px;
            flex: 2;
            text-align: left;
            ul {
                margin: 0;
                li {
                    padding: 10px;
                    border: 1px solid #85bef3;
                    i {
                        padding: 0 10px;
                    }
                    &:nth-child(1) {
                        font-size: 20px;
                        background-color: #85bef3;
                        display: flex;
                        justify-content: space-between;
                        .moreText {
                            font-size: 16px;
                            color: #fff;
                        }
                        &:hover .moreText {
                            color: #2448a8;
                        }
                    }
                }
            }

            .goCreateCasePagesBox {
                &:hover .bottonLine::after {
                    width: 100%;
                }
                .bottonLine {
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: 3px;
                        width: 0;
                        border-bottom: 3px solid #fff;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
}
li {
    cursor: pointer;

    &:not(:nth-child(1)):hover {
        color: #85bef3;
    }
    &:hover .bottonLine::after {
        width: 100%;
    }
    .bottonLine {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 3px;
            width: 0;
            border-bottom: 3px solid #85bef3;
            transition: all 0.5s;
        }
    }
}

.goCreateCasePagesBox {
    margin-bottom: 10px;
    margin-left: 40px;
    cursor: pointer;
    :deep(.el-card__body) {
        height: 60px;
        transition: all 0.3s;
        background-color: #c9e8ab;
        &:hover {
            background-color: #999fd8;
            color: #fff !important;
        }
    }
    i {
        font-size: 30px;
        margin: 0 10px;
    }
}
</style>