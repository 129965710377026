var userAgent = navigator.userAgent,
	rMsie = /(msie\s|trident.*rv:)([\w.]+)/,
	rFirefox = /(firefox)\/([\w.]+)/,
	rOpera = /(opera).+version\/([\w.]+)/,
	rChrome = /(chrome)\/([\w.]+)/,
	rSafari = /version\/([\w.]+).*(safari)/;
var browser;
// eslint-disable-next-line no-unused-vars
var version;
// var ua = userAgent.toLowerCase();
function uaMatch(ua) {
	var match = rMsie.exec(ua);
	if (match != null) {
		return { browser: "IE", version: match[2] || "0" };
	}
	match = rFirefox.exec(ua);
	if (match != null) {
		return { browser: match[1] || "", version: match[2] || "0" };
	}
	match = rOpera.exec(ua);
	if (match != null) {
		return { browser: match[1] || "", version: match[2] || "0" };
	}
	match = rChrome.exec(ua);
	if (match != null) {
		return { browser: match[1] || "", version: match[2] || "0" };
	}
	match = rSafari.exec(ua);
	if (match != null) {
		return { browser: match[2] || "", version: match[1] || "0" };
	}
	if (match != null) {
		return { browser: "", version: "0" };
	}
}
var browserMatch = uaMatch(userAgent.toLowerCase());
if (browserMatch.browser) {
	browser = browserMatch.browser;
	version = browserMatch.version;
}

var str = '';

// var copyright = '金格科技iWebOffice2015智能文档中间件[演示版];V5.0S0xGAAEAAAAAAAAAEAAAAJEBAACgAQAALAAAAEnETMLfjzlFKkLbsBP8QVd2P5OtkkDyMgxgC1pFIRacyXbx8diNwmCITUtMwEDfDyAdCEVVr6yXCHD97ZiaT8YIZCNeE4A7gs3HY2tM8sspVWCd30/CiZaDUx0AsqcXEEXoB/vsqnZaET0u1wGsphKe8ZKcvJJJdQ3Ciy2cTiQObN//pSPY/3LgdxdJeEyC5ibUvVW9ngjJG0brXt4N17r1TDygcaC+SZ/pBTLg3Hac6bHoHHks5P9R5HvAjICsShbtkO2snAIvvslWn0BSDoL4XRSKyUErCdOwgUs+QjQ7HkoziQI+YpszJD05dP2AxH39S8feWlTg0D9ouIEHTCQH0KNvzaqKc2c2dYqrHqY77SJ9JRozb9SsqruQvUBo/CG91rVivzUiZrybJ14KjepAVO1mAmbCzbSYMFtGFWlBIwN6NbNYBtD+jZ4EB2dLs+V7dQpbR7J/EZVI2Sg8npwls3b9DTxkaNOubL7CkvTNfpETB8Irmq8kkWYzGRrv0iOKgRvkwm8AnQqliHCzRdvytzmoaUVhjEiTMjbEMYkovu1U1W2cVLD4jxA1xcDiXw==';
var copyright = '中国国际经济贸易仲裁委员会[专用];V5.0S0xGAAEAAAAAAAAAEAAAAFcBAABgAQAALAAAADwvXAO+K2vDKBBh9ol/b7rEMQknTN8IHLvpjirjQy5x1E8PppsMdd9VT8NneLicuSATOF7RvJoRSOyZAaANjYSGxBJ/DhQg+xF9BStGRufjQJ57KNSUWH6QSSGLAaR/k1ByrwWISFDokivrvPAMof9ON+6OQW7/la2a2uAfkm1d48BelY6pkY8q5rkkWm/klG7LiLBD3VWc46xxcuHeFDAtQzQWCCtyF0h4TuZuCHsLEeNODGlQqi96HT5nM4gv9IfhcQocfuVJ24zk8PJAs0ZWlYv2Wa7bshcjcJKfsDewxr1tC786FDOx9tASDTaxdNp1ey5d7Ugcy5wBT3ljjW+IoLKLD0UkJbjknAgkecOsrJ1KHJ+hCwbSdz+f2jEllWWNFkHvOWMMdgcaOyOg4jGxYZP5d20qBPh1aMAXKJuRL5F/3MI2NDUnNs9iQBjurRS7gbfa2bXbSzouFI1OTypLFuIbCawGHCm1bXsM2LZb';


if ((window.ActiveXObject != undefined) || (window.ActiveXObject != null) || "ActiveXObject" in window) {
	str += '<object id="iWebPDF2018" ';
	str += ' width="100%"';
	str += ' height="100%"';
	// str += ' codebase="iWebPDF2018.cab#version=V9.0.2800.1834" ';
	str += ' codebase="iWebPDF2018.cab#version=V10.5.0.2140" ';
	/*
	if(window.navigator.platform == "Win32")
		str += ' CLASSID="CLSID:D89F482C-5045-4DB5-8C53-D2C9EE71D025"  codebase="iWebOffice2015.cab#version=12,5,0,648"';
	if (window.navigator.platform == "Win64")
		str += ' CLASSID="CLSID:D89F482C-5045-4DB5-8C53-D2C9EE71D024"  codebase="iWebOffice2015.cab#version=12,5,0,648"';
	str += '>';
	*/
	if (window.navigator.platform == "Win32")
		str += ' CLASSID="CLSID:7017318C-BC50-4DAF-9E4A-10AC8364C315"';
	if (window.navigator.platform == "Win64")
		str += ' CLASSID="CLSID:7017318C-BC50-4DAF-9E4A-10AC8364C364"';
	str += '>';

}
else if (browser == "chrome") {
	str += '<object id="iWebPDF2018" ';
	str += ' width="100%"';
	str += ' height="100%"';
	str += ' clsid="CLSID:7017318C-BC50-4DAF-9E4A-10AC8364C315"';
	str += ' type="application/kg-plugin"';
	str += ' OnAddinEventInvoke="OnAddinEventInvoke"';
	str += ' OnCommand="OnCommand"';
	str += ' OnOLECommand="OnOLECommand"';
	str += ' OnToolsClick="OnToolsClick"';
	str += ' Copyright="' + copyright + '"';
	str += '>';
}
else if (browser == "firefox") {
	str += '<object id="iWebPDF2015" ';
	str += ' width="100%"';
	str += ' height="100%"';
	str += ' clsid="CLSID:7017318C-BC50-4DAF-9E4A-10AC8364C315"';
	str += ' type="application/kg-activex"';
	str += ' OnCommand="OnCommand"';
	str += ' OnAddinEventInvoke="OnAddinEventInvoke"';
	str += ' OnOLECommand="OnOLECommand"';
	str += ' Copyright="' + copyright + '"';
	str += '>';
}
str += '</object>';
// document.write(str);
//alert(str);

function getStr() {
	if (str != null) {
		return str;
	}
}

export {
	getStr
}
