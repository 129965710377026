<template>
    <!-- 仲裁请求  -->
    <div v-loading="loadingBox">
        <el-form :model="info" class="partyForm" ref="partyFormRef">
            <!-- 添加申请人信息 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>{{$t('msg.AddmodifyanArbitrationRequest')}}</h4>
                    <el-divider></el-divider>
                    <!-- 仲裁请求  -->
                    <el-row style="margin-top: 20px">
                        <el-col :span="4"><p>{{$t('msg.ArbitrationClaims')}}</p></el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <!-- 富文本编辑器  -->
                                <TinymceEditor
                                    :boxHeight="300"
                                    style="z-index: 100"
                                    ref="TinymceEditorRefA"
                                />
                                <!-- 富文本编辑器  -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 事实与理由 -->
                    <el-row>
                        <el-col :span="4"><p>{{$t('msg.FactsandReasons')}}</p></el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <!-- 富文本编辑器  -->
                                <TinymceEditor
                                    :boxHeight="300"
                                    style="z-index: 100"
                                    ref="TinymceEditorRefB"
                                />
                                <!-- 富文本编辑器  -->
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 金额种类  -->
                    <el-row>
                        <el-col :span="4">
                            <p>{{$t('msg.TypeofAmount')}}</p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-select
                                    filterable
                                    placeholder="请选择"
                                    style="width: 100%"
                                    v-model="info.coinType"
                                >
                                    <el-option
                                        v-for="item in amountTypeList"
                                        :key="item.dictionaryItemValue"
                                        :label="item.remark"
                                        :value="item.dictionaryItemValue"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button
                        type="primary"
                        @click="addPartySubmit"
                        v-if="allType"
                        >{{$t('msg.Save')}}</el-button
                    >
                </div>
            </el-row>
        </el-form>
    </div>
</template>
 
<script>
import TinymceEditor from '../../components/TinymceEditor.vue'
export default {
    name: 'claimsDialog',
    components: {
        TinymceEditor
    },
    data() {
        return {
            // 页面已进入loading效果
            loadingBox: true,
            // 表格数据
            tableData: [],
            // 表单对象
            info: {
                // 仲裁请求
                arbClaim: '',
                // 事实与理由
                factReason: '',
                // 金额种类选中的
                coinType: 1
            },

            myObj: {},

            // 金额种类数组下拉框
            amountTypeList: [],

            // 在第一个弹窗保存的对象-----反显作用
            rowObj: '',
            // 第一次打开的默认数组
            list: [],
            // 传递过来用作反显的对象
            maxObj: {},
            // 下载枚举
            downEnum: this.$store.state.downEnum,
            fileShow: {},
            // 全局的应该用vuex的，大意了
            myReceiveNo: '',
            allType: false
        }
    },
    methods: {
        async load(caseObj, obj, myReceiveNo, allType) {
            this.$refs.TinymceEditorRefA.myValue = ''
            this.$refs.TinymceEditorRefB.myValue = ''
            this.myObj = caseObj
            this.maxObj = obj
            this.myReceiveNo = myReceiveNo
            this.allType = allType

            await this.getAmountType()
            await this.getPreserveTypeList()

            if (obj.arbClaim) {
                this.info = {
                    ...obj
                }
                this.$refs.TinymceEditorRefA.myValue = obj.arbClaim
                this.$refs.TinymceEditorRefB.myValue = obj.factReason
                this.list = obj.preserveIns
            }
            this.loadingBox = false
        },
        // 请求金额类型
        async getAmountType() {
            let param = {
                req: 24
            }
            let { data } = await this.$API.sendRequest('styleTypeList', param)
            this.amountTypeList = data.materialStyleList
        },
        // 请求保全类型
        async getPreserveTypeList() {
            let param = {
                req: 277
            }
            let { data } = await this.$API.sendRequest('styleTypeList', param)
            this.preserveTypeList = data.materialStyleList
        },
        // 点击保存
        addPartySubmit() {
            this.info.caseId = this.myObj.caseId
            this.$refs.partyFormRef.validate(async (valid) => {
                this.info.arbClaim = this.$refs.TinymceEditorRefA.myValue
                this.info.factReason = this.$refs.TinymceEditorRefB.myValue
                if (!this.info.arbClaim) {
                    return this.$message.error('请填写仲裁请求！')
                }
                if (!this.info.factReason) {
                    return this.$message.error('请填写事实与理由！')
                }
                if (valid) {
                    const newArbClaim = this.checkStrLabel(this.info.arbClaim)
                    const newFactReason = this.checkStrLabel(
                        this.info.factReason
                    )
                    let param = {
                        req: {
                            ...this.info,
                            receiveNo: this.myReceiveNo,
                            arbClaimText: newArbClaim,
                            factReasonText: newFactReason
                        }
                    }
                    let res = await this.$API.sendRequest('SaveAppend', param)
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.emitClose()
                    } else {
                        this.$message.error(res.msg)
                    }
                } else {
                    this.$message.error('请先填写完整表单！')
                }
            })
        },
        // 转换字符串的方法去去掉标签<>传入字符串   且将&nbsp;变成''空字符串
        checkStrLabel(str) {
            let str1 = str
                .replace(/<\/p>/g, '</p>(=￥=)')
                .replace(/<[^>]+>/g, '')
            str1 = str1.replaceAll('&nbsp;', '')
            return str1
        },

        // 时间组件设置当天以后不能选择
        disabledDateFn(time) {
            if (time.getTime() > Date.now()) {
                return true
            } else {
                return false
            }
        },

        // 清空表单
        clearForm() {
            this.info = {
                // 金额种类选中的
                coinType: 1
            }
            this.$refs.partyFormRef.resetFields()
        },

        // 清空表单和校验
        emitClose() {
            this.$emit('claimsDialogClose')
        }
    }
}
</script>
 



<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;
    .partyInfoBox {
        width: 100%;
        font-size: 16px;
    }
    .el-col-4 {
        display: flex;
        align-items: center;
        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;
            margin-top: 0;
        }
    }
    .el-col-20 {
        display: flex;
        align-content: center;
    }
}
:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}
.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cardTextBox {
    margin-top: 20px;
    a {
        text-decoration: none;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;
        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}
:deep(.el-date-editor--date) {
    width: 100% !important;
}

.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.tableBox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>