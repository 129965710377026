<template>
  <div class="lang_wrapper">
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <span :style="styleObj">{{ $t("msg.lang") }}</span>
        <!--
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
        -->
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="zh">中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "LangOption",
  props: {
    styleObj: {
      type: Object,
      default() {
        return {
          border: '1px solid #7F96CD',
          padding: '0px 10px',
          borderRadius: '3px'
        }
      }
    },
    position: {
      type: String
    }
  },
  created() {
    const lang = localStorage.getItem('lang')
    if (lang) this.$i18n.locale = lang
  },
  methods: {
    handleCommand(val) {
      this.$i18n.locale = val
      localStorage.setItem('lang', val)
      if (['login', 'home', 'finder'].includes(this.position)) {
        this.$listener.$emit('lang', val)
      }
    }
  }
}
</script>

<style scoped>
.el-dropdown-link {
  color: #fff;
  font-size: 13px;
}
.el-dropdown-link span  {
  word-break: keep-all;
}
</style>