<template>
  <el-container>
    <el-header>
      <Header/>
    </el-header>
    <el-main>
      <MainContent />
    </el-main>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
import MainContent from "@/components/MainContent";

export default {
  name: "index",
  components: {
    Header,
    MainContent
  }
}
</script>

<style scoped lang="sass">
.el-main
  position: relative
  top: -2px
</style>
