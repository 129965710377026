<template>
    <div class="containerMessage">
        <!-- 消息中心 -->
        <!-- 案件审批  -->
        <div class="titleBox" ref="top">
            <h3>{{$t('msg.Messagecenter')}}</h3>

            <div>
                 {{$t('msg.MessageType')}}：
                <el-select
                    v-model="dateInfo.msgStateValue"
                    filterable
                   :placeholder="$t('msg.Status')"
                >
                    <el-option label="全部" :value="0"> </el-option>
                    <el-option label="留言" :value="1"> </el-option>
                    <el-option label="消息" :value="2"> </el-option>
                </el-select>

                &nbsp; &nbsp; &nbsp; {{$t('msg.MessageStatus')}}：
                <el-select
                    v-model="dateInfo.typeValue"
                    filterable
                    :placeholder="$t('msg.Status')"
                >
                    <el-option
                        v-for="(item, index) in msgStateList"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                &nbsp; &nbsp; &nbsp;
                <el-date-picker
                    v-model="dateInfo.startDate"
                    type="date"
                    :placeholder="$t('msg.StartDate')"
                >
                </el-date-picker>
                &nbsp; &nbsp; &nbsp;
                <el-date-picker
                    v-model="dateInfo.endDate"
                    type="date"
                  :placeholder="$t('msg.EndingDate')"
                >
                </el-date-picker>
                &nbsp; &nbsp; &nbsp;

                <el-button @click="searchDate">{{$t('msg.finder_search')}}</el-button>
            </div>

            <!-- <div class="button">
                <el-button type="primary">打印案件审批</el-button>
            </div> -->
        </div>
        <el-card class="box-card">
            <myMessagePage ref="myMessagePageRef"></myMessagePage>
        </el-card>
    </div>
</template>
 
<script>
// import MyJournal from './MyJournal.vue'
import myMessagePage from './myMessagePage.vue'
export default {
    name: 'messageCenter',
    components: {
        myMessagePage
        // MyJournal
    },
    data() {
        return {
            // 全部的数组
            allList: [],
            // 切换状态

            // 时间
            dateInfo: {
                startDate: '',
                endDate: '',
                typeValue: 0,
                // 状态
                msgStateValue: 0
            },
            // 状态的枚举
            // msgStateObj:
            //     0: '全部',
            //     1: '已读',
            //     2: '未读'
            // }
            msgStateList: [
                {
                    id: 0,
                    text: '全部'
                },
                {
                    id: 1,
                    text: '已读'
                },
                {
                    id: 2,
                    text: '未读'
                }
            ]
        }
    },
    created() {
        if (this.$route.query.token) {
            this.GetOneTimeToken(this.$route.query.token)
            this.$nextTick(() => {
                this.load()
            })
        }
    },
    methods: {
        load() {
            this.dateInfo = {
                startDate: '',
                endDate: '',
                typeValue: 0,
                // 状态
                msgStateValue: 0
            }
            this.$refs.myMessagePageRef.load(this.dateInfo)
        },
        searchDate() {
            this.$refs.myMessagePageRef.clickSearch(this.dateInfo)
        },
        async GetOneTimeToken(token) {
            console.log(token, 'token')
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.CaseTimeLenDetail({
                caseId: this.$route.query.caseId
            })
        }
    },
    watch: {
        switchType(newValeu) {
            this.$refs.myMessagePageRef.clickMessageType(newValeu)
            this.dateInfo = {
                startDate: '',
                endDate: ''
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
.titleBox {
    display: flex;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
    }
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        margin-right: 20px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #ff0000;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
    .butBox {
        margin-right: 20px;
        display: flex;
        align-self: center;
    }
    .button {
        display: flex;
        align-items: center;
    }
}
</style>    