<template>
    <!-- 扫码检索 -->


    <div>
        <!-- 顶部搜索框开始 -->
        <div class="header" style="margin: 10px 0 10px 10px;">
            <el-row>
                <el-col :span="6">
                    <el-input v-model="searchContent" :placeholder="$t('msg.PleasentertheReceivingNumber')" @keyup.enter="serachClick" ref="focusInput"
                        :clearable="true"></el-input>

                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="serachClick" style="background-color: #79bbff;">
                        <el-icon class="el-icon--right">
                            <Search />
                        </el-icon>&nbsp;{{$t('msg.finder_search')}}
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-button @click="clearContent">
                        <el-icon class="el-icon--right">
                            <Delete />
                        </el-icon>&nbsp; {{$t('msg.case_reset')}}
                    </el-button>
                </el-col>
                <el-col :span="14"></el-col>
            </el-row>
        </div>
        <!-- 顶部搜索框结束 -->


        <!-- 主题表格开始 -->
        <el-table :data="searchObj" stripe style="width: 100%;display:flex;font-size:15px" :row-style="{ height: '50px' }"
            v-if="searchObj.data !== null">

            <el-table-column prop="receiveLetterNo" :label="$t('msg.ReceiptletterNumber')" min-width="15%">
            </el-table-column>

            <el-table-column prop="transferDate" :label="$t('msg.DateofReceipt')" min-width="15%">
                <template #default=scope>
                    <div>
                        {{ formData(scope.row.transferDate) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="materialTypeName" :label="$t('msg.MaterialType')" min-width="15%">
            </el-table-column>

            <el-table-column prop="profile" :label="$t('msg.FileDescription')" min-width="25%" show-overflow-tooltip>
            </el-table-column>


            <el-table-column :label="$t('msg.ImageprojectionFile')" min-width="30%" show-overflow-tooltip>
                <template #default="scope">
                    <div v-if="scope.row.fileName">
                        <!-- {{ scope.row.fileName }} -->
                        <el-dropdown>
                            <div v-if="scope.row.fileName.endsWith('.pdf')" style="margin:0 10px">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-pdf"></use>
                                </svg>
                            </div>
                            <div v-else style="margin:0 10px">
                                <svg t="1694139430288" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="5036" width="15" height="15">
                                    <path
                                        d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                        fill="#FF5562" p-id="5037"></path>
                                    <path
                                        d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                        fill="#FFBBC0" p-id="5038"></path>
                                    <path
                                        d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                        fill="#FFFFFF" p-id="5039"></path>
                                </svg>
                            </div>
                            <el-button type="text">{{ scope.row.fileName }}</el-button>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="PreviewPdfClick(scope.row)">{{$t('msg.Preview')}}</el-dropdown-item>
                                    <el-dropdown-item @click="PdfUpload(scope.row)">{{$t('msg.Download')}}</el-dropdown-item>

                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <div v-else>
                        {{$t('msg.Notyet')}}
                    </div>
                </template>
            </el-table-column>


        </el-table>
        <!-- 主题表格结束 -->

    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 搜索框内容
            searchContent: '',
            // 返回结果
            searchObj: []
        };
    },
    methods: {
        load() {
            // this.serachClick()
            this.focusInput()
        },
        // 点击第一页数组某一项去第二页数组
        async serachClick() {
            if (!this.searchContent) return this.$message.error('请先输入案件编号')
            const param = {
                "req": this.searchContent
            }
            const res = await this.$API.sendRequest('OcrFlagUpload', param)
            if (res.errorCode !== '10001') {
                this.searchObj = []
                this.searchContent = ''
                this.$message.error('暂无该编号')
                return
            }
            // this.searchContent = ''
            this.searchObj = res.data
            this.PreviewPdfClick(res.data[0])
            this.searchContent = ''

            // if (res.errorCode === '10001') {
            //     this.tableData = res.data.map(item => {
            //         const obj = {}
            //         obj.fileName = item.fileName
            //         obj.fileType = item.materialTypeName
            //         obj.fileText = item.profile
            //         obj.type = false
            //         return obj
            //     })
            // }
        },
        // 页面已进入就聚焦
        focusInput() {
            let focusInput = this.$refs.focusInput
            focusInput.focus()
        },
        // 点击清除
        clearContent() {
            this.searchContent = ''
            this.searchObj = []
        },
        // 预览
        async PreviewPdfClick(row) {
            // console.log(row);
            let param = {
                "req": {
                    "fileNameDsp": row.fileName,
                    "filePath": row.filePath,
                    "flagPdf": row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest('ocrDownFilePathAndName', param)
            let blob;
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }

            let url = window.URL.createObjectURL(blob)
            window.open(url)
        },
        // 下载
        async PdfUpload(row) {
            // console.log(row);
            let param = {
                "req": {
                    "fileNameDsp": row.fileName,
                    "filePath": row.filePath,
                    "flagPdf": row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest('ocrDownFilePathAndName', param)
            let blob;
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
        },
        // 时间格式化
        formData(data) {
            return this.$GF.formatTime(data, 'yyyy-MM-DD')
        }

    },
};
</script>
 
<style scoped >
:deep(.el-table td.el-table__cell div) {
    display: flex;
    align-items: center;
}
</style>
