<template>
	<el-config-provider :locale="locale">
		<router-view></router-view>
	</el-config-provider>
</template>

<script>
	// eslint-disable-next-line no-unused-vars
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'
	import en from 'element-plus/es/locale/lang/en'


	export default {
		name: 'App',
		created() {
			this.$listener.$on('lang', (val) => {
				if (val === 'en') this.locale = en
				if (val === 'zh') this.locale = zhCn
			})
			const lang = localStorage.getItem('lang')
			if (lang === 'en') this.locale = en
			if (lang === 'zh') this.locale = zhCn
		},
		data() {
			return {
				locale: zhCn
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	
</style>