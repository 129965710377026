<template>
    <div>
        <!-- 渲染数据表格开始 -->
        <div class="table">
            <el-table :data="list" strip border style="width: 100%">

                <el-table-column prop="receiveLetterNo" :label="$t('msg.Receiptnumber')" sortable width="180">
                    <template #default="scope">
                        <div class="tool" style="display: flex;margin-bottom:5px;">
                            <el-button type="text" @click="showDialog(scope.row.receiveLetterId)">
                                <el-icon class="el-icon--left">
                                </el-icon>{{ scope.row.receiveLetterNo }}
                            </el-button>
                        </div>
                    </template>

                </el-table-column>
                <el-table-column prop="senderCompany" :label="$t('msg.Unitofletter')" sortable width="180"></el-table-column>
                <el-table-column prop="receiveLetterDate" :label="$t('msg.Dateofletter')" sortable width="180"></el-table-column>
                <el-table-column prop="caseNo" :label="$t('msg.case')" width="180"></el-table-column>
                <el-table-column prop="dictionaryItemName" :label="$t('msg.Typeofcorrespondence')"></el-table-column>

            </el-table>
            <!-- 分页 -->
            <el-pagination style="margin-top:10px;display: flex;justify-content: center;" small="small"
                @size-change="paginationSizeChange" @current-change="paginationCurrentChange"
                :current-page="pagination.page" :page-sizes="pagination.sizes" :page-size="pagination.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
            </el-pagination>
        </div>
        <!-- 渲染数据表格结束 -->

        <!-- el-dalog弹出框开始 -->

        <!-- el-dalog弹出框结束 -->




        <!-- 点击案件编号显示弹出框开始 -->
        <el-dialog v-model="dialogFormVisible" :title="dialogTitle" width="60%" center @close="closeDialog">
            <el-form :model="bindModel" :rules="rules" label-width="100px" ref="bindModel" style="padding-right: 50px;">
                <!-- 第一行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <div style="width: 100%;height: 100%">
                            <el-col :span="10" class="fontWeight">{{$t('msg.ReceiptletterNumber')}} ： </el-col>
                            <el-col :span="14">{{ bindModel.receiveLetterNo }}</el-col>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.case')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.caseNo }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第二行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Subjectofreceipt')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.receiveLetterTitle }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Numberofdocumentsreceived')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.receiveLetterAmount }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第三行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.DateofReceipt')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.receiveLetterDate }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.MaterialType')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.materialStyleName }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第四行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.ModeofCommunication')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.receiveTypeName }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="8" class="fontWeight">{{$t('msg.Nameofthewriter')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.senderName }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第五行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.CorrespondenceAffiliation')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.senderCompany }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.PostalCode')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.senderZipcode }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第六行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Nationality')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.senderNationalityName }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.PhoneNumber')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.senderPhone }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第七行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Province')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.senderProvinceName }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Fax')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.senderFax }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第八行  -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.register_address')}}  ： </el-col>
                        <el-col :span="14">{{ bindModel.senderAddress }}</el-col>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="10" class="fontWeight">{{$t('msg.Secretary')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.secName }}</el-col>
                    </el-col>
                </el-row>
                <!-- 第九行 -->
                <el-row :gutter="10" class="borderLine">
                    <el-col :span="24">
                        <el-col :span="10" class="fontWeight">{{$t('msg.FileDescription')}} ： </el-col>
                        <el-col :span="14">{{ bindModel.letterContent }}</el-col>
                    </el-col>

                </el-row>



            </el-form>
            <template #footer>
                <el-button type="primary" @click="closeDialog">{{$t('msg.back')}} </el-button>
            </template>
        </el-dialog>
        <!-- 点击案件编号显示弹出框结束 -->

    </div>
</template>

<script>
// import {Plus} from '@element-plus/icons-vue'
export default {
    created() {
        //this.GetFileList()
    },
    props: {
        listMaterialListRtn: {
            type: Object
        }
    },
    data() {
        return {

            // 弹出框行为
            dialogFormVisible: false,
            // 渲染数组列表
            list: [],
            // 加载等待效果
            loading: false,
            // 分页参数
            pagination: {
                page: 1,
                pages: 0,
                limit: 100,
                sizes: [10, 20, 50, 100, 500, 1000],
                total: 0
            },
            // 弹出框提示语句
            dialogTitle: this.$t('msg.Detailsofreceipt'), // 弹出框标题 点编辑按钮 dialogTitle: '编辑'
            // 国别
            countryList: [],
            // 表单数据对象
            bindModel: {

            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            // 时间相关
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    methods: {
        // 页面一进入触发
        load() {
            this.GetFileList()
        },
        // 每一页的条数改变后执行
        paginationSizeChange(val) {
            this.pagination.limit = val;
            this.GetFileList()
        },
        // 当前第几页
        paginationCurrentChange(val) {
            this.pagination.page = val;
            this.GetFileList()
        },
        onSearch() {
            this.pagination.page = 1
            this.case_id = 16384
            this.GetFileList()
        },
        // 发送请求   获取表单数据
        async GetFileList() {
            const param = {
                "req": {
                    "current": this.pagination.page,
                    "size": this.pagination.limit,
                    "q": {
                        "startDate": this.listMaterialListRtn.startDate || '',
                        "endDate": this.listMaterialListRtn.endDate || '',
                        "materialStyle": this.listMaterialListRtn.dictionaryItemValue || ''
                    }

                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('listReceive', param)
            this.loading = false
            if (res.success) {
                this.list = res.data.records || []
                this.pagination.total = res.data.total
            } else {
                this.$message.error(res.msg)
            }
        },

        showDialog(receiveletterId) {
            this.dialogFormVisible = true
            this.GetReceiveDetail(receiveletterId)
        },
        closeDialog() {
            this.dialogFormVisible = false
            // 重置表单 所有字段全部清空
            this.bindModel = {
                name: '',
                receiveLetterDate: '',
                nation: '',
                register_type: ''
            }
            this.$refs.bindModel.resetFields()
        },
        reset() { },
        onSubmit() {
            this.$refs.bindModel.validate(async (valid) => {
                if (valid) {
                    // 收集表单数据 提交到服务端
                    const params = {
                        "req": {
                            senderCompany: '北京1234',
                            senderAddress: '西直门',
                            materialStyle: 2
                        }
                        // "req": this.bindModel
                    }
                    // 特殊字段处理
                    // params.req.content = this.bindModel.content + '123'
                    const res = await this.$API.sendRequest('saveOrUpdate', params)
                    if (res && res.success) {
                        // 关闭对话框
                        this.closeDialog()
                        this.$message.success('操作成功') // 保存成功弹框提示
                        // 保存成功刷新列表
                        this.GetFileList()
                    } else {
                        this.$message.error('获取数据失败')
                    }
                } else {
                    return false;
                }
            });
        },
        async GetReceiveDetail(receiveletterId) {
            const params = {
                "req": receiveletterId
            }
            const res = await this.$API.sendRequest('getReceiveDetail', params)
            if (res && res.success) {
                this.bindModel = res.data
            } else {
                this.$message.error('获取数据失败')
            }
        },
        senderNationalityChange() { }
    }
}
</script>

<style lang="scss" scoped>
.marginRight {
    :deep(label) {
        margin-right: 12px;
    }
}

.paginationStyle {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.borderLine {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.fontWeight {
    font-weight: 600;
}

:deep(.butItemColor .el-button) {
    background-color: #79bbff;
}
</style>