<template>
    <!-- 文书模版下载 -->
    <div>
        <div class="topBtnBox">
            <el-button type="info" @click="clickGomyCaseList">返回</el-button>
        </div>
        <div class="contextBox">
            <div
                v-for="(item, index) in docTemFileList"
                :key="index"
                class="contentItem"
            >
                <el-dropdown trigger="hover">
                    <div class="dropdownBox">
                        <span class="el-dropdown-link">
                            {{ index + 1 }}.
                            <span class="hrefText">{{ item.fileName }}</span>
                        </span>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                @click="previewClick(item)"
                                v-if="
                                    item.fileName.toLowerCase().endsWith('.pdf')
                                "
                                >预览</el-dropdown-item
                            >
                            <el-dropdown-item @click="uploadClick(item)"
                                >下载</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 文书模版的下载数组
            docTemFileList: []
        }
    },
    methods: {
        load() {
            this.getDocTemFile()
        },
        // 点击了返回
        clickGomyCaseList() {
            this.$emit('gomyCaseList')
        },

        async getDocTemFile() {
            let res = await this.$API.sendRequest('docTemFileList')
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.docTemFileList = res.data
        },

        // 预览
        async previewClick(item) {
            let param = {
                req: item.fileId
            }
            let res = await this.$API.sendRequest('downLoadDocTemFile', param)
            let blob = new Blob([res], { type: 'application/pdf' })
            let url = URL.createObjectURL(blob)
            window.open(url)
        },

        // 下载
        async uploadClick(item) {
            const filename = item.fileName
            let downEnum = this.$store.state.downEnum
            let fileType = filename.substring(filename.lastIndexOf('.'))
            let param = {
                req: item.fileId
            }
            let res = await this.$API.sendRequest('downLoadDocTemFile', param)
            for (let key in downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: downEnum[key] })
                    this.tableDataLoading = false
                    let url = window.URL.createObjectURL(blob)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = item.fileName
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
.contextBox {
    text-align: left;

    .hrefText {
        color: rgb(43, 73, 246);
        cursor: pointer;
        font-size: 16px;
    }
    .contentItem {
        margin: 20px;
    }
}

.topBtnBox {
    text-align: right;
}
</style>