<template>
    <div class="pdf_wrapper">
        <div v-if="query.sign" class="tool_bar">
            <el-button type="primary" @click="ShowSignDlg">盖大章</el-button>
            <el-button type="primary" @click="WebSave">保存</el-button>
            <el-button type="danger" @click="CloseTab">关闭</el-button>
        </div>
        <div class="main" v-html="PDF2018Str"></div>
    </div>
</template>

<script>
import { getStr } from "../../assets/js/iWebPDF2018.js";
import axiosConfig from "@/config/api";

// console.log("控件Str:" + getStr());
const PDF2018Str = getStr();
let addins = "";
let record_id = "1407921461311";
const path_root = "";
let iWebPDF2018 = "";

export default {
    name: "PDFPreview",
    created() {
        this.query = this.$route.query
    },
    mounted() {
        //初始化插件
        this.$nextTick(() => {
            iWebPDF2018 = document.getElementById("iWebPDF2018");
            console.log('===========nextTick.iWebPDF2018:' + iWebPDF2018);
            console.log('===========nextTick.iWebPDF2018.iWebPDFFun:' + iWebPDF2018.iWebPDFFun);
            addins = iWebPDF2018.iWebPDFFun;
            this.HideShowBar();
            this.WebOpen();
        });
    },
    data() {
        return {
            PDF2018Str,
            nClick: 1,
            PageNo: '',
            query: {},
        };
    },
    methods: {
        /*****************************************            文档操作               ************************************************/
        async GetPDF_T() {
            const res = await this.$API.sendRequest('GetPDF_T', {})
            return res.data
        },

        //在线打开文档
        async WebOpen() {
            console.log('=========在线打开文档==========')
            if (addins != null) {
                const t = await this.GetPDF_T()
                const query = `?attId=${this.query.attId}&id=${this.query.id}&t=${t}`
                addins.WebUrl = window.location.protocol + '//' + window.location.host + axiosConfig.apis.OpenPDF.url + query;
                addins.RECORDID = record_id;
                addins.FILENAME = this.query.filename;
                addins.FILETYPE = ".pdf";
                addins.USERNAME = "演示人";
                var ret = addins.WebOpen();
                if (!ret) {
                    addins.Alert("文档打开失败！");
                }
            }
        },

        //在线保存文档
        async WebSave() {
            console.log('=============保存文档===========')
            if (addins != null) {
                const t = await this.GetPDF_T()
                const c = this.SignatureCount()
                console.log('c', c)
                const query = `?attId=${this.query.attId}&id=${this.query.id}&t=${t}&signCount=${c}`
                addins.WebUrl = window.location.protocol + '//' + window.location.host + axiosConfig.apis.SavePDF.url + query;
                addins.RECORDID = record_id;
                addins.FILENAME = this.query.filename;
                addins.FILETYPE = ".pdf";
                addins.USERNAME = "演示人";
                var ret = addins.WebSave();
                if (!ret) {
                    addins.Alert("文档保存失败！");
                } else {
                    addins.Alert(`${this.query.filename} 保存成功！`);
                }
            }
        },
        //打开文档
        HttpClientOpen() {
            try {
                addins.WebUrl = path_root; //WebUrl:系统服务器路径，与服务器文件交互操作，如保存、打开文档，重要文件
                addins.FileType = ".pdf"; //FileType:文档类型  .PDF  .OFD
                iWebPDF2018.Documents.OpenFromURL(
                    path_root + "//Document//" + addins.FileName + addins.FileType
                );
            } catch (e) {
                addins.Alert(e.description);
            }
        },

        //HttpClient保存文档
        HttpClientSave() {
            var tempName = "test";
            var i = Math.round(Math.random() * 1000000);
            tempName = tempName + i;
            var mResuld = iWebPDF2018.HttpClient.UpLoadToServer(
                path_root +
                "servlet/iStylePDF?DocumentID=" +
                encodeURI(encodeURI(tempName))
            );
            if (mResuld == 200) {
                addins.Alert("提交文档成功!");
            } else {
                addins.Alert("提交文档失败!注：未加密");
            }
        },

        //打开URL文档
        WebOpenUrl() {
            if (addins != null) {
                addins.WebOpenUrlFile(
                    // "http://171.34.78.70:8899/KGBrowserEnt_iWebPDF2018/1001.pdf"
                    this.url
                );
            }
        },

        //获取页码信息
        GetCurrentPageNo() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            this.PageNo = iWebPDF2018.Documents.ActiveDocument.Views.ActiveView.PageNum;
            var nPageCount = iWebPDF2018.Documents.ActiveDocument.Pages.Count;
            addins.Alert("本文档共有" + nPageCount + "页，当前页是" + this.PageNo + "页");
        },

        //跳转到指定页
        GoToPage() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            if ("" == document.getElementById("pgText").value) {
                addins.Alert("请输入跳转的页码");
                return;
            }
            iWebPDF2018.Documents.ActiveDocument.Views.ActiveView.PageNum = document.getElementById("pgText").value;
            var info;
            if (
                this.PageNo > iWebPDF2018.Documents.ActiveDocument.Pages.Count || this.PageNo < 1
            ) {
                info = "页码错误,跳转失败！";
                alert(info);
            } else {
                info = "当前页是" + this.PageNo + "页";
                alert("跳转成功！");
            }
        },

        /*******************************************       工具栏操作     *****************************************/
        //添加工具栏按钮
        AddAppendTools() {
            var as = iWebPDF2018.Images.Add("D:/Test24x24.png", 10000); //设置自定义图片
            addins.AppendTools("10", "测试按钮一", as);
        },

        //谷歌下工具栏按钮触发事件
        // eslint-disable-next-line no-unused-vars
        OnToolsClick(vIndex, vCaption) {
            //alert('编号:'+vIndex+'\n\r'+'条目:'+vCaption+'\n\r'+'请根据这些信息编写按钮具体功能');
            if (vIndex == 10) {
                addins.Alert("测试按钮一事件！");
            }
        },

        //添加自定义菜单
        AddOnMenuClick() {
            addins.AppendMenu("10", "测试菜单一");
            addins.AppendMenu("11", "测试菜单二");
        },

        //谷歌下自定义菜单触发事件
        // eslint-disable-next-line no-unused-vars
        OnMenuClick(vIndex, vCaption) {
            //alert('编号:'+vIndex+'\n\r'+'条目:'+vCaption+'\n\r'+'请根据这些信息编写菜单具体功能');
            if (vIndex == 10) {
                addins.Alert("自定义菜单一事件");
            }
            if (vIndex == 11) {
                addins.Alert("自定义菜单二事件");
            }
        },

        //隐藏标准工具栏
        EnableShowTools(val) {
            addins.ShowTools = val;
        },

        //隐藏所有工具栏
        HideShowBar() {
            var nCount = iWebPDF2018.CommandBars.Count;
            this.nClick++;
            for (var i = 0; i < nCount; i++) {
                if (0 == this.nClick % 2) {
                    iWebPDF2018.CommandBars.Item(i).Visible = false;
                } else {
                    iWebPDF2018.CommandBars.Item(i).Visible = true;
                }
            }
        },

        //显示隐藏标签页
        ShowTabBarVisible() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            if (addins.ShowTabBarVisible == true) {
                addins.ShowTabBarVisible = false;
            } else {
                addins.ShowTabBarVisible = true;
            }
        },

        //显示隐藏工具栏打印按钮
        HideShowState() {
            if (addins.ShowState == 1) {
                addins.ShowState = 0;
            } else {
                addins.ShowState = 1;
            }
        },

        /*******************************************         批注操作          *******************************************/
        //添加文字批注
        AddComment() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            addins.UserName = "test";
            addins.AddComment(1, 100, 600, "这里是插入的附注内容");
        },

        //添加图片批注
        InsertImage() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            addins.UserName = "test";
            addins.InsertImage(1, 100, 200, 50, 50, "c:\\aa.jpg");
        },

        //添加文字水印
        AddWateMake() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            addins.WateMark(
                1,
                addins.PageCount,
                "水印内容",
                25,
                50,
                45,
                false,
                -1,
                -1
            );
        },

        //添加图片水印
        AddWateMakeImage() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            addins.InsertImageExt(1, 200, 500, 100, 100, "c:\\aa.jpg", 0, 0.5, 11);
        },

        //添加打字机文本
        AddFreeAnnot() {
            if (0 == iWebPDF2018.Documents.Count) {
                addins.Alert("没有已打开文档");
                return;
            }
            var annot = iWebPDF2018.Documents.ActiveDocument.Pages.Item(0).Annots.Add(
                2
            );
            annot.FromUserRect(100, 100 * 6, 100 * 2, 100 * 6);
            annot.Title = "RedHead";
            annot.FreeTextAnnot.BorderWidth = "-0.0001";
            annot.Contents = "金格科技";
            annot.UpdateAppearance();
            iWebPDF2018.Documents.ActiveDocument.Views.ActiveView.Refresh();
        },

        //禁止移动批注
        LockAnnots() {
            try {
                if (0 == iWebPDF2018.Documents.Count) {
                    alert("没有已打开文档");
                    return;
                }
                var nAnnot = iWebPDF2018.Documents.ActiveDocument.Pages.Item(0).Annots
                    .Count;
                for (var i = 0; i < nAnnot; i++) {
                    if (
                        iWebPDF2018.Documents.ActiveDocument.Pages.Item(0).Annots.item(i)
                            .Locked == false
                    ) {
                        iWebPDF2018.Documents.ActiveDocument.Pages.Item(0).Annots.item(
                            i
                        ).Locked = true;
                    } else {
                        iWebPDF2018.Documents.ActiveDocument.Pages.Item(0).Annots.item(
                            i
                        ).Locked = false;
                    }
                }
            } catch (e) {
                alert(e.description);
            }
        },

        //获取手写批注信息
        GetAnnotString() {
            try {
                if (0 == iWebPDF2018.Documents.Count) {
                    alert("没有已打开文档");
                    return;
                }
                var annotString = addins.GetAnnotString();
                addins.Alert(annotString);
            } catch (e) {
                alert(e.description);
            }
        },

        ShowSignDlg() {
            addins.ShowSignDlg(1)
        },
        CloseTab() {
            window.close()
        },
        SignatureCount() {
            return addins.SignatureCount();
        }
    },

    watch: {
        //监听

        "$store.state.home.option": function () {
            //你需要执行的代码
            // debugger;
            //初始化option
            var option = this.$store.state.home.option;
            // console.log(option);
            if (option == "1-1") {
                // console.log("1-1");
                this.WebOpen();
                this.$store.state.home.option = "";
            } else if (option == "1-2") {
                // console.log("1-2");
                this.WebSave();
                this.$store.state.home.option = "";
            }
            else if (option == "1-3") {
                // console.log("1-2");
                this.HttpClientOpen();
                this.$store.state.home.option = "";
            }
            else if (option == "1-4") {
                // console.log("1-2");
                this.HttpClientSave();
                this.$store.state.home.option = "";
            }
            else if (option == "1-5") {
                // console.log("1-2");
                this.WebOpenUrl();
                this.$store.state.home.option = "";
            }
            else if (option == "1-6") {
                // console.log("1-2");
                this.GetCurrentPageNo();
                this.$store.state.home.option = "";
            }
            else if (option == "1-7") {
                // console.log("1-2");
                this.GoToPage();
                this.$store.state.home.option = "";
            }
            else if (option == "2-1") {
                // console.log("1-2");
                this.AddAppendTools();
                this.$store.state.home.option = "";
            }
            else if (option == "2-2") {
                // console.log("1-2");
                this.AddOnMenuClick();
                this.$store.state.home.option = "";
            }
            else if (option == "2-3") {
                // console.log("1-2");
                this.EnableShowTools('1');
                this.$store.state.home.option = "";
            }
            else if (option == "2-4") {
                // console.log("1-2");
                this.EnableShowTools('0');
                this.$store.state.home.option = "";
            }
            else if (option == "2-5") {
                // console.log("1-2");
                this.HideShowBar();
                this.$store.state.home.option = "";
            }
            else if (option == "2-6") {
                // console.log("1-2");
                this.ShowTabBarVisible();
                this.$store.state.home.option = "";
            }
            else if (option == "2-7") {
                // console.log("1-2");
                this.HideShowState();
                this.$store.state.home.option = "";
            }
            else if (option == "3-1") {
                // console.log("1-2");
                this.AddComment();
                this.$store.state.home.option = "";
            }
            else if (option == "3-2") {
                // console.log("1-2");
                this.InsertImage();
                this.$store.state.home.option = "";
            }
            else if (option == "3-3") {
                // console.log("1-2");
                this.AddWateMake();
                this.$store.state.home.option = "";
            }
            else if (option == "3-4") {
                // console.log("1-2");
                this.AddWateMakeImage();
                this.$store.state.home.option = "";
            }
            else if (option == "3-5") {
                // console.log("1-2");
                this.AddFreeAnnot();
                this.$store.state.home.option = "";
            }
            else if (option == "3-6") {
                // console.log("1-2");
                this.LockAnnots();
                this.$store.state.home.option = "";
            }
            else if (option == "3-7") {
                // console.log("1-2");
                this.GetAnnotString();
                this.$store.state.home.option = "";
            }
        },
    },
}
</script>

<style scoped>
.main {
    height: 100vh;
}

.tool_bar {
    display: flex;
    gap: 15px;
    padding: 5px;
}
</style>
