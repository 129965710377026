<template>
    <div v-loading="submitLoading" style="width: 99%">
        <el-form ref="upload_form" label-width="70px">
            <el-form-item prop="realName" :label="$t('msg.FileDescription')">
                <el-input
                    v-model="title"
                    size="default"
                    :placeholder="$t('msg.PleaseentertheDescriptionoftheFile')"
                    style="width: 400px"
                    id="realName"
                ></el-input>
            </el-form-item>
            <el-form-item prop="realName" :label="$t('msg.SelectFile')">
                <el-upload
                    style="width: 400px; text-align: left"
                    action="xxx"
                    :http-request="() => {}"
                    multiple
                    :file-list="file_list"
                >
                    <el-button size="default">{{$t('msg.SelectFile')}}</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <div style="text-align: right; margin-bottom: 10px; margin-right: 10px">
            <el-button
                color="#4578FF"
                type="primary"
                size="default"
                @click="OpenUpload"
                >{{$t('msg.finder_upload')}}</el-button
            >
        </div>
        <!-- 进度条  -->
        <!-- 上传文件进度  percentageNumType-->
        <el-row
            v-if="percentageNumType"
            style="display: flex; align-items: center"
        >
            <p style="text-align: right; padding-right: 10px">{{$t('msg.Uploadfileprogress')}}</p>

            <div class="progressBox">
                <el-progress :percentage="percentageNum" style="width: 80%" />
            </div>
        </el-row>

        <!-- 表格   -->
        <el-table
            v-loading="loading"
            :data="list"
            border
            :stripe="true"
            style="width: 100%"
        >
            <el-table-column
                :label="$t('msg.Party')"
                prop="submitUserName"
                align="center"
            >
            </el-table-column>
            <el-table-column :label="$t('msg.FileDescription')" prop="remark" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.Dateofsubmission')" prop="subDate" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.FileName')" prop="attName" align="center">
            </el-table-column>
            <el-table-column :label="$t('msg.OperationLog')" prop="operateDesc" align="center">
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            style="margin-top: 10px; display: flex; justify-content: center"
            small="small"
            @size-change="paginationSizeChange"
            @current-change="paginationCurrentChange"
            :current-page="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size="pagination.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
        >
        </el-pagination>
        <!-- 上传文件对话框 -->
        <el-dialog
            v-model="uploadVisible"
            :title="$t('msg.finder_upload')"
            width="400px"
        >
            <el-upload
                action="xxx"
                :http-request="() => {}"
                drag
                multiple
                :file-list="file_list"
            >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                </div>
            </el-upload>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="CloseUploadDialog">{{
                        $t('msg.finder_cancel')
                    }}</el-button>
                    <el-button type="primary" @click="ConfirmUpload">{{
                        $t('msg.finder_confirm')
                    }}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'MaterialUpload',
    data() {
        return {
            uploadVisible: false,
            list: [],
            loading: false,
            pagination: {
                page: 1,
                pages: 0,
                limit: 10,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            file_list: [],
            title: '',
            case_id: '',
            submitLoading: false,
            // 进度条进度
            percentageNum: 0,
            // 进度条是否展示
            percentageNumType: false
        }
    },
    methods: {
        load(case_id) {
            this.clearData()
            this.case_id = case_id
            this.GetFileList()
        },
        paginationSizeChange(val) {
            this.pagination.limit = val
            this.GetFileList()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.GetFileList()
        },
        OpenUpload() {
            // this.uploadVisible = true
            if (this.file_list.length === 0) {
                this.$message.error('您还没有选择文件')
                return
            }
            this.ConfirmUpload()
        },
        CloseUploadDialog() {
            this.uploadVisible = false
            this.list = []
        },
        async ConfirmUpload() {
            // 构造form表单
            // this.submitLoading = true
            let form = new FormData()
            form.append('req.caseId', this.case_id)
            form.append('req.cmd', 'upload')
            form.append('req.args.fromUploadList', true)
            form.append('req.args.target', '')
            form.append('req.args.title', this.title)
            this.file_list.forEach((item) => {
                form.append('req.args.upload', item.raw)
            })

            let myThis = this
            this.percentageNumType = true
            let res = await axios({
                url: '/api/filemanager/connector',
                method: 'POST',
                data: form,
                headers: {
                    c_Auth_token: localStorage.getItem('c_Auth_token')
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )

                    if (percentCompleted < 100) {
                        myThis.percentageNum = percentCompleted
                    } else {
                        myThis.percentageNum = 99
                    }
                }
            })
            if (!res.data.success) {
                this.percentageNumType = false
                this.percentageNum = 0
                this.$message.error(res.data.msg)
            } else {
                this.file_list = []
                this.$message.success('上传成功')
                this.percentageNumType = false
                this.percentageNum = 0
                await this.GetFileList()
            }
            this.submitLoading = false
        },

        // 清空表格
        clearData() {
            this.file_list = []
            this.percentageNumType = false
            this.percentageNum = 0
        },
        async GetFileList() {
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        caseId: this.case_id
                        // "remark": "a"
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('SubmitList', param)
            this.loading = false
            if (res.success) {
                this.list = res.data.records || []
                this.pagination.total = res.data.total
            } else {
                this.$message.error(res.msg)
            }
        },
        OpenFolder() {
            window.open(window.location.href + `finder?case_id=${this.case_id}`)
        }
    }
}
</script>

<style scoped lang='scss'>
.drawerContainerBox {
    text-align: left;
    font-size: 16px;

    span {
        font-weight: 600;
        white-space: nowrap;
        display: inline-block;
    }
    :deep(.el-col-12) {
        display: flex;
    }

    i {
        font-style: normal;
    }
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 80%;
}
.progressTextColor {
    color: red;
}
</style>
