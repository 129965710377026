<template>
	<div class="table_wrapper">
		<!-- 除了立案以外的表格 -->
		<el-table v-loading="loading" v-if="searchModel.sign !== 3" :data="list" border :stripe="true"
			style="width: 99%">
			<!-- 序号 -->
			<el-table-column :label="$t('msg.S_N')" prop="caseNo" type="index" width="60px">
			</el-table-column>
			<!-- 案件编号 -->
			<el-table-column :label="$t('msg.case')" prop="caseNo" align="center">
				<template #default="scope">
					<div>
						{{ scope.row.caseNo || '暂无案件编号' }}
					</div>
				</template>
			</el-table-column>

			<!-- 案件名称 -->
			<el-table-column :label="$t('msg.case_name')" prop="caseName" align="center">
				<template v-slot="scope">
					<el-link type="primary" @click="GoFinder(scope.row)"
						v-if="scope.row.caseName">{{ scope.row.caseName }}</el-link>
					<span v-else> 暂无案件名称</span>
				</template>
			</el-table-column>

			<!-- 申请方 -->
			<el-table-column :label="$t('msg.Claimant')" prop="caseApplicant" align="center">
			</el-table-column>
			<!-- 被申请方 -->
			<el-table-column :label="$t('msg.Respondent')" prop="caseRequested" align="center">
			</el-table-column>
			<!-- 受理时间 -->
			<el-table-column :label="$t('msg.ProcessingTime')" prop="caseAcceptanceDate" align="center">
			</el-table-column>

			<!-- 办案秘书 -->
			<el-table-column :label="$t('msg.CaseSecretary')" prop="caseHandle" align="center">
			</el-table-column>
			<!-- 办案秘书电话 -->
			<el-table-column :label="$t('msg.TelephoneNumberofSecretary')" prop="caseHandlePhone" align="center">
			</el-table-column>
			<!-- 仲裁员 -->
			<el-table-column :label="$t('msg.Arbitrator')" prop="caseArbitrator" align="center">
			</el-table-column>
			<!-- 案件状态 -->
			<el-table-column :label="$t('msg.case_status')" prop="caseStateStr" align="center">
				<template #default="scope">
					<el-tag type="info" v-if="
                            scope.row.caseState === 10 ||
                            scope.row.caseState === 1
                        ">{{ scope.row.caseStateStr }}</el-tag>
					<el-tag type="danger" v-else-if="
                            scope.row.caseState === 3 ||
                            scope.row.caseState === 6
                        ">{{ scope.row.caseStateStr }}</el-tag>
					<el-tag type="success" v-else>{{
                        scope.row.caseStateStr
                    }}</el-tag>
				</template>
			</el-table-column>
			<!-- 操作 -->
			<el-table-column :label="$t('msg.Operate')" prop="caseNo" align="center">
				<template #default="scope">
					<div class="tabListBtnBox">
						<el-button size="small" type="primary" @click="GoFinder(scope.row)">
							<!-- 查看 -->
							{{ $t('msg.View') }}
						</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<!-- 立案的表格 -->
		<el-table v-loading="loading" v-else :data="list" border :stripe="true" style="width: 100%">
			<!-- 序号 -->
			<el-table-column :label="$t('msg.S_N')" prop="caseNo" type="index" width="60px">
			</el-table-column>

			<!-- 案件编号 -->
			<el-table-column :label="$t('msg.Application_Number')" prop="caseNo" align="center" width="130px">
				<template #default="scope">
					<div>
						{{
                            scope.row.caseState === 1 ? '' : scope.row.receiveNo
                        }}
					</div>
				</template>
			</el-table-column>

			<!-- 申请方 -->
			<el-table-column :label="$t('msg.Claimant')" prop="caseApplicant" align="center">
			</el-table-column>

			<!-- 被申请方 -->
			<el-table-column :label="$t('msg.Respondent')" prop="caseRequested" align="center">
			</el-table-column>

			<!-- 立案秘书 -->
			<el-table-column :label="$t('msg.Secretary')" prop="caseRegister" align="center" width="120px">
			</el-table-column>

			<!-- 立案秘书 -->
			<el-table-column :label="$t('msg.TelephoneNumberofSecretary')" prop="caseRegisterPhone" align="center">
			</el-table-column>

			<!-- 仲裁员 -->
			<!-- <el-table-column
                label="仲裁员"
                prop="caseArbitrator"
                align="center"
            >
            </el-table-column> -->
			<!-- 案件状态 -->
			<el-table-column :label="$t('msg.case_status')" prop="caseStateStr" align="center">
				<template #default="scope">
					<el-tag type="info" v-if="
                            scope.row.caseState === 10 ||
                            scope.row.caseState === 1
                        ">{{ scope.row.caseStateStr }}</el-tag>
					<el-tag type="danger" v-else-if="
                            scope.row.caseState === 3 ||
                            scope.row.caseState === 6
                        ">{{ scope.row.caseStateStr }}</el-tag>
					<el-tag type="success" v-else>{{
                        scope.row.caseStateStr
                    }}</el-tag>
				</template>
			</el-table-column>
			<!-- pdf -->
			<el-table-column :label="$t('msg.GenerateaPDFArbitrationApplication')" width="120px">
				<template #default="scope">
					<div class="dropdownLinkBox" v-if="
                            scope.row.caseState === 7 ||
                            scope.row.caseState === 8
                        ">
						<div v-if="scope.row.pdfFlag !== 8">
							<el-button @click="clickGenerate(scope.row)">{{$t('msg.GeneratePDF')}}</el-button>
						</div>
						<div v-else>
							<el-dropdown>
								<span class="el-dropdown-link">
									<span>{{$t('msg.GenerateaPDFArbitrationApplication')}} </span>
									<el-icon class="el-icon--right">
										<arrow-down />
									</el-icon>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item @click="viewPdf(scope.row)">{{$t('msg.Preview')}}</el-dropdown-item>
										<el-dropdown-item @click="downPdf(scope.row)">{{$t('msg.Download')}}</el-dropdown-item>
									</el-dropdown-menu>
								</template>
							</el-dropdown>
						</div>
					</div>
					<div v-else>
						<el-button disabled>{{$t('msg.GeneratePDF')}}</el-button>
					</div>
				</template>
			</el-table-column>
			<!-- 操作 -->
			<el-table-column :label="$t('msg.Operate')" prop="caseNo" align="center">
				<template #default="scope">
					<div class="tabListBtnBox">
						<el-button size="small" type="primary" @click="goRegisterCase(scope.row)">
							<!-- 查看 -->
							{{ $t('msg.View') }}
						</el-button>
						<el-button size="small" type="danger" plain @click="clickReason(scope.row)" v-if="
                                scope.row.caseState !== 1 &&
                                scope.row.caseState !== 10
                            ">
							<!-- 撤案 -->
							{{ $t('msg.WithdrawACase') }}
						</el-button>
						<el-button v-if="scope.row.payFeeId" size="small" type="primary"
							@click="downloadReceipt(scope.row)">
							<!-- 收据 -->
							{{ $t('msg.Receipt') }}
						</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页 -->
		<el-pagination style="margin-top: 10px; display: flex; justify-content: center" small="small"
			@size-change="paginationSizeChange" @current-change="paginationCurrentChange"
			:current-page="pagination.page" :page-sizes="pagination.sizes" layout="total, prev, pager, next, jumper"
			:total="pagination.total">
		</el-pagination>

		<!-- 送达弹窗   开始  -->
		<el-dialog :title="$t('msg.ReasonForWithdraw')" v-model="clickReasonShow" width="50%" center>
			<template #title>
				<div class="dialogTitle">{{$t('msg.ReasonForWithdraw')}}</div>
			</template>
			<div>
				<el-row>
					<el-col :span="2">
						<p style="font-size: 16px" class="requireIconBox">
							{{$t('msg.Reason')}}：
							<span>
								<svg class="icon" aria-hidden="true">
									<use xlink:href="#icon-bitian"></use>
								</svg></span>
						</p>
					</el-col>
					<el-col :span="20"><el-input type="textarea" v-model="reasonText"></el-input></el-col>
				</el-row>
			</div>
			<template #footer>
				<div>
					<el-button type="danger" @click="clickReasonSubmit">{{$t('msg.ConfirmtheDismissaloftheCase')}}</el-button>
				</div>
			</template>
		</el-dialog>

		<!-- 送达弹窗   结束  -->
	</div>
</template>

<script>
	export default {
		name: 'TabList',
		data() {
			return {
				list: [],
				pagination: {
					page: 1,
					pages: 0,
					limit: 20,
					sizes: [10, 20, 50, 100],
					total: 0
				},
				loading: false,
				searchModel: {
					sign: '',
					totalCount: 0
				}, // 不同的案件状态
				statusMap: {
					1: '提交申请',
					2: '立案审查',
					3: '立案审批',
					4: '指定秘书',
					5: '仲裁通知',
					6: '组庭',
					7: '开庭',
					8: '裁决',
					9: '结案',
					10: '入库'
				},
				// 送达弹窗窗
				serviceDialogShow: false,
				// 详细信息
				info: {},
				// 撤案理由弹窗
				clickReasonShow: false,
				// 撤案理由
				reasonText: ''
			}
		},
		methods: {
			load(sign, totalCount) {
				// 1.在办 2.已结 0.全部
				this.searchModel.sign = sign
				this.searchModel.totalCount = totalCount
				this.GetCaseList()
			},
			paginationSizeChange(val) {
				this.pagination.limit = val
				this.GetCaseList()
			},
			paginationCurrentChange(val) {
				this.pagination.page = val
				this.GetCaseList()
			},
			doSearch() {
				this.pagination.page = 1
				this.GetCaseList()
			},
			reset() {
				this.searchModel = {
					word: ''
				}
			},
			async GetCaseList() {
				this.loading = true
				const param = {
					req: {
						current: this.pagination.page,
						size: this.pagination.limit,
						q: {
							caseId: null,
							caseNo: null,
							state: null,
							sign: this.searchModel.sign,
							totalCount: this.searchModel.totalCount ?
								this.searchModel.totalCount :
								0
						}
					}
				}
				const res = await this.$API.sendRequest('CaseList', param)
				if (res) {
					this.list = res.data.records
					this.pagination.total = res.data.total
					this.loading = false
				}
			},
			async GoFinder(row) {
				this.loading = true
				if (this.$store.state.loginInfo.role === 1) {
					let param = {
						req: {
							caseId: row.caseId,
							userId: row.userId,
							regUserId: row.regUserId
						}
					}
					let res = await this.$API.sendRequest(
						'UnifiedFileTransfer',
						param
					)
					this.loading = false
					if (res.success) {
						this.$listener.$emit('case_change', row) // 触发MainContent case_change
					} else {
						this.$message.error('同步文件失败，请重新尝试！')
					}
				} else {
					this.$listener.$emit('case_change', row) // 触发MainContent case_change
				}
			},

			// 通过有caseId去申请详情页
			goRegisterCase(row) {
				this.$emit('goRegisterCase', row)
			},

			// 撤案理由弹窗开启
			async clickReason(row) {
				this.reasonText = ''
				this.clickReasonShow = true
				this.info = row
			},
			// 点击确认撤案
			async clickReasonSubmit() {
				if (!this.reasonText) {
					return this.$message.info('撤案理由为必填！')
				}
				let param = {
					req: {
						caseId: this.info.caseId,
						receiveNo: this.info.receiveNo,
						reason: this.reasonText
					}
				}
				let res = await this.$API.sendRequest('WithdrawCase', param)
				if (!res.success) {
					this.clickReasonShow = false
					return this.$message.error(res.msg)
				}
				this.$message.success(res.msg)
				this.GetCaseList()
				this.clickReasonShow = false
			},

			// 预览pdf
			async viewPdf(row) {
				let param = {
					req: row.caseId
				}
				let res = await this.$API.sendRequest('GeneratePdf', param)
				let blob
				blob = new Blob([res], {
					type: 'application/pdf'
				})
				let url = window.URL.createObjectURL(blob)
				window.open(url)
			},

			// 下载pdf
			async downPdf(row) {
				let param = {
					req: row.caseId
				}
				let res = await this.$API.sendRequest('GeneratePdf', param)
				let blob = new Blob([res], {
					type: 'application/pdf'
				})
				let url = window.URL.createObjectURL(blob)
				let link = document.createElement('a')
				link.href = url
				link.download = '申请书'
				link.click()
				window.URL.revokeObjectURL(link.href)
			},

			// 转换字符串的方法去去掉标签<>传入字符串   且将&nbsp;变成''空字符串
			checkStrLabel(str) {
				let str1 = str
					.replace(/<\/p>/g, '</p>(=￥=)')
					.replace(/<[^>]+>/g, '')
				str1 = str1.replaceAll('&nbsp;', '')
				return str1
			},

			// 点击生成
			async clickGenerate(row) {
				let param = {
					req: row.caseId
				}
				await this.$API.sendRequest('GeneratePdf', param)
				this.load(3, this.searchModel.totalCount)
			},

			// 下载收据
			async downloadReceipt(row) {
				this.loading = true
				let param = {
					req: row.payFeeId
				}
				let res = await this.$API.sendRequest('DownloadReceiptPdf', param)
				let blob = new Blob([res], {
					type: 'application/pdf'
				})
				this.tableDataLoading = false
				let url = window.URL.createObjectURL(blob)
				let link = document.createElement('a')
				link.href = url
				link.download = '电子收据'
				link.click()
				window.URL.revokeObjectURL(link.href)
				this.loading = false
			}
		}
	}
</script>

<style scoped lang=scss>
	.table_wrapper {
		padding-bottom: 20px;
	}

	.dialogTitle {
		text-align: left;
		font-size: 20px;
		font-weight: 500;
	}

	.dialogContent {
		padding-left: 2em;
		text-align: left;

		button {
			font-size: 16px;
		}

		span {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.requireIconBox {
		position: relative;

		span {
			position: absolute;
			top: -22%;
			right: 18%;
		}
	}

	.tabListBtnBox {
		display: flex;
	}

	.dropdownLinkBox {
		.el-dropdown-link {
			color: #409eff;
		}
	}
</style>